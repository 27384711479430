import { placeholder } from '@babel/types';
import React, { useEffect, useRef, useState } from 'react';
import Icons from '../../icons/icons';
import { InputStyled, InputStyledDiv, InputStyledIcon, Inputstring, ErrorMessage } from "./inputStringStyles"
import AppData from '../../appData';
interface propsif {
    regex: any
    shape?: string
    spacing?: string
    errorMessage?: string
    stroke?: string
    cancel?: boolean
    fill?: string
    IconName?: string
    IconSize?: string
    font?: string
    Value?: string
    icon?: boolean
    iconcolor?: string
    disabled?: boolean
    placeholder?: string
    getValue?: (text: string) => void;
    clickIcon?: (text: string) => void;
    onKeyPress?: any
    fonts: ""
    Error?: boolean;
    content: any;
    Enter?: boolean
}
export default function InputString({ onKeyPress,Enter = false, shape = 'hug', Error, cancel, content, iconcolor, placeholder, font = 'normal', icon, spacing = 'normal', regex, errorMessage = '', stroke = 'normal', fill = 'none', IconName = '', IconSize = '14px', Value, disabled = false, getValue = (name: string) => { }, clickIcon = (name: string) => { } }: propsif) {
    const [value, setvalue] = useState(content)
    const [regexError,setRegexError]=useState<boolean>()

    useEffect(() => { 
        setvalue(content)
    },[content])
    // validation part
    // var inputRule = regex
    // const myRegEx = new RegExp(inputRule);
    // const error = myRegEx.test(value)
    // console.log('error', errorMessage, Error)
    // const ErrorHandler = () => (error === false ? setRegexError(true):setRegexError(false))

    // validation part
    const ErrorHandler = (v:any)=>{
        var inputRule=regex
        const myRegex = new RegExp(inputRule);
        const error = myRegex.test(v);
        console.log('value',v)
        console.log('reg erorrrr',error)
        if(error === false){
            setRegexError(true)
        }
        else{
            setRegexError(false)
        }
        
    }

    // value handling part
    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setvalue(e.target.value);
        getValue(e.target.value)
        ErrorHandler(e.target.value)
    }
    console.log(value)

    const handleSubmit = (e: any) => {
        e.preventDefault();
        clickIcon(value)
        // or you can send to backend
    };
    return (
        <Inputstring shape={shape} disabled={disabled}>
            <InputStyledDiv stroke={stroke} shape={shape} fill={fill}>
                {!Enter && <InputStyled disabled={disabled} shape={shape} placeholder={placeholder} spacing={spacing} font={font} value={value} onChange={onValueChange} onKeyPress={onKeyPress} ></InputStyled>}
                {icon && <InputStyledIcon shape={shape} fill={fill} spacing={'none'} onClick={() => { clickIcon(value) }} ><Icons iconName={IconName} size={IconSize} stroke={iconcolor} /></InputStyledIcon>}
                {cancel && <InputStyledIcon shape={shape} fill={fill} spacing={spacing} onClick={() => { setvalue('') }}><Icons iconName={'cancel'} size={IconSize} /></InputStyledIcon>}
                {Enter && <form style={{width:"90%"}} onSubmit={handleSubmit}>
                    <InputStyled disabled={disabled} shape={shape} placeholder={placeholder} spacing={spacing} font={font} value={value} onChange={onValueChange} onKeyPress={onKeyPress} ></InputStyled>
                    <button style={{ display: 'none' }} type="submit" ></button></form>}
            </InputStyledDiv>
            {regexError && value !=="" &&<ErrorMessage font={font}>Invalid Format</ErrorMessage>}
            {Error && <ErrorMessage font={font}> {errorMessage}</ErrorMessage>}
        </Inputstring>
    )
}
