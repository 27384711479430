
import React, { useRef } from 'react';
import Component from './component';
import { ComponentType, ComponentLibrary, SizeIF, PointIF } from './componentLibrary'
import Net from './net'
import { RestServer } from '../../REST/axios';
import { ErrorIF } from '../../appTypes';
import AppData from '../../appData';
// import { selectComp } from '../../appComponents/screens/functionalScreen/functionalScreen';

export interface PlaceIF {
    type: ComponentType,
    name: string,
    scale: number | SizeIF,
    rotation: number,
    position: PointIF,
    label_offset: PointIF,
    label_rotation: number
    label: string,
    port_size: number,
    on_click: (type: string, id: string) => void,
    fields: any,
    structure: string,
    roles: string[],
    clickable?: string,
    functions?: string[],
    component_name?: string
}

export interface RouteIF {
    start: { name: string, port: string },
    end: { name: string, port: string },
    bend: "VERTICAL" | "HORIZONTAL",
    bend_location: number,
    marker_end?: string,
    dashed?: string
}
const getPortFromCenter = (offset: number, position: "left" | "bottom" | "right" | "top", width: number, height: number) => {
    switch (position) {
        case ("left"):
            return [-width / 2, height / 2 - offset]
        case ("right"):
            return [width / 2, height / 2 - offset]
        case ("top"):
            return [offset - width / 2, height / 2]
        case ("bottom"):
            return [offset - width / 2, -height / 2]
    }
}
const getPortCoordinates = (id: string, port: string, blockDiagramData: BlockdiagramIF) => {
    const _component = blockDiagramData.place.find(item => item.name == id) as PlaceIF
    const [type, scale, rotation, position, port_size] = [_component.type, _component.scale, _component.rotation, _component.position, _component.port_size]
    const { size, center } = ComponentLibrary[type].details
    const ports = ComponentLibrary[type].ports
    const { offset: portOffset, position: portPosition } = ports[port]
    const [xc, yc] = getPortFromCenter(portOffset, portPosition, size.width, size.height)
    //console.log(`id: ${id} port: ${port}`)
    //console.log(`xc: ${xc} yc: ${yc}`)
    const theta = Math.atan2(yc, xc) - rotation * (Math.PI / 180)
    const radius = Math.sqrt(xc * xc + yc * yc)
    //console.log(`theta: ${theta} radius: ${radius}`)
    if (typeof scale === "number") {
        var xr = position.x + scale * (center.x + radius * Math.cos(theta));
        var yr = position.y + scale * (center.y - radius * Math.sin(theta));
    }
    else {
        var xr = position.x + scale.width * (center.x + radius * Math.cos(theta));
        var yr = position.y + scale.height * (center.y - radius * Math.sin(theta));
    }
    //console.log(`xr: ${xr} yr: ${yr}`)
    return { x: xr, y: yr }
}

export interface BlockdiagramIF {
    "size": SizeIF;
    "style": {
        compStyle: string,
        boundaryStyle: string,
        labelStyle: string,
        portStyle: string,
        lineStyle: string,
        nonActiveStyle: string,
        activeStyle: string,
        nonActiveRecStyle: string
    },
    "place": PlaceIF[],
    "route": RouteIF[],
    "fieldState"?: any,
    onClick:(name:string,label:string,clickable:any,component_name:any)=> void
}
function clickPlace(clickValue:any,props:any,name:any){
    if((name == 'rec0') && (clickValue == 'NO')){
        return props.style.nonActiveRecStyle
    }
    if (clickValue == "NO"){
        return props.style.nonActiveStyle
    }
    else
        return props.style.compStyle
}
function Blockdiagram(props: BlockdiagramIF) {
    return (
        <svg height={props.size.height} width={props.size.width} >
            {props.place.map(
                thisComp => {
                    return (<Component type={thisComp.type} id={thisComp.name} scale={thisComp.scale} rotation={thisComp.rotation} label_offset={thisComp.label_offset} labelRotation={thisComp.label_rotation} position={thisComp.position}
                        compStyle={clickPlace(thisComp.clickable,props,thisComp.name)} boundaryStyle={props.style.boundaryStyle} labelStyle={props.style.labelStyle} portStyle={props.style.portStyle}
                        port_size={thisComp.port_size} label={thisComp.label} functions={thisComp.functions} onClick={() => {props.onClick(thisComp.name,thisComp.label,thisComp.clickable,thisComp.component_name) }} nonActiveStyle={props.style.nonActiveStyle} />)
                })}
            {props.route.map(
                thisLine => {
                    return (<Net dashed = {thisLine.dashed} start={getPortCoordinates(thisLine.start.name, thisLine.start.port, props)} end={getPortCoordinates(thisLine.end.name, thisLine.end.port, props)} marker_end={thisLine.marker_end}                        bend={thisLine.bend} bend_location={thisLine.bend_location} style={props.style.lineStyle} />)
                }
            )}
        </svg>
    )
}

export default Blockdiagram

