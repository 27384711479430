
import React, { useState } from "react";
import GridContainer from "../../../components/gridContainer/gridContainer";
import Icons from "../../../icons/icons";
import FlexBox from "../../../components/flexBox/flexBox";
import { Button } from "../../../components/button/button";
import AppTable from "../../appTable/appTable";
import DeviceTable from "./deviceTable";
import AppData from "../../../appData";
import LocalTable from "./localTable";
import configJson from "../../../config.json"
function DeviceScreen() {
    const[local,setlocal]=useState(true)
    const[deviceData,setDeviceData]=useState(AppData.device.data.local)
    const columns=(configJson.WEB_ONLY===0)?[{width:"15vw", toolbar:false, header:"Base Part"},
    {width:"20vw", toolbar:false, header:"Status"},
    {width:"12vw", toolbar:false, header:"Version"},
    {width:"12vw", toolbar:false, header:"Silicon revision"},
    {width:"10vw", toolbar:false, header:"Date created"},
    {width:"22vw", toolbar:false, header:""}]:
    [{width:"25vw", toolbar:false, header:"Base Part"},
    {width:"15vw", toolbar:false, header:"Version"},
    {width:"17vw", toolbar:false, header:"Silicon revision"},
    {width:"12vw", toolbar:false, header:"Date created"},
    {width:"22vw", toolbar:false, header:""}]

    const localcolumns=[{width:"20vw", toolbar:false, header:"Base Part"}, 
    {width:"20vw", toolbar:false, header:"Status"},
    {width:"15vw", toolbar:false, header:"Version"},
    {width:"10vw", toolbar:false, header:"Silicon revision"},
    {width:"25vw", toolbar:false, header:"Date created"},]

    return (
        <GridContainer shape='configarationMain' layout='configarationMain' >
            <FlexBox gridName='b' shape="hug" layout='vertical-top-left' spacing="remote">
                <div style={{ width: "213px", height: "40px", display: "flex", gap: "7px",padding:'0px',margin:"0px" }}><p style={{ fontWeight: "500", fontSize: "26px",margin:'0px',padding:'0px' }}>Devices</p><p style={{ fontWeight: "700", fontSize: "26px", color: "#888888",margin:'0px',padding:'0px' }}>{(configJson.WEB_ONLY===0)?AppData.device.data.local.length+AppData.device.data.remote.length:AppData.device.data.remote.length}</p></div>
                <FlexBox shape='hug' spacing="devicetableouter2" layout='vertical-top-left'>
                    <FlexBox shape="hug" layout="vertical-top-left" spacing="devicetablebutton">
                        {(configJson.WEB_ONLY===0)&&<FlexBox shape="hug" layout="horizontal-center-left" spacing="none">
                            <Button fill={(local===true)?'local':'remote'} spacing="none" shape="localButton" onClick={()=>{return setlocal(true)}}>
                                <Icons iconName={"monitor"} size={"14px"}  stroke="white"></Icons>
                                <p style={{margin:'0px',fontSize:'14px',fontWeight:400,fontStyle:"normal",color:'white'}}>Local {AppData.device.data.local.length}</p>
                             </Button>
                            <Button fill={(local===false)?'local':'remote'} spacing="none" shape="remoteButton" onClick={()=>{return setlocal(false)}}> 
                                <Icons iconName={"cloud"} size={"18px"}  stroke="white" fill="white"></Icons>
                                <p style={{height:'21px',width:'auto', margin:'0px',display:'flex',fontSize:'14px',fontWeight:400,fontStyle:"normal",color:'white',alignItems:'center',justifyContent:"center"}}>Remote {(navigator.onLine)?AppData.device.data.remote.length:0}</p>
                            </Button>
                        </FlexBox>}
                        <FlexBox shape="hug" spacing="none" layout="vertical-top-left">
                                {(configJson.WEB_ONLY===0)?(local===true)?<LocalTable data={deviceData} shape='local' fill="table1" stroke="rowOnly" Columns={localcolumns}/>:<DeviceTable data={(navigator.onLine)?AppData.device.data.remote:[]} onSync={setDeviceData} shape='remote' fill="table1" stroke="rowOnly" Columns={columns}/>:
                                <DeviceTable data={(navigator.onLine)?AppData.device.data.remote:[]} onSync={setDeviceData} shape='remote' fill="table1" stroke="rowOnly" Columns={columns}/>}
                        </FlexBox>
                     </FlexBox>
                </FlexBox>
            </FlexBox>
        </GridContainer>
    )
}
export default DeviceScreen