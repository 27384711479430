
// interface PropsIF {
//     data: { name: string, icon: string, onclick: () => void }[]
//     onClick?: (itemName: string) => void;

import { useState } from "react"
import { ThemeProvider } from "styled-components"
import AppData from "../../../appData"
import { Button } from "../../../components/button/button"
import { DataGrid } from "../../../components/dataGrid/dataGrid"
import { Menu } from "../../../components/menu/menu"
import PopUpForm from "../../../components/popupForm/popupForm"
import Icons from "../../../icons/icons"
import PrimaryTheme from "../../../Themes/primaryTheme"
import { generateStates, getBlockDiagramData, initializeConfig, syncConfig } from "./configurationEvents"
import Message from "../../../components/messages/messages"
import { convertUTCDateToLocalDate } from "../configurationScreen/configLocalTable"
import DialogBox from "../../../components/dialogBox/dialogBox"
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner"
import { getBlockFunctions, getDevicePropertiesByName } from "../blockDiagramScreen/blockDiagramEvents"
import PopupPdf from "../../../components/popupForm/popupPdf"
import configJson from "../../../config.json"



// }
interface PropsIF {
	data: any[]
	Columns: any[]
	shape?: string
	fill?: string
	stroke?: string
	recentData: any
	onSubmitDuplicate: (formData: { id: string, value: string }[]) => void
	onSelectRow: (row: any) => void
	onSync: any
	onclickConfig: (active: boolean) => void
	OnclickViewAddendum?:any
	handleCopy: any
	pdfHandle?:any
	hide?:any
	onSelectSetMessage?: any
}

function ConfigRemoteTable({ onSelectSetMessage, data, shape = 'normal', pdfHandle,handleCopy,fill = 'normal',OnclickViewAddendum, hide,stroke = 'normal', recentData, Columns, onSubmitDuplicate, onSelectRow, onSync, onclickConfig }: PropsIF) {
	const [show, setShow] = useState(false);
	const [selectedRow, setSelectedrow] = useState();
	const [active, setactive] = useState(false)
	const [syncSelect, setSyncSelect] = useState(false);
	const [message, setMessage] = useState()
	const [visible, setVisible] = useState(false)
	const [dialogActive, setDialogActive] = useState(false)
	const [syncData,setSyncData] = useState<any>()
	const[loader,setloader]=useState(false)
	const[pdf,setpdf]=useState(false)
	const [pdfView,setPdfView] = useState(false)
	const [viewAddendum, setViewAddendum] = useState(false);
	const[html,setHtml]=useState<any>()
	const [device,setDevice] = useState(false)
	const setMessageComp = (visible: boolean, message: any) => {
		return setMessage(message), setVisible(visible)
	}
	var formData = require('./editconfig.json')
	const handler = () => {
		setShow(!show);
	}
	const lostfocus = () => {
		setShow(false);
	}
	var localdata: any[] = []
	var remotedata: any[] = []
	var platform: any[] = []
	var name: any[] = []
	var version: any[] = []
	var variant: any[] = []
	var modified_date: any[] = []
	var id: any = []
	AppData.userConfig.data.remote.forEach((value: any) => { return platform.push(value['platform']) })
	AppData.userConfig.data.remote.forEach((value: any) => { return name.push(value['name']) })
	AppData.userConfig.data.remote.forEach((value: any) => { return version.push(value['version']) })
	AppData.userConfig.data.remote.forEach((value: any) => { return modified_date.push(value['modified_date']) })
	AppData.userConfig.data.remote.forEach((value: any) => { return variant.push(value['variant']) })
	localdata.push(name, platform, modified_date)
	// async function GetHtml() {
	// 	AppData.api.pdfGeneratorHtml.bodyParams = {header:true,footer:true}
	// 	var result:any
	// 	result = await AppData.remoteServer.sendCommand( AppData.api.pdfGeneratorHtml  ,"Uploading User config to local server failed.", 1)
	// 	setHtml(result)
	// 	console.log(html,"htmllog")
	//   }
	  function resolveAfter3Seconds() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve('resolved');
            }, 4000);
        });
    }
	async function PdfHandling (rowSelected:any){
		setloader(true)
		var config: any
		var item: any
		var val: any
		AppData.server = 'remote'
		try {
			config = AppData.userConfig.data.remote[rowSelected]
			item = await initializeConfig(config, AppData.server)
			val = await generateStates(AppData.server)
			setpdf(true)
			setloader(false)
		}
		catch {
			setloader(false)
		}
	}
	async function historySearch() {
		setloader(true)
		try{
			await AppData.remoteServer.setResponseTypeHeader("blob");
			AppData.api.pdfGeneratorFile.bodyParams = { header: true, footer: true }
			const response = await AppData.remoteServer.sendCommandReceiveAllData(AppData.api.pdfGeneratorFile, "Downloading PDF from local server failed.", 1)
			const contentDisposition =
			response.headers['content-disposition'];

			console.log('contentDisposition: ', contentDisposition);

			// 2) set the fileName variable to the default value
			let fileName = "Temp.pdf";

			// 3) if the header is set, extract the filename
			if (contentDisposition) {
				const fileNameMatch =
				contentDisposition.match(/filename="(.+)"/);
				console.log('fileNameMatch', fileNameMatch);
				if (fileNameMatch.length === 2) {
					fileName = fileNameMatch[1];
				}
			}	
			// create "a" HTML element with href to file & click
			const anchorElement = document.createElement('a');
			// create file link in browser's memory
			var blobObj = new Blob([response.data], { type: "application/pdf" });
			const href = URL.createObjectURL(blobObj);	
      		anchorElement.href = href;

			anchorElement.download = fileName;

			document.body.appendChild(anchorElement);
			anchorElement.click();
	
			document.body.removeChild(anchorElement);
			window.URL.revokeObjectURL(href);

			await AppData.remoteServer.setResponseTypeHeader("json");
			setloader(false)
		}
		catch{
			await AppData.remoteServer.setResponseTypeHeader("json");
		  	setloader(false)
		}
	  }
	const col1 = (coldata: any, rowindex: any) => {
		return (rowindex.map((row: any, res: any) => ((data[res].frozen)||(data[res].custom_part_number)) ?
			<div style={{ width: '19vw',display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'end', gap: '4px' }}>
				<Button onClick={() => setSyncSelect(true)} shape="downloadLocal" fill="transparent" spacing="downloadLocal" stroke="sync" color="sync" ><Icons iconName={"download"} size={"12px"} />Download to Local</Button>
			</div>
			:
			<>
			<div  style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"end"}}>
			<div  style={{ width: '19vw', display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'end', gap: '4px' }}> <Button onClick={() => setSyncSelect(true)} shape="downloadLocal" fill="transparent" spacing="downloadLocal" stroke="sync" color="sync" ><Icons iconName={"download"} size={"12px"} />Download to Local</Button><div style={{ width: '30px', height: '31px', border: '1px solid #3399D5', display: 'flex',flexDirection:"column", justifyContent: 'center', alignItems: 'center' ,cursor:"pointer"}} onClick={handler}><Icons stroke='menu' iconName={'more-vertical'} size={'15px'} /></div></div>
				<div onMouseLeave={lostfocus} >
					{show && selectedRow === row && (
						<Menu hide={setShow} spacing='local' shape='local' fontSize='local' effects='outerShadow' fill='local' data={[
							{ name: 'View Configuration', icon: 'settings', tooltip:'view current configuration', onclick: () => { activeRow(row, true) } },
							{ name: 'Download to Local', icon: 'logout', tooltip:'download configuration to local', onclick: () => { setSyncSelect(true) } },
							{ name: 'Duplicate', icon: "file", tooltip:'duplicate configuration', onclick: () => { return handleCopy()} }]} visible={show} />
					)}</div>
			</div>
			</>)
		)
	};
	const col2 = (coldata: any) => {
		return (coldata.map((col: string,row:number) => (
			<Button shape="remotebutton" spacing="localbutton" fill="transparent" stroke='local'onClick={()=>activeRow(row,true)}><Icons iconName={'cloud'} size={'18px'} stroke='remote' fill="remote"></Icons><p style={{ fontWeight: "500", fontSize: "12px", color: '#8E8D8A', fontFamily: "Inter" }}>Remote</p></Button>
		)))
	}

	const col6 = (coldata: any) => {
		return (coldata.map((col: any) => (
			<p style={{ padding: '0px', margin: '0px', cursor: 'pointer' }}>{col}</p>))
		)
	};
	const col3 = (coldata: any) => {
		return (coldata.map((col: any, res: any) => (
			(data[res].custom_part_number) ? <div style={{width:"15vw"}} onClick={()=>activeRow(res,true)}>
				<p style={{ padding: '0px', margin: '0px',display:"flex",gap:"5px",width:"100%",alignItems:"center"}}><p style={{wordWrap:"break-word",width:"13.5vw",padding:"0px",margin:"0px"}}>{col}</p><img src='/logo/Freeze Icon.svg' alt="Freeze" height={15} width={15} color='#3399D5'></img></p>
				<p style={{ padding: '0px', margin: '0px', color: "#8E8D8A", fontSize: '0.8vw' }}>Part Number {data[res].custom_part_number}</p>
			</div> : <p style={{ padding: '0px', margin: '0px',width:"13.5vw",wordWrap:"break-word",}}>{col}</p>
		))
		)
	};
	console.log('local', localdata)
	const colum0 = platform.map(x => (x))
	const colum1 = col2(data.map(x => (x[1])))
	const rowLengthArray = Array.from({ length: data.length }, (v, i) => i)
	const colum3 = col3(name.map(x => (x)))
	const colum4 = col6(variant.map(x => (x)))
	const colum6 = col6(modified_date.map(x => (convertUTCDateToLocalDate(new Date(x + 'Z')).toLocaleString())))
	const colum8 = col1(localdata.map(x => (x[1])), rowLengthArray)

	const col5 = (coldata: any, data: any, rowindex: any) => {
		return (rowindex.map((row: any) => (data[row] == 'local' ? coldata = localdata : coldata = remotedata))
		)
	};
	const displayData = colum0.map(function (e, i) {
		return  (configJson.WEB_ONLY===0)?[colum3[i], colum1[i], colum4[i], colum6[i], colum8[i]]:[colum3[i], colum4[i], colum6[i], colum8[i]];
	});

	function resolveAfter2Seconds() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve('resolved');
			}, 2000);
		});
	}
	async function summaryView() {
		var index
		var blockFunctions = await getBlockFunctions(AppData.componentList, AppData.server)
		AppData.deviceFrequenciesData = []
        let devicePropertyData = await getDevicePropertiesByName(AppData.server,AppData.currentConfig.variant.toUpperCase() + '_FREQUENCIES')
        if(devicePropertyData.length != 0){
            devicePropertyData[0].inputs.map((data:any)=>{
                AppData.deviceFrequenciesData.push(data.toString())
            })
        }

        AppData.deviceFrequenciesDataDiff = []
        devicePropertyData = await getDevicePropertiesByName(AppData.server,AppData.currentConfig.variant.toUpperCase() + '_FREQUENCIES_DIFF')
        if(devicePropertyData.length != 0){
            devicePropertyData[0].inputs.map((data:any)=>{
                AppData.deviceFrequenciesDataDiff.push(data.toString())
            })
        }

        AppData.deviceFrequenciesDataSE = []
        devicePropertyData = await getDevicePropertiesByName(AppData.server,AppData.currentConfig.variant.toUpperCase() + '_FREQUENCIES_LVCMOS')
        if(devicePropertyData.length != 0){
            devicePropertyData[0].inputs.map((data:any)=>{
                AppData.deviceFrequenciesDataSE.push(data.toString())
            })
        }
		blockFunctions.map((data: any) => {
			var functionData: {}[] = []
			try {
				for (const [key, value] of Object.entries(data.Functions)) {
					if(data.Functions[key].name === 'VDD'){
						AppData.vddList = data.Functions[key].parameters[0].inputs
						AppData.vddState = data.Functions[key].function_state
					}
					if(data.Functions[key].name === 'VDDA'){
						AppData.vddaList = data.Functions[key].parameters[0].inputs
						AppData.vddaState = data.Functions[key].function_state
					}
				}
				for (const [key, value] of Object.entries(data.Functions)) {
					functionData.push({
						'name': data.Functions[key].name, 'parameters': data.Functions[key].parameters, "function_state": data.Functions[key].function_state
					})
				}
				index = AppData.componentList.indexOf(data['Block/component name']) 
				AppData.compData[index] = functionData
			} catch {
			}
		})
		//const result = await resolveAfter2Seconds();
		console.log('comppp', AppData.compData)
		var compCount = 0
		AppData.summaryData = []
		AppData.compData.forEach((functionData: any) => {
			console.log('functions', functionData)
			let compFunction: any = {}
			for (const [key, value] of Object.entries(functionData)) {
				let paramName = functionData[key].parameters[0].param_name
				let functionValue = functionData[key].function_state
				if ((paramName == 'Mode') || (paramName == 'VDDO') || (paramName == 'Frequency(MHz)') || (paramName == 'Type') || (paramName == 'Hi-Z State') || (paramName == 'Delay Common Mode')) { compFunction[paramName] = functionValue }
				else if ((paramName == 'Delay CLK1N') || (paramName == 'Delay CLK0N') || (paramName == 'Delay CLK2N') || (paramName == 'Delay CLK3N')) { compFunction[paramName] = functionValue }
				else if ((paramName == 'Delay CLK0P') || (paramName == 'Delay CLK1P') || (paramName == 'Delay CLK2P') || (paramName == 'Delay CLK3P')) { compFunction[paramName] = functionValue }
			}
			AppData.summaryData[compCount] = compFunction
			compCount += 1
			console.log('summaryData', AppData.summaryData)

		})
	}
	const activeRow = (rowSelected: any, viewSelected: boolean) => {
		setVisible(false)
		if (viewSelected) {
			setloader(true)
		}
		AppData.componentList = []
		AppData.labelList = []
		AppData.blockDiagramData = []
		setSelectedrow(rowSelected)
		setSyncData(data[rowSelected])
		console.log('syncdata 3333333',syncData,data[rowSelected])
		AppData.server = 'remote'
		onSelectRow(AppData.userConfig.data.remote[rowSelected])
		onSelectSetMessage(false,'')
		var config: any
		config = AppData.userConfig.data.remote[rowSelected]
		try 
		{			
			initializeConfig(config, AppData.server).then((item) => {
				if (viewSelected) {
					generateStates(AppData.server).then((val) => {
						try {
							getBlockDiagramData(config, AppData.server).then((response) => {
								try {
									AppData.componentList = []
									AppData.blockDiagramData[0].place.map((placeData: any) => {
										if ((placeData.type == 'Driver') && (placeData.clickable != 'NO')) {
											AppData.componentList.push(placeData.name)
											AppData.labelList.push(placeData.label)
										}
									})
									console.log('app', AppData.componentList, AppData.labelList)

									summaryView().then(res => {
										AppData.compData = []
										console.log('navigation')
										onclickConfig(true)
										if (viewSelected) {
											setloader(false)
										}
									})
								}

								catch {
									if (viewSelected) {
										setloader(false)
									}
								}
							})
						}
						catch {
							if (viewSelected) {
								setloader(false)
							}
						}
					})
				}
			})
		}
		catch {
			if (viewSelected) {
				setloader(false)
			}
		 }
		AppData.currentConfig = config
	}
	const syncConfiguration = () => {
		syncConfig('remote', syncData, onSync, recentData).then((res) => { return setMessageComp(true, `${AppData.currentConfig.name} configuration was synced to ${AppData.server == 'remote' ? "local" : 'remote'}`), setSyncSelect(false); })
	}
	if (syncSelect && (selectedRow || selectedRow == 0)) {
		let check = false
		let deviceCheck = 0
		AppData.device.data.local.map((device:any) => {
			if(device.variant != data[selectedRow].variant){
				deviceCheck +=1
			}
		})
		if(deviceCheck === AppData.device.data.local.length){
			check = true
			setDevice(true)
			setSyncSelect(false)
		}
		AppData.userConfig.data.local.map((configData: any) => {
			if ((configData.name === data[selectedRow].name)&&(configData.variant === data[selectedRow].variant)) {
				check = true
				setDevice(false)
				setSyncSelect(false)
			}
		})
		if(check){
			setDialogActive(true)
		}
		else{
			syncConfig('remote', data[selectedRow], onSync, recentData).then((res) => { return setMessageComp(true, `${AppData.currentConfig.name} configuration was synced to ${AppData.server == 'remote' ? "local" : 'remote'}`), setSyncSelect(false); })
		}
	}
	if (viewAddendum && (selectedRow || selectedRow == 0)) {
		OnclickViewAddendum();
		setViewAddendum(false)
	}

	formData.duplicate[0].content = ''
	formData.duplicate[1].content = ''

	return (
		<ThemeProvider theme={PrimaryTheme}>
			 {<PopupPdf visible={pdfHandle} shape={"hug"} spacing={"none"} fill={"popupform"} stroke={""} html={html} onClickDownload={historySearch} onClickCancle={()=>hide(false)}></PopupPdf>}
			{loader&&<LoadingSpinner shape={"medium"} stroke={"normal"} fill={"none"} innerfill={"loader"} speed={"1"} text={""} position={{top:'40vh',left:'45vw'}}type={'circular'} disabled={false} ></LoadingSpinner>}
			<DialogBox shape="dialog" Buttoncontent={device?['Cancel']:["Cancel", "Continue"]} position='middle-center' visible={dialogActive} header={'Sync Configuration'} text={device?"Configuration selected has a device that does not exist in local. Download the device to local to sync the configuration.":"Configuration selected is already present in local. The existing local data will be overwritten.Do you wish to continue?"}
				spacing="dialog" fill="dialog" font="dialog" hide={setDialogActive} OnclickYes={syncConfiguration}></DialogBox>
			<Message visible={visible} shape="config" font="config" position="bottom-right" text={message} hide={setVisible}></Message>
			<PopUpForm shape={"hug"} spacing={"popupform1"} fill={"white"} stroke={""} font={""} initFormData={formData.duplicate} onSubmit={onSubmitDuplicate} visible={active} hide={setactive} popupformshape={"popupform1"} popupfill={"popupform"} popupstroke={"popupform"} items={[]}
				buttonContent={"Duplicate Configuration"} heading={'Duplicate Configuration'} buttonName="popupform1"></PopUpForm>
			<DataGrid Scroll={displayData.length>5?true:false} onfocuslost={lostfocus} data={data} displayData={displayData} columns={Columns} shape={shape} fill={fill} stroke={stroke} onClick={activeRow} />
		</ThemeProvider>
	);
}

export default ConfigRemoteTable;
