import React from 'react';
import GridContainerStyles from './gridContainerStyles'
export interface PropsIF {
    shape?: string
    layout?: string
    spacing?: string
    stroke?: string
    fill?: string
    effects?:string
    gridName?:string
    children:React.ReactNode
}


export default function GridContainer({shape="normal", layout="normal", spacing="none", stroke="none", fill="none", effects="none", gridName="",children}:PropsIF) {

    return (
        <GridContainerStyles shape={shape} layout={layout} spacing={spacing} stroke={stroke} fill={fill} effects={effects} gridName={gridName} > 
            {children}
        </GridContainerStyles>
    )
}