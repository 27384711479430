import React from 'react';


function Logo({onClick=()=>{}})  {
    return(
        
        <div className='logoContainer' onClick={onClick}>
               <img src='/logo/logo web capture.jpg' alt="SiTime" width="87px"></img> 
        </div>
    );
}

export default Logo;