import { GridContainerThemeIF } from "../components/gridContainer/gridContainerThemeIF"

const GridContainerThemes: GridContainerThemeIF = {

    shape: {
        hug: { width: "auto", height: "auto", radius: "0px" },
        normal: { width: "800px", height: "720px", radius: "0px" },
        fullPage: { width: "1200px", height: "720px", radius: "0px" },
        dialog: { width: "400px", height: "200px", radius: "0px" },
        card: { width: "250px", height: "220px", radius: "0px" },
        message: { width: "300px", height: "300px", radius: "0px" },
        configaration:{width:'100vw',height:'900px',radius:'0px'},
        configarationMain:{width:'100vw',height:'860px',radius:'0px'},
        signin: { width: "100vw", height: '100vh', radius: '0px' },
        blockdiagram: { width: "100vw", height: "755px", radius: '0px'},
        Realtime:{width:'100vw',height:'860px',radius:'0px'},
    },
    layout: {
        landing: {
            columns: "80px 1fr 1fr 1fr 1fr 80px",
            rows: "40px 100px 1fr 1fr 1fr",
            areas: ["logo header header header header avatar",
                "menu1 menu2 menu3 menu4 menu5 blank",
                "blank1 grid11 grid12 grid13 grid14 blank2",
                "blank1 grid21 grid22 grid23 grid24 blank2",
                "blank1 grid31 grid32 grid33 grid34 blank2",
                "blank1 grid41 grid42 grid43 grid44 blank2"]
        },
        normal: {
            columns: "1fr",
            rows: "100px 1fr",
            areas: ["menu", "content"]
        },
        message: {
            columns: "1fr 30px",
            rows: "30px 1fr 50px",
            areas: ["blank close", "message message", "button button"]
        },
        signin: {
            columns: "100vw",
            rows: "20vh 60vh 20vh",
            areas: ['a','b','c']

        },
        password: {
            columns: "100vw",
            rows: "20vh 60vh 20vh",
            areas: ['a','b','c']

        },
        signup: {
            columns: "77vh 45vh",
            rows: "182px 556px 182px",
            areas: ['a b c', 'd e f', 'h i j']

        },
        blockdiagram: {
            columns: "5vw 90vw 5vw",
            rows: "720px",
            areas: ["a b c"]
        },
        history: {
            columns: "80px 761px 439px",
            rows: "40px 601px 149px ",
            areas: ["a b c", "d e f", "g h i"]
        },
        //device
        Device: {
            columns:'5vw 90vw 5vw',
            rows:'720px',
            areas:['a b c']

        }  ,
        configaration:{
            columns:'100vw',
            rows:'90px 740px 65px',
            areas:['a','b','c']
        },
        configarationMain:{
            columns:'5vw 90vw 5vw',
            rows:'auto',
            areas:['a b c']
        },
        Realtime: {
            columns:'5vw 90vw 5vw',
            rows:'auto',
            areas:['a b c']
        }
    },
    spacing: {
        none: { gap: "0px" },
        normal: { gap: "10px" }
    },
    stroke: {
        none: "none",
        normal: "1px solid #067D6F"
    },
    fill: {
        none: "none",
        signin: 'lightgrey'
    },
    effects: {
        none: "none"
    }
}

export default GridContainerThemes