
import React from 'react'
import { PointIF } from './componentLibrary'



// a: Start x or y
// b: End x or y
// fraction: starting from a the fraction of length at which the bend happens
// Returns the position of the bend 
const tmpFn = (a: number, b: number, fraction: number) => {
    if (b > a)
        return (a + (b - a) * fraction)
    else
        return (a - (a - b) * fraction)
}

// start: starting point
// end: ending point
// direction: direction of the bend line horizontal|vertical
// fraction: starting from a the fraction of length at which the bend happens
// Returns the starting and ending points of the bend part of the line
const bendPoint = (start: PointIF, end: PointIF, direction: string, fraction: number) => {
    if (direction == "VERTICAL") {
        const xbend = tmpFn(start.x, end.x, fraction)
        return [xbend, start.y, xbend, end.y]
    }
    else {
        const ybend = tmpFn(start.y, end.y, fraction)
        return [start.x, ybend, end.x, ybend]
    }
}

// start: starting point of the net
// end: ending point of the net
// bendDirection: Net can only take 90 degree bends. if a net start horizontal, it can take a vertical bend and vis.
// bend_location: From start, at what fraction of the distance the 90 degree bend if located
// style: for styling the net
interface PropsIF {
    start: PointIF,
    end: PointIF,
    bend: "VERTICAL" | "HORIZONTAL",
    bend_location: number,
    style: string,
    marker_end?: string,
    dashed?: string
}

const Net = ({ start, bend, bend_location, style, marker_end, dashed, end }: PropsIF) => {
    const { x: x1, y: y1 } = start
    const { x: x2, y: y2 } = end
    console.log('route', dashed)

    if ((x1 == x2) || (y1 == y2))
        return (
            <g>
                <line markerEnd={`url(#${marker_end})`} x1={x1} y1={y1} x2={x2} y2={y2} className={style} />
                <marker id="ARROW" markerWidth="10" markerHeight="7" refX="10" refY="3.5" orient="auto" stroke='black' fill='none' strokeWidth={1}>
                    <polyline points="0 0, 10 3.5, 0 7" />
                </marker>
            </g>
        )
    else {
        let [xb1, yb1, xb2, yb2] = bendPoint({ x: x1, y: y1 }, { x: x2, y: y2 }, bend, bend_location)
        return (
            <g>
                {(dashed === 'YES') ? <path stroke-dasharray="5,5" className={style} markerEnd={`url(#${marker_end})`} d={`M${x1} ${y1} L${xb1} ${yb1} L${xb2} ${yb2} L${x2} ${y2}`} /> : <path markerEnd={`url(#${marker_end})`} className={style} d={`M${x1} ${y1} L${xb1} ${yb1} L${xb2} ${yb2} L${x2} ${y2}`} />}
                <marker id="ARROW" markerWidth="10" markerHeight="7" refX="10" refY="3.5" orient="auto" stroke='black' fill='none' strokeWidth={1}>
                    <polyline points="0 0, 10 3.5, 0 7" />
                </marker>
            </g>
        )

    }
}

export default Net