import React from 'react';
import styled, {css} from 'styled-components';

interface InputStringIF {
    shape:string
    disabled:boolean
}
const Inputstring=styled.div <InputStringIF> `
   position:relative;
   width:${props => props.theme.inputString.shape[props.shape].width};
   height:${props => props.theme.inputString.shape[props.shape].height};
   pointer-events: ${props=>props.disabled==true?'none':''};
   opacity: ${props=>props.disabled==true?'0.4':''};

`
interface ErrorMessageIF {
    font:string   
}
const ErrorMessage=styled.p <ErrorMessageIF>`

   display:flex;
   width:max-content;
   color:red;
   font-size:${props => props.theme.inputString.font[props.font].size};
   font-style:${props => props.theme.inputString.font[props.font].style};
   padding:none;
   margin:0px;
`
interface InputStyledDivIF{
    stroke:string;
    shape: string
    fill: string
    
}
const InputStyledDiv=styled.div <InputStyledDivIF>`
   border: ${props => props.theme.inputString.stroke[props.stroke]};
   border-radius:${props => props.theme.inputString.shape[props.shape].radius };
   position:relative;
   display: flex;
   align-items:center;
   width:100%;
   height:max-content;
   background:${props => props.theme.inputString.fill[props.fill].inputfill};

`

interface InputStyleIF{
 font:string
 spacing:string
 shape:string
}
const InputStyled=styled.input <InputStyleIF>`
   padding: ${props => props.theme.inputString.spacing[props.spacing].padding };
   cursor:auto;
   outline: none;
   border:none;
   width:100%;
   height:max-content;
   color:${props => props.theme.inputString.font[props.font].color};
   font-size:${props => props.theme.inputString.font[props.font].size};
   font-style:${props => props.theme.inputString.font[props.font].style};
   background:inherit;
`

interface InputStyledIconIF{
spacing:string
shape:string
fill:string
}
const InputStyledIcon=styled.button <InputStyledIconIF>`

   font-weight: bold;
   border: none;
   width:${props => props.theme.inputString.shape[props.shape].iconwidth};
   height:${props => props.theme.inputString.shape[props.shape].iconheight};
   cursor: pointer;
   background:${props => props.theme.inputString.fill[props.fill].iconfill};

`
export {InputStyled,InputStyledDiv,InputStyledIcon,Inputstring,ErrorMessage}
