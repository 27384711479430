import { useState } from "react";
import AppData from "../../appData";
import Icons from "../../icons/icons";
import { Button } from "../button/button";
import FlexBox from "../flexBox/flexBox";
import { FormDataStyle, FormLayout, Modal } from "./popupFormStyles";
import LoadingSpinner from "../loadingSpinner/loadingSpinner";

interface propsIF {
  spacing?: string;
  shape?: string;
  fill?: string;
  stroke?: string;
  onClickCancle: any;
  onClickDownload?:any;
  visible?:any
  hide?:boolean
  html?: any
}
function PopupPdf({ spacing = "none", visible=false,shape = "hug",hide, fill = "normal", stroke = "normal", onClickCancle, html,onClickDownload }: propsIF) {
  const [loader, setLoader] = useState(false)

  return (
    <Modal style={{display:visible?"block":"none"}}>
      <FormLayout spacing={spacing} shape={shape} fill={fill} stroke={stroke}>
        <FlexBox shape="hug" layout="vertical-top-left">
          {loader && <LoadingSpinner shape={"medium"} stroke={"normal"} fill={"none"} innerfill={"loader"} speed={"1"} text={"Downloading PDF..."} position={{ top: '40vh', left: '45vw' }} type={'circular'} disabled={false} ></LoadingSpinner>}
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <Button onClick={()=>{onClickCancle();hide=false}} shape="hug" fill="white" spacing="form">
              <Icons stroke="black" iconName={"cancel"} size={"24px"}></Icons>
            </Button>
            <Button shape="downloadLocal" fill="signin" color="white" onClick={() => onClickDownload()}>
              <Icons iconName="download" size={"24px"}></Icons>Download Pdf
            </Button>
          </div>
          <FormDataStyle spacing={spacing}>
            <div style={{ width: "750px", height: "100vh", overflow: "scroll", padding: "20px", margin: "0px" }} dangerouslySetInnerHTML={{ __html: html }}>

            </div>
          </FormDataStyle>
        </FlexBox>
      </FormLayout>
    </Modal>
  );
}
export default PopupPdf;
