
import React, { useState } from "react";
import Icons from "../../icons/icons";
import { DropdownStyle, DropdownButton, DropdownItemsDiv, DropdownInput, DropdownItems, DropdownIcon, SelectOptions, SelectedItems, DropdownInputDiv, DropdownCheckItemsDiv } from './dropDownStyles'
import AppData from "../../appData";
import Checkbox from "../checkBox/checkBox";

interface propIF {
  select: "single" | "multi"
  shape?: string
  InputSearch: boolean
  effects?: string
  contentItems: string[]
  fill?: string
  disabled?: boolean
  placeholder?: string
  disabledItems?: boolean[]
  iconcolor?: string
  spacing?: string
  stroke?: string
  checkbox?: boolean
  onClick: (selected: any) => void;
  current?: string;
  unCheck?: (deselect: any) => void

}
const DropdownCheckBox = ({ unCheck = (itemName: string | string[]) => { }, shape = 'medium', placeholder, checkbox = false, disabledItems = [], disabled = false, iconcolor, InputSearch = false, select = 'single', stroke = "normal", contentItems = [], spacing = 'normal', effects = "none", fill = 'normal', onClick = (itemName: string | string[]) => { }, current }: propIF) => {
  const [dropdown, Setdropdown] = useState<boolean>(false)
  const [item, Setitem] = useState(current)
  const [selected, setSelected] = useState<string[]>([])
  const [dropdownDiv, SetdropdownDiv] = useState<boolean>(false)
  const [searchInput, setSearchInput] = useState("")

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // e.preventDefault
    setSearchInput(e.target.value)
  }
  var state = current
  const val = current != undefined ? state : item
  const filteredItems = contentItems.filter((item) => item.toLowerCase().includes(searchInput.toLowerCase()))
  const toggleOption = (item: string) => {
    if (state === item) {
      state = ''
      unCheck(item)
    }
    else {
      state = item
      onClick(item)
    }
    Setitem(state)
    setSearchInput('')
    Setdropdown(false)
  }
  const DropdownHandler = () => {
    if (dropdown == false) {
      return Setdropdown(true), SetdropdownDiv(false)
    } else {
      return Setdropdown(false)
    }
  }
  const checkBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value == current) {
      state = ''
      unCheck(event.target.value)
    }
    else {
      state = event.target.value
      onClick(event.target.value)
    }
    Setitem(state)
    console.log('check', state, event.target.value)
  }
  return (
    <DropdownStyle disabled={disabled} shape={shape} onMouseLeave={() => Setdropdown(false)}>
      <DropdownButton stroke={stroke} selected={dropdownDiv} dropdown={dropdown} fill={fill} shape={shape} >
        <div style={{ margin: '0px', paddingLeft: '7px', fontWeight: 'bold', display: 'flex', width: 'fit-content', height: "100%", alignItems: "center" }}>
          {val}
        </div>
        <DropdownIcon onClick={DropdownHandler}>
          <Icons iconName={"chevron-down"} size={"30px"} stroke={iconcolor} />
        </DropdownIcon>
      </DropdownButton>
      <DropdownItems stroke={stroke} fill={fill} spacing={spacing} shape={shape} dropdown={dropdown} options={filteredItems.length} >
        <SelectOptions stroke={stroke} shape={shape} effects={effects} inputsearch={false} options={filteredItems.length}>
          {filteredItems.map((item: string, key: number) => {
            let isSelected = selected.includes(item)
            return (
              <DropdownCheckItemsDiv Itemselected={isSelected} spacing={spacing} disabled={disabledItems[key]} shape={shape} onClick={() => { toggleOption(item) }}>
                <input value={item} type="checkbox" checked={(state == item)} />
                <div>{item}</div>
              </DropdownCheckItemsDiv>
            )
          })}
        </SelectOptions>
      </DropdownItems>
    </DropdownStyle>
  )
}
export default DropdownCheckBox