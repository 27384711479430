import React from "react";
import { DataGrid } from "../../../components/dataGrid/dataGrid";
import AppData from "../../../appData";

interface PropIF{
    shape?: string
	fill?: string
	stroke?: string 
    columns:any[]
}

function RegisterTable({shape,fill,stroke,columns}:PropIF){

   var DeviceConfigurations=AppData.comparedData.DeviceConfigurations
   var UserConfigurations=AppData.comparedData.UserConfigurations
   var regName:any[]=[]
   var feildName:any[]=[] 
   var deviceConfig:any[]=[]
   var userConfig=Array.from({length:DeviceConfigurations.length}).map(i=>"-")
   
   DeviceConfigurations.forEach((value:any)=>{ return feildName.push((Object.keys(value))[0])})
   DeviceConfigurations.forEach((value:any)=>{return deviceConfig.push(value["field_value"].toLocaleString())})
   DeviceConfigurations.forEach((value:any)=>{return regName.push(value['register_name'])})
   for (let i=0;i<feildName.length;i++){
    for(let j=0;j<UserConfigurations.length;j++){
        if(Object.keys(UserConfigurations[j]).includes(feildName[i])){
            userConfig[i]=UserConfigurations[j]["field_value"]
        }   
    }
   }

   const column=(coldata:any)=>{
    return(coldata.map((col:any)=>(
        <p style={{ padding: '0px', marginLeft:"0px", cursor: 'pointer' }}>{col}</p>
    )))
    };
   const column1=(coldata:any)=>{
    return(coldata.map((col:any)=>(
        <p style={{ padding: '0px', marginLeft:"0px", cursor: 'pointer' }}>{col.toLocaleString()}</p>
    )))
   }

   const colum0=column(feildName.map((x,index)=>(index+1))) 
   const colum1=column1(regName.map(x=>(x)))
   const colum2=column(feildName.map(x=>(x))) 
   const colum3=column(userConfig.map(x=>(x)))
   const colum4=column(deviceConfig.map(x=>(x)))


   const displayData=colum1.map(function(e:any,i:any){
    return [colum0[i],colum1[i],colum2[i],colum3[i],colum4[i]]
   })
   
   return(
    
    <DataGrid Scroll={true} data={DeviceConfigurations} displayData={displayData} columns={columns} shape={shape} fill={fill} stroke={stroke} />

   )
}
export default RegisterTable