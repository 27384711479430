import React from 'react'
import {ComponentType, ComponentLibrary, PortDataIF} from "./componentLibrary"



// Position left|right|top|bottom
// Offset: distance from top for left and right, distance from left for top and bottom
// Width: Width of the bounding box. From the size parameter of the component
// port_size: Port is indicated by a square. Then size of the square
// Returns the [x o y] position of the port with respect to the bounding box origin (left, top)
const _x = (position:string, offset:number, width:number, port_size:number ) => {
    switch(position){
        case("left"):
            return 0;
        case ("right"):
            return width-port_size ;
        default:
            return offset-port_size/2 ;
    }
}
const _y = (position:string, offset:number, height:number, port_size:number ) => {
    switch(position){
        case("top"):
            return 0;
        case ("bottom"):
            return height-port_size ;
        default:
            return offset-port_size/2 ;
    }
}

// Component type: From this string we can get all properties from global record ComponentLibrary
// Port name: One component can have many ports. From the name we can get more info about each port
// Size of the port: required to ensure the port is within the bounding box
// Port style: to render port style
interface PropsIF {
    component: ComponentType,
    port: string
    port_size: number
    portStyle: string

}
const Port = (props:PropsIF) => {
    const _details = { ...ComponentLibrary[props.component].details}
    const _port = { ...ComponentLibrary[props.component].ports}
    const {offset, position} = _port[props.port]
    return (
            <rect x={_x(position, offset, _details.size.width, props.port_size)} y={_y(position, offset, _details.size.height, props.port_size)} height={props.port_size} width={props.port_size} className={props.portStyle} />
    )
}

export default Port
