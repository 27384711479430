import axios, { AxiosError, AxiosResponse } from "axios";
import {ErrorIF} from '../appTypes'
import AppData from "../appData";
import configData from "./../config.json";
type bodyFnType = ((bodyParams: any|undefined) => any) 
export interface ApiIF {
    verb: string,
    ep: (epParams:any) => string,
	epParams:any,
    body: (bodyParams: any) => any
	bodyParams: any
    extract?: (item:any) => any
	config?:any 
}


class RestServer {
	private allowedVerbs = ["post", "put", "delete","get"];
	private restServer: any;
	private url: string;
	private timeOut: number;
	private restServerforform: any;

	public constructor(_url: string, _timeOut: number) {
		this.restServer = ""
		this.url = _url
		this.timeOut = _timeOut
		this.restServerforform = "";
	}

	public async create() {
		try {
			this.restServer = await axios.create({
				baseURL: this.url,
				headers: {
					"Content-type": "application/json",
					"accept": "application/json",
					"timeout": this.timeOut
				}
			});
			// Add a response interceptor
			this.restServer.interceptors.response.use(
				
                (response: AxiosResponse) => {
                  return response;
                },
                (error: AxiosError) => {
                  try {
                    const { response } = error;                    
					if (response?.status === 403) {
						// Retry the original request with the new token
						const originalRequest = error.config;
						console.log("Invalid Token " , originalRequest , AppData.mainUrl )
						if(!AppData.loginScreen && AppData.mainUrl !== ""){
							alert("Token expired. Please re-login")
							if(configData.WEB_ONLY == 0){
								window.location.href = AppData.mainUrl;
							}
							else{
								window.location.href = "https://timingconfigurator.sitime.com/";
							}
						}
					}
                  } catch (e) {
                    console.error(e);
                  }
				  return Promise.reject(error.message);
                },
              );
		}
		catch(err: any)
		{
			throw {severity:"error", message:`Not able to create axios server for ${this.url}`} as ErrorIF;
		}
		try {
			this.restServerforform = await axios.create({
				baseURL: this.url,
				headers: {
					"Content-type": "application/x-www-form-urlencoded",
					"accept": "application/json",
					"timeout": this.timeOut
				}
			});

			// Add a response interceptor
			this.restServerforform.interceptors.response.use(
                (response: AxiosResponse) => {
                  return response;
                },
                (error: AxiosError) => {				  
                  try {
                    const { response } = error;                   
					if (response?.status === 403) {
						const originalRequest = error.config;
						console.log("Invalid Token " , originalRequest , AppData.mainUrl )
						if(!AppData.loginScreen && AppData.mainUrl !== ""){
							alert("Token expired. Please re-login")
							if(configData.WEB_ONLY == 0){
								window.location.href = AppData.mainUrl;
							}
							else{
								window.location.href = "https://timingconfigurator.sitime.com/";
							}
						}
					}
                  } catch (e) {
                    console.error(e);
                  }
				  return Promise.reject(error.message);
                },
              );
		}
		catch(err: any)
		{
			throw {severity:"error", message:`Not able to create axios server for ${this.url}`} as ErrorIF;
		}
	}

	public async setHeader()
	{
		// Add a request interceptor
		// Set the AUTH token for any request
		this.restServer.interceptors.request.use(function (config: { headers: { Authorization: string; }; }) {
			const token = AppData.User.token;
			// eslint-disable-next-line no-template-curly-in-string
			config.headers.Authorization =  token ? token : '';
			return config;
		});
	}

	public async setResponseTypeHeader(responseType:string)
	{
		this.restServer.defaults['responseType'] = responseType;
	}
	
	public async send(args:{verb:string, ep:string, body:any, headers:any}, errorMsg:string) {
		let pr: AxiosResponse;
		let name = `verb ${args.verb} to ${args.ep}`
		try {
			switch (args.verb) {
				case ("get"):
					pr = await this.restServer.get(args.ep);
					break;
				case ("post"):
					if(args.body !== '')
						pr = await this.restServer.post(args.ep, args.body, args.headers);
					else
						pr = await this.restServer.post(args.ep, args.headers);
					break;
				case ("postform"):
					pr = await this.restServerforform.post(args.ep, args.body, args.headers);
					break;
				case ("put"):
					pr = await this.restServer.put(args.ep, args.body);
					break;
				case ("delete"):
					pr = await this.restServer.delete(args.ep, args.body);
					break;
				default:
					throw {severity:"error", message:`verb ${args.verb} not supported`} as ErrorIF;
			}
			if (pr.status > 199  && pr.status <300) {
				return pr.data
			}
			else
				throw {severity:"error", message:`${errorMsg} \n Could not: ${name}`} as ErrorIF;
		}
		catch (err: any) {
			switch (err.code) {
				case (AxiosError.ERR_BAD_REQUEST):
					throw {severity:"error", message:`${errorMsg} \n Bad request in ${name}, ${JSON.stringify(err.response.data)}`} as ErrorIF;
				case (AxiosError.ERR_NETWORK):
					throw {severity:"error", message:`${errorMsg} \n REST request ${name}. Network error, may be server not running`} as ErrorIF;
				default:
					throw {severity:"error", message:`${errorMsg} \n REST request ${name}. Something happened in setting up the request that triggered an Error`} as ErrorIF;
			}
		}
	}

	public async sendReceiveAllData(args:{verb:string, ep:string, body:any, headers:any}, errorMsg:string) {
		let pr: AxiosResponse;
		let name = `verb ${args.verb} to ${args.ep}`
		try {
			switch (args.verb) {
				case ("get"):
					pr = await this.restServer.get(args.ep);
					break;
				case ("post"):
					if(args.body !== '')
						pr = await this.restServer.post(args.ep, args.body, args.headers);
					else
						pr = await this.restServer.post(args.ep, args.headers);
					break;
				case ("postform"):
					pr = await this.restServerforform.post(args.ep, args.body, args.headers);
					break;
				case ("put"):
					pr = await this.restServer.put(args.ep, args.body);
					break;
				case ("delete"):
					pr = await this.restServer.delete(args.ep, args.body);
					break;
				default:
					throw {severity:"error", message:`verb ${args.verb} not supported`} as ErrorIF;
			}
			if (pr.status > 199  && pr.status <300) {
				return pr
			}
			else
				throw {severity:"error", message:`${errorMsg} \n Could not: ${name}`} as ErrorIF;
		}
		catch (err: any) {
			switch (err.code) {
				case (AxiosError.ERR_BAD_REQUEST):
					throw {severity:"error", message:`${errorMsg} \n Bad request in ${name}, ${JSON.stringify(err.response.data)}`} as ErrorIF;
				case (AxiosError.ERR_NETWORK):
					throw {severity:"error", message:`${errorMsg} \n REST request ${name}. Network error, may be server not running`} as ErrorIF;
				default:
					throw {severity:"error", message:`${errorMsg} \n REST request ${name}. Something happened in setting up the request that triggered an Error`} as ErrorIF;
			}
		}
	}

	public async sendCommand(_api: ApiIF, _message:string, debug = 0)  {
		let _returnVal: any
		let _args = {verb:"", ep:"", body:{}, headers:{}}

		// Form the command arguments
		try {
			_args = {verb:_api.verb, ep:_api.ep(_api.epParams), body: _api.body(_api.bodyParams) , headers:_api.config}
		}
		catch {
			console.log(`argument mismatch either in end point paramters: ${JSON.stringify(_api.epParams)} or in body parameters: ${JSON.stringify(_api.bodyParams)}`)
		}
		if (debug > 0) console.log(`Args: ${JSON.stringify(_args)}`)

		// Send the command to API
		let _result = await this.send(_args, _message) as []
		if (debug > 0) console.log(`Results: ${JSON.stringify(_result)}`)

		// If needed extarct further
		if ("extract" in _api) {
			const _extract = _api.extract as (item:any) => any 
			_returnVal =   _result.map((item:any)=> {return _extract(item)})
		}
		else
			_returnVal =  _result
		if (debug > 0) console.log(`Final Results: ${JSON.stringify(_returnVal)}`)

		return _returnVal
	}


	public async sendCommandReceiveAllData(_api: ApiIF, _message:string, debug = 0)  {
		let _returnVal: any
		let _args = {verb:"", ep:"", body:{}, headers:{}}

		// Form the command arguments
		try {
			_args = {verb:_api.verb, ep:_api.ep(_api.epParams), body: _api.body(_api.bodyParams) , headers:_api.config}
		}
		catch {
			console.log(`argument mismatch either in end point paramters: ${JSON.stringify(_api.epParams)} or in body parameters: ${JSON.stringify(_api.bodyParams)}`)
		}
		if (debug > 0) console.log(`Args: ${JSON.stringify(_args)}`)

		// Send the command to API
		let _result = await this.sendReceiveAllData(_args, _message)
		if (debug > 0) console.log(`Results: ${JSON.stringify(_result)}`)

		_returnVal =  _result
		if (debug > 0) console.log(`Final Results: ${JSON.stringify(_returnVal)}`)

		return _returnVal
	}
}

export { RestServer }

