/* eslint-disable no-lone-blocks */
import { execPath } from 'process'
import { useRef } from 'react'
import { RestServer } from './REST/axios'
import configData from "./config.json";

const AppData = {
    api: {
        // App loading API's
        userLogin: {
            verb: "post",
            epParams: { username: "" },
            ep: (params: any) => { return "user_login/login?username=" + params.username },
            bodyParams: {},
            body: () => { return "" },
        },
        validateSession: {
            verb: "post",
            ep: (params: any) => { return "user_login/validate_session" },
            epParams: {},
            bodyParams: {},
            body: (params: any) => { return {} },
            config: {}
        },
        deleteSession: {
            verb: "delete",
            epParams: {},
            ep: (params: any) => { return "/user_details/sessions" },
            bodyParams: { session_id: "" },
            body: (params: { session_id: string }) => { return { data: { session_id: params.session_id } } }
        },
        user_login: {
            verb: "postform",
            ep: (params: any) => { return "/user_login/loginPassword" },
            epParams: {},
            bodyParams: { a: "" },
            body: (params: { a: any }) => { return params.a },
        },
        userSearch: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "user_details/user_details/search" },
            bodyParams: { username: "" },
            body: (params: { username: "" }) => { return { "filter": { "username": params.username }, "unset": {} } },
        },
        userDeviceSearch: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "user_details/user_details/search" },
            bodyParams: { username: "" },
            body: (params: { username: "" }) => { return { "filter": { "username": params.username }, "unset": {}, "expand": ["USER_DEVICES"] } },
        },
        userDownload: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "user_details_sync/user_details_sync/export" },
            bodyParams: { _id: "" },
            body: (params: { _id: "" }) => { return { "uid": [params._id] } },
        },
        passwordHash: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/user_login/passwordHash" },
            bodyParams: { password: "" },
            body: (params: { password: "" }) => { return { "password": params.password } },
        },
        pdfGenerator: {
            verb: "get",
            epParams: {},
            ep: (params: any) => { return "/addendum_file_generation/get_pdf?header=true&footer=true" },
            body: (params: { header: true, footer: true }) => { return "" },
            bodyParams: { header: true, footer: true }
        },
        pdfGeneratorFile: {
            verb: "get",
            epParams: {},
            ep: (params: any) => { return "/addendum_file_generation/get_pdf_file?header=true&footer=true" },
            body: (params: { header: true, footer: true }) => { return "" },
            bodyParams: { header: true, footer: true }
        },
        pdfGeneratorHtml: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/addendum_file_generation/get_html?header=true&footer=true" },
            bodyParams: { header: true, footer: true, user_id: "", customer_company: "", customer_address: "", customer_contact_name_title: "", customer_contact_number: 1 },
            body: (params: { header: true, footer: true, user_id: "", customer_company: "", customer_address: "", customer_contact_name_title: "", customer_contact_number: 1 }) => {
                return {
                    "user_id": params.user_id,
                    "customer_company": params.customer_company,
                    "customer_address": params.customer_address,
                    "customer_contact_name_title": params.customer_contact_name_title,
                    "customer_contact_number": params.customer_contact_number
                }
            },
        },
        modifyUserDetails: {
            verb: "put",
            epParams: {},
            ep: (params: any) => { return "/user_details/user_details" },
            bodyParams: { username: "", _id: "", password: "" },
            body: (params: { username: "", _id: "", password: "" }) => { return { "user_details": { "username": params.username, "password": params.password }, "user_details_id": params._id } }

        },
        modifyUserDeviceDetails: {
            verb: "put",
            epParams: {},
            ep: (params: any) => { return "/user_details/user_details" },
            bodyParams: { _id: "", devices: [] },
            body: (params: { _id: "", devices: [] }) => { return { "user_details": { "devices": params.devices}, "user_details_id": params._id } }

        },
        loginPassword: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/user_login/loginPassword" },
            bodyParams: { username: "", password: "" },
            body: (params: { username: "", password: "" }) => { return { "username": params.username, "password": params.password } },
        },
        userUpload: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "user_details_sync/user_details_sync/import" },
            bodyParams: { _result: {} },
            body: (params: { _result: {} }) => { return params._result },
        },
        deviceSearch: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "devices/devices/search" },
            bodyParams: {},
            body: (params: {}) => { return { "filter": {}, "unset": { "_id": 1, "platform": 1, "variant": 1, "silicon_revision": 1, "version": 1, "created_on": 1 } } },
        },
        deviceSearchWithId: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "devices/devices/search" },
            bodyParams: { _id: "" },
            body: (params: { _id: "" }) => { return { "filter": { "_id": params._id }, "unset": { "_id": 1, "platform": 1, "variant": 1, "silicon_revision": 1, "version": 1, "created_on": 1 } } },
        },
        configSearchWithUUID: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "user_device_configurations/user_device_configurations/search" },
            bodyParams: { udc_uuid: "" },
            body: (params: { udc_uuid: string }) => { return { "control": { "filter": { udc_uuid: params.udc_uuid }, "unset": {}, "expand": [] } }},
        },
        configSearch: {
            verb: "post",
            ep: (params: any) => { return "user_device_configurations/user_device_configurations/search" },
            epParams: {},
            body: (params: any) => { return { "control": { "filter": { created_by: params.created_by }, "unset": { "name": 1, "udc_uuid": 1, "device_id": 1, "device": 1, "created_by": 1, "frequencies": 1, "configuration_steps": 1, "created_date": 1, "description": 1, "frozen": 1, "custom_part_number": 1, "modified_date": 1, "customer_details_id": 1, "product_details": 1, "sales_details": 1 } }, "expand": ["DEVICES"] } },
            bodyParams: { created_by: "" },
            extract: (item: any) => { return ({ _id: item._id, device_id: item.device_id, name: item.name, udc_uuid: item.udc_uuid, platform: item.device[0].platform, variant: item.device[0].variant, silicon_revision: item.device[0].silicon_revision, created_by: item.created_by, frequencies: item.frequencies, configuration_steps: item.configuration_steps, created_date: item.created_date, description: item.description, frozen: item.frozen, custom_part_number: item.custom_part_number, modified_date: item.modified_date, customer_details_id: item.customer_details_id, product_details: item.product_details, sales_details: item.sales_details }) }
        },
        deviceSyncDownload: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/device_sync/device_sync/export" },
            bodyParams: { device_id: "" },
            body: (params: { device_id: string }) => { return { "device_id": params.device_id } },
        },
        deviceSyncUpload: {
            verb: "post",
            ep: (params: any) => { return "/device_sync/device_sync/import" },
            epParams: {},
            bodyParams: { device: {} },
            body: (params: { device: any }) => { return params.device },
        },
        configSyncDownload: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/user_device_conf_sync/user_device_conf_sync/export" },
            bodyParams: { udc_uuids: [""] },
            body: (params: { udc_uuids: [""] }) => { return { "udc_uuids": params.udc_uuids } },
        },
        configSyncUpload: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/user_device_conf_sync/user_device_conf_sync/import" },
            bodyParams: { user_device_configurations: {} },
            body: (user_device_configurations: []) => { return user_device_configurations },
        },
        fieldSearch: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/fields/fields/search" },
            bodyParams: { name: "" },
            body: (params: { name: "" }) => {
                return {
                    "filter": { "name": params.name },
                    "unset": {}
                }
            }
        },
        historySearch: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/user_device_configurations/configuration_steps/search" },
            bodyParams: { udc_uuid: "" },
            body: (params: { udc_uuid: string }) => {
                return {
                    "control": {
                        "filter": {},
                        "unset": {}
                    },
                    "udc_uuid": params.udc_uuid
                }
            },
            extract: (item: any) => {
                return ({
                    enableColor: item.configuration_steps.head, step_id: item.configuration_steps.step_id, action_id: item.configuration_steps.action_id,
                    source: item.configuration_steps.source_screen, order: item.configuration_steps.tokens[0].order, value: item.configuration_steps.tokens[0].value, name: item.configuration_steps.name,
                    reference: item.configuration_steps.reference, modified_date: item.configuration_steps.modified_date
                })
            },
        },
        historyEdit: {
            verb: "put",
            epParams: {},
            ep: (params: any) => { return "/configurator/configuration/history_view/modify_step" },
            bodyParams: { step_id: "", action_id: "", source_screen: "", order: "", value: {} },
            body: (params: { step_id: string, action_id: string, source_screen: string, order: string, value: any }) => {
                return {
                    action_id: params.action_id,
                    source_screen: params.source_screen,
                    step_id: params.step_id,
                    tokens: [
                        {
                            order: params.order,
                            value: params.value
                        }
                    ]
                }
            },
        },
        setHead: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/configurator/configuration/history_view/set_head" },
            bodyParams: { step_id: "" },
            body: (params: { step_id: string }) => { return { "step_id": params.step_id } }
        },
        removeConfigStep: {
            verb: "delete",
            epParams: {},
            ep: (params: any) => { return "/user_device_configurations/configuration_steps" },
            bodyParams: { udc_uuid: "", step_id: "" },
            body: (params: { udc_uuid: string, step_id: string }) => {
                return { data: { udc_uuid: params.udc_uuid, step_id: params.step_id } }
            },
        },

        // Config CRUD APIs
        addConfig: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/user_device_configurations/user_device_configurations" },
            bodyParams: { device_id: "", name: "", created_by: "", configuration_steps: [], created_date: "", modified_date: "", description: "", frequencies: {} },
            body: (params: { device_id: string, name: string, description: string, configuration_steps: any, created_by: string, created_date: string, modified_date: string, frequencies: any }) => {
                return {
                    device_id: params.device_id, name: params.name, description: params.description, created_by: params.created_by,
                    created_date: params.created_date, modified_date: params.modified_date, configuration_steps: params.configuration_steps, frequencies: params.frequencies
                }
            },
        },
        removeConfig: {
            verb: "delete",
            epParams: {},
            ep: (params: any) => { return "/user_device_configurations/user_device_configurations" },
            bodyParams: { udc_uuid: "" },
            body: (params: { udc_uuid: string }) => {
                return { data: { udc_uuid: params.udc_uuid } }
            },
        },
        getCustomPartNumberHistory: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/custom_part_number_history/custom_part_number_history/search" },
            bodyParams: { configuration_id: "" },
            body: (params: { configuration_id: string }) => { return { "filter": { "configuration_id": params.configuration_id }, "unset": {} } }
        },
        customPartNumberHistory: {
            verb: "put",
            epParams: {},
            ep: (params: any) => { return "/custom_part_number_history/custom_part_number_history" },
            bodyParams: {
                custom_part_number_history: {
                    _id: "",
                    platform: "",
                    variant: "",
                    sequence: 0,
                    configuration_id: "",
                    user_id: "",
                    custom_part_number: ""
                },
                doc_id: ""
            },
            body: (params: {
                custom_part_number_history: {
                    _id: string,
                    platform: string,
                    variant: string,
                    sequence: number,
                    configuration_id: string,
                    user_id: string,
                    custom_part_number: string
                },
                doc_id: string
            }) => {
                return {
                    custom_part_number_history: {
                        _id: params.custom_part_number_history._id,
                        platform: params.custom_part_number_history.platform,
                        variant: params.custom_part_number_history.variant,
                        sequence: params.custom_part_number_history.sequence,
                        configuration_id: params.custom_part_number_history.configuration_id,
                        user_id: params.custom_part_number_history.user_id,
                        custom_part_number: params.custom_part_number_history.custom_part_number
                    },
                    doc_id: params.doc_id
                }
            }
        },
        updateConfig: {
            verb: "put",
            epParams: {},
            ep: (params: any) => { return "/user_device_configurations/user_device_configurations" },
            bodyParams: { name: "", description: "", device_id: "", udc_uuid: "" },
            body: (params: { name: string, description: string, device_id: string, udc_uuid: string }) => {
                return { "user_device_configuration": { name: params.name, description: params.description, device_id: params.device_id, udc_uuid: params.udc_uuid }, "udc_uuid": params.udc_uuid }
            },
        },
        updateCustomerId: {
            verb: "put",
            epParams: {},
            ep: (params: any) => { return "/user_device_configurations/user_device_configurations" },
            bodyParams: { customer_details_id: "", udc_uuid: "" },
            body: (params: { name: string, description: string, customer_details_id: string, udc_uuid: string }) => {
                return { "user_device_configuration": { customer_details_id: params.customer_details_id, udc_uuid: params.udc_uuid }, "udc_uuid": params.udc_uuid }
            },
        },
        updateId: {
            verb: "put",
            epParams: {},
            ep: (params: any) => { return "/user_device_configurations/user_device_configurations" },
            bodyParams: { udc_uuid: "" },
            body: (params: { udc_uuid: string }) => {
                return {
                    "user_device_configuration": {
                        "product_details": {
                            market_segment: AppData.marketId,
                            application_description: AppData.application_description,
                            end_product_program_name: AppData.end_product_program_name,
                            mass_production_date: AppData.mass_production_date
                        }, udc_uuid: params.udc_uuid,
                        "sales_details": {
                            sales_region: AppData.salesId,
                            email_pdf_Datasheet_to: AppData.email_pdf_Datasheet_to,
                            sales_comments: AppData.sales_comments
                        }
                    }, "udc_uuid": params.udc_uuid
                }
            },
        },
        initialyzeContext: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/configurator/configuration/initialize" },
            bodyParams: { configuration_id: "", user_details_id: "" },
            body: (params: { configuration_id: "", user_details_id: "" }) => {
                return { configuration_id: params.configuration_id, user_details_id: params.user_details_id }
            },
        },
        currentConfig: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/configurator/configuration/current" },
            bodyParams: {},
            body: () => { return "" },
        },
        generateState: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/configurator/configuration/generate_states" },
            bodyParams: {},
            body: () => { return "" },
        },
        getSequenceNumber: {
            verb: "put",
            epParams: {},
            ep: (params: any) => { return "/get_sequence_number" },
            bodyParams: { platform: "", variant: "", user_id: "", config_uuid: "", category: "" },
            body: (params: { platform: "", variant: "", user_id: "", config_uuid: "", category: "" }) => { return { "platform": params.platform, "variant": params.variant, "user_id": params.user_id, "config_uuid": params.config_uuid, "category": params.category } }
        },
        freezeConfig: {
            verb: "put",
            epParams: {},
            ep: (params: any) => { return "/configurator/configuration/freeze" },
            bodyParams: { sequence: "", category: "", packaging: "", frequency_stability: "", temperature_range: "" },
            body: (param: { sequence: "", category: "", packaging: "", frequency_stability: "", temperature_range: "" }) => {
                return {
                    "freeze_structure": {
                        "temperature_range": param.temperature_range,
                        "frequency_stability": param.frequency_stability,
                        "packaging": param.packaging,
                        "category": param.category
                    },
                    "sequence": param.sequence
                }
            },
        },
        // Register update API's
        registerSearch: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/registers/registers/search" },
            bodyParams: { device_id: "" },
            body: (params: { device_id: "" }) => { return { "filter": { "device_id": params.device_id }, "unset": {} } },
            extract: (item: any) => { return ({ _id: item._id, device_id: item.device_id, name: item.name, address: item.address, size: item.size, value: "0x00", default: "0x00" }) }
        },
        registerFields: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/registers/registers/fields" },
            bodyParams: { register_id: "" },
            body: (params: { register_id: "" }) => { return { "registers": [params.register_id] } }
        },
        getAllRegisterStates: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/configurator/register/search" },
            bodyParams: {},
            body: () => { return { register: [] } },
            extract: (item: any) => { return ({ _id: item._id, name: item.name, address: item.address, size: item.size, value: item.register_state, fields: item.fields }) }
        },
        modifyRegisterState: {
            verb: "put",
            epParams: {},
            ep: (params: any) => { return "/configurator/field/state_set" },
            bodyParams: { field_id: "", token_value: "", source_screen: "" },
            body: (params: { field_id: "", token_value: "", source_screen: "" }) => { return { field_id: params.field_id, token_value: params.token_value, source_screen: params.source_screen } }
        },

        //Blockdiagram API
        blockDiagramSearch: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/block_diagrams/block_diagrams/search" },
            bodyParams: { device_id: "", name: "" },
            body: (params: { device_id: "", name: "" }) => { return { "control": { "filter": { "device_id": params.device_id, "name": params.name }, "unset": {} }, "expand": [] } },
            // extract : (item: any) => { return ({ _id: item._id, device_id: item.device_id, name: item.name}) }
        },

        blockDiagramFields: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/block_diagrams/fields/search" },
            bodyParams: { user_id: "", device_id: "", block_diagram_id: "", component_name: "" },
            body: (params: { user_id: "", device_id: "", block_diagram_id: "", component_name: "" }) => {
                return {
                    "user_id": params.user_id,
                    "device_id": params.device_id,
                    "block_diagram_id": params.block_diagram_id,
                    "component_name": params.component_name
                }
            }
        },

        //Functionaldiagram API
        //Functionaldiagram API
        componentFunctionsSearch: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/block_diagrams/functions/get_component_function" },
            bodyParams: { user_id: "", device_id: "", block_diagram_id: "", component_name: [""] },
            body: (params: { user_id: "", device_id: "", block_diagram_id: "", component_name: [""] }) => {
                return {
                    "user_id": params.user_id,
                    "device_id": params.device_id,
                    "block_diagram_id": params.block_diagram_id,
                    "component_name": params.component_name
                }
            }
        },
        getFunctionState: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/block_diagrams/functions/get_function_state" },
            bodyParams: { user_id: "", device_id: "", block_diagram_id: "", function_name_list: [""] },
            body: (params: { user_id: "", device_id: "", block_diagram_id: "", function_name_list: [""] }) => {
                return {
                    "user_id": params.user_id,
                    "device_id": params.device_id,
                    "block_diagram_id": params.block_diagram_id,
                    "function_name_list": params.function_name_list
                }
            }
        },
        componentFunctionsProcess: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/block_diagrams/functions/process_component_functions" },
            bodyParams: { device_id: "", function_name: "", parameter_name: "", token_name: "", component_name: "", block_diagram_id: "" },
            body: (params: { device_id: "", function_name: "", parameter_name: "", token_name: "", component_name: "", block_diagram_id: "" }) => {
                return {
                    "device_id": params.device_id,
                    "function_name": params.function_name,
                    "parameter_name": params.parameter_name,
                    "token_name": params.token_name,
                    "component_name": params.component_name,
                    "block_diagram_id": params.block_diagram_id
                }
            }
        },
        resetFunctions: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/Functions/Functions/Set_default" },
            bodyParams: { device_id: "", function_list: [""], component_name: "", block_diagram_id: "" },
            body: (params: { device_id: "", function_list: [""], component_name: "", block_diagram_id: "" }) => {
                return {
                    "device_id": params.device_id,
                    "function_list": params.function_list,
                    "component_name": params.component_name,
                    "block_diagram_id": params.block_diagram_id
                }
            }
        },
        vddCalculation: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/functional_view/vdd_calculation" },
            bodyParams: { vdd: 0, vol: "", voh: "", refVal: "", refFunction: "" },
            body: (params: { vdd: number, vol: "", voh: "", refVal: "", refFunction: "" }) => {
                return {
                    "vdd": params.vdd,
                    "vol": params.vol,
                    "voh": params.voh,
                    "refres_function": params.refFunction,
                    "refres_voh_vol_value": params.refVal,
                }
            }
        },
        voh_volValidation: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/functional_view/voh_vol_selection_validation" },
            bodyParams: { voh: "", vol: "", voh_selected_val: "", vol_selected_val: "", component_name: "" },
            body: (params: { voh: "", vol: "", voh_selected_val: "", vol_selected_val: "", component_name: "" }) => {
                return {
                    "voh": params.voh,
                    "vol": params.vol,
                    "voh_selected_val": params.voh_selected_val,
                    "vol_selected_val": params.vol_selected_val,
                    "component_name": params.component_name
                }
            }
        },
        slewRateCalculation: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/slew_rate/slewrate_calculation" },
            bodyParams: { vddio: 0, function: "" },
            body: (params: { vddio: number, function: "" }) => {
                return {
                    "vddio": params.vddio,
                    "function": params.function
                }
            }
        },
        devicePropertiesSearch: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/device_properties/device_properties/search" },
            bodyParams: { device_id: '' },
            body: (params: { device_id: '' }) => {
                { return { "filter": { "device_id": params.device_id }, "unset": {} } }
            }
        },
        devicePropertiesSearchByName: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/device_properties/device_properties/search" },
            bodyParams: { device_id: '', name: '' },
            body: (params: { device_id: '', name: '' }) => {
                { return { "filter": { "device_id": params.device_id, "name": params.name }, "unset": {} } }
            }
        },
        functionSearch: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return '/Functions/Functions/search' },
            bodyParams: { functionName: '' },
            body: (params: { functionName: '' }) => {
                { return { "filter": { "name": params.functionName } } }
            }
        },
        customerCreate: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return '/customer_details/customer_details' },
            bodyParams: { user_id: "", customer_company: "", customer_address: "", customer_contact_name_title: "", customer_contact_number: 123 },
            body: (params: { user_id: "", customer_company: "", customer_address: "", customer_contact_name_title: "", customer_contact_number: 123 }) => {
                {
                    return {
                        "user_id": params.user_id,
                        "customer_company": params.customer_company,
                        "customer_address": params.customer_address,
                        "customer_contact_name_title": params.customer_contact_name_title,
                        "customer_contact_number": params.customer_contact_number,
                    }
                }
            }
        },
        customerSearch: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return '/customer_details/customer_details/search' },
            bodyParams: { _id: "" },
            body: (params: { _id: "" }) => {
                {
                    return {
                        "filter": {
                            "_id": params._id
                        }

                    }
                }
            }
        },
        customerDetailsSearch: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return '/customer_details/customer_details/search' },
            bodyParams: { user_id: "" },
            body: (params: { user_id: "" }) => {
                {
                    return { "user_id": params.user_id, }
                }
            }
        },
        marketSegmentSearch: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return '/market_segment/market_segment/search' },
            bodyParams: {},
            body: (params: any) => { return {} },
        },
        salesRegionSearch: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return '/sales_region/sales_region/search' },
            bodyParams: {},
            body: (params: any) => { return {} },
        },
        customerUpdate: {
            verb: "put",
            epParams: {},
            ep: (params: any) => { return '/customer_details/customer_detail' },
            bodyParams: { customer_details: { _id: "", customer_company: "", customer_address: "", customer_contact_name_title: "", customer_contact_number: 123 }, customer_detail_id: "" },
            body: (params: { customer_details: { _id: "", customer_company: "", customer_address: "", customer_contact_name_title: "", customer_contact_number: 123 }, customer_detail_id: "" }) => {
                return {
                    "customer_detail": {
                        "_id": params.customer_details._id,
                        "customer_company": params.customer_details.customer_company,
                        "customer_address": params.customer_details.customer_address,
                        "customer_contact_name_title": params.customer_details.customer_contact_name_title,
                        "customer_contact_number": params.customer_details.customer_contact_number
                    },
                    "customer_detail_id": params.customer_detail_id
                }
            }
        },
        realizeFrequency: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return 'block_diagrams/functions/realize_frequencies' },
            bodyParams: { device_id: "", block_diagram_id: "", frequencies_data: [{}] },
            body: (params: { device_id: "", block_diagram_id: "", frequencies_data: [{}] }) => {
                {
                    return {
                        "device_id": params.device_id,
                        "block_diagram_id": params.block_diagram_id,
                        "frequencies_data": params.frequencies_data
                    }
                }
            }
        },
        Server_check: {
            verb: "get",
            epParams: {},
            ep: (params: any) => { return "/" },
            bodyParams: {},
            body: () => { return "" },
        },
        // Temp Config CRUD APIs
        addTempConfig: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/temp_dc/temp_dc" },
            bodyParams: { device_id: "", name: "", created_by: "", configuration_steps: [], created_date: "", modified_date: "", description: "" },
            body: (params: { device_id: string, name: string, description: string, configuration_steps: any, created_by: string, created_date: string, modified_date: string }) => {
                return {
                    device_id: params.device_id, name: params.name, description: params.description, created_by: params.created_by,
                    created_date: params.created_date, modified_date: params.modified_date, configuration_steps: params.configuration_steps
                }
            },
        },
        tempDCSearch: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return 'temp_dc/temp_dc/search' },
            bodyParams: {},
            body: (params: {}) => {
                return {
                    "control": {
                        "filter": {},
                        "unset": {}
                    },
                    "expand": []
                }
            }

        },
        deleteTempConfig: {
            verb: "delete",
            epParams: {},
            ep: (params: any) => { return '/temp_dc/temp_dc' },
            bodyParams: { udc_uuid: '' },
            body: (params: { udc_uuid: string }) => {
                return { data: {"udc_uuid": params.udc_uuid} }
            }
        },
        //Driver's library API's
        establishDriver: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return `/driver/establish?protocol=${AppData.protocol}`},
            body: (params: {protocol: string}) => { return "" },
            bodyParams: { protocol: ""}
        },
        connectDriver: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return `/driver/connect?protocol=${AppData.protocol}`},
            bodyParams: { protocol: ""},
            body: (params: {protocol: string}) => {return ""}
        },
        disconnectDriver: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return `/driver/disconnect?protocol=${AppData.protocol}`},
            bodyParams: { protocol: ""},
            body: (params: {protocol: string}) => {return ""}
        },
        scanDriver: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return `/driver/scan?protocol=${AppData.protocol}`},
            bodyParams: {protocol: ""},
            body: (params: {protocol: string}) => {return ""}
        },
        readDriver: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return `/driver/read?protocol=${AppData.protocol}&slave_address=${AppData.slave_address}`},
            bodyParams: {slave_address:"",regData:[""],protocol:""},
            body: (params : {slave_address: string,regData: any,protocol: string}) => {
                return {
                    "register_address": params.regData
                  }
            }
        },
        writeDriver: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return `/driver/write?protocol=${AppData.protocol}&slave_address=${AppData.slave_address}`},
            bodyParams: {slave_address:"",regData:[""],protocol:""},
            body: (params : {slave_address: string,regData: any,protocol: string}) => {
                return {
                    "data_list": params.regData
                  }
            }
        },
        initializeTempConfig: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return '/configurator/temp_device_configuration/initialize'},
            bodyParams: {temp_configuration_id: "",user_details_id: "",slave_address: ""},
            body: (params: {temp_configuration_id: string,user_details_id: string,slave_address: string}) => {
                return {"temp_configuration_id": params.temp_configuration_id,"user_details_id": params.user_details_id,"slave_address":params.slave_address}
            }
        },
        createTempDCSteps: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return '/configurator/temp_device_configuration/create'},
            bodyParams: {data:{}},
            body: (params: {data:any}) => {
                return { "data_read_back": params.data}
            }
        },
        compareConfig: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return `/udc_compare/configuration_evaluate?device_configuration_id=${AppData.deviceConfig.udc_uuid}&user_configuration_id=${AppData.currentConfigId}`},
            bodyParams: {device_id: ""},
            body: (params: {device_id: string}) => {return ""}
        },
        percentSpread: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return '/Functions/ssc_fodo/percent_spread' },
            bodyParams: {mode: "",device: "",function_name: ""},
            body: (params : {mode: string,device: string,function_name: string}) => {
                return {
                    "mode": params.mode,
                    "device": params.device,
                    "function_name": params.function_name
                }
            }
        },
        overWriteConfig: {
            verb: "put",
            epParams: {},
            ep: (params: any) => { return "/user_device_configurations/user_device_configurations" },
            bodyParams: { configuration_steps: [], udc_uuid: "" },
            body: (params: { configuration_steps: any, udc_uuid: string }) => {
                return { "user_device_configuration": {  "configuration_steps": params.configuration_steps }, "udc_uuid": params.udc_uuid }
            },
        },
        registerSearchWithAddress: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/registers/registers/search" },
            bodyParams: { device_id: "",address: "" },
            body: (params: { device_id: string ,address: string }) => { return { "filter": {device_id: params.device_id,"address": params.address}, "unset": {} } },
            extract: (item: any) => { return ({ _id: item._id}) }
        },
        getRegisterStates: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/configurator/register/search" },
            bodyParams: {regList: ['']},
            body: (params: { regList: any }) => { return {
                "register": params.regList
              }}
        },
        deleteTCRegisterStates: {
            verb: "delete",
            epParams: {},
            ep: (params: any) => { return "/temp_register_state/temp_register_state" },
            bodyParams: {regList: ['']},
            body: (params: { regList: any }) => { return { data:{ 
                "temp_register_state_id": params.regList
              }}
        }},
        getTCRegisterStates: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/temp_register_state/temp_register_state/search" },
            bodyParams: {config_uuid: ""},
            body: (params: { config_uuid: any }) => { return { 
                "config_uuid": params.config_uuid
              }}
        },
        getFieldValue: {
            verb: "post",
            epParams: {},
            ep: (params: any) => { return "/get_token_state_value/get_token_state_value" },
            bodyParams: {register_address: "",field_name: "",state: "",device_id: ""},
            body: (params: { register_address: any ,field_name: any,state: any,device_id : any}) => { return { 
                "register_address": params.register_address ,
                "field_name": params.field_name ,
                "state":params.state,
                "device_id": params.device_id
              }}
        },
        
    },


    User: {
        username: '',
        session_id: '',
        token: '',
        data: {} as any
    },

    localServer: configData.WEB_ONLY == 0 ? new RestServer(configData.LOCAL_SERVER_URL, 2000) : new RestServer(configData.REMOTE_SERVER_URL, 2000),
    remoteServer: new RestServer(configData.REMOTE_SERVER_URL, 2000),
    deviceServer: new RestServer(configData.DEVICE_SERVER_URL, 2000),
    currentConfig: {
        _id: "", device_id: "", udc_uuid: "", platform: "", variant: "", silicon_revision: "", name: "", frozen: "", custom_part_number: "", description: "", customer_details_id: "", product_details: {
            market_segment: "", application_description: "",
            end_product_program_name: "",
            mass_production_date: ""
        }, sales_details: {
            sales_region: "", email_pdf_Datasheet_to: "",
            sales_comments: ""
        }
    },
    frequencies_data: [{} as any], freqData: [{} as any],
    historyData: {},
    device: {
        columns: [{ field: 'platform', header: 'Platform', filter: true, sort: true, width: "140px" }, { field: 'variant', header: 'Variant', filter: true, sort: true, width: "130px" }, { field: 'silicon_revision', header: 'Version', filter: true, sort: true, width: "110px" }],
        data: {
            remote: [] as any,
            local: [{}],
        },
    },
    userConfig: {
        syncColumns: [{ field: 'platform', header: 'Platform', filter: true, sort: true, width: "250px" }, { field: 'name', header: 'Config', filter: true, sort: true, width: "250px" }],
        emulationColumns: [{ field: 'platform', header: 'Platform', filter: true, sort: true, width: "250px" }, { field: 'variant', header: 'Variant', filter: true, sort: true, width: "150px" }, { field: 'silicon_revision', header: 'Version', filter: true, sort: true, width: "120px" }, { field: 'name', header: 'Config', filter: true, sort: true, width: "250px" }],
        data: {
            remote: [] as any,
            local: [{}],
            recent: [{}]
        },
    },
    history: {
        columns: [{ field: 'source', header: 'Source', filter: true, sort: true, width: "350px" }, { field: 'name', header: 'Field name', filter: true, sort: true, width: "300px" }, { field: 'value', header: 'Field value', filter: true, sort: true, width: "250px" }],
        data: {
            remote: [{}],
            local: [{}],
        },
    },
    register: {
        columns: [{ field: 'name', header: 'Name', filter: true, sort: true, width: "300px" }, { field: 'address', header: 'Address', filter: true, sort: true, width: "200px" }, { field: 'value', header: 'Value', filter: true, sort: true, width: "100px" }],
        data: [{} as any]
    },
    blockDiagramData: [{} as any],
    blockDiagramFields: [{} as any],
    devicePropertyData: [{} as any],
    functions: [{} as any],
    functionFormData: { "param": [{} as any], "commonfunctions": [{} as any], "delay": [{} as any], "type": [{} as any], "differential": [{} as any], "single": [{} as any], "op": [{} as any], "singlecommon": [{} as any], "singlevoh": [{} as any], "diffvoh": [{} as any], "slew": [{} as any], "diffref": [{} as any], "freq": [{} as any], "vdd": [{} as any] },
    componentName: '',
    fieldState: '',
    block: false,
    activity: '',
    label: '', type: '', vohid: '', volid: '', refId: '', refIdList: [] as any,mode: '', signal: '', checkoutp: '', checkoutn: '', sequenceNum: { sequence: "" }, vddVal: 0, refFunction: "", slewFunctionCLKP: '', slewFunctionCLKN: '', vohVal: "", volVal: "", volData: "", vohData: "",CLK_P: "",CLK_N:"",
    username: '', devicelist: [''], server: '',
    freezeFormData: { "freeze": [{} as any] }, freeze: [] as any, freezeData: [] as any, variant: '', checkClkPId: '', checkClkNId: "", radioClkPId: "", radioClkNId: "", signalingId: "", componentList: [] as any, compData: [[] as any],
    functionViewData: [{} as any],
    vohList: [] as any,
    volList: [] as any, summaryData: [] as any, labelList: [] as any, compList: [] as any, freqList: [] as any, alert: '',
    FUNCTIONALDIAGRAM: 'FUNCTIONAL DIAGRAM',
    GPIOCONFIGURE: 'GPIO DIAGRAM CONFIGURE',
    GPIONONE: 'GPIO DIAGRAM NONE',
    GPIOI2C: 'GPIO DIAGRAM I2C',
    GPIOSPI: 'GPIO DIAGRAM SPI', signalList: [] as any, clkPlist: [] as any, clkP: false, clkN: false, clkNlist: [] as any,vddVallist: [] as any,
    clkPDataList: [] as any, clkNDataList: [] as any,deviceFrequenciesData: [] as any,deviceFrequenciesDataDiff: [] as any,deviceFrequenciesDataSE: [] as any,
    disableDiff: false, disableLVCMOS: false,
    pinDiagramData: [{} as any], GPIOstate: '', pinComp: '', diagramName: '', ppmValue: "",
    Frequency: "", solutionData: [[] as any],
    CustomerData: [[] as any], vdd: [], html: "", direction: "",
    PdfClose: false, customerDetails: [] as any, vddList: [] as any, vddaList: [] as any, vddState: '', vddaState: '',
    Customer: { comapany_name: "", address: "", name: "", number: '' }, marketSegment: [] as any, salesRegion: [] as any, marketDetails: [] as any, salesDetails: [] as any, marketId: "", salesId: "", application_description: "",
    end_product_program_name: "", mass_production_date: "", email_pdf_Datasheet_to: "", sales_comments: "", limit: [] as any, errorLimit: '', clkList: [] as any,
    connectedDevice: '', connectedDeviceId: '', connectedDevicePlatform: '',protocol: '',slave_address: '',currentConfigId: "",loginScreen: false,mainUrl: "",
    deviceConfig: {
        _id: "",configuration_steps: [] as any, device_id: "", udc_uuid: "", platform: "", variant: "", silicon_revision: "", name: "", frozen: "", custom_part_number: "", description: "", customer_details_id: "", product_details: {
            market_segment: "", application_description: "",end_product_program_name: "",mass_production_date: ""}, sales_details: {
            sales_region: "", email_pdf_Datasheet_to: "",sales_comments: ""}},
    comparedData: {"UserConfigurations":[],"DeviceConfigurations":[]},enabled:"",registerList: [] as any, connected: false

}
export default AppData;