import { useState } from "react";
import AppData from "../../../appData";
import { SummaryView, getBlockDiagramData } from "../configurationScreen/configurationEvents";
import { getPinDiagramData, getRealTimePinDiagramData, processGPIO } from "../blockDiagramScreen/blockDiagramEvents";

async function addTempConfig() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //if single digit pad 0 at start
    var yyyy = today.getFullYear();
    var date = dd + '-' + mm + '-' + yyyy;
    var result = {
        device_id: AppData.connectedDeviceId,
        name: `Device Configuration ${AppData.connectedDevice}`,
        created_by: AppData.User.data._id,
        configuration_steps: [],
        created_date: date,
        modified_date: date,
        description: ''
    }
    AppData.api.addTempConfig.bodyParams = result
    await AppData.localServer.sendCommand(AppData.api.addTempConfig, "Uploading User config to local server failed.", 1)
}
async function addUserConfig(data: any, setLoader: any) {
    const tempDC = await tempDCSearch()
    AppData.deviceConfig = tempDC[0]
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //if single digit pad 0 at start
    var yyyy = today.getFullYear();
    var date = dd + '-' + mm + '-' + yyyy;
    var result = {
        device_id: AppData.connectedDeviceId,
        name: data[1].value,
        created_by: AppData.User.data._id,
        configuration_steps: AppData.deviceConfig.configuration_steps,
        created_date: date,
        modified_date: date,
        description: data[2].value,
        frequencies: {}
    }
    AppData.api.addConfig.bodyParams = result
    const configResult = await AppData.localServer.sendCommand(AppData.api.addConfig, "Creating user config failed", 1)
    console.log('configresult', configResult)
    await initializeRealtimeConfig(configResult[0],setLoader)
    await getRealTimePinDiagramData(AppData.server, AppData.GPIOI2C,AppData.connectedDeviceId,AppData.currentConfig.platform,AppData.currentConfig.variant)
    await processGPIO('GPIO4', 'I2C_SPI_NONE', 'Mode', 'I2C')
    await processGPIO('GPIO4', 'Address', 'Address', AppData.slave_address)
    setLoader(true)
}
async function tempDCSearch() {
    var result: any
    result = await AppData.localServer.sendCommand(AppData.api.tempDCSearch, "temperory device search failed", 1)
    return result
}
async function deleteTempConfig(udc_uuid: string) {
    AppData.api.getTCRegisterStates.bodyParams = { config_uuid: udc_uuid }
    const result = await AppData.localServer.sendCommand(AppData.api.getTCRegisterStates,`getting temp register states failed`)
    var regList : string[] = []
    for (const data in result) {
        regList.push(result[data]._id)
    }
    AppData.api.deleteTCRegisterStates.bodyParams = { regList: regList}
    await AppData.localServer.sendCommand(AppData.api.deleteTCRegisterStates,`Deleting temp register state failed`)
    AppData.api.deleteTempConfig.bodyParams = { udc_uuid: udc_uuid }
    await AppData.localServer.sendCommand(AppData.api.deleteTempConfig, "Deleting temporary config failed", 1)
}

async function initializeRealtimeConfig(selectedRowConfig: any,setLoader:any) {
    try{AppData.currentConfigId = selectedRowConfig.udc_uuid
    AppData.api.initialyzeContext.bodyParams = { 'configuration_id': selectedRowConfig.udc_uuid, 'user_details_id': AppData.User.data._id }
    await AppData.localServer.sendCommand(AppData.api.initialyzeContext, "Setting the user config to work on config selection", 1)
    await AppData.localServer.sendCommand(AppData.api.generateState, "Generating states and bringing the states to the head", 1)
    const result = await AppData.localServer.sendCommand(AppData.api.currentConfig, `Fetching current config failed`)
    result.variant = AppData.connectedDevice
    AppData.api.deviceSearchWithId.bodyParams = { _id: AppData.connectedDeviceId }
    var currentDevice = await AppData.localServer.sendCommand(AppData.api.deviceSearchWithId, `Getting current Device failed`, 1)
    result.platform = currentDevice[0].platform
    AppData.currentConfig = result
    AppData.server = 'local'}
    catch{setLoader(false)}
}
async function connectDevice(setLoader: any) {
    var driverError:any
    try {
        let result = []
        try{
            result = await AppData.deviceServer.sendCommand(AppData.api.scanDriver, `Scanning the device failed`, 1)
        }
        catch(e:any){
            console.log("message",e["message"])
            // console.log('driver error',e['message'].search("driver_error.semantic"))
            // console.log(e['message'].search("driver_error.semantic"))
            driverError=(e['message'].search("Channel not found"))
            await AppData.deviceServer.sendCommand(AppData.api.disconnectDriver,`Failed to disconnect device`)
            return
        }
        await AppData.deviceServer.sendCommand(AppData.api.establishDriver, `Connecting the device failed`)
        await AppData.deviceServer.sendCommand(AppData.api.connectDriver, 'Connecting the device failed')
        AppData.api.registerSearch.bodyParams = { 'device_id': AppData.connectedDeviceId }
        const regData = await AppData.localServer.sendCommand(AppData.api.registerSearch, `Getting register state failed`)
        let registerList: string[] = []
        await regData.map((register: any) => {
            registerList.push(register["address"])
        })
        console.log('regData', registerList)
        AppData.slave_address = result["slaveaddress"]
        AppData.api.readDriver.bodyParams = { 'protocol': AppData.protocol, 'slave_address': result["slaveaddress"], "regData": registerList }
        const registerAddress = await AppData.deviceServer.sendCommand(AppData.api.readDriver, `Reading the device failed`, 1)
        //XOR with 32 for 0x0 to 0x3
        const valuesRead = registerAddress["data_read_back"]
        var regAddress = Object.keys(valuesRead)
        for (const key of regAddress) {
            if(AppData.connectedDevicePlatform == "Neolite-4" && 
                (key ==  "0x0" ||  key ==  "0x1" ||  key ==  "0x2" ||  key ==  "0x3")) {
                    console.log("Before Update" + valuesRead[key])
                    var hexString = "0x20"
                    if(valuesRead[key] != "0x0"){
                        var val1 = parseInt(valuesRead[key], 16)
                        var val2 = parseInt(hexString, 16)
                        var value = val1 ^ val2
                        valuesRead[key] = value.toString(16)
                        console.log("After Update" + valuesRead[key])
                    }
                }
        }
        AppData.api.initializeTempConfig.bodyParams = { 'slave_address':result["slaveaddress"],'temp_configuration_id': AppData.deviceConfig.udc_uuid, "user_details_id": AppData.User.data._id }
        await AppData.localServer.sendCommand(AppData.api.initializeTempConfig, `Initializing temp configuration failed`)
        AppData.api.createTempDCSteps.bodyParams = { "data": registerAddress["data_read_back"] }
        await AppData.localServer.sendCommand(AppData.api.createTempDCSteps, `Creating temp DC steps failed`)
    }
    catch {
        if(driverError !== -1){
            setLoader(false)
            alert('No channels detected. Please check the device connection.')
            return 'Error'
        }
        else{
            setLoader(false)
            alert('Interface not supported') 
            return 'Error'
        }
    }
}
async function compareConfig() {
    const result = await AppData.localServer.sendCommand(AppData.api.compareConfig, `comparing configuration failed`)
    return result
}
async function overwriteConfig(setLoader: any) {
    setLoader(true)
    const tempDC = await tempDCSearch()
    AppData.deviceConfig = tempDC[0]
    AppData.api.overWriteConfig.bodyParams = { "configuration_steps": AppData.deviceConfig.configuration_steps, "udc_uuid": AppData.currentConfig.udc_uuid }
    const res = await AppData.localServer.sendCommand(AppData.api.overWriteConfig, `Overwriting Configuration steps failed`)
    await initializeRealtimeConfig(res,setLoader)
    await getBlockDiagramData(AppData.currentConfig, AppData.server)
    AppData.componentList = []
    AppData.blockDiagramData[0].place.map((placeData: any) => {
        if ((placeData.type === 'Driver') && (placeData.clickable !== 'NO')) {
            AppData.componentList.push(placeData.name)
            AppData.labelList.push(placeData.label)
        }
    })
    await SummaryView()
}
async function disconnectDriver(){
    await AppData.deviceServer.sendCommand(AppData.api.disconnectDriver,`Failed to disconnect device`)
}
export { disconnectDriver,overwriteConfig, compareConfig, connectDevice, addUserConfig, addTempConfig, tempDCSearch, deleteTempConfig, initializeRealtimeConfig }