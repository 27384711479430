import React, { useEffect, useRef, useState } from "react";
import AppData from "../../../appData";
import { Button } from "../../../components/button/button";
import FlexBox from "../../../components/flexBox/flexBox";
import GridContainer from "../../../components/gridContainer/gridContainer";
import InputString from "../../../components/inputString/inputString";
import PassWord from "../../../components/passWord/passWord";
import { Password } from "../../../components/passWord/passWordStyles";
import Logo from "../../logo/logo";
import { signinValidate } from "./signinEvents";
import { Server } from "../../../appEvents";
import DialogBox from "../../../components/dialogBox/dialogBox";

function SigninScreen({ onSigninSuccess = () => { }, onClickSignup = () => { } }) {
    const userDetails = useRef({ userName: "", passWord: "" })
    const [signinUser, setsigninUser] = useState('')
    const [alert, setalert] = useState('')
    const [Loginwait, setLoginwait] = useState(false)
    const [validate, SetValidate] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [password, setPassword] = useState(false)
    const [passVal,setPassVal] = useState('')
    const [active,setActive]=useState(false)
    const [valpassword, setvalPassword] = useState(false)
    let [online, isOnline] = useState(navigator.onLine);

    const submit = () => {
        signinValidate(showPassword, setShowPassword, AppData.username, userDetails.current.passWord, onSigninSuccess, setsigninUser, setLoginwait, setalert, SetValidate, setPassword, setvalPassword)
        console.log(showPassword)

    }
    console.log(online, 'onlinee')
    const handleKeywordKeyPress = (e: React.KeyboardEvent) => {
        if (e.key == 'Enter') {
            submit()
        }
    };
    const setOnline = () => {
        console.log('We are online!');
        isOnline(true);
    };
    const setOffline = () => {
        console.log('We are offline!');
        isOnline(false);
    };

    // Register the event listeners
    useEffect(() => {
        window.addEventListener('offline', setOffline);
        window.addEventListener('online', setOnline);

        // cleanup if we unmount
        return () => {
            window.removeEventListener('offline', setOffline);
            window.removeEventListener('online', setOnline);
        }
    }, []);

    return (
        <GridContainer shape="signin" layout={showPassword ? "password" : "signin"} fill='signin' >
            <FlexBox gridName="b" shape="hug">
            <DialogBox shape="server" Buttoncontent={[]} position='middle-center' visible={active} text={'Please check the server and refresh the application'} spacing="server" fill="dialog" font="server" hide={setActive} ></DialogBox>
                <FlexBox layout="vertical-top-left" shape={showPassword ? "signinoutpass" : 'signinout'} spacing='signintext'>
                    <FlexBox shape="signinpass" layout="vertical-top-left" spacing="signin" fill='signin'>
                        <FlexBox shape='siTimelogo' layout="vertical-top-left">
                            <div style={{ height: "25.77px", width: '5.6640625vw' }}><Logo /></div>
                        </FlexBox>
                        <FlexBox layout="vertical-top-left">
                            <FlexBox shape="signinHeader" layout="vertical-top-left" spacing="welcome">
                                <p style={{ margin: '0px', width: '111px', height: '39px', color: " #231F20", fontStyle: 'normal', fontSize: '26px', fontWeight: '700', lineHeight: '150%' }}>Welcome</p>
                                {/* {showPassword ?<p style={{ margin: '0px', color: '#888888', fontWeight: '500', fontSize: '16px', fontFamily: 'Inter', fontStyle: 'normal' }}>Please enter the password to continue</p>: */}
                                <p style={{ margin: '0px', color: '#595A5A', fontWeight: '400', fontSize: '14px', fontStyle: 'normal', lineHeight: '150%' }}>Please sign in to continue</p>
                            </FlexBox>
                            <FlexBox shape="signinButton" layout="vertical-top-left" spacing="none">
                                <p style={{ margin: '0px', color: " #231F20", fontWeight: '400', fontStyle: 'normal', fontSize: '12px' }}>Email</p>
                                <InputString Error={validate} errorMessage={'User Does not exist!! Please SignUp..'} onKeyPress={handleKeywordKeyPress} placeholder="Enter your email" shape="signin" fill="signin" stroke="signin" spacing="frequency" regex={undefined} fonts={""} getValue={(un: string) => { userDetails.current.userName = un; AppData.username = un; }} content={""}></InputString>
                                {showPassword && !validate ? <p style={{ margin: '0px', color: " #1E1E1E", fontWeight: '500', fontStyle: 'normal', fontSize: '12px' }}>Password</p> : null}
                                {showPassword && !validate ?
                                    <PassWord showEyeIcon={false} spacing="password" Error={valpassword} errorMessage={'Invalid Password!! Please contact admin..'} placeHolder="Enter a password" shape="signin" fill="signin" stroke="none" getValue={(up: string) => {userDetails.current.passWord = up;setPassVal(up)}} regex={undefined} onKeyPress={passVal!='' && handleKeywordKeyPress} /> : null}
                                <div style={{ margin: "10px 0px 6px 0px", width: "21.6796875vw" }}><Button color="signin" fill="signin" shape="signin" onClick={submit} disabled={(showPassword)&&(passVal==='')?true:false}>Sign in</Button></div>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox shape="signinFinal" layout="vertical-top-left" spacing="none">
                        {showPassword ? <FlexBox shape="signinFinal" layout="horizontal-bottom-left" spacing="none"><p style={{ margin: '10px 0px 0px', display: 'flex', color: '#595A5A', fontWeight: '400', fontSize: '14px', fontStyle: 'normal', paddingRight: '5px' }}>Forgot password</p>
                            <p style={{ margin: '10px 0px 0px 0px', color: '#3399D5', fontWeight: '400', fontSize: '14px', fontStyle: 'normal', cursor: 'pointer' }}>Contact us</p></FlexBox> : <p style={{ margin: '0px', color: '#595A5A', fontWeight: '400', fontSize: '14px', fontStyle: 'normal', display: 'flex' }}>Don't have an account?
                            <p onClick={onClickSignup} style={{ margin: '0px 0px 0px 3px', color: '#3399D5', fontWeight: '400', fontSize: '14px', fontStyle: 'normal', cursor: 'pointer' }}> Sign Up</p></p>}
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </GridContainer>
    )
}

export default SigninScreen