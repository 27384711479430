import styled, { css } from 'styled-components';

interface MenuStyleIF {
    shape: string;
    stroke: string;
    fill: string;
    effects: string;
    spacing: string;
    fontSize:string
    animated: boolean;

};

const Container = styled.div<MenuStyleIF>`
  display:block;
  position:absolute;
  z-index:1;
  box-sizing: border-box;
  color:${props => props.theme.menu.fill[props.fill].fontcolor};
  width:${props => props.theme.menu.shape[props.shape].width};
  height:${props => props.theme.menu.shape[props.shape].height};
  border:${props => props.theme.menu.stroke[props.stroke]};
  background-color:${props => props.theme.menu.fill[props.fill].normal};
  border-radius:${props => props.theme.menu.shape[props.shape].radius};
  box-shadow:${props => props.theme.menu.effects[props.effects]};
  li{
    list-style-type: none;
    box-sizing: border-box;
    position:relative;
    font-size:${props => props.theme.menu.fontSize[props.fontSize]};
    padding:${props => props.theme.menu.spacing[props.spacing].padding};
  }
  ${props => props.animated && css` 
    li:hover {
        cursor:pointer;
        transition: ${props => props.theme.menu.transition};
        background: ${props => props.theme.menu.fill[props.fill].hover};
        border-radius:${props => props.theme.menu.shape[props.shape].radius}; 
      }
    `
    } 
 `;

const Iconstyle = styled.div`
  margin:8px 8px 8px 0px;
  display:inline;
`;

export { Container, Iconstyle };
