
import React from 'react';
import styled, { css } from 'styled-components';

interface TextareaIF {
    shape: string
    stroke: string
    fill: string;
    disabled: boolean;
    font: string;
    spacing: string;
    resize: boolean
}
const Textarea = styled.textarea<TextareaIF>`
   overflow:scroll;
   outline:none;
   resize: ${props => props.resize?'':'none'};
   width:${props => props.theme.inputtextarea.shape[props.shape].width};
   height:${props => props.theme.inputtextarea.shape[props.shape].height};
   background:${props => props.theme.inputtextarea.fill[props.fill]};
   border-radius:${props => props.theme.inputtextarea.shape[props.shape].radius};
   border:${props => props.theme.inputtextarea.stroke[props.stroke]};
   color:${props => props.theme.inputtextarea.font[props.font].color};
   padding:${props => props.theme.inputtextarea.spacing[props.spacing].padding};
   ${props => props.disabled ?
        css`
        opacity: 0.5;
        cursor: default;`:
        css`
        opacity: 1;
        `
    }
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  
      scrollbar-width: none; 
    }
    

`
export { Textarea }