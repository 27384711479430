import { MessageThemeIF } from "../components/messages/messagesThemeIF";
const MessageTheme: MessageThemeIF = {
    shape: {
        normal: { width: "500px", height: "50px", radius: "10px" },
        large: { width: "980px", height: "50px", radius: "10px" },
        maxcontent: { width: "max-content", height: "max-content", radius: "10px" },
        auto: { width: "auto", height: "auto", radius: "10px" },
        config: { width: "auto", height: "55px", radius: "0px" },
    },
    stroke: {
        thick: { info: "8px solid #696cff", success: "8px solid #1ea97c;", error: " 8px solid #ff5757", warn: "8px solid #cc8925" },
        thin: { info: "5px solid #696cff", success: "5px solid #1ea97c", error: " 5px solid  #ff5757", warn: "5px solid #cc8925" }
    },
    fill: {
        normal: { info: "#e9e9ff", success: " #56BA64", error: "#ffe7e6", warn: "#fff2e2" },

    },  
    font: {
        normal: { size: '2.0rem', successcolor: "#1ea97c", errorcolor: "#ff5757", infocolor: "#696cff", warncolor: " #cc8925" },
        medium: { size: '2.1rem', successcolor: "#1ea97c", errorcolor: "#ff5757", infocolor: "#696cff", warncolor: "#cc8925" },
        config: { size: '14px', successcolor: "white", errorcolor: "#ff5757", infocolor: "#696cff", warncolor: "#cc8925" },
    },
    transition: "all 0.5s",
}
export default MessageTheme;