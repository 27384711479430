import React, { useState } from 'react';
import { SelectStyle, PStyle } from "./selectButtonStyles"
import FlexBox from '../flexBox/flexBox';
import AppData from '../../appData';
interface PropsIF {
    values: string[];
    onClick: (item: any) => void;
    type?: "first" | "last" | "middle";
    select: "multi" | "single";
    orientation: "vertical" | "horizontal"
    animated?: boolean
    disabled: boolean[];
    shape?: string
    spacing?: string
    stroke?: string
    fill?: string
    effects?: string
    font?: string
    initial?: any
}
const postion = (index: number, length: number) => (index == 0) ? "first" : (index == length - 1) ? "last" : "middle"

function SelectButton({initial, disabled, animated = true, orientation = "horizontal", type = "first", shape = "normal", fill = "normal", select = "single", stroke = "thin", font = "normal", values, spacing = "normal", effects = "none", onClick = (itemName: string | string[]) => { } }: PropsIF) {
    var buttonSelect = initial
    var onClick = onClick
    const [Selectbutton, setselectbutton] = useState<string[]>([]);
    const [singleSelect,setSingleSelect] = useState<any>(initial)
    function onClickHandler(item: string) {
        if (select == "single") {
            var currentItem = item
            setSingleSelect(currentItem)
            buttonSelect = currentItem
            onClick(currentItem)
            if(currentItem === 'Differential'){
                AppData.type='Differential'
            }
            else if(currentItem === 'Single Ended (LVCMOS)'){
                AppData.type='Single Ended (LVCMOS)'
            }
            console.log('select button',currentItem,AppData.type,singleSelect)
        }
        if (select == "multi") {
            setselectbutton(Selected => {
                const newItem = [...Selected]
                if (newItem.includes(item)) {
                    newItem.filter(id => id != item)
                    const id = newItem.indexOf(item)
                    newItem.splice(id)
                    onClick(newItem)
                    return newItem
                }
                else {
                    newItem.push(item)
                    onClick(newItem)
                    return newItem
                }
            })
        }
    }
    return (
        <>
            <SelectStyle orientation={orientation} shape={shape}>
                {values.map(
                    (item: string, i: number): JSX.Element => {
                        const colors = (select=='single'?buttonSelect==item:Selectbutton.includes(item))
                        return (
                            <PStyle effects={effects} disabled={disabled[i]} animated={animated} shape={shape} font={font} orientation={orientation} fill={fill} spacing={spacing} stroke={stroke} position={postion(i, values.length)} onClick={() => { onClickHandler(item) }} onselect={colors}>
                                {item}
                            </PStyle>
                        );

                    }
                )}
            </SelectStyle>
        </>
    )
}
export default SelectButton;
