import { useState } from 'react';
import { Button } from '../button/button';
import FlexBox from '../flexBox/flexBox';
import { Dialogbutton, Dialogbuttondiv, Dialog, DialogIcon, DialogInlet, Modal } from "./dialogBoxStyles"

interface PropsIF {
    onClick?: () => void;
    header?: string
    text?: string
    shape?: string
    stroke?: string
    fill?: string;
    animated?: boolean;
    effects?: string;
    iconName?: string;
    iconStroke?: string;
    Buttoncontent: string[];
    font?: string;
    icon?: boolean;
    OnclickYes?: any;
    OnclickNo?:any;
    visible?: any;
    hide?: any;
    spacing?:string
    position?: "top-left" | "top-center" | "top-right" | "middle-left" | "middle-center" | "middle-right" | "bottom-left" | "bottom-center" | "bottom-right"

}
function DialogBox({ onClick,spacing='none', position = "top-left", iconName = "alert-triangle", iconStroke = "", icon, visible, hide, OnclickYes,OnclickNo, font = "normal", animated = true, effects = "none", fill = "normal", shape = "normal", text, stroke = "none", header, Buttoncontent }: PropsIF) {
    const [Color, SetColor] = useState('')
    const [open, setOpen] = useState(visible);
    const Onclickyes = () => {
        (OnclickYes(true))
        hide(false)
    }
    const closing = (item: string) => (item == "Cancel") ? hide(false) : (item == "Delete") ? (Onclickyes(), hide(false)) : (item == 'Continue') ? (Onclickyes(), hide(false)) :(item==="Yes")?(OnclickYes(),hide(false)):(item==="No")?(OnclickNo(),hide(false)):"none"

    return (
        <Modal style={{ display: visible ? 'block' : 'none' }}>
            <Dialog spacing={spacing} shape={shape} stroke={stroke} position={position} fill={fill} effects={effects} font={font}>
                <DialogInlet font={font}>
                    {header}
                    <DialogIcon onClick={() => { hide(false) }}>
                        {/* <Icons iconName={'cancel'} size={'20px'} stroke={"black"} /> */}
                    </DialogIcon>
                </DialogInlet>
                 <p style={{position:'relative',marginTop:'40px',marginBottom:'30px'}}>{text}</p>
                {/* {icon ? <span style={{ marginRight: "5px" }}><Icons iconName={iconName} size={'14px'} stroke={iconStroke} /></span> : null} */}

                <Dialogbuttondiv>
                    {header==="Delete Configuration"?Buttoncontent.map((item: string): JSX.Element => {
                        return (
                            <Dialogbutton shape={shape} fill={item==="Delete"?fill:"normal1"} animated={animated} onClick={() => { closing(item) }} stroke={stroke}>
                                {item}
                            </Dialogbutton>
                        )
                    }):Buttoncontent.map((item: string): JSX.Element => {
                        return (
                            <Dialogbutton shape={shape} fill={fill} animated={animated} onClick={() => { closing(item) }} stroke={stroke}>
                                {item}
                            </Dialogbutton>
                        )
                    })}
                </Dialogbuttondiv>
            </Dialog>
        </Modal>
    );
};
export default DialogBox;