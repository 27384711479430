import React, { useEffect, useRef, useState } from 'react';
import { CalendarContainer } from "./calendarStyles";
import Calendar from 'react-calendar';

interface propsif {
  shape: string
  stroke: string
  font: string
  effects: string
  fill: string
  onClick?: (date: string) => void
  content?: string
}

export default function CalendarInputComp({content, font = "normal", onClick = (date: string) => { }, shape = 'hug', effects, stroke = "normal", fill = "normal" }: propsif) {
  const [date, setDate] = useState(new Date());
  const[ Value,setValue]=useState(content)
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef(null);



  
  return ( 
    <div style={{width:"100%"}} onMouseLeave={()=>{setIsFocused(false)}} onMouseDown={()=>{setIsFocused(true)}}>
        <input ref={ref} type="text" value={Value}  style={{outline:"none",padding:"9px 10px 9px 12px",width:"363px",border:"none",background:"#F0EFEF"}}     onFocus={() => setIsFocused(true)}  />
        {isFocused&&<CalendarContainer font={font} fill={fill} stroke={stroke} shape={shape} effects={effects} >
            <Calendar onChange={(value:any) => { onClick(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(value));setValue(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(value));setIsFocused(false); return (setDate(value)) }} value={Value} />
        </CalendarContainer>}
    </div>
  )
}
