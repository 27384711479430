import React from "react";
import styled, { css } from 'styled-components';

interface FormLayoutStyleIF {
    shape: string
    stroke:string
    fill:string
    spacing:string
}
const FormLayout = styled.div<FormLayoutStyleIF>`
  height: ${props => props.theme.popupform.shape[props.shape].height};
  width: ${props => props.theme.popupform.shape[props.shape].width};
  border-radius: ${props => props.theme.popupform.shape[props.shape].radius};
  border:${props => props.theme.popupform.stroke[props.stroke]};
  background:${props => props.theme.popupform.fill[props.fill]};
  padding:${props => props.theme.popupform.spacing[props.spacing].padding};
  gap:${props => props.theme.popupform.spacing[props.spacing].gap};
  float:right;
  overflow-y:scroll;
  @-webkit-keyframes {
    from {
      -webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(100px);
    }
    to {
      -webkit-transform: rotate(0deg) scale(2) skew(0deg) translate(100px);
    }
  }
  `
interface FormDataStyleIF{
  spacing:string
}
const FormDataStyle=styled.div<FormDataStyleIF>`
  display:block;
  height:100%;
  width:100%;
`

const Modal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 500;
`
export {Modal,FormLayout,FormDataStyle}