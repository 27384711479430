import { RadiobuttonThemeIF } from "../components/radioButton/radioButtonThemeIF";

const RadioButtonThemes: RadiobuttonThemeIF = {

    shape: {
        normal: { width: "20px", height: "20px", radius: "50px",spacing:'5px' },
        large: { width: "30px", height: "30px", radius: "100px",spacing:'0px' },
        small: { width: "10px", height: "10px", radius: "50px",spacing:"0px"},
    },
    stroke: {
        normal: { outerstroke: "2px solid #ced4da", innerstroke: "5px solid #6366F1" },
        thin: { outerstroke: "2px solid black ", innerstroke: "5px solid black" },
        thick: { outerstroke: "2px solid #495057; ", innerstroke: "8px solid #6366F1" },
    },
    fill: {
        normal: { normal: "white;", hover: "#6366F1" },
        medium: { normal: "red", hover: "#6366F1" },
        transparent: { normal: "none", hover: "none" },
    },
    font: {
        normal: { color: '#495057', size: '15px' },
        Dark: { color: 'black', size: '20px' }
    },
    transition: {
        normal: { normal: " 0.2s all linear;", innertransition: "width 0.2s ease-out, height 0.2s ease-out;" }
    }


}
export default RadioButtonThemes;