import { dropDownThemeIF } from "../components/dropDown/dropDownThemeIF"

const DropdownThemes: dropDownThemeIF = {
   shape: {
      none: { height: '50px', width: '100px', radius: '5px', dropdownheight: '100px',search:"" },
      medium: { height: '50px', width: '200px', radius: '', dropdownheight: '70px' ,search:''},
      large: { height: '50px', width: '250px', radius: '15px', dropdownheight: '80px',search:'' },
      popupform1:{height:'40px',width:'380px',radius:'0px',dropdownheight:'100px',search:""},
      mode:{height:'40px',width:'12vw',radius:'0px',dropdownheight:'100px',search:"10vw"},
      voh:{height:'40px',width:'25vw',radius:'0px',dropdownheight:'100px',search:"23vw"},
      device:{height:'40px',width:'300px',radius:'0px',dropdownheight:'100px',search:""},
   },
   stroke: {
      none: { buttonStroke: 'none', dropdownStroke: 'none' },
      normal: { buttonStroke: '1px solid red', dropdownStroke: '1px solid black' },
      thick: { buttonStroke: '2px solid black', dropdownStroke: '2px solid black' }

   },
   fill: {
      normal: { innerfill: 'none', outerfill: 'none' },
      grey: { innerfill: 'grey', outerfill: 'grey' },
      yellow: { innerfill: 'blue', outerfill: 'yellow' },
      popupform1:{innerfill:'white',outerfill:'#F0EFEF'}
   },
   effects: {
      none: "none",
      insetShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      dropShadow: " 3px 4px 4px 3px rgba(0, 0, 0, 0.25)",     
   },
   spacing: {
      normal: { gap: '10px', padding: '5px' }
   },
   transition: ''

}
export default DropdownThemes