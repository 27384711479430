
import { InputStringThemeIF } from "../components/inputString/inputStringThemeIF"

const InputStringThemes: InputStringThemeIF = {
    shape: {
        hug: { width: "200px", height: "auto", radius: "5px",iconwidth:'0px',iconheight:'0px' },
        medium: { width: "250px", height: "auto", radius: "10px" ,iconwidth:'0px',iconheight:'0px'},
        large: { width: "300px", height: "auto", radius: "15px",iconwidth:'0px',iconheight:'0px' },
        signin:{width:'333px',height:'50px',radius:'0px',iconwidth:'0px',iconheight:'0px'},
        signup:{width:'333px',height:'50px',radius:'0px',iconwidth:'0px',iconheight:'0px'},
        popupform1:{width:'380px',height:'40px',radius:'0px',iconwidth:'0px',iconheight:'0px'},
        Description:{width:'353px',height:'95px',radius:'0px',iconwidth:'0px',iconheight:'0px'},
        frequency:{width:'25vw',height:'auto',radius:'0px',iconwidth:'40px',iconheight:'40px'},
        serial:{width:"393px",height:"auto",radius:"0px",iconwidth:"0px",iconheight:"0px"},
        mode: {height:'40px',width:'12vw',radius:'0px',iconwidth:'0px',iconheight:"0px"}
    },
    spacing: {
        none: { gap: "0px", padding: '0px' },
        normal: { gap: "5px", padding: '5px' },
        medium: { gap: "10px", padding: '10px' },
        large: { gap: "15px", padding: '15px' },
        popupform1:{ gap: "0px", padding: '9px 10px 9px 12px' },
        frequency:{gap:'5px',padding:'9px 10px 9px 12px'}
    },
    stroke: {
        none: 'none',
        normal: "1px solid black",
        thick: "6px solid black",
    },
    fill: {
        none: {inputfill:"white",iconfill:'inherit'},
        red: {inputfill:"red",iconfill:'inherit'},
        black: {inputfill:"black",iconfill:'inherit'},
        pink: {inputfill:"pink",iconfill:'inherit'},
        signin:{inputfill:"#F0EFEF",iconfill:'inherit'},
        signup:{inputfill:"#F0EFEF",iconfill:'inherit'},
        popupform1:{inputfill:"#F0EFEF",iconfill:'inherit'},
        functional:{inputfill:"#F0EFEF",iconfill:'#3399D5'},
    },
    font: {
        none: { color: 'black', style: 'none', size: '14px' },
        normal: { color: 'black', style: 'none', size: '14px' },
        medium: { color: 'red', style: 'none', size: '30px' },
    },
}

export default InputStringThemes
