import { useEffect, useState } from 'react';
import Icons from '../../icons/icons';
import { Button } from '../button/button';
import FlexBox from '../flexBox/flexBox';
import { Messagetext, MessageIcon, Messagecontainer } from './messagesStyles';
interface PropsIF {
    onClick?: () => void;
    type?: "success" | "warn" | "info" | "error"
    text?: string
    shape?: string
    stroke?: string
    fill?: string;
    visible?: any;
    hide?: any;
    font?: string;
    position?: "top-left" | "top-center" | "top-right" | "middle-left" | "middle-center" | "middle-right" | "bottom-left" | "bottom-center" | "bottom-right"

}
function Message({ onClick, font = "normal", position = "top-left", visible, fill = "normal", type = "success", shape = "normal", text, stroke = "thin", hide }: PropsIF) {

    const [active, setactive] = useState(visible)
    function closeItem() {
        hide(false)
    }
    return (
        <Messagecontainer shape={shape} stroke={stroke} fill={fill} font={font} position={position} type={type} style={{ display: visible ? 'flex' : 'none' }}>
            <Messagetext>
                {text}
            </Messagetext>
            <MessageIcon onClick={closeItem}>
                <Icons iconName={'cancel'} size={'20px'} />
            </MessageIcon>
        </Messagecontainer>
    )
}
export default Message