import React from 'react';
import styled, { css } from 'styled-components';

interface InputIF{
    disabled:boolean
}
const Input = styled.div<InputIF>`
color:red;
pointer-events: ${props=>props.disabled==true?'none':''};
opacity: ${props=>props.disabled==true?'0.4':''};
`;
const Checkboxstyle = styled.input`
apperance:none;
border-radius:5px;
width:18px;
height:18px;
position:relative;
`;
export {Input,Checkboxstyle}