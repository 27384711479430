import React from 'react';
import styled from 'styled-components';

interface FlexBoxStyleIF {
    shape: string
    layout: string
    spacing: string
    stroke: string
    fill: string
    effects:string
    gridName:string
}


const FlexBoxStyle = styled.div <FlexBoxStyleIF>`
    box-sizing:border-box;
    display:flex ;
    flex-direction:${props => props.theme.flexBox.layout[props.layout].direction};
    justify-content: ${props => props.theme.flexBox.layout[props.layout].justifyContent};
    align-items:${props => props.theme.flexBox.layout[props.layout].alignItems};
    gap: ${props => props.theme.flexBox.spacing[props.spacing].gap};
    padding: ${props => props.theme.flexBox.spacing[props.spacing].padding};
    height: ${props => props.theme.flexBox.shape[props.shape].height};
    width: ${props => props.theme.flexBox.shape[props.shape].width};
    border-radius: ${props => props.theme.flexBox.shape[props.shape].radius};
    background: ${props => props.theme.flexBox.fill[props.fill]};
    border: ${props => props.theme.flexBox.stroke[props.stroke].outline};
    box-shadow: ${props => props.theme.flexBox.effects[props.effects]};
    grid-area:${props => props.gridName};
    position:relative;
    border-bottom:${props => props.theme.flexBox.stroke[props.stroke].borderBottom}
    `;
export default FlexBoxStyle