import React from 'react';
import styled, { css } from 'styled-components';

interface SelectstyleIF {
    orientation: "vertical" | "horizontal"
    shape: string;
}
const SelectStyle = styled.div<SelectstyleIF>`

   display:flex;
   flex-direction:${props => props.orientation == "vertical" ? "column" : "row"};
   border-radius:${props => props.theme.Selectbutton.shape[props.shape].radius}
    `;

const borderRight = (inp: string, position: string, direction: string) => (direction == "vertical") ? inp : (position == "last") ? inp : "none"
const borderBottom = (inp: string, position: string, direction: string) => (direction == "horizontal") ? inp : (position == "last") ? inp : "none"
const borderRadius = (inp: string, position: string, direction: string) => (
    (direction == "vertical") ? (position == "middle") ? `0px` : (position == "first") ? ` ${inp} ${inp} 0px 0px` : `0px 0px ${inp} ${inp}` :
        (position == "middle") ? `0px` : (position == "first") ? `${inp} 0px 0px ${inp}` : `0px ${inp} ${inp} 0px`
)

interface pstyleIF {
    orientation: "vertical" | "horizontal"
    position: "first" | "last" | "middle"
    disabled: boolean
    animated: boolean
    shape: string
    fill: string
    spacing: string
    stroke: string
    effects: string
    onselect: boolean
    font: string
}
const PStyle = styled.div<pstyleIF>`
    display:flex ;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    position:relative;
    color: ${props => props.theme.Selectbutton.font[props.font].color};
    font-size:${props => props.theme.Selectbutton.font[props.font].size};
    font-style: ${props => props.theme.Selectbutton.font[props.font].style};
    gap: ${props => props.theme.Selectbutton.spacing[props.spacing].gap};
    padding: ${props => props.theme.Selectbutton.spacing[props.spacing].padding};
    height: ${props => props.theme.Selectbutton.shape[props.shape].height};
    width: ${props => props.theme.Selectbutton.shape[props.shape].width};
    border-radius: ${props => borderRadius(props.theme.Selectbutton.shape[props.shape].radius, props.position, props.orientation)}    ;
    background-color: ${props => (props.onselect === true) ? props.theme.Selectbutton.fill[props.fill].selectcolor : props.theme.Selectbutton.fill[props.fill].normal};
    border-left: ${props => props.theme.Selectbutton.stroke[props.stroke]};
    border-top: ${props => props.theme.Selectbutton.stroke[props.stroke]};
    border-right: ${props => borderRight(props.theme.Selectbutton.stroke[props.stroke], props.position, props.orientation)};
    border-bottom: ${props => borderBottom(props.theme.Selectbutton.stroke[props.stroke], props.position, props.orientation)};
    box-shadow: ${props => props.theme.Selectbutton.effects[props.effects]};
    pointer-events: ${props=>props.disabled==true?'none':''};
    ${props => props.disabled ?
        css`
        opacity: 0.5;
        cursor: default;`:
        css`
        opacity: 1;
        cursor: pointer;`
    }
    ${props => props.animated && !props.disabled && css` 
    &:hover {
        transition: ${props => props.theme.Selectbutton.transition};
        background: ${props => props.theme.Selectbutton.fill[props.fill].hover};
    }
    `
    } 
    ${props => !props.disabled && css` 
    &:focus {
        transition: ${props => props.theme.Selectbutton.transition};
        background: ${props => props.theme.Selectbutton.fill[props.fill].hover};
    }
   `
    }
   `;
export { SelectStyle, PStyle };
