import { LoadingSpinnerThemeIF } from "../components/loadingSpinner/loadingSpinnerThemeIF"


const LoadingSpinnerThemes: LoadingSpinnerThemeIF = {
    shape: {
        hug: { width: "50px", height: "50px", radius: "0px" },
        small: { width: "500px", height: "10px", radius: "0px" },
        medium: { width: "100px", height: "100px", radius: "0px" },
        large: { width: "200px", height: "200px", radius: "0px" },
    },
    stroke: {
        none: 'none',
        normal: 'solid 5px #cfd0d1',
        thick: "6px solid black",
    },
    fill: {
        none:"none",
        blue: "blue",
        grey: "grey",
        orange: 'orange',
        pink: 'pink',
    },
    innerfill: {        
        none: "none",
        red: "red",
        black: 'black',
        green: 'green',
        loader:"#B90B2E"
    },
    type:{
        circular:"circular",
        linear:"linear"
    }
}

export default LoadingSpinnerThemes