import { DialogThemeIF } from "../components/dialogBox/dialogBoxThemeIF";

const DialogTheme: DialogThemeIF = {
    shape: {
        normal: { width: "380px", height: "100px", radius: "5px", buttonradius: "3px", buttonheight: "auto", buttonwidth: "auto" },
        large: { width: "580px", height: "100px", radius: "15px", buttonradius: "5px", buttonheight: "20px", buttonwidth: "55px" },
        none: { width: "100%", height: "fit-content", radius: "3px", buttonradius: "0px", buttonheight: "fit-content", buttonwidth: "fit-content" },
        maxcontent: { width: "max-content", height: "max-content", radius: "10px", buttonradius: "3px", buttonheight: "max-content", buttonwidth: "max-content" },
        dialog:{ width: "413px", height: "226px", radius: "0px", buttonradius: "0px", buttonheight: "41px", buttonwidth: "161px" },
        server:{ width: "max-content", height: "150px", radius: "0px", buttonradius: "0px", buttonheight: "0px", buttonwidth: "0px" },
    },
    stroke: {
        none: { normal: "none", buttonstroke: "none" },
        thin: { normal: "3px solid black", buttonstroke: "2px solid black" },
        thick: { normal: "5px solid #067D6F", buttonstroke: "3px solid black" }
    },
    fill: {
        normal: { normal: "#1E6883", hover: "#B5F0F5", buttonfill: "" },
        normal1:{ normal: "#1E6883", hover: "", buttonfill: "" },
        medium: { normal: "#e6e6fa", hover: "#035437", buttonfill: "#5ABCB0" },
        transparent: { normal: "none", hover: "#183462", buttonfill: "#6366F1" },
        dialog:{normal: "#FFFFFF", hover: "", buttonfill: "#B90B2E"},
        
    },
    effects: {
        none: "none",
        insetShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
        dropShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)"
    },
 
    font: {
        normal: { size: '15px',color:"black" ,fontweight:"bold" },
        Dark: { size: '20px',color:"black" ,fontweight:"bolder" },
        dialog: { size: '14px',color:"black" ,fontweight:"bolder" },
        server:{ size: '17px',color:"black" ,fontweight:"bolder" },
    },
    spacing:{
        none:{padding:'0px'},
        dialog:{padding:'40px'},
        server:{padding:"20px"}
    },
    transition: "all 0.5s",

}
export default DialogTheme;