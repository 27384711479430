import React from 'react';
import styled, {css} from 'styled-components';


interface LoadingSpinnerIF{
  stroke:string;
  top:string
  left:string
  shape: string
  fill: string
}

const LoadingSpinnerContainer=styled.div <LoadingSpinnerIF>`
  width:fit-content;
  height:${props => props.theme.loadingSpinner.shape[props.shape].height};
  z-index:1;
  position: relative; 
  top:${props => props.top};
  left:${props => props.left};
`

const LoadingSpinnertext = styled.div`
    display:block;
    width:fit-content;
    text-align:center;
    font-weight:bold;
    font-size:1vw;
    vertical-align:middle;
    margin:3px;
    align-items:center;
  `

interface LoadingSpinnerDivIF{
    stroke:string;
    shape: string
    innerfill:string
    fill: string
    speed: string
}


const LoadingSpinnerDiv=styled.div <LoadingSpinnerDivIF>`
@keyframes spinner {
   0% {
     transform: translate3d(-50%, -50%, 0) rotate(0deg);
   }
   100% {
     transform: translate3d(-50%, -50%, 0) rotate(360deg);
   }
 }
  animation: ${props => props.speed}s linear infinite spinner;
  animation-play-state: inherit;
  border: ${props => props.theme.loadingSpinner.stroke[props.stroke]};
  border-bottom-color:  ${props => props.theme.loadingSpinner.innerfill[props.innerfill]};
  border-radius: 50%;
  content: "";
  width:${props => props.theme.loadingSpinner.shape[props.shape].width};
  height:${props => props.theme.loadingSpinner.shape[props.shape].height};
  position: relative; 
  top:50%;
  left:50%;
  background:${props => props.theme.loadingSpinner.fill[props.fill]};
  display:inline-flex;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;  
`

interface LinearloadingSpinnerDivIF{
  stroke:string;
  shape: string
  innerfill:string;
  fill: string
  speed: string
}

const LinerloadingSpinnerDiv=styled.div <LinearloadingSpinnerDivIF>`
  
  width:${props => props.theme.loadingSpinner.shape[props.shape].width};
  height:${props => props.theme.loadingSpinner.shape[props.shape].height};
  border: ${props => props.theme.loadingSpinner.stroke[props.stroke]};
  background: 
    linear-gradient(90deg, #0000 ,${props => props.theme.loadingSpinner.innerfill[props.innerfill]}) left -50px top 0/50px ${props => props.theme.loadingSpinner.shape[props.shape].height} no-repeat 
    ${props => props.theme.loadingSpinner.fill[props.fill]};
  animation: ct2 ${props => props.speed}s infinite linear;
  @keyframes ct2 {
    100% {background-position: right -50px top 0}
  } 
`
const Modal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 501;
`
export {LoadingSpinnerDiv,LoadingSpinnerContainer,LoadingSpinnertext,LinerloadingSpinnerDiv,Modal}
