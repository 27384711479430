import { FlexboxThemeIF } from "../components/flexBox/flexBoxThemeIF"

const FlexBoxThemes: FlexboxThemeIF = {
    shape: {
        hug: { width: "auto", height: "auto", radius: "0px" },
        normal: { width: "250px", height: "220px", radius: "20px" },
        wide: { width: "1000px", height: "100px", radius: "20px" },
        fullPage: { width: "1000px", height: "800px", radius: "0px" },
        app: { width: "1200px", height: "800px", radius: "0px" },
        header1:{width:'440px',height:'27px',radius:'0px'},
        header2:{width:'100%',height:'40px',radius:'0px'},
        configurationMain:{width:'90vw',height:'60px',radius:'0px'},        signin: {  width: '26.818181818181817vw', height: '330px', radius: '0px' },
        signinout: {  width: '413px', height: '371px', radius: '0px' },
        siTimelogo: {  width: '413px', height: '26px', radius: '0px' },
        signinHeader: { width: '317px', height: '', radius: '0px' },
        signinButton: { width: '333px', height: '110px', radius: '0px' },
        signinFinal: { width: '317px', height: '21px', radius: '0px' },
        signup: { width:'413px',height:'556px',radius:'0px'},
        signupinner: { width: "413px", height: "515px", radius: "0px" },
        signupText: { width: "307px", height: "320px", radius: "0px" },
        signUpHeader: { width: '307px', height: 'none', radius: '0px' },
        blockdiagramText: { width: "90vw", height: "auto", radius: "0px" },
        buttonalign: { width: "321px", height: "50px", radius: "0px" },
        details: { width: "max-content", height: "42px", radius: "0px" },
        detailbutton: { width: "90vw", height: "59px", radius: "0px" },
        detailbottom: {width:'1280px',height:'1px',radius:'0px'},
        blockdiagrambox: { width: "60vw", height: "560px", radius: "1px" },
        boxcontainer: { width: "90vw", height: "560px", radius: "0px" },
        tablecontainer: { width: "90vw", height: "auto", radius: "1px" },
        blockdiagrambox2: { width: "28.5vw", height: "560px", radius: "1px" },
        historydetailbutton: { width: "205px", height: "40px", radius: "12px" },
        historyblockdiagram: { width: "90vw", height: "496px", radius: "1px" },
        //device
        devicepage: { width: "1120px", height: "70px", radius: "0px" },
        devicebutton: { width: "50px", height: "50px", radius: "10px" },
        devicetable: { width: "1120px", height: "649px", radius: "0px" },
        devicetableouter: { width: "1120px", height: "193px", radius: "0px" },
        devicetable1: { width: "1120px", height: "150px", radius: "12px" },
        devicetableouter2: { width: "1120px", height: "389px", radius: "0px" },
        devicetablebutton: { width: "265px", height: "32px", radius: "0px" },
        devicetable2: { width: "1120px", height: "302px", radius: "12px" },
        devicetable1spacing: { width: "1120px", height: "302px", radius: "0px" },
        deviceTextheader: { width: "251px", height: "32px", radius: "" },
        deviceconfigurations: { width: "131px", height: "32px", radius: "0px" },
        deviceonline: { width: "138px", height: "50px", radius: "" },
        confugaration1:{width:'100%',height:'39px',radius:''},
        confugaration2:{width:'100%',height:'197px',radius:''},
        confugaration3:{width:'100%',height:'auto',radius:''},
        //blockdiagram
        backtext:{width:'90vh',height:'21px',radius:'0px'},
        password:{ width: '413px', height: '382px', radius: '0px' },
        mainscreen:{width:'100vw',height:'auto',radius:"0px"},
        createNew:{width:'100%',height:'auto',radius:'0px'},
        signinpass:{ width: '413px',height:"420px",radius:'0px'},
        signinoutpass: {  width: '413px', height: '430px', radius: '0px' },
        formHeader:{width: '100%', height: '62px', radius: '0px'},
        header:{width: '100%', height: '100%', radius: '0px'},
        summaryView:{width:'26vw',height:'90px',radius:'0px'},
        Expand:{width:'26vw',height:'215px',radius:'0px'},
        Summaryedit:{width:'100%',height:'auto',radius:'0px'},
        //pinout
        serial:{width:"100%",height:"auto",radius:"0px"},
        customerpopup:{width:"auto",height:"950px",radius:"0px"},
        realtimeTop: {width: '100%',height:'100px',radius:'0px'},
        configTable: {width: '100%',height:'500px',radius:'0px'},
        realtime: {width:'100%',height:'100%',radius:'0px'},
        realtimeButton: {width:'100%',height:'50px',radius:'0px'}
    },
    layout: {
        "vertical-top-left": { direction: "column", justifyContent: "flex-start", alignItems: "flex-start" },
        "vertical-top-center": { direction: "column", justifyContent: "flex-start", alignItems: "center" },
        "vertical-top-right": { direction: "column", justifyContent: "flex-start", alignItems: "flex-end" },
        "vertical-center-left": { direction: "column", justifyContent: "center", alignItems: "flex-start" },
        "vertical-center-center": { direction: "column", justifyContent: "center", alignItems: "center" },
        "vertical-center-right": { direction: "column", justifyContent: "center", alignItems: "flex-end" },
        "vertical-bottom-left": { direction: "column", justifyContent: "flex-end", alignItems: "flex-start" },
        "vertical-bottom-center": { direction: "column", justifyContent: "flex-end", alignItems: "center" },
        "vertical-bottom-right": { direction: "column", justifyContent: "flex-end", alignItems: "flex-end" },
        "horizontal-top-left": { direction: "row", justifyContent: "flex-start", alignItems: "flex-start" },
        "horizontal-top-center": { direction: "row", justifyContent: "center", alignItems: "flex-start" },
        "horizontal-top-right": { direction: "row", justifyContent: "flex-end", alignItems: "flex-start" },
        "horizontal-center-left": { direction: "row", justifyContent: "flex-start", alignItems: "center" },
        "horizontal-center-center": { direction: "row", justifyContent: "center", alignItems: "center" },
        "horizontal-center-right": { direction: "row", justifyContent: "flex-end", alignItems: "center" },
        "horizontal-bottom-left": { direction: "row", justifyContent: "flex-start", alignItems: "flex-end" },
        "horizontal-bottom-center": { direction: "row", justifyContent: "center", alignItems: "flex-end" },
        "horizontal-bottom-right": { direction: "row", justifyContent: "flex-end", alignItems: "flex-end" },
    },
    spacing: {
        normal: { gap: "10px", padding: "10px" },
        none: { gap: '0px', padding: '0px' },
        signin: { gap: '30px', padding: '40px' },
        signintext: {gap:'20px',padding:'0px'},
        welcome: { gap: '4px', padding: '0px' },
        signup: {gap:'20px',padding:'0px'},
        signupinner: { gap: '30px', padding: '40px' },
        header: { gap: '50px', padding: '0px' },
        header2: { gap: '30px', padding: '0px' },
        buttonalign: { gap: "8px", padding: '0px 0px 0px 0px' },
        detailbutton: { gap: '0px', padding: '0px 0px 0px 0px' },
        detailbutton1: { gap: '0px', padding: '27px 0px 0px 0px' },
        blockdiagrambox: { gap: '13px', padding: "0px 0px 0px 0px" },
        boxcontainer: { gap: "20px", padding: "0px 0px 0px 0px" },
        blockdiagrambox2: { gap: "0px", padding: "0px 0px 0px 0px" },
        historydetailbutton: { gap: "10px", padding: "4px 5px 4px 10px" },
        historyblockdiagram: { gap: "10px", padding: "20px" },
        //device
        headerdevice: { gap: "0px", padding: "10px 0px 10px 0px" },
        devicetable: { gap: '0px', padding: "0px 0px 0px 0px" },
        devicetableouter: { gap: "20px", padding: "0px 0px 0px 0px" },
        devicetable1: { gap: "10px", padding: "20px" },
        devicetablespacing: { gap: "0px", padding: "0px 0px 0px 0px" },
        devicetableouter2: { gap: "18px", padding: "0px 0px 0px 0px" },
        devicetablebutton: { gap: "12px", padding: "0px 0px 0px 0px" },
        devicetable2: { gap: "10px", padding: "20px" },
        devicetable1spacing: { gap: "0px", padding: "0px 0px 0px 0px" },
        deviceTextheader: { gap: "40px", padding: "0px 0px 0px 340.5px" },
        deviceconfigurations: { gap: "0px", padding: "4px 10px 4px 10px" },
        deviceonline: { gap: "10px", padding: "0px 0px 0px 357.5px" },
        devicebuttonspacing: { gap: "40px", padding: "10px 0px 0px 0px" },
        configuration:{gap:'340.5px',padding:'0px'},
        configurationMain:{gap:'0px',padding:'0px 0px 0px 0px'},
        remote:{gap:'20px',padding:'0px'},
        remote1:{gap:'16px',padding:'0px'},
        remote2:{gap:'10px',padding:'30px 13px 30px 13px'}, 
        //blockdiagram
        blockdiagramText:{gap:'0px',padding:'0px 0px 0px 0px'},
        details:{gap:'10px',padding:'0px 0px 0px 0px'},
        detailbottom:{gap:'0px',padding:'40px 0px 0px 0px'},
        form:{gap:"15px", padding:"5px"},
        popup:{gap:"32px",padding:'0px'},
        customerPopup:{gap:"12px",padding:'0px'},
        mode:{gap:"11px",padding:'0px'},
        type:{gap:"14px",padding:'0px'},
        functional:{gap:'10px',padding:'20px'},
        SE:{gap:'8vw',padding:'0px'},
        outp:{gap:'9vw',padding:'0px'},
        summaryView:{gap:'10px',padding:'20px'},
        collapse:{gap:'7px',padding:'0px'},
        summaryCard:{gap:'5px',padding:"4px 5px"},
        summaryGap:{gap:'10px',padding:"0px"},
        pinout:{gap:'10px',padding:"20px"},
        serial:{gap:"10px",padding:"16px 20px 16px 20px"},
        pinbuttons:{gap:"4px",padding:"0px"},
        pinoutDrop:{gap:"14px",padding:"0px"},
        summary:{gap:"30px",padding:'0px'},
        realtimeTop: {gap:'30px',padding:'0px'},
        realtimeContainer: {gap: '60px', padding:'0px'}
    },
    stroke: {
        none: {outline:"none",borderBottom:''},
        // normal: "1px solid #067D6F"
        normal: {outline:"1px solid #067D6F",borderBottom:''},
        detailbutton:{borderBottom:'2px solid #3399D5',outline:''},
        device:{outline:'1px solid #E2E2E2',borderBottom:''},
    },
    fill: {
        none: "none",
        detailbutton: "#F3F3F3",
        blockdiagrambox: "#F3F3F3",
        blockdiagrambox2: "#F3F3F3",
        historydetailbutton: "#F3F3F3",
        historyblockdiagram: "#FFFFFF",
        //device
        devicetable1: "#F3F3F3",
        devicetable2: "#F3F3F3",
        devicebutton: "#F3F3F3",
        remote:'1px solid #888888',
        configurationMain:'#595A5A',
        signin: 'white',
        red:'red',
        summaryBg:"#EBF4FA",
        serial:"#F0EFEF"
    },
    effects: {
        none: "none"
    }
}

export default FlexBoxThemes
