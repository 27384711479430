import { type } from 'os';
import React from 'react';
import styled, { css } from 'styled-components';

interface InputcontainerIF {
  font: string;
  display: string
  shape:string
}
const Inputcontainer = styled.div<InputcontainerIF>`
  display:flex;
  width:fit-content;
  height:fit-content;
  flex-direction:row;
  color:${props => props.theme.radiobutton.font[props.font].color};
  font-size:${props => props.theme.radiobutton.font[props.font].size};
  margin:0px;
  gap:${props => props.theme.radiobutton.shape[props.shape].spacing};
`;

interface InputstyleIF {
  shape: string;
  stroke: string;
  fill: string;
  transition: string;
  disabled: boolean;
}
const Input = styled.input<InputstyleIF>`
  appearance: none;
  border-radius:${props => props.theme.radiobutton.shape[props.shape].radius};
  width:${props => props.theme.radiobutton.shape[props.shape].width};
  height:${props => props.theme.radiobutton.shape[props.shape].height};
  border:${props => props.theme.radiobutton.stroke[props.stroke].outerstroke};
  transition: ${props => props.theme.radiobutton.transition[props.transition].innertransition};
  margin:0px;
  position: relative;
  &:checked {
    border:${props => props.theme.radiobutton.stroke[props.stroke].innerstroke};
    background:${props => props.theme.radiobutton.fill[props.fill].normal};
    transition:  ${props => props.theme.radiobutton.transition[props.transition].outertransition};
    margin:0px;
    padding:0px;
  }
  ${props => props.disabled ?
    css`
    opacity: 0.5;
    cursor: default;`:
    css`
    opacity: 1;
    cursor: pointer;`
  }
`

export { Inputcontainer, Input }