import { useState } from "react"
import { ThemeProvider } from "styled-components"
import AppData from "../../../appData"
import { Button } from "../../../components/button/button"
import { DataGrid } from "../../../components/dataGrid/dataGrid"
import Icons from "../../../icons/icons"
import PrimaryTheme from "../../../Themes/primaryTheme"
import {syncDevice}from "./deviceEvents"
import {convertUTCDateToLocalDate} from "../configurationScreen/configLocalTable"

interface PropsIF{
	data:any[]
	Columns:any[]
	shape?:string
	fill?:string
	stroke?:string
}
function LocalTable({data,shape='normal',fill='normal',stroke='normal',Columns}:PropsIF) {
	const [selectedRow, setSelectedrow] = useState();
	var localdata:any[]=[]
	var remotedata:any[]=[]
	var platform:any[]=[]
	var variant:any[]=[]
	var silicon_revision:any[]=[]
	var version:any[]=[]
	var created_on:any[]=[]
	var id:any=[]
	localdata.push(platform,variant,silicon_revision,version,created_on)
	AppData.device.data.local.forEach((value:any)=>{return platform.push(value['platform'])})
	AppData.device.data.local.forEach((value:any)=>{return variant.push(value['variant'])})
	AppData.device.data.local.forEach((value:any)=>{return silicon_revision.push(value['silicon_revision'])})
	AppData.device.data.local.forEach((value:any)=>{return version.push(value['version'])})
	AppData.device.data.local.forEach((value:any)=>{return created_on.push(value['created_on'])})
	AppData.device.data.local.forEach((value:any)=>{return id.push(value['_id'])})
	const col1 = (coldata:any,rowindex:any) => {
		return(rowindex.map((row:any) => (coldata[row]=='local'?"":''))
	)};
	const col2 = (coldata:any) => {
		return(coldata.map((col:string) => (
			<Button shape="localbutton" spacing="localbutton" fill="transparent" stroke='status'><Icons iconName={'monitor'} size={'14px'} stroke='status'></Icons><p style={{ fontWeight: "500", fontSize: "12px",color:'#56BA64', fontFamily: "Inter" }}>Local</p></Button>
		)))}

	const col6 = (coldata:any) => {
		return(coldata.map((col:any) => (
			<p>{col}</p>))
	)};
	const colum0=platform.map( x => ( x)) 
	const colum1 =col2(data.map( x => ( x[ 1 ] ) ))
	const colum2 =col6(variant.map( x => ( x) ))
	const rowLengthArray = Array.from({length: data.length}, (v, i) => i)
	const colum3 =col6(version.map( x => ( x) ))
    const colum5=col6(silicon_revision.map( x => ( x) ))
    const colum6=col6(data.map( x => ( x[ 5] ) ))
    const colum7=col6(created_on.map(  x => ( convertUTCDateToLocalDate(new Date(x+ 'Z')).toLocaleString()) ))
	const colum8=col1(localdata.map( x => ( x[ 1 ] ) ),rowLengthArray)

	const displayData=colum0.map(function(e,i) {
		return [colum2[i],colum1[i],colum3[i],colum5[i],colum7[i]];
	  });

	  const activeRow = (rowSelected:any) => {
		setSelectedrow(rowSelected)
		console.log(id[rowSelected])
	}

  return (
	<ThemeProvider theme={PrimaryTheme}>
		<DataGrid data={data} displayData={displayData} columns={Columns} shape={shape} fill={fill} stroke={stroke} onClick={activeRow}/>
    </ThemeProvider>
  );
}

export default LocalTable;
