
import React, {useState, useRef} from 'react';
import { TableStyle, RowStyle, CellStyle,SearchStyle, ContentStyle,HeaderStyle,HeaderSpanStyle, DataStyle, IconStyle, ContentStyleActive } from "./dataGridStyles"
import Icons from "../../icons/icons"
import { useTheme } from 'styled-components'
import { Button } from '../button/button';
import FlexBox from '../flexBox/flexBox';
interface PropsIF {
    data: string[][]
    displayData?:any[][]
    columns: {width:string, toolbar: boolean, header:string}[]
    disabled?: boolean
    spacing?: string
    fill?: string
    shape?: string
    stroke?: string
    effects?: string
    search?: string
    text?:string
    onClick?: (rowNum: number, viewSelected:boolean) => void,
    onfocuslost?:any,
    Scroll?: boolean,
    NextButton?:boolean,
}

const cellType = (index:number, size: number) => (index == 0 ? "first" : index == size-1 ? "last" : "between")

export const DataGrid = ({ Scroll=false,NextButton=false,onfocuslost, data, displayData, columns, disabled = false, spacing = "normal", shape = "normal", stroke = "none", effects = "none", fill = "normal",  search="normal", text="normal", onClick = (rowNum: number, viewSelected:boolean) => { } }: PropsIF) => {
    const colIndices = columns.map((_,index) => index)
    const theme = useTheme() as any
    const [sort, setSort] = useState({column:-1, order:"none"});
    const filterData = useRef(columns.map(()=>""))
    const [displayChange, setDisplayChange] = useState(false)
    const [selectRow,setSelectRow] = useState(-1)
    
    const[currentPage,setCurrentPage]=useState(1);
    const datasPerPage=15
    const indexOfLastData=currentPage * datasPerPage
    const indexOfFirstData=indexOfLastData - datasPerPage
    const datas=NextButton?data.slice(indexOfFirstData,indexOfLastData):data
    const displayDatas=NextButton?displayData?.slice(indexOfFirstData,indexOfLastData):displayData
    const totalNoOfPages=Math.ceil(data.length/datasPerPage)
    // console.log('data',data)
    // console.log('datass',datas)
    // console.log('last',indexOfLastData)
    // console.log('first',indexOfFirstData)
    // console.log('current page',currentPage)
    // console.log('displayData',displayDatas)

    const prevPage=(currentPageNumber:number)=>{
        setCurrentPage(currentPageNumber-1)
    }
    const nextPage=(currentPageNumber:number)=>{
        setCurrentPage(currentPageNumber+1)
    }

    const onClickRow=(row:any,col:any)=>{
        var viewSelected=false
        if(displayDatas){
            if(displayDatas[row][col].type == 'p' || displayDatas[row][col].type == undefined){
                viewSelected=true
            }
        }
        setSelectRow(row)
        onClick(row,viewSelected)
    }
    const changeSort = (columnNum:number) => {
        if(columns[columnNum].toolbar)
        setSort(sort.order=="increasing" ? {order:"decreasing", column:columnNum} : sort.order=="decreasing" ? {order:"none", column:-1} : {order:"increasing", column:columnNum})
    }
    const onFilterChange = (value:string, columnNum:number) => {
        filterData.current[columnNum]=value
        setDisplayChange(!displayChange)
    }

    let activeIndex:number[] = [];
    datas.forEach((row, rowNum) => {
        let include = true
        filterData.current.forEach((filterVal, colNum) => {
            if ((filterVal!="") && (!row[colNum].includes(filterVal))) {
                include = false
                return
            }
        })
        if (include) activeIndex.push(rowNum)
    })

    if ((sort.column != -1) && (sort.order != "none")) {
        let filteredColumnData = activeIndex.map(activeIndexValue =>  {return {data:datas[activeIndexValue][sort.column], rowIndex:activeIndexValue}})
        filteredColumnData.sort((a,b):number => (((sort.order == "increasing") && (a.data > b.data)) || ((sort.order == "decreasing") && (b.data > a.data))) ? 1 : -1)
        activeIndex = filteredColumnData.map(value => value.rowIndex)
    }

    return (
        <TableStyle  shape={shape}  stroke={stroke} fill={fill} onMouseLeave={onfocuslost}>
            <RowStyle  stroke={stroke} fill={fill} shape={shape} type="header" >
                {colIndices.map((index: number) => (
                <CellStyle text={text} width={columns[index].width} stroke={stroke} type={cellType(index,colIndices.length)} onClick={() => changeSort(index)}>
                    <HeaderStyle text={text}> 
                        <HeaderSpanStyle> 
                            {columns[index].header} 
                        </HeaderSpanStyle>
                        {(sort.column == index  && columns[index].toolbar) && (
                        <IconStyle size={theme.dataGrid.text[text].headerSize} >
                            <Icons iconName={sort.order=="increasing" ? "arrow-up" : (sort.order=="decreasing" ? "arrow-down" : "arrow-updown")} size={theme.dataGrid.text[text].headerSize} />
                        </IconStyle> )}
                    </HeaderStyle>
                </CellStyle>))}
            </RowStyle>

            <RowStyle stroke={stroke} fill={fill} shape={shape} type="search" >
                {colIndices.map((columnNum: number) => (
                <CellStyle text={text} width={columns[columnNum].width} stroke={stroke} type={cellType(columnNum,colIndices.length)}>
                    {columns[columnNum].toolbar?<SearchStyle search={search}  onChange={(e:React.ChangeEvent<HTMLInputElement>) => {onFilterChange(e.target.value, columnNum)}} />:null}
                </CellStyle>
                ))}
            </RowStyle>
            <DataStyle scroll={Scroll} shape={shape} fill={fill}>
                {activeIndex.map((activeRowValue) => (
                <RowStyle  stroke={stroke} fill={fill} shape={shape} type={"row"} >
                    {colIndices.map((columnNum: number) => (
                    <CellStyle text={text} width={columns[columnNum].width} stroke={stroke} onClick={()=>onClickRow(activeRowValue,columnNum)} type={cellType(columnNum,colIndices.length)}>
                        {activeRowValue!=selectRow?
                            <ContentStyle text={text}> 
                                {displayDatas? displayDatas[activeRowValue][columnNum]: datas[activeRowValue][columnNum]} 
                            </ContentStyle>:
                            <ContentStyleActive text={text}> 
                                {displayDatas? displayDatas[activeRowValue][columnNum]: datas[activeRowValue][columnNum]} 
                            </ContentStyleActive>}
                    </CellStyle>))}
                </RowStyle>))}
            </DataStyle>
            {NextButton && 
            // <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around'}}>
            <FlexBox layout="horizontal-bottom-right" spacing="none" shape='header2'>
                 <FlexBox layout="horizontal-bottom-left" spacing="none" shape='hug'>
                 <button style={{border:'none',height:'25px'}} disabled={currentPage===1} onClick={()=>prevPage(currentPage)}><a style={{color:'black',textDecoration:'none'}} href="#">&#8249;</a></button>
                 <button style={{border:'none',height:'25px'}} disabled={currentPage===totalNoOfPages} onClick={()=>nextPage(currentPage)}><a style={{color:'black',textDecoration:'none'}} href="#">&#8250;</a></button> 
                 </FlexBox>            
            </FlexBox>}
        </TableStyle>

    )

}
