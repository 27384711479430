import React from 'react';
import styled, { css } from 'styled-components';

interface IconStyleIF {
    stroke: string
    fill: string
}
const IconStyle = styled.svg <IconStyleIF>`
    stroke: ${props => props.theme.icon.stroke[props.stroke]};
    fill:${props => props.theme.icon.fill[props.fill]}
    `;
export default IconStyle