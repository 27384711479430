import React from 'react';
import styled, { css } from 'styled-components';

interface TableContainerIF {
    shape: string
    stroke: string
    fill: string
}

const TableStyle = styled.div <TableContainerIF>`
    box-sizing:border-box;
    display:flex ;
    flex-direction:column;
    justify-content: flex-start;
    align-items:flex-start;
    height: ${props => props.theme.dataGrid.shape[props.shape].height};
    width: ${props => props.theme.dataGrid.shape[props.shape].width};
    border-radius: ${props => props.theme.dataGrid.shape[props.shape].radius};
    background: ${props => props.theme.dataGrid.fill[props.fill].row};
    border:  ${props => props.theme.dataGrid.stroke[props.stroke].outside};
    box-shadow: none;
    position:relative;
    `;

interface DataStyleIF {
    shape: string
    fill: string
    scroll?: boolean
}

const DataStyle = styled.div <DataStyleIF>`
    ::-webkit-scrollbar { 
       display: ${props => (props.scroll) ? '' : 'none'}; 
    }
    overflow-y: ${props => (props.scroll) ? 'scroll' : 'initial'};
    overflow-x:${props => (props.scroll) ? 'hidden' : 'initial'};
`;

interface RowStyleIF {
    shape: string
    stroke: string
    fill: string
    type: "header" | "search" | "row"
}

const rowRadius = (type: string, inp: string) => (
    (type == "header") ? `${inp} ${inp} 0px 0px` : `0px 0px 0px 0px`
)

const RowStyle = styled.div <RowStyleIF>`
    box-sizing:border-box;
    display:flex ;
    flex-direction:row;
    justify-content: center;
    align-items:center;
    height:${props => (props.type == "header") ? props.theme.dataGrid.shape[props.shape].header : (props.type == "search") ? props.theme.dataGrid.shape[props.shape].search : props.theme.dataGrid.shape[props.shape].row};
    width: 100%;
    border-radius: ${props => rowRadius(props.type, props.theme.dataGrid.shape[props.shape].radius)};
    background: ${props => (props.type == "header") ? props.theme.dataGrid.fill[props.fill].header : "none"};
    border-bottom: ${props => props.theme.dataGrid.stroke[props.stroke].row};
    box-shadow: none;
    position:relative;
    cursor:${props => (props.type === "header") && "context-menu"};
    ${props => ((props.type === "row")) && css` &:hover 
        {
            transition: ${props => props.theme.dataGrid.transition};
            background: ${props => props.theme.dataGrid.fill[props.fill].rowHover};
        }`
    }
    `;


interface CellIF {
    width: string
    stroke: string
    text: string
    type: "first" | "last" | "between"
}

const getAlign = (inp: string) => (inp == "left" ? "flex-start" : (inp == "right") ? "flex-end" : "center")
const CellStyle = styled.div <CellIF>`
    box-sizing:border-box;
    display:flex ;
    flex-direction:row;
    justify-content: ${props => getAlign(props.theme.dataGrid.text[props.text].align)};
    align-items:center;
    height:100%;
    width: ${props => props.width};
    border-right: ${props => (props.type == "last") ? "none" : props.theme.dataGrid.stroke[props.stroke].column};
    position:relative;
    `;

interface contentIF {
    text: string,
}
const ContentStyle = styled.span<contentIF>`
    color: ${props => props.theme.dataGrid.text[props.text].contentColor};
    text-align:  ${props => props.theme.dataGrid.text[props.text].align};
    font-size: ${props => props.theme.dataGrid.text[props.text].contentSize};
    margin: 0px 2px 0px 2px;
` ;

interface contentIF {
    text: string,
}
const ContentStyleActive = styled.span<contentIF>`
    color: ${props => props.theme.dataGrid.text[props.text].activeColor};
    text-align:  ${props => props.theme.dataGrid.text[props.text].align};
    font-size: ${props => props.theme.dataGrid.text[props.text].contentSize};
    margin: 0px 2px 0px 2px;
` ;


interface SearchIF {
    search: string
}

const radius = (inp: string) => `${inp} ${inp} ${inp} ${inp}`

const SearchStyle = styled.input <SearchIF>`
    type:text;
    height: ${props => props.theme.dataGrid.search[props.search].inputHeight};
    width: 100%;
    border-radius: ${props => radius(props.theme.dataGrid.search[props.search].radius)};
    border-bottom: ${props => props.theme.dataGrid.search[props.search].strokeType == "none" ? "none" : props.theme.dataGrid.search[props.search].stroke};
    border-top:${props => props.theme.dataGrid.search[props.search].strokeType == "all" ? props.theme.dataGrid.search[props.search].stroke : "none"};
    border-left:${props => props.theme.dataGrid.search[props.search].strokeType == "all" ? props.theme.dataGrid.search[props.search].stroke : "none"};
    border-right:${props => props.theme.dataGrid.search[props.search].strokeType == "all" ? props.theme.dataGrid.search[props.search].stroke : "none"};
    background: ${props => props.theme.dataGrid.search[props.search].fill};
    margin: 0px 2px 0px 2px;
    position:relative;

    outline: none;
    ${css` 
    &:focus-visible {
        transition: ${props => props.theme.dataGrid.search.transition};
        background: ${props => props.theme.dataGrid.search[props.search].hover};
    }
    `
    };

    `;
interface HeaderIF {
    text: string
    shape?: string
}
const HeaderStyle = styled.span<HeaderIF>`
    box-sizing:border-box;
    display:flex ;
    flex-direction:row;
    width:100%;
    justify-content: center;
    align-items:center;
    color: ${props => props.theme.dataGrid.text[props.text].headerColor};
    text-align:  ${props => props.theme.dataGrid.text[props.text].align};
    font-size: ${props => props.theme.dataGrid.text[props.text].headerSize};
    margin: 0px 2px 0px 2px;
    height: ${props => (props.shape) ? props.theme.dataGrid.shape[props.shape].header : '46px'}
` ;

const HeaderSpanStyle = styled.span`
    width:100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select:none;
`;

interface IconIF {
    size: string
}
const IconStyle = styled.span<IconIF>`
    box-sizing:border-box;
    display:flex ;
    flex-direction:row;
    width: ${props => props.size};
    height:  ${props => props.size};
    justify-content: center;
    align-items:center;
` ;

export { TableStyle, RowStyle, CellStyle, SearchStyle, ContentStyle, ContentStyleActive, HeaderStyle, HeaderSpanStyle, DataStyle, IconStyle }

