
import React, { useEffect, useState } from "react";
import Icons from "../../icons/icons";
import { DropdownStyle, DropdownButton, DropdownItemsDiv, DropdownInput, DropdownItems, DropdownIcon, SelectOptions, SelectedItems, DropdownInputDiv, DropdownCheckItemsDiv } from './dropDownStyles'
import AppData from "../../appData";
import Checkbox from "../checkBox/checkBox";

interface propIF {
  select: "single" | "multi"
  shape: string
  InputSearch: boolean
  effects: string
  contentItems: string[]
  fill: string
  disabled?:boolean
  placeholder?:string
  iconcolor?:string
  spacing: string
  stroke: string
  checkbox?:boolean
  onClick: (selected: any) => void;
  current?: string;
  form?: string
}
const Dropdown = ({ form='',shape = 'none',placeholder,checkbox=false,disabled=false,iconcolor,InputSearch = false, select = 'single', stroke = "normal", contentItems, spacing = 'normal', effects = "none", fill = 'normal', onClick = (itemName: string | string[]) => { }, current}: propIF) => {
  const [dropdown, Setdropdown] = useState<boolean>(false)
  const [item, Setitem] = useState(current)
  useEffect(() => { 
    Setitem(current)
  },[current])
  const [selected, setSelected] = useState<string[]>([])
  const [dropdownDiv, SetdropdownDiv] = useState<boolean>(false)
  const [searchInput, setSearchInput] = useState("")
  const [search, setsearch] = useState(false)
  const [inputSearch, SetinputSearch] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // e.preventDefault
    setSearchInput(e.target.value)
    setsearch(true)
  }
  var state = current
  var val
  if(form==='PPMform' || form === 'FreezeForm'){
    val = item
  }
  else{val = ( current !== undefined && current !== '' && current !== null ) ? state : item}
  console.log('itemss', contentItems,val,current,item)
  const filteredItems = contentItems.filter((item) => item.toLowerCase().includes(searchInput.toLowerCase()))
  const toggleOption = (item: string) => {
    if (select == 'multi') {
      setSelected(prevSelected => {
        // if it's in, remove
        const newArray = [...prevSelected]
        if (newArray.includes(item)) {
          return newArray.filter(id => id != item)
          // else, add
        } else {
          newArray.push(item)
          return newArray;
        }
      })
    } else {
      Setitem(item)
      onClick(item)
      Setdropdown(false)
      setsearch(false)
      setSearchInput('')
    }
    onClick(selected)
  }
  const DropdownHandler = () => {
    if (dropdown == false) {
      return Setdropdown(true), setsearch(false), SetdropdownDiv(false), SetinputSearch(true)
    } else {
      return Setdropdown(false), SetinputSearch(false)
    }
  }
  return (
    <DropdownStyle disabled={disabled} shape={shape} onMouseLeave={()=>Setdropdown(false)}>
      <DropdownButton stroke={stroke} selected={dropdownDiv} dropdown={dropdown} fill={fill} shape={shape} >
        {/* {(select=='multi') && <SelectedItems onClick={()=>{SetdropdownDiv(true),Setdropdown(false),SetinputSearch(false),setsearch(false)}}>{selected.length}selected</SelectedItems>}  */}
        <div style={{ margin: '0px', paddingLeft: '7px', fontWeight: 'bold',display:'flex',width:'fit-content',height:"100%",alignItems:"center"}} >
          {val}</div>
        <DropdownIcon onClick={DropdownHandler} contentEditable={false}>
          <Icons iconName={"chevron-down"} size={"30px"} stroke={iconcolor} />
        </DropdownIcon>
      </DropdownButton>
      {dropdownDiv && <SelectOptions stroke={stroke} shape={shape} effects={effects} inputsearch={inputSearch} options={filteredItems.length}>{selected.map((item: string) => { return (<DropdownItemsDiv spacing={spacing} shape={shape}> {item}</DropdownItemsDiv>) })}</SelectOptions>}
      {<DropdownItems stroke={stroke} fill={fill}  spacing={spacing} shape={shape} dropdown={dropdown} options={filteredItems.length} >
      {InputSearch && <DropdownInputDiv dropdown={dropdown} stroke={stroke} shape={shape} spacing={spacing} inputsearch={inputSearch}><Icons iconName={"search"} size={"20px"}></Icons><DropdownInput onChange={handleChange} placeholder={placeholder} shape={shape}></DropdownInput></DropdownInputDiv>}
        <SelectOptions stroke={stroke} shape={shape} effects={effects} inputsearch={inputSearch} options={filteredItems.length}>{filteredItems.map((item: string) => {
          let isSelected = selected.includes(item)
          return (
            <>
            {checkbox?<DropdownCheckItemsDiv Itemselected={isSelected} spacing={spacing} shape={shape} >
              <Checkbox values={[]} ></Checkbox><div onClick={() => { toggleOption(item) }}>{item}</div>
            </DropdownCheckItemsDiv>:< DropdownItemsDiv Itemselected={isSelected} spacing={spacing} shape={shape} onClick={() => { toggleOption(item) }}>{item}</DropdownItemsDiv>}
            </>
          )
        })}
        </SelectOptions>
      </DropdownItems>}


    </DropdownStyle>
  )
}
export default Dropdown