import styled from 'styled-components';


interface TooltipIF {
    font: string
    shape: string
    fill: string
    spacing:string
    effects:string
}

const TooltipStyle = styled.div <TooltipIF>`
    position:relative;
    display:inline-block ;
    text-align: center;
    height: ${props => props.theme.tooltip.shape[props.shape].height};
    width: ${props => props.theme.tooltip.shape[props.shape].width};
    border-radius: ${props => props.theme.tooltip.shape[props.shape].radius};
    padding:${props => props.theme.tooltip.spacing[props.spacing].padding};
    background: ${props => props.theme.tooltip.fill[props.fill]};
    box-shadow: ${props => props.theme.tooltip.effects[props.effects]};
    grid-area: tooltip;
    `;

interface TooltipArrowIF {
    fill: string
    effects:string
    location: "left" | "right" | "top" | "bottom"
}

const TooltipArrowStyle = styled.div <TooltipArrowIF>`
    height: 10px;
    width: 10px;
    background: ${props => props.theme.tooltip.fill[props.fill]};
    transform: ${props => (props.location == "top") ? "translate(0px, -5px) rotate(45deg)" : (props.location == "right") ? "translate(5px, 0px) rotate(45deg) " : 
                            (props.location == "bottom") ? "translate(0px, 5px) rotate(45deg)": "translate(-5px, 0px) rotate(45deg)"};
    grid-area: arrow;
    transform-origin: 50% 50%;

    `;    

interface TooltipContainerIF {
    location: "left" | "right" | "top" | "bottom"|"none"
    font:string
    spacing:string
}

const ToolTipContainer = styled.div <TooltipContainerIF>`
    position:absolute;
    justify-content:center;
    align-items:center;
    box-sizing:border-box;
    display:flex;
    width:max-content;
    color: ${props => props.theme.tooltip.font[props.font].color};
    font-style: ${props => props.theme.tooltip.font[props.font].style};
    font-size:${props => props.theme.tooltip.font[props.font].size};
    
    flex-direction:${props => ((props.location == "left") || (props.location == "right")) ? "row" : "column"};
    visibility:hidden;
`;
const ToolChildern =styled.div `

`

interface TooltipBoxIF{
    location: "left" | "right" | "top" | "bottom"
    top:{X:string,Y:string}
    right:{X:string,Y:string}
    bottom:{X:string,Y:string}
    left:{X:string,Y:string}
    
}
const ToolTipBox = styled.div <TooltipBoxIF>`
    
    & ${ToolChildern}:hover + ${ToolTipContainer} {
        visibility:visible;
    transform: ${props => (props.location === "top") ? `translate(${props.top.X}, ${props.top.Y}) ` : (props.location === "right") ? `translate(${props.right.X}, ${props.right.Y}) `  : 
                            (props.location === "bottom") ?  `translate(${props.bottom.X}, ${props.bottom.Y}) ` : `translate(${props.left.X}, ${props.left.Y}) ` };
    }
    color:${props => props.color}; 
`
export  {TooltipStyle, TooltipArrowStyle,ToolTipContainer,ToolTipBox,ToolChildern} 