import React, {useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { Button } from '../../components/button/button'
import PrimaryTheme from '../../Themes/primaryTheme'
import { DataGrid } from '../../components/dataGrid/dataGrid'
import Icons from '../../icons/icons'
import { Menu } from '../../components/menu/menu'
import PopUpForm from '../../components/popupForm/popupForm'
import AppData from '../../appData'
// interface PropsIF {
//     data: { name: string, icon: string, onclick: () => void }[]
//     onClick?: (itemName: string) => void;
// }
interface PropsIF{
	data:any[]
	Columns:any[]
	shape?:string
	fill?:string
	stroke?:string
}

function AppTable({data,shape='normal',fill='normal',stroke='normal',Columns}:PropsIF) {
	const [show, setShow] = useState(false);
	const [selectedRow, setSelectedrow] = useState();
	const [active, setactive] = useState(false)
    var formdata1= require('./popupdata.json')
    const handler = () => {
        setShow(!show);
    }

	const col1 = (coldata:any,rowindex:any) => {
		return(rowindex.map((row:any) => (coldata[row]=='Local'?			
		<>
            <div  style={{width:'250px',display:'flex',position:'relative',alignItems:'center',justifyContent:'end',gap:'4px',cursor:'pointer'}}> <Button spacing='none' shape='localButton' stroke='sync' color='sync' fill='transparent'>Sync</Button><div style={{width:'30px',height:'30px',border:'1px solid #3399D5',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={handler}><Icons stroke='menu' iconName={'more-vertical'} size={'15px'} /></div></div>
            {show && selectedRow==row && (
            <Menu hide={setShow} spacing='local' shape='local' fontSize='local' effects='outerShadow' fill='local'  data={[{ name: 'Sync to remote', icon: 'user', tooltip:'duplicate configuration', onclick: () => { alert('Syncing to remote') } },
            { name: 'View Configuration', icon: 'settings', tooltip:'duplicate configuration', onclick: () => { alert('settings') } },
            { name: 'Sync to Remote', icon: 'logout', tooltip:'duplicate configuration', onclick: () => { localStorage.clear(); window.location.href = '/'; alert('logout successfully') } },
            { name: 'Freeze', icon: "file", tooltip:'duplicate configuration', onclick: () => { alert('files updated') } },
			{ name: 'Edit Details', icon: "file", tooltip:'duplicate configuration', onclick: () => { setactive(true)  } },
			{ name: 'Duplicate', icon: "file", tooltip:'duplicate configuration', onclick: () => { alert('Duplicate file') } },
			{ name: 'Delete', icon: "delete", tooltip:'duplicate configuration', onclick: () => { alert('files deleted') } }]} visible={show}/>
        )}
        </>:
		<>
            <div onClick={handler}><Icons iconName={'more-vertical'} size={'15px'} /> </div>
            {show && selectedRow==row  && (
            <Menu hide={setShow} shape='local' effects='outerShadow' fill='local' data={[{ name: 'Sync to local', icon: 'user', tooltip:'duplicate configuration', onclick: () => { alert('Syncing to local') } },
            { name: 'Settings', icon: 'settings', tooltip:'duplicate configuration', onclick: () => { alert('settings') } },
            { name: 'Logout', icon: 'logout', tooltip:'duplicate configuration', onclick: () => { localStorage.clear(); window.location.href = '/'; alert('logout successfully') } },
            { name: 'File', icon: "file", tooltip:'duplicate configuration', onclick: () => { alert('files updated') } }]} visible={show}/>
        )}
        </>))
	)};

	const col2 = (coldata:any) => {
		return(coldata.map((col:string) => (
			col=='Local'?<Button shape="localbutton" spacing="localbutton" fill="transparent" stroke='status'><Icons iconName={'monitor'} size={'14px'} stroke='status'></Icons><p style={{ fontWeight: "500", fontSize: "12px",color:'#56BA64', fontFamily: "Inter" }}>{col}</p></Button>:<Button shape="remotebutton" spacing="localbutton" fill="transparent" stroke='local'><Icons iconName={'cloud'} size={'18px'} stroke='remote'></Icons><p style={{ fontWeight: "500", fontSize: "12px", color:'#8E8D8A',fontFamily: "Inter" }}>{col}</p></Button>
		)))}

	const col5 = (coldata:any) => {
		return(coldata.map((col:any) => (
			<p>{col}</p>))
	)};
	const col6 = (coldata:any) => {
		return(coldata.map((col:any) => (
			<p>{col}</p>))
	)};

	const colum0=data.map( x => ( x[ 0 ] )) 
	const colum1 =col2(data.map( x => ( x[ 1 ] ) ))
	const colum2 =col5(data.map( x => ( x[ 2 ] ) ))
	const rowLengthArray = Array.from({length: data.length}, (v, i) => i)
	const colum3 =col6(data.map( x => ( x[ 3 ] ) ))
	const colum4=col1(data.map( x => ( x[ 1 ] ) ),rowLengthArray)
	
	const displayData=colum0.map(function(e, i) {
		return [e,colum1[i],colum2[i],colum3[i],colum4[i] ];
	  });

	const activeRow = (rowSelected:any) => {
		setSelectedrow(rowSelected)
	}
	console.log(AppData.userConfig.data.remote)
  return (
	<ThemeProvider theme={PrimaryTheme}>
		 <PopUpForm shape={"hug"} spacing={"popupform1"} fill={"white"} stroke={""} font={""} initFormData={formdata1.data} onSubmit={() => { } } visible={active} hide={setactive} popupformshape={"popupform1"} popupfill={"popupform"} popupstroke={"popupform"} items={[]}></PopUpForm>
		<DataGrid data={data} displayData={displayData} columns={Columns} shape={shape} fill={fill} stroke={stroke} onClick={activeRow}/>
    </ThemeProvider>
  );
}

export default AppTable;
