import React, { useRef, useState } from 'react';
import AppData from '../../appData';
import Icons from '../../icons/icons';
import { Button } from '../button/button';
import Dropdown from '../dropDown/dropDown';
import FlexBox from '../flexBox/flexBox';
import InputString from '../inputString/inputString';
import InputTextarea from '../multiLineTextArea/multiLineTextArea';
import { Modal, FormLayout, FormDataStyle } from './popupFormStyles';
import SelectButton from '../selectButton/selectButton';
import CalendarInputComp from '../calendar/calendarInput';
import Checkbox from '../checkBox/checkBox';
import RadioButton from '../radioButton/radioButton';

type ElemType = "bool" | "select" | "text" | "textarray" | "button"|"selectbutton"|"header"|"inputcalendar"|"checkBox"|"radio";

export interface ElemIF {
    type: ElemType;
    id: string;
    name: string;
    label: string;
    placeHolder?: string;
    fill?: string
    stroke?: string
    content: string;
    regExp?: string;
    disabled?:boolean;
}
export interface DropIF extends ElemIF {
    items: string[];
    enableFilter: boolean;
    contentItems: any;
    current:any;
    disable:any;
    name:any;
}
interface CheckIF extends ElemIF {
    initial:boolean
    items: { name: string, key: string }[],
    labelvalues:string
}
interface RadIf extends ElemIF {
    items: { name: string, key: string }[],
    initial: string,
    tooltipposition: 'right' | 'left' | 'top' | 'bottom',
    help: string
}
interface PropsIF {
    popupformshape: string
    shape: string,
    spacing: string,
    fill: string,
    stroke: string,
    popupfill: string
    popupstroke: string,
    heading?: string
    font: string
    initFormData: ElemIF[];
    onSubmit: (inpData: { id: string, value: string }[]) => void;
    hide?: any;
    OnclickCancel?:()=> void
    Error?:any;
    visible: any;
    valueChange?:any
    items: string[];
    buttonContent?: string;
    buttonName?: string;
    form?: string
    subHeading?: string;
    customerPopup?: boolean
}

function PopUpForm({ customerPopup=false,subHeading,form,popupformshape, popupfill,valueChange, Error,popupstroke, hide, heading,OnclickCancel=()=>{} ,visible, shape = "hug", spacing, initFormData, onSubmit, items, buttonName, buttonContent }: PropsIF) {
    const formData = useRef(initFormData.map(item => { return { id: item.id, value: "" } }))
    const [render,setRender] = useState(false)
    const [radio,setRadio ] = useState('')
    const tmp = initFormData.map(item => { return { id: item.id, value: "" } })
    console.log('initFormData',initFormData,AppData.solutionData)
    const getElement = (elemData: ElemIF) => {
        const assignVal = (myVal: any) => {
            console.log('change', elemData.id, myVal, heading)
            if(elemData.type === 'radio') {
                setRadio(myVal)
            }
            if( heading === 'Write to Device' && myVal != "") {
                if(elemData.type === 'radio') {
                    let thisRadiodata = elemData as RadIf
                    thisRadiodata.initial = myVal
                }                
                valueChange(myVal,elemData.id)
            }
            if (heading === 'Create New Configuration' && myVal != "") {
                if (elemData.type=='select') {
                    AppData.variant = myVal
                }
                valueChange(myVal,elemData.id)
            }
            if ((heading === "Enter Details")&&(typeof (myVal) == 'string')){
                valueChange(myVal,elemData.id)
            }
            if (heading === "Edit Configuration"){
                valueChange(myVal,elemData.id)
            }
            if (heading === "Duplicate Configuration"){
                valueChange(myVal,elemData.id)
            }
            if (heading === "Solution Finder"){
                valueChange(myVal,elemData.id)
            }
            if (heading === 'Spread Spectrum Control'){
                valueChange(myVal,elemData.id)
            }
            if (heading === 'Freeze Configuration' && myVal != "") {
                if (elemData.id == 'Temperature Range*') {
                    AppData.freeze[0] = myVal
                }
                else if (elemData.id == 'Customer Ordering Code*') {
                    AppData.freeze[1] = myVal
                }
                else if (elemData.id == 'Frequency Stability*') {
                    AppData.freeze[2] = myVal
                    AppData.ppmValue = myVal
                    AppData.freezeFormData.freeze[2].current = myVal
                    setRender(!render)
                }
                else if (elemData.id == 'Packaging*') {
                    AppData.freeze[3] = myVal
                }
                console.log('freeze', AppData.freeze)
                console.log('myVal', myVal)
            }
            let num = formData.current.findIndex((item) => { return (item.id == elemData.id) })
            if (typeof (myVal) == 'string' && num != -1) { formData.current[num].value = myVal }
            else if (typeof (myVal) == 'boolean'){formData.current[num].value = myVal.toString()}
        }
        switch (elemData.type) {
            case "text":
                return (
                    <InputString spacing='popupform1' shape={elemData.name} placeholder={elemData.placeHolder} regex={elemData.id==="number"?/^[0-9]+$/:undefined} fill='popupform1' stroke='none' getValue={assignVal} fonts={''} content={elemData.content} disabled={elemData.disabled}></InputString>
                )
                break;
            case "select":
                let thisSelectdata = elemData as DropIF
                return (
                    <Dropdown disabled={thisSelectdata.disable} form={form} current={thisSelectdata.current} spacing="normal"  shape="popupform1" fill='popupform1' select={'single'} InputSearch={heading==='Spread Spectrum Control'?thisSelectdata.contentItems.length>6?true:false:false} effects={'dropShadow'} contentItems={thisSelectdata.contentItems || items} stroke={'none'} onClick={assignVal}></Dropdown>
                    // <Dropdown  disabled={thisSelectdata.disable} iconcolor="dropdown"  spacing="normal" shape={"popupform1"} fill='popupform1' select={'single'} InputSearch={false} effects={''} contentItems={thisSelectdata.contentItems||items} stroke={'none'} onClick={assignVal}></Dropdown>
                )
                break;
            case "textarray":
                return (
                    <InputTextarea resize={false} stroke='none' fill='popupform1' shape={elemData.name} placeholder={elemData.placeHolder} spacing={'popupform1'} getValue={assignVal} content={elemData.content}></InputTextarea>
                )
            case "button":
                return (
                    <Button fill='signin' color='white' shape={elemData.name}>{elemData.content}</Button>
                )
            case "selectbutton":
                return(
                    <SelectButton values={["entry1","entry2"]} disabled ={[false,false]} onClick={()=>{}} select={"single"} orientation={"vertical"}/>
                )
            case "header":
                return(
                    <div style={{ width: '365px', height: '15px', margin: '0px', padding: '0px', color: '#231F20', fontWeight: 'bold', fontSize: '19px',display:'flex',flexDirection:'row' }}>{elemData.content}
                    {elemData.placeHolder && <div style={{ width: '100px', height: '11px', marginLeft: '5px', padding: '0px', color: '#231F20', fontWeight: 'bold', fontSize: '10px' }}>{elemData.placeHolder}</div>}</div>
                )
            case "inputcalendar":
                return(
                    <CalendarInputComp content={elemData.content} onClick={assignVal} shape={"normal"} stroke={"normal"} font={"normal"} effects={"none"} fill={"white"}></CalendarInputComp>
                )
            case 'checkBox' : 
                let thisCheckdata = elemData as CheckIF
                return (
                    <Checkbox values={thisCheckdata.items} onClick={assignVal}/>
                )
            case "radio":
                let thisRadiodata = elemData as RadIf
                return (
                    <RadioButton radios={thisRadiodata.items} display={"column"} onClick={assignVal} initialState={thisRadiodata.initial}/>
                )
        }
    }

    const allElements = (elemData: ElemIF) => {
        return (
            <div>
                <p style={{ margin: '0px', padding: '0px' }}>{elemData.label}</p>
                {getElement(elemData)}
            </div>
        )
    };

    const onClick = () => {
        onSubmit(formData.current)
        console.log('Visible ', visible,formData.current)
        hide(visible)
    }

    return (
        <Modal style={{ display: visible ? 'block' : 'none' }}>
            <FormLayout spacing={spacing} shape={popupformshape} fill={popupfill} stroke={popupstroke}>
                <FlexBox shape={customerPopup?"customerpopup":"hug"} spacing={customerPopup?"customerPopup":'popup'} layout='vertical-top-left'>
                    <Button onClick={() => { hide(false); OnclickCancel()}} shape='hug' fill='white' spacing='form'><Icons stroke='black' iconName={'cancel'} size={'24px'}></Icons></Button>
                    <FormDataStyle spacing={spacing}>
                        <FlexBox shape='hug' spacing='blockdiagrambox' layout='vertical-top-left'>
                            <FlexBox shape='hug' spacing='blockdiagrambox' layout='vertical-top-left'>
                               {!customerPopup && <div style={{ width: '363px', height: '39px', margin: '0px', padding: '0px', color: '#231F20', fontWeight: 'bold', fontSize: '26px',display:'flex',flexDirection:'row' }}>{heading}
                                {subHeading && <div style={{ width: '100px', height: '11px', marginLeft: '5px', padding: '0px', color: '#231F20', fontWeight: 'bold', fontSize: '10px' }}>{subHeading}</div>}
                                </div>}
                                {initFormData.map(item => allElements(item))}
                            </FlexBox>
                            <Button fill='signin' color='white' shape={buttonName} onClick={onClick}>{buttonContent}</Button>
                        </FlexBox>
                    </FormDataStyle>
                </FlexBox>
                <FlexBox shape='hug' layout='vertical-top-left' spacing='none'><div style={{color:'red',marginTop:'10px',fontSize:'18px'}}>{Error}</div></FlexBox>
            </FormLayout>
        </Modal>
    )
}

export default PopUpForm;