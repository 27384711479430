import React, { useCallback, useEffect, useRef, useState } from "react";
import Icons from "../../../icons/icons";
import GridContainer from "../../../components/gridContainer/gridContainer";
import FlexBox from "../../../components/flexBox/flexBox";
import { Button } from "../../../components/button/button";
// import injectStyle from "../../../styles/injectStyle";
// import Form from "../../../Components/form/form"
// // import NeoliteBlockdiagram from '../../../deviceSpecific/neolite/blockDiagramData';
// import Blockdiagram, { BlockdiagramIF, PlaceIF, RouteIF } from '../../../Components/blockDiagram/blockDiagram';
import AppData from '../../../appData'
import { RestServer } from './../../../REST/axios'
// import { ErrorIF } from '../../../appTypes'
import { changeRegister, configurationSearch, freezeConfig, functionSearch, getBlockFields, getBlockFunctions, getDeviceProperties, getDevicePropertiesByName, getFunctionState, getPinDiagramData, getRegisterState, historySearch, writeToDevice } from "./blockDiagramEvents";
import DetailScreen from "./detailscreen";
import HistoryScreen from "../historyScreen/historyScreen";
import { SummaryView, copyUserConfig, generateStates, getBlockDiagramData, initializeConfig, syncConfig, updateUserConfig } from "../configurationScreen/configurationEvents";
import PopUpForm from "../../../components/popupForm/popupForm";
import { Menu } from "../../../components/menu/menu";
import DialogBox from "../../../components/dialogBox/dialogBox";
import Message from "../../../components/messages/messages";
import PinoutViewScreen from "./pinoutViewScreen";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import Tooltip from "../../../components/toolTip/toolTip";
import configJson from "../../../config.json"
import "./writeDevice.json"

const getFormElement = (data: any) => {
    var items: string[] = []
    data.inputs.map((val: any) => {
        try {
            for (const [key, value] of Object.entries(val)) {
                items.push(key + ' [' + value + ']')
            }

        }
        catch { }
    })
    if (data.type == 'LIST') {
        var current = ''
        if (data.name === 'Frequency Stability*') {
            items.map((val) => {
                if (val.indexOf(AppData.ppmValue) > -1) {
                    current = val
                    AppData.ppmValue = val
                }
            })
            return ({ type: "select", id: data.name, label: data.name, contentItems: items, current: current, disable: current != '' ? true : false })
        }
        else {
            return ({ type: "select", id: data.name, label: data.name, contentItems: items })
        }
    }
}


interface propsIF {
    configBack: (value: boolean) => void
    configData?: any
    onSync?: any
    recentData?: any
    onClickDelete: () => void
    setConfigData?: any
    setRemoteData?: any
    setRecentData?: any
    configBackButton?: any
    setBlock?: any
    MessageText?: any
    setShowMessage?: any

}
function BlockDiagramScreen({ MessageText='',setShowMessage=false,configBack, configBackButton, configData, onSync, setBlock, recentData, onClickDelete, setConfigData, setRemoteData, setRecentData }: propsIF) {
    const [, updateState] = useState({});
    const [showDetails, setShowDetails] = useState(true)
    const [history, setHistory] = useState(false)
    const [pinout, setPinout] = useState(false)
    const [freeze, setFreeze] = useState(false)
    const [active, setactive] = useState(false)
    const [copy, setCopy] = useState(false)
    const [edit, setEdit] = useState(false)
    const [show, setShow] = useState(false);
    const [render, setRender] = useState(false)
    const [message, setMessage] = useState(MessageText)
    const [visible, setVisible] = useState(setShowMessage)
    const [dialogActive, setDialogActive] = useState(false)
    const [loader, setLoader] = useState(false)
    const [loaderMessage, setLoaderMessage] = useState("Loading...")
    const [error, setError] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [inpContent, setInpContent] = useState<string>()
    const [directionCurrent, setDirectionCurrent] = useState<any>()
    const [typeCurrent, setTypeCurrent] = useState<any>()
    const [showInterface, setShowInterface] = useState<boolean>(true)
    const [directionList, setDirectionList] = useState<any>([])
    const [typeList, setTypeList] = useState<any>([])
    const [functionList, setFunctionList] = useState<any>([])
    const [functionCurrent, setFunctionCurrent] = useState<any>()
    const [disableList, setDisableList] = useState<any>([])
    const [showFunction, setShowFunction] = useState(true)
    const [showType, setShowType] = useState(false)
    const [duplicate, SetDuplicate] = useState(false)   
    const [inverseCurrent, setInverseCurrent] = useState<any>()
    const [inverseList, setInverseList] = useState<any>([])
    const [showInverse, setShowInverse] = useState(true)
    const [writeDevice,setWriteDevice] = useState(false)
    const [onClickDuplicate,setOnClickDuplicate] = useState(false)
    const [opValue, setOpValue] = useState<any>()
    const [opList, setOPList] = useState<any>([])
    const [showOPType, setShowOPType] = useState(true)
    useEffect(() => { 
        setMessage(MessageText)
    },[MessageText])
    useEffect(() => { 
        setVisible(setShowMessage)
    },[setShowMessage])
    const setMessageComp = (visible: boolean, message: any) => {
        return setMessage(message), setVisible(visible)
    }
    console.log('webOnly', configJson)
    var formData = require('../configurationScreen/editconfig.json')
    var deviceFormData = require("./writeDevice.json")
    function freezeFormData() {
        //Forming data to display in freeze form
        AppData.freezeFormData = { "freeze": [] }
        let formData: any
        AppData.devicePropertyData.map((data: any) => {
            if (data.name == 'Temperature Range' ||
                data.name == 'Customer Ordering Code' ||
                data.name == 'Frequency Stability' ||
                data.name == 'Packaging') {
                data.name += "*"
                formData = getFormElement(data)
                AppData.freezeFormData.freeze.push(formData)
            }
        })
    }
    const syncConfiguration = () => {
        //Sync configuration
        syncConfig(AppData.server, AppData.currentConfig, onSync, recentData).then((res) => { return setMessageComp(true, `${AppData.currentConfig.name} configuration was synced to ${AppData.server == 'remote' ? "local" : 'remote'}`) })
    }
    const handleFreeze = () => {
        //OnClick of freeze button
        setVisible(false)
        setError('')
        if (AppData.currentConfig.custom_part_number) {
            alert("Configuration already Frozen. Cannot Freeze again")
            return;
        }
        getDeviceProperties().then((val: any) => { freezeFormData(); setFreeze(true) })
    }
    const handleLocalSync = async () => {
        //get current config as the data may have been modified
        AppData.api.configSearchWithUUID.bodyParams.udc_uuid = AppData.currentConfig.udc_uuid
        const current = await AppData.localServer.sendCommand(AppData.api.configSearchWithUUID, `Getting config data from local server failed.`, 1)
        AppData.api.deviceSearchWithId.bodyParams = { _id: current.device_id }
        var currentDevice = await AppData.localServer.sendCommand(AppData.api.deviceSearchWithId, `Getting current Device failed`, 1)
        current[0]['platform'] = currentDevice[0].platform
        current[0]['variant'] = currentDevice[0].variant
        AppData.currentConfig = current[0];
        syncConfig(AppData.server, AppData.currentConfig, onSync, recentData).then((res) => { return setMessageComp(true, `${AppData.currentConfig.name} configuration was synced to ${AppData.server == 'remote' ? "local" : 'remote'}`) })
    }
    const handleSync = () => {
        //Configuration check when synced from remote to local
        //Open dialog box if config already present
        let check = false
        AppData.userConfig.data.local.map((configData: any) => {
            if (configData.name == AppData.currentConfig.name) {
                check = true
            }
        })
        if (check) {
            setDialogActive(true)
        }
        else {
            syncConfig(AppData.server, AppData.currentConfig, onSync, recentData).then((res) => { return setMessageComp(true, `${AppData.currentConfig.name} configuration was synced to ${AppData.server == 'remote' ? "local" : 'remote'}`) })
        }
    }
    const handleShowDetails = () => {
        //Search history of config and open the history view
        setVisible(false)
        AppData.label = ''
        AppData.functionFormData = {
            "param": [],
            "commonfunctions": [],
            "delay": [],
            "type": [],
            "differential": [],
            "single": [],
            "op": [],
            "singlecommon": [],
            "singlevoh": [],
            "diffvoh": [],
            "slew": [],
            "diffref": [],
            "freq": [],
            "vdd": []
        }
        historySearch().then((val) => { setShowDetails(false); setPinout(false); setHistory(true) })
    }
    const handler = () => {
        setShow(!show);
    }
    const lostfocus = () => {
        setShow(false);
    }
    const handleFreezeSubmit = (formData: { id: string, value: string }[]) => {
        //Submit freeze and send to API
        AppData.freezeData = []
        AppData.freeze[2] = AppData.ppmValue
        AppData.freeze.map((data: any) => {
            let val = data.split(' ')
            var freezeInputs = val[0]
            AppData.freezeData.push(freezeInputs)
        })
        if (AppData.freezeData.length == 4) {
            freezeConfig(setFreeze, setConfigData, setRemoteData, setRecentData, setMessageComp).then((val) => {
                setFreeze(false)
                setRender(!render)
            })
            AppData.freezeFormData = { "freeze": [] }
            AppData.freeze = []
        }
        else {
            setFreeze(true)
            setError('Enter all the properties to freeze the configuration.')
        }
    }
    const editSubmit = (formData: { id: string, value: string }[]) => {
        var checkName = 0
        var checkDescription = 0
        //OnClick edit form submit
        formData.map((val) => {
            if (val.id == 'name') {
                const newValue = val.value.trim()
                if ((newValue != '')) {
                    if (newValue.length > 60) {
                        checkName = 0
                        setError('Configuration name must contain less than 60 characters.')
                        setCopy(true)
                        setRefresh(true)
                    }
                    else {
                        checkName = 1
                    }
                }
                else if ((newValue === '') && (error === '')) {
                    //if unaltered send the same content data to API
                    if (val.id == 'name') {
                        val.value = AppData.currentConfig.name
                    }
                    checkName = 1
                }
                else {
                    //Check if name is empty and show error accordingly
                    checkName = 0
                    setError('Please enter the required fields')
                    setCopy(true)
                    setRefresh(true)

                }
            }
            else if (val.id === 'description') {
                if (val.value.length > 255) {
                    checkDescription = 0
                    setError('Description must contain less than 255 characters.')
                    setCopy(true)
                    setRefresh(true)
                }
                else { checkDescription = 1 }
            }
        })
        if ((checkName === 1) && (checkDescription === 1)) {
            let formdata = formData.map(item => { return (`${item.id}:${item.value}`) })
            updateUserConfig(formdata, AppData.currentConfig, setConfigData, setRecentData).then((val) => {
                setCopy(false)
                setMessageComp(true, 'Configuration is updated successfully')
                setRefresh(false)
                setError('')
                setRender(!render)
            })
        }
    }
    const onEditChange = (value: string, id: string) => {
        //If name is empty throw error immediately
        if (id === 'name') {
            const newValue = value.trim()
            if (newValue === '') {
                setError('Enter the configuration name')
            }
            else if (newValue.length > 60) { setError('Configuration name must contain less than 60 characters.') }
            else if (newValue != '') { setError('') }
        }
        else if (id === 'description') {
            if (value.length > 255) { setError('Description must contain less than 255 characters.') }
            else { setError('') }
        }
    }

    const onValueChangeProgram = async (value: string, id: string) => {
        //If name is empty throw error immediately        
        if (id === 'Program') {
            deviceFormData.write[1].label = "Frequency"
            deviceFormData.write[2].label = "Drivers"
            deviceFormData.write[3].label = "GPIO"

            var regList = ['']
            if(value === "In System Programming"){
                setLoader(true)
                setLoaderMessage("Device Interaction")
                // update isp_update_status to update the counts
                // this has to be done when user clicks ISP
                const devicePropertyData = await getDevicePropertiesByName(AppData.server,'ISP_UPDATESTATUS')              
                
                regList = []
                devicePropertyData[0].inputs.map((data:any)=>{
                    regList.push(data)
                })
                AppData.registerList = []
                await getRegData(regList)
                try {await writeToDevice(AppData.registerList)}
                catch {
                    alert('Write to device failed.')                
                    setLoader(false)
                }
                const readDevicePropertyData = await getDevicePropertiesByName(AppData.server,'ISP_READSTATUS')
                regList = []
                readDevicePropertyData[0].inputs.map((data:any)=>{
                    regList.push(data)
                })
                try {
                    AppData.api.readDriver.bodyParams = { 'protocol': AppData.protocol, 'slave_address': AppData.slave_address, "regData": regList }
                    const registerAddress = await AppData.deviceServer.sendCommand(AppData.api.readDriver, `Reading the device failed`, 1)
                    const valuesRead = registerAddress["data_read_back"]
                    console.log(valuesRead)
                    var regAddress = Object.keys(valuesRead)
                    for (const key of regAddress) {
                        console.log(valuesRead[key])
                        try {
                            if(key === "0x1C") {
                                AppData.api.getFieldValue.bodyParams = {'register_address': key, 'field_name': "isp_freq_copy_left", "state": valuesRead[key],"device_id": AppData.currentConfig.device_id}
                                const isp_freq_copy_left = await AppData.localServer.sendCommand(AppData.api.getFieldValue, `Reading the field value failed`, 1)
                                deviceFormData.write[1].label = "Frequency " + "( " + isp_freq_copy_left + " section copies left )"

                                AppData.api.getFieldValue.bodyParams = {'register_address': key, 'field_name': "isp_driver_copy_left", "state": valuesRead[key],"device_id": AppData.currentConfig.device_id}
                                const isp_driver_copy_left = await AppData.localServer.sendCommand(AppData.api.getFieldValue, `Reading the field value failed`, 1)
                                deviceFormData.write[2].label = "Drivers " + "( " + isp_driver_copy_left + " section copies left )"

                                AppData.api.getFieldValue.bodyParams = {'register_address': key, 'field_name': "isp_gpio_copy_left", "state": valuesRead[key],"device_id": AppData.currentConfig.device_id}
                                const isp_gpio_copy_left = await AppData.localServer.sendCommand(AppData.api.getFieldValue, `Reading the field value failed`, 1)
                                deviceFormData.write[3].label = "GPIO " + "( " + isp_gpio_copy_left + " section copies left )"

                                setRender(!render)
                            }
                            if(key === "0x1D") {
                                AppData.api.getFieldValue.bodyParams = {'register_address': key, 'field_name': "isp_mstr_row_left", "state": valuesRead[key],"device_id": AppData.currentConfig.device_id}
                                const isp_mstr_row_left = await AppData.localServer.sendCommand(AppData.api.getFieldValue, `Reading the field value failed`, 1)
                            }
                        }
                        catch {
                            alert('Convertion date to field state failed.')                    
                            setLoader(false)
                        }
                    }
                }
                catch {
                    alert('Read from device failed.')                    
                    setLoader(false)
                }
                regList = []
                devicePropertyData[0].inputs.map((data:any)=>{
                    regList.push(data)
                })
                AppData.registerList = []
                await setInitRegData(regList)
                try {
                    await writeToDevice(AppData.registerList)
                }
                catch {
                    alert('Write to device failed.')                    
                    setLoader(false)
                }
                setLoader(false)
            }
        }
    }
    const onDuplicateChange = (value: string, id: string) => {
        //If name is empty throw error immediately
        if (id === 'name') {
            const newValue = value.trim()
            if (newValue === '') {
                setError('Enter the configuration name')
            }
            else if (newValue.length > 60) { setError('Configuration name must contain less than 60 characters.') }
            else if (newValue != '') { setError('') }
        }
        else if (id === 'description') {
            if (value.length > 255) { setError('Description must contain less than 255 characters.') }
            else { setError('') }
        }
    }
    const copySubmit = async (formData: { id: string, value: string }[]) => {
        let count = false
        var checkName = 0, checkDescription = 0
        formData.map((val) => {
            if (val.id == 'name') {
                const newValue = val.value.trim()
                AppData.userConfig.data.local.map((local: any) => {
                    if (local.name === newValue) {
                        count = true
                    }
                })
                if ((newValue != '')) {
                    if (count) {
                        checkName = 0
                        setError("Please enter a unique configuration name")
                        setRefresh(true)
                        setCopy(true)
                    }
                    //Duplicate the configuration
                    else {
                        if(newValue.length>60){
                            checkName = 0
                            setError('Configuration name must contain less than 60 characters.')
                            setCopy(true)
                            setRefresh(true)
                        }
                        else{checkName = 1}
                    }
                }
                else {
                    checkName = 0
                    //If empty field throw error
                    setError('Please enter the required fields')
                    setCopy(true)
                    setRefresh(true)
                }
            }
            else if (val.id === 'description'){
                if(val.value.length > 255){
                    checkDescription = 0
                    setError('Description must contain less than 255 characters.')
                    setCopy(true)
                    setRefresh(true)
                }
                else {checkDescription = 1}
            }
        })
        if ((checkDescription === 1) && (checkName === 1)) {
            let formdata = formData.map(item => { return (`${item.id}:${item.value}`) })
            //get current config as the data may have been modified
            AppData.api.configSearchWithUUID.bodyParams.udc_uuid = AppData.currentConfig.udc_uuid
            const current = await AppData.localServer.sendCommand(AppData.api.configSearchWithUUID, `Getting config data from local server failed.`, 1)
            AppData.api.deviceSearchWithId.bodyParams = { _id: current.device_id }
            var currentDevice = await AppData.localServer.sendCommand(AppData.api.deviceSearchWithId, `Getting current Device failed`, 1)
            current[0]['platform'] = currentDevice[0].platform
            current[0]['variant'] = currentDevice[0].variant
            AppData.currentConfig = current[0];
            copyUserConfig(formdata, AppData.currentConfig, setConfigData, setRecentData).then((res) => {
                SetDuplicate(true)
            })
            setCopy(false)
            setRefresh(false)
            setMessageComp(true, 'Duplicate configuration has been created')
            configurationSearch(setConfigData, setRemoteData, setRecentData)
            setError('')
        }

    }
    if (edit) {
        //Assign placeHolder and content for edit form based on the row selected from configuration table
        formData.edit[0].placeHolder = AppData.currentConfig.name
        formData.edit[1].placeHolder = AppData.currentConfig.description

        formData.edit[0].content = AppData.currentConfig.name
        formData.edit[1].content = AppData.currentConfig.description
    }
    else {
        //Assign placeHolder and content for duplicate form based on the row selected from configuration table
        formData.duplicate[0].content = ''
        formData.duplicate[1].content = ''
    }

    const onclickNo = () => {
        SetDuplicate(false)
    }

    const onclickYes = () => {
        setVisible(false)
        setLoader(true)
        setLoaderMessage("Loading...")
        var config: any
        config = AppData.server === "local" ? AppData.userConfig.data.local[0] : AppData.userConfig.data.remote[0]
        initializeConfig(config, AppData.server).then((item) => {
            generateStates(AppData.server).then((val) => {
                getBlockDiagramData(config, AppData.server).then((response) => {
                    try {
                        AppData.componentList = []
                        AppData.blockDiagramData[0].place.map((placeData: any) => {
                            if ((placeData.type === 'Driver') && (placeData.clickable !== 'NO')) {
                                AppData.componentList.push(placeData.name)
                                AppData.labelList.push(placeData.label)
                            }
                        })
                        SummaryView().then(res => {
                            setOnClickDuplicate(false)
                            console.log('navigation')
                            setRender(!render)
                            setLoader(false)
                        })
                    }
                    catch {
                        setLoader(false)
                    }
                })

            })
        })
        AppData.currentConfig = config
        setRender(!render)
        console.log(config, AppData.userConfig.data.local[0], AppData.server, "uuu.....")
    }
    const deleteClick = () => {
        onClickDelete()
    }
    async function onClickBack() {
        //OnClick Details tab, load the SUmmaryView details 
        setLoader(true)
        setLoaderMessage("Loading...")
        await summaryView()
        setShowDetails(true)
        setHistory(false)
        setPinout(false)
        setLoader(false)
    }
    async function summaryView() {
        setVisible(false)
        var index
        var blockFunctions = await getBlockFunctions(AppData.componentList, AppData.server)
        AppData.deviceFrequenciesData = []
        let devicePropertyData = await getDevicePropertiesByName(AppData.server,AppData.currentConfig.variant.toUpperCase() + '_FREQUENCIES')
        if(devicePropertyData.length != 0){
            devicePropertyData[0].inputs.map((data:any)=>{
                AppData.deviceFrequenciesData.push(data.toString())
            })
        }

        AppData.deviceFrequenciesDataDiff = []
        devicePropertyData = await getDevicePropertiesByName(AppData.server,AppData.currentConfig.variant.toUpperCase() + '_FREQUENCIES_DIFF')
        if(devicePropertyData.length != 0){
            devicePropertyData[0].inputs.map((data:any)=>{
                AppData.deviceFrequenciesDataDiff.push(data.toString())
            })
        }

        AppData.deviceFrequenciesDataSE = []
        devicePropertyData = await getDevicePropertiesByName(AppData.server,AppData.currentConfig.variant.toUpperCase() + '_FREQUENCIES_LVCMOS')
        if(devicePropertyData.length != 0){
            devicePropertyData[0].inputs.map((data:any)=>{
                AppData.deviceFrequenciesDataSE.push(data.toString())
            })
        }
        //Get all component data from dB
        blockFunctions.map((val: any) => {
            var functionData: {}[] = []
            for (const [key, value] of Object.entries(val.Functions)) {
                if (val.Functions[key].name === 'VDD') {
                    AppData.vddList = val.Functions[key].parameters[0].inputs
                    AppData.vddState = val.Functions[key].function_state
                }
                if (val.Functions[key].name === 'VDDA') {
                    AppData.vddaList = val.Functions[key].parameters[0].inputs
                    AppData.vddaState = val.Functions[key].function_state
                }
            }
            try {
                for (const [key, value] of Object.entries(val.Functions)) {
                    functionData.push({
                        'name': val.Functions[key].name, 'parameters': val.Functions[key].parameters, "function_state": val.Functions[key].function_state
                    })
                }
                index = AppData.componentList.indexOf(val['Block/component name'])
                AppData.compData[index] = functionData
            } catch {
            }
        })
        //const result = await resolveAfter4Seconds();
        var compCount = 0
        AppData.summaryData = []
        AppData.compData.forEach((functionData: any) => {
            let compFunction: any = {}
            for (const [key, value] of Object.entries(functionData)) {
                let paramName = functionData[key].parameters[0].param_name
                let functionValue = functionData[key].function_state
                if ((paramName == 'Mode') || (paramName == 'VDDO') || (paramName == 'Frequency(MHz)') || (paramName == 'Type') || (paramName == 'Hi-Z State') || (paramName == 'Delay Common Mode')) { compFunction[paramName] = functionValue }
                else if ((paramName == 'Delay CLK1N') || (paramName == 'Delay CLK0N') || (paramName == 'Delay CLK2N') || (paramName == 'Delay CLK3N')) { compFunction[paramName] = functionValue }
                else if ((paramName == 'Delay CLK0P') || (paramName == 'Delay CLK1P') || (paramName == 'Delay CLK2P') || (paramName == 'Delay CLK3P')) { compFunction[paramName] = functionValue }
            }
            AppData.summaryData[compCount] = compFunction
            compCount += 1
        })
    }
    const delay = (ms: number | undefined) => new Promise(res => setTimeout(res, ms));

    const onClickPinout = async () => {
        setVisible(false)
        var function_list = ["I2C_SPI_NONE", "Address"]
        var function_list1 = ["GPIO0_Direction", "GPIO0_Type", "GPIO0_Inverse","GPIO0_OPType"] 
        var diagramName = AppData.GPIOCONFIGURE
        AppData.pinComp = 'GPIO0'
        var data: any
        var val: any
        var res: any
        var inputData: any
        var outputData: any
        var stateData: any
        var result: any
        data = await getFunctionState(function_list, AppData.server, AppData.blockDiagramData[0]._id)
        AppData.GPIOstate = data[0].function_state
        setInpContent(data[1].function_state)
        if (data[0].function_state == 'I2C') {
            diagramName = AppData.GPIOI2C
            AppData.GPIOstate = 'I2C'
        }
        else if (data[0].function_state == 'SPI') {
            diagramName = AppData.GPIOSPI
            AppData.GPIOstate = 'SPI'
        }
        else if (data[0].function_state == 'NONE') {
            diagramName = AppData.GPIONONE
            AppData.GPIOstate = 'NONE'
        }
        else { diagramName = AppData.GPIOCONFIGURE }

        AppData.diagramName = diagramName
        //Open respective Pin diagram based on the function_state
        val = await getPinDiagramData(AppData.currentConfig, AppData.server, diagramName)
        var check = 0
        res = await getFunctionState(function_list1, AppData.server, AppData.pinDiagramData[0]._id)
        setDirectionCurrent(res[0].function_state)
        setDirectionList(res[0].parameters[0].inputs)
        if (res[1].function_state != 'disable') { setTypeCurrent(res[1].function_state) }
        else { setTypeCurrent('') }
        var typeListt: string[] = [], count = 0
        res[1].parameters[0].inputs.map((type: string) => {
            if (type != 'disable') {
                typeListt[count] = type
                count += 1
            }
        })
        setTypeList(typeListt)
        setInverseList(res[2].parameters[0].inputs)
        setInverseCurrent(res[2].function_state)
        setOPList(res[3].parameters[0].inputs)
        setOpValue(res[3].function_state)
        if (res[0].function_state == 'Input' && AppData.GPIOstate != 'disable') {
            setShowInverse(true)
            setShowOPType(true)
            setShowType(false)
            AppData.direction = res[0].function_state
            var function_List: any[] = []
            inputData = await getDevicePropertiesByName(AppData.server, AppData.pinComp + '_INPUT_' + AppData.GPIOstate)
            setFunctionList(inputData[0].inputs)
            function_List = inputData[0].inputs
            stateData = await getFunctionState(function_List, AppData.server, AppData.pinDiagramData[0]._id)
            var disablelist: boolean[] = []
            stateData.map((state: any, index: number) => {
                if (state.function_state === AppData.pinComp) {
                    check = 1
                    disablelist[index] = false
                    setFunctionCurrent(state.parameters[0].param_name)
                    setRender(!render)
                }
                else if (state.function_state == null || state.function_state == 'disable') {
                    disablelist[index] = false
                }
                else { disablelist[index] = true }
            })
            setDisableList(disablelist)
            if (check != 1) {
                setFunctionCurrent('')
            }
        }
        if (res[0].function_state == 'Output' && AppData.GPIOstate != 'disable') {
            setShowInverse(false)
            setShowOPType(true)
            setShowType(true)
            setDisableList([])
            AppData.direction = res[0].function_state
            var fun_list = [AppData.pinComp + "_OUTPUT_" + AppData.GPIOstate]
            result = await functionSearch(AppData.pinComp + "_OUTPUT_" + AppData.GPIOstate, AppData.server)
            if (result[0].parameters[0].inputs.length != 0) {
                setShowFunction(true)
                outputData = await getFunctionState(fun_list, AppData.server, AppData.pinDiagramData[0]._id)
                setFunctionList(outputData[0].parameters[0].inputs)
                val = await getFunctionState([AppData.pinComp + '_OUTPUT_' + AppData.GPIOstate], AppData.server, AppData.pinDiagramData[0]._id)
                if (val[0].function_state != null) { setFunctionCurrent(val[0].function_state) }
                else { setFunctionCurrent('') }
            }
            else {
                setShowFunction(false)
            }


        }
        setShowDetails(false)
        setHistory(false)
        setPinout(true)
        if (data[0].function_state == 'disable') { setShowInterface(false) }
        else { setShowInterface(true) }
    }
    const onCloseForm = () => {
        setRefresh(false)
    }
    const FreezeClose = () => {
        setError('')
        AppData.freeze = []
    }
    const handleWrite = async() => {
        // setWriteDevice(true)
        setMessageComp(false,'')
        console.log(deviceFormData.write[0])
        deviceFormData.write[0].initial = 'In System Configuration'
        deviceFormData.write[1].label = "Frequency"
        deviceFormData.write[2].label = "Drivers"
        deviceFormData.write[3].label = "GPIO"
        var result = await getFunctionState(["I2C_SPI_NONE", "Address"],'local',AppData.blockDiagramData[0]._id)
        console.log('result',result,AppData.slave_address,AppData.protocol)
        if((result[0].function_state === AppData.protocol) && (result[1].function_state === AppData.slave_address)){
            setWriteDevice(true)
        }
        else {
            setMessageComp(true,'Protocol and Slave Address do not match the device.')
        }
    }
    const handleWriteSubmit = async(formData: { id: string, value: string }[]) => {
        setLoader(true);
        setLoaderMessage("Writing to Device")
        AppData.registerList = []
        setError('')
        var dataList = [""],regList = ['']
        console.log(formData)
        var check = 0
        if(formData[0].value === ''){
            formData[0].value = 'In System Configuration'
        }
        formData.map((data:any)=>{
            if((data.value === 'false') || (data.value === '')){
                check += 1
            }
        })
        if(check === 3){
            setWriteDevice(true)
            setError('Please select one of the checkbox.')
            setLoader(false);
        }
        formData.map((data: any)=>{
            if(data.id === 'Program'){
                if(data.value === 'In System Configuration'){dataList[0] = 'ISC'} 
                else{dataList[0] = 'ISP'}
            }
            else if(data.id === 'Frequency'){if(data.value === 'true'){dataList[1] = "true"}else{dataList[1]="false"}}
            else if(data.id === 'Drivers'){if(data.value === 'true'){dataList[2] = "true"}else{dataList[2]="false"}}
            else if(data.id === 'GPIO'){if(data.value === 'true'){dataList[3] = "true"}else{dataList[3]="false"}}
        })
        if(dataList[0] === 'ISC') {
            if((dataList[1] === 'true')){
                const devicePropertyData = await getDevicePropertiesByName(AppData.server, dataList[0]+'_FREQUENCY')
                regList = []
                devicePropertyData[0].inputs.map((data:any)=>{
                    regList.push(data)
                })
                AppData.registerList = []
                await getRegData(regList)
                
                try {await writeToDevice(AppData.registerList)}
                catch {
                    alert('Write to device failed.')
                    setWriteDevice(false)
                    setLoader(false);
                }
            }
            if((dataList[2] === 'true')){
                const devicePropertyData = await getDevicePropertiesByName(AppData.server,dataList[0]+'_DRIVERS')
                regList = []
                devicePropertyData[0].inputs.map((data:any)=>{
                    regList.push(data)
                })
                AppData.registerList = []
                await getRegData(regList)
                try {await writeToDevice(AppData.registerList)}
                catch {
                    alert('Write to device failed.')
                    setWriteDevice(false)
                    setLoader(false)}
            }
            if((dataList[3] === 'true')){
                const devicePropertyData = await getDevicePropertiesByName(AppData.server,dataList[0]+'_GPIO')
                regList = []
                devicePropertyData[0].inputs.map((data:any)=>{
                    regList.push(data)
                })
                AppData.registerList = []
                await getRegData(regList)
                try {await writeToDevice(AppData.registerList)}
                catch {
                    alert('Write to device failed.')
                    setWriteDevice(false)
                    setLoader(false)}
            } 
        }  
        if(dataList[0] === 'ISP') { 
            //frequency
            var updateStatus = ""
            if((dataList[1] === 'true')){
                updateStatus = "ISP_FREQUENCY_UPDATE"
                const devicePropertyData = await getDevicePropertiesByName(AppData.server, 'ISC_FREQUENCY')
                regList = []
                devicePropertyData[0].inputs.map((data:any)=>{
                    regList.push(data)
                })
                AppData.registerList = []
                await getRegData(regList)
                try {await writeToDevice(AppData.registerList)}
                catch {
                    alert('Write to device failed.')
                    setWriteDevice(false)
                    setLoader(false);
                }
            } 
            //DRIVERS
            if((dataList[2] === 'true')){
                updateStatus = "ISP_DRIVER_UPDATE"
                if((dataList[1] === 'true')){
                    updateStatus = "ISP_FREQDRIVE_UPDATE"
                }
                const devicePropertyData = await getDevicePropertiesByName(AppData.server,'ISC_DRIVERS')
                regList = []
                devicePropertyData[0].inputs.map((data:any)=>{
                    regList.push(data)
                })
                AppData.registerList = []
                await getRegData(regList)
                try {await writeToDevice(AppData.registerList)}
                catch {
                    alert('Write to device failed.')
                    setWriteDevice(false)
                    setLoader(false)}
            } 
            //GPIO
            if((dataList[3] === 'true')){
                updateStatus = "ISP_GPIO_UPDATE"
                if((dataList[1] === 'true')){
                    updateStatus = "ISP_FREQGPIO_UPDATE"
                }
                else if((dataList[2] === 'true')){
                    updateStatus = "ISP_DRIVGPIO_UPDATE"
                }
                if((dataList[1] === 'true') && (dataList[2] === 'true')){
                    updateStatus = "ISP_ALL_UPDATE"
                }
                const devicePropertyData = await getDevicePropertiesByName(AppData.server,'ISC_GPIO')
                regList = []
                devicePropertyData[0].inputs.map((data:any)=>{
                    regList.push(data)
                })
                AppData.registerList = []
                await getRegData(regList)
                try {await writeToDevice(AppData.registerList)}
                catch {
                    alert('Write to device failed.')
                    setWriteDevice(false)
                    setLoader(false)}
            } 

            if( updateStatus !== ""){
                const devicePropertyUpdate = await getDevicePropertiesByName(AppData.server,updateStatus)
                regList = []
                devicePropertyUpdate[0].inputs.map((data:any)=>{
                    regList.push(data)
                })
                AppData.registerList = []
                await getRegData(regList)
                try {await writeToDevice(AppData.registerList)}
                catch {
                    alert('Write to device failed.')
                    setWriteDevice(false)
                    setLoader(false)
                }
                //set ISP_START
                const devicePropertyStartUpdate = await getDevicePropertiesByName(AppData.server,"ISP_START_UPDATE")
                regList = []
                devicePropertyStartUpdate[0].inputs.map((data:any)=>{
                    regList.push(data)
                })
                AppData.registerList = []
                await getRegData(regList)
                try {await writeToDevice(AppData.registerList)}
                catch {
                    alert('Write to device ISP_START_UPDATE failed.')
                    setWriteDevice(false)
                    setLoader(false)
                }
                //wait till the update is done
                //set ISP_START
                const devicePropertyWriteUpdate = await getDevicePropertiesByName(AppData.server,"ISP_READ_CMD_DONE")
                regList = []
                devicePropertyWriteUpdate[0].inputs.map((data:any)=>{
                    regList.push(data)
                })
                AppData.api.readDriver.bodyParams = { 'protocol': AppData.protocol, 'slave_address': AppData.slave_address, "regData": regList }
                const registerAddress = await AppData.deviceServer.sendCommand(AppData.api.readDriver, `Reading the device failed`, 1)
                const valuesRead = registerAddress["data_read_back"]
                console.log(valuesRead)
                var regAddress = Object.keys(valuesRead)
                var isp_cmd_done = 0
                for (const key of regAddress) {
                    console.log(valuesRead[key])
                    try {
                        AppData.api.getFieldValue.bodyParams = {'register_address': key, 'field_name': "isp_cmd_done", "state": valuesRead[key],"device_id": AppData.currentConfig.device_id}
                        isp_cmd_done = await AppData.localServer.sendCommand(AppData.api.getFieldValue, `Reading the field value failed`, 1)
                        while(isp_cmd_done != 1){
                            await delay(500)
                            isp_cmd_done = await AppData.localServer.sendCommand(AppData.api.getFieldValue, `Reading the field value failed`, 1)
                        }
                    }
                    catch {
                        alert('Convertion date to field state failed.')                    
                        setLoader(false)
                    }
                }
                //set ISP_START to 0
                const devicePropertyStartUpdateNew = await getDevicePropertiesByName(AppData.server,"ISP_START_UPDATE")
                regList = []
                devicePropertyStartUpdateNew[0].inputs.map((data:any)=>{
                    regList.push(data)
                })
                AppData.registerList = []
                await setInitRegData(regList)
                try {await writeToDevice(AppData.registerList)}
                catch {
                    alert('Write to device ISP_START_UPDATE failed.')
                    setWriteDevice(false)
                    setLoader(false)
                }
                //when done check for error registers
                for (const key of regAddress) {
                    console.log(valuesRead[key])
                    try {
                        AppData.api.getFieldValue.bodyParams = {'register_address': key, 'field_name': "isp_freq_burn_error", "state": valuesRead[key],"device_id": AppData.currentConfig.device_id}
                        const isp_freq_burn_error = await AppData.localServer.sendCommand(AppData.api.getFieldValue, `Reading the field value failed`, 1)

                        AppData.api.getFieldValue.bodyParams = {'register_address': key, 'field_name': "isp_gpio_burn_error", "state": valuesRead[key],"device_id": AppData.currentConfig.device_id}
                        const isp_gpio_burn_error = await AppData.localServer.sendCommand(AppData.api.getFieldValue, `Reading the field value failed`, 1)

                        AppData.api.getFieldValue.bodyParams = {'register_address': key, 'field_name': "isp_driver_burn_error", "state": valuesRead[key],"device_id": AppData.currentConfig.device_id}
                        const isp_driver_burn_error = await AppData.localServer.sendCommand(AppData.api.getFieldValue, `Reading the field value failed`, 1)

                        AppData.api.getFieldValue.bodyParams = {'register_address': key, 'field_name': "isp_mstr_burn_error", "state": valuesRead[key],"device_id": AppData.currentConfig.device_id}
                        const isp_mstr_burn_error = await AppData.localServer.sendCommand(AppData.api.getFieldValue, `Reading the field value failed`, 1)

                        if(isp_freq_burn_error == 1 || isp_gpio_burn_error == 1 || isp_driver_burn_error == 1 || isp_mstr_burn_error == 1)
                        {
                            alert('Error writing to device')                    
                            setLoader(false)
                            setWriteDevice(true)
                            return
                        }
                    }
                    catch {
                        alert('Convertion date to field state failed.')                    
                        setLoader(false)
                    }
                }
            }
        }
        setWriteDevice(false)
        setLoader(false)
    }
    async function setInitRegData(regList: any){
        var address
        for (const data of regList) {
            var registerData={
                register_address: "",
                data: ""
              }
            var regAddress = Object.keys(data)
            address = ""
            address = regAddress[0]
            registerData.register_address = address
            registerData.data = "0x0"
            AppData.registerList.push(registerData)
            
        }
    }
    async function getRegData(regList: any){
        var address
        var regValueList: any
        regValueList = []
        for (const data of regList) {
            var registerData={
                register_address: "",
                data: []
              }
            var regAddress = Object.keys(data)
            address = ""
            address = regAddress[0]
            if (data[address] === null) {
                var regValue = await getRegisterState(address)
                //update pDiv with XOR with 32
                if(AppData.connectedDevicePlatform == "Neolite-4")
                { 
                    if(address ==  "0x0" ||  address ==  "0x1" ||  address ==  "0x2" ||  address ==  "0x3") {
                        if(regValue != "0x0"){
                            var hexString = "0x20"
                            var val1 = parseInt(regValue, 16)
                            var val2 = parseInt(hexString, 16)
                            var value = val1 ^ val2
                            regValue = value.toString(16)
                            regValueList.push(regValue)
                            data[address] = regValue
                            registerData.register_address = address
                            registerData.data = regValueList
                            AppData.registerList.push(registerData)
                            regValueList = []
                            console.log(AppData.registerList,address,regValue)
                        }
                    }
                    if(address ==  "0x4")
                    {
                        regValueList.push(regValue)
                    }
                    if(address ==  "0x5"  ||  address ==  "0x6")
                    {
                        regValueList.push(regValue)
                        if (address ==  "0x6") {
                            data[address] = regValue
                            registerData.register_address = "0x4"
                            registerData.data = regValueList
                            AppData.registerList.push(registerData)
                            regValueList = []
                            console.log(AppData.registerList,address,regValue)
                        }
                    }
                }                
            }
            else {
                registerData.register_address = address
                regValueList.push(data[address])
                registerData.data = regValueList
                AppData.registerList.push(registerData)
                regValueList = []
            }
        }
    }
    return (
        <GridContainer shape='blockdiagram' layout='blockdiagram'>
            {loader && <LoadingSpinner shape={"medium"} stroke={"normal"} fill={"none"} innerfill={"loader"} speed={"1"} text={loaderMessage} position={{ top: '40vh', left: '45vw' }} type={'circular'} disabled={false} ></LoadingSpinner>}
            <Message visible={visible} shape="config" font="config" position="bottom-right" text={message} hide={setVisible}></Message>
            <FlexBox shape="hug" gridName='b' layout="vertical-top-left" spacing="signintext">
                <FlexBox shape="hug" layout="vertical-top-left" spacing="details">
                    <FlexBox shape='backtext' layout="horizontal-top-left" spacing='none'>
                        <div style={{ display: 'flex', gap: '14px', cursor: 'pointer' }} onClick={() => { configBack(true); configBackButton() }}> <Icons iconName='arrow-left' size={'20px'} stroke='#8E8D8A'></Icons> <p style={{ margin: '1.5px 0px 0px 0px ', color: " #8E8D8A", fontStyle: 'normal', fontSize: '14px', lineHeight: '21px', fontWeight: '400' }}>BACK TO CONFIGURATIONS</p></div>
                    </FlexBox>
                    {AppData.server == 'remote' && <div style={{ width: '100%', height: '33px', background: ' #EBF4FA', display: 'flex', alignItems: 'center', justifyContent: "center", letterSpacing: '-0.011em', color: '#3399D5', gap: '7px' }}><Icons iconName={"eye"} size={"16px"}></Icons><u style={{ cursor: 'pointer' }} onClick={handleSync}>DOWNLOAD</u>this configuration to local to edit it </div>}
                    <FlexBox shape="blockdiagramText" layout='horizontal-top-left' spacing='blockdiagramText'>
                        <FlexBox shape="hug" spacing="none" layout="vertical-top-left">
                            <FlexBox spacing="details" layout="horizontal-center-right" shape="hug">
                                <p style={{ width: 'max-content', margin: '0px', color: " #231F20", fontStyle: 'normal', fontSize: '26px', fontWeight: '500', lineHeight: '39px' }} >{AppData.currentConfig.name}</p>
                                {(AppData.currentConfig.custom_part_number) && <img src='/logo/Freeze Icon.svg' alt="Freeze" height={15} width={15} color='#3399D5'></img>}
                                <p style={{ padding: 'opx', marginBottom: '5px', fontSize: "14px", fontWeight: '550', color: '#888888', lineHeight: '21px' }}>{AppData.currentConfig.variant}</p>
                                {(configJson.WEB_ONLY === 0) ? (AppData.server == "local") ? <Button shape="localbutton" spacing="localbutton" fill="transparent" stroke='status'><Icons iconName={'monitor'} size={'14px'} stroke='status'></Icons><p style={{ fontWeight: "500", fontSize: "12px", color: '#56BA64', fontFamily: "Inter" }}>Local</p></Button> : <Button shape="remotebutton" spacing="localbutton" fill="transparent" stroke='local'><Icons iconName={'cloud'} size={'20px'} stroke='remote' fill="remote"></Icons><p style={{ fontWeight: "500", fontSize: "12px", color: '#8E8D8A', fontFamily: "Inter" }}>Remote</p></Button> : <></>}
                            </FlexBox>
                            <FlexBox shape="details" layout="vertical-top-left" spacing="none">
                                {(AppData.currentConfig.custom_part_number) && <div style={{ padding: "0px", margin: "0px", color: "#8E8D8A", fontSize: '14px' }}>PART NUMBER  {AppData.currentConfig.custom_part_number}</div>}
                                <p style={{ padding: 'opx', margin: '0px', fontSize: "14px", fontWeight: '500', color: '#888888', lineHeight: '21px',width:'45vw',wordWrap:'break-word', whiteSpace:'pre-wrap',hyphens:'manual' }}>{AppData.currentConfig.description}</p>
                            </FlexBox>
                        </FlexBox>
                        <FlexBox shape="confugaration3" spacing="buttonalign" layout="horizontal-top-right" >
                            {(configJson.WEB_ONLY === 0) && <Tooltip shape='menu' fill='grey' spacing='normal' message={AppData.server === 'local' ? 'Sync configuration to remote' : 'Download configuration to local'} X={'-210px'} Y={'-35px'} location='left' >
                                <Button shape="syncbutton" color="button" spacing="button" fill="syncbutton" disabled={(navigator.onLine == true) ? false : true} onClick={AppData.server == 'local' ? handleLocalSync : handleSync}>
                                    <p style={{ margin: "0px", fontSize: '16px', fontWeight: '500', }}>
                                        {AppData.server == 'local' ? 'Sync to remote' : 'Download to local'}
                                    </p>
                                </Button>
                            </Tooltip>}
                            {(AppData.server != 'remote') && (AppData.connected) && (AppData.connectedDeviceId === AppData.currentConfig.device_id) && <Tooltip shape='menu' fill='grey' spacing='normal' message={'write to device'} X={'-20px'} Y={'0px'} location='bottom' ><Button shape="syncbutton" color="button" spacing="freeze" disabled={(navigator.onLine == true) ? false : true}  fill="syncbutton" onClick={handleWrite}><p style={{ margin: "0px", fontSize: '16px', fontWeight: '500', }}>Write to Device</p></Button></Tooltip>}
                            {(!AppData.currentConfig.frozen) && (AppData.server != 'remote') && (AppData.currentConfig.custom_part_number == '') && <Tooltip shape='menu' fill='grey' spacing='normal' message={'freeze configuration'} X={'-210px'} Y={'-35px'} location='left' ><Button shape="freeze" color="freeze" spacing="freeze" disabled={(navigator.onLine == true) ? false : true} stroke='freeze' fill="transparent" onClick={handleFreeze}><img src='/logo/Freeze Icon.svg' alt="Freeze" height={15} width={15} color='#3399D5'></img><p style={{ marginLeft: "6px", fontSize: '14px', fontWeight: '400', color: '#3399D5' }}>Freeze</p></Button></Tooltip>}
                            {(!AppData.currentConfig.frozen) && (AppData.server != 'remote') && (AppData.currentConfig.custom_part_number == '') &&
                                <div onMouseLeave={lostfocus} style={{ display: "flex", flexDirection: "column" }}>
                                    <Button shape="menu" spacing="menu" fill="transparent" stroke='freeze' onClick={() => { setVisible(false); setShow(true); handler() }}><Icons stroke='#56BA64' iconName={"more-vertical"} size={"24px"} fill='#3399D5'></Icons></Button>
                                    <div>
                                        {show && (
                                            <Menu hide={setShow} spacing='local' shape='blockdiagram' fontSize='local' effects='outerShadow' fill='local' data={[
                                                { name: 'Edit Details', icon: "file", tooltip: 'edit the  configuration', onclick: () => { setError(''); setRefresh(true); setEdit(true); setCopy(true) } },
                                                { name: 'Duplicate', icon: "file", tooltip: 'duplicate configuration', onclick: () => { setError(''); setRefresh(true); setEdit(false); setCopy(true) } },
                                                { name: 'Delete', icon: "delete", tooltip: 'delete the configuration', onclick: () => { setactive(true) } }]} visible={show} />
                                        )}
                                    </div>
                                </div>
                            }
                            {((AppData.currentConfig.frozen) || (AppData.currentConfig.custom_part_number)) && (AppData.server != 'remote') && <Tooltip shape='menu' fill='grey' spacing='normal' message={'duplicate configuration'} X={'-45px'} Y={'0px'} location="bottom" ><Button shape="duplicate" color="freeze" spacing="duplicate" stroke='freeze' fill="transparent" onClick={() => { setError(''); setRefresh(true); setEdit(false); setCopy(true) }}><Icons iconName={"copy"} size={"15px"} stroke='duplicate'></Icons><p style={{ marginLeft: "6px", fontSize: '14px', fontWeight: '400', color: '#3399D5', padding: '0px', margin: '0px' }}>Duplicate</p></Button></Tooltip>}
                        </FlexBox>
                    </FlexBox>
                    <FlexBox shape="blockdiagramText" layout="horizontal-top-left" spacing={AppData.currentConfig.frozen && AppData.currentConfig.description.length>50?"detailbutton1":AppData.currentConfig.description.length>50?'detailbutton1':'detailbutton'} stroke='detailbutton'>
                        <Button shape='detailbutton' fill={showDetails ? 'detailbutton' : 'changehistorybutton'} color={showDetails ? 'button' : 'sync'} spacing="detailbutton" onClick={() => { return onClickBack() }}><p style={{ fontSize: "14px", fontWeight: '500', lineHeight: '21px' }}>Details</p></Button>
                        <Button shape='changehistorybutton' fill={pinout ? 'detailbutton' : 'changehistorybutton'} color={pinout ? 'button' : 'sync'} spacing="detailbutton" onClick={onClickPinout}><p style={{ fontSize: "14px", fontWeight: '500', margin: '5px 0px 5px 10px', fontStyle: "Medium", lineHeight: '150%', verticalAlign: 'Top' }}>Pinout View</p></Button>
                        <Button shape='changehistorybutton' fill={history ? 'detailbutton' : 'changehistorybutton'} color={history ? 'button' : 'sync'} spacing="detailbutton" onClick={handleShowDetails}><p style={{ fontSize: "14px", fontWeight: '500', margin: '5px 0px 5px 10px', fontStyle: "Medium", lineHeight: '150%', verticalAlign: 'Top' }}>Change History</p></Button>
                    </FlexBox>
                </FlexBox>
                {/* <FlexBox stroke='detailbutton' shape="detailbottom" spacing="detailbottom"> </FlexBox> */}
                <FlexBox shape="hug" spacing="none" >
                    {showDetails && <DetailScreen onDuplicate={onClickDuplicate}/>}
                    {history && <HistoryScreen />}
                    {pinout && <PinoutViewScreen OPValue={opValue} OPList={opList} ShowOPType={showOPType} InverseCurrent={inverseCurrent} InverseList={inverseList} ShowInverse={showInverse} showtype={showType} showfunction={showFunction} disabledList={disableList} FunctionCurrent={functionCurrent} DirectionList={directionList} TypeList={typeList} FunctionList={functionList} typecurrent={typeCurrent} directioncurrent={directionCurrent} pinOut={showInterface} inputContent={inpContent} />}                    <DialogBox shape="dialog" Buttoncontent={["Cancel", "Continue"]} position='middle-center' visible={dialogActive} header={'Sync Configuration'} text={"Configuration selected is already present in local. The existing local data will be overwritten.Do you wish to continue?"}
                        spacing="dialog" fill="dialog" font="dialog" hide={setDialogActive} OnclickYes={syncConfiguration}></DialogBox>
                    <DialogBox shape="dialog" Buttoncontent={["Cancel", "Delete"]} position='middle-center' visible={active} header={'Delete Configuration'} text={"Are you sure you want to delete the configuration ? This process can't be undone."} spacing="dialog" fill="dialog" font="dialog" hide={setactive} OnclickYes={deleteClick}></DialogBox>
                    <DialogBox shape="dialog" Buttoncontent={["Yes", "No"]} position='middle-center' visible={duplicate} header={'Duplicate Configuration'} text={"Do you want to open duplicate configuration ?"} spacing="dialog" fill="dialog" font="dialog" hide={SetDuplicate} OnclickYes={onclickYes} OnclickNo={onclickNo}></DialogBox>
                    {refresh ? <PopUpForm valueChange={edit ? (value: any, id: any) => { onEditChange(value, id) } : (value: any, id: any) => { onDuplicateChange(value, id) }} OnclickCancel={onCloseForm} shape={"hug"} spacing={"popupform1"} fill={"white"} stroke={""} font={""} initFormData={edit ? formData.edit : formData.duplicate} onSubmit={edit ? editSubmit : copySubmit} visible={copy} hide={setCopy} popupformshape={"popupform1"} popupfill={"popupform"} popupstroke={"popupform"} items={[]}
                        buttonContent={edit ? "Save Changes" : "Duplicate Configuration"} buttonName="popupform1" heading={edit ? "Edit Configuration" : "Duplicate Configuration"} Error={error}></PopUpForm> : <></>}
                    {freeze ? <PopUpForm form='FreezeForm' OnclickCancel={FreezeClose} shape={"hug"} spacing={"popupform1"} fill={"white"} stroke={""} font={""} initFormData={AppData.freezeFormData.freeze} onSubmit={handleFreezeSubmit} visible={freeze} hide={setFreeze} popupformshape={"popupform1"} popupfill={"popupform"} popupstroke={"popupform"} items={[]}
                        buttonContent={"Freeze"} buttonName="popupform1" heading={"Freeze Configuration"} subHeading="* required field" Error={error}></PopUpForm> : <></>}
                    {writeDevice ? <PopUpForm valueChange={(value: any, id: any) => onValueChangeProgram(value, id)} OnclickCancel={FreezeClose} shape={"hug"} spacing={"popupform1"} fill={"white"} stroke={""} font={""} initFormData={deviceFormData.write} onSubmit={handleWriteSubmit} visible={writeDevice} hide={setWriteDevice} popupformshape={"popupform1"} popupfill={"popupform"} popupstroke={"popupform"} items={[]}
                        buttonContent={"Write to Device"} buttonName="popupform1" heading={"Write to Device"} subHeading="* required field" Error={error}></PopUpForm> : <></>}

                </FlexBox>
            </FlexBox>
        </GridContainer>
    )
}
export default BlockDiagramScreen
