import { DataGridThemeIF } from "../components/dataGrid/dataGridThemeIF"

const DataGridThemes: DataGridThemeIF = {

    shape: {
        hug: { width: "auto", height: "auto", radius: "0px", header: "40px", search: "20px", row: "10px" },
        normal: { width: "400px", height: "220px", radius: "2px", header: "30px", search: "30px", row: "50px" },
        recent:{width:"1280px",height:"136px",radius:'0px',header:'46px',search:'0',row:'45px'},
        recent2:{width:"1280px",height:"406px",radius:'0px',header:'46px',search:'0',row:'45px'},
        local:{width:"90vw",height:"auto",radius:'0px',header:'46px',search:'0',row:'45px'},
        remote:{width:"90vw",height:"auto",radius:'0px',header:'46px',search:'0',row:'45px'},
        realtimeconfig:{width:"90vw",height:"400px",radius:'0px',header:'46px',search:'0',row:'45px'},
        history:{width:"90vw",height:"450px",radius:'0px',header:'46px',search:'0',row:'45px'},
        config:{width:"90vw",height:"275px",radius:'0px',header:'46px',search:'0',row:'45px'},
        recentConfig:{width:"90vw",height:"auto",radius:'0px',header:'46px',search:'0',row:'45px'},
    },

    stroke: {
        none: { outside: "none", row: "none", column: "none", header: "none" },
        normal: { outside: "1px solid #067D6F", row: "1px solid #067D6F", column: "1px solid #067D6F", header: "3px solid #067D6F" },
        rowOnly: { outside: "1px solid #E2E2E2", row: "1px  #067D6F", column: "none", header: "none" },
        columnOnly: { outside: "1px solid #067D6F", row: "none", column: "1px solid #067D6F", header: "3px solid #067D6F" },
        outsideOnly: { outside: "1px solid #067D6F", row: "none", column: "none", header: "none" },
    },
    fill: {
        normal: { header: "#5ABCF0", row: "#5ABCB0", rowHover: "#035437" },
        recent: {header:'#F3F3F3',row:'#F3F3F3',rowHover:'#F3F3F3'},
        table1:{header:'#F0EFEF',row:'',rowHover:''}
    },
    search: {
        normal: { inputHeight: "15px", strokeType: "none", stroke: "0px solid black", radius: "0px", fill: "", hover: "" }
    },
    text: {
        normal: { headerSize: "14px", headerColor: "black", contentSize: "12px", contentColor: "black", activeColor:"blue", align: "left" }
    },
    transition: "all 0.5s",
    effects: {
        none: "none"
    },
}

export default DataGridThemes
