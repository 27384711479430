import React from 'react';
import { TooltipStyle, TooltipArrowStyle, ToolTipContainer, ToolTipBox, ToolChildern } from "./toolTipStyles"


interface PropsIF {
    location?: "left" | "right" | "top" | "bottom"
    children: React.ReactNode
    font?: string
    shape?: string
    spacing?: string
    fill?: string
    effects?: string
    message: string
    X: string
    Y: string
}
function Tooltip({ location = "top", font = "normal", shape = "normal", X, Y, children, fill = "normal", effects = "none", spacing = 'none', message }: PropsIF) {

    return (
        <ToolTipBox location={location} top={{ X: X, Y: Y }} bottom={{ X: X, Y: Y }} left={{ X: X, Y: Y }} right={{ X: X, Y: Y }}>
            <ToolChildern>
                {children}
            </ToolChildern>
            <ToolTipContainer spacing={spacing} font={font} location={location}>

                {((location === "right") || (location === "bottom")) && <TooltipArrowStyle location={location} fill={fill} effects={effects} />}
                <TooltipStyle spacing={spacing} font={font} shape={shape} fill={fill} effects={effects} >
                    <p style={{padding:"0px",margin:"0px"}}> {message} </p>
                </TooltipStyle>
                {((location === "left") || (location === "top")) && <TooltipArrowStyle location={location} fill={fill} effects={effects} />}
            </ToolTipContainer>
        </ToolTipBox>
    )

}
export default Tooltip;
