import { PassWordThemeIF } from "../components/passWord/passWordThemeIF"

const PassWordThemes: PassWordThemeIF = {
    shape: {
        hug: { width: "max-content", height: "10px", radius: "5px" },
        normal: { width: "150px", height: "10px", radius: "0px" },
        large: { width: "200px", height: "max-content", radius: "3px" },
        signin:{width:'310px',height:'45px',radius:'0px'},
    },
    stroke: {
        none: 'none',
        normal: "1px solid #444",
        thin: "2px solid black",
    },
    spacing:{
        normal:"5px",
        password:"9px 10px 9px 12px"
    },
    fill: {
        none: { normal: "transparent" },
        normal: { normal: "#ffe6e6" },
        color: { normal: "#FFFFE0" },
        signin:{normal:'#F0EFEF'},
    },
    font: {
        none: { color: 'black',size: '12px' },
        normal: { color: '#8E8D8A',  size: '14px' },
        medium: { color: '#C0C0C0',size: '16px' }

    },
}
export default PassWordThemes
