import { ButtonThemeIF } from "../components/button/buttonThemeIF"

const ButtonThemes: ButtonThemeIF = {
    shape: {
        hug: { width: "fit-content", height: "fit-content", radius: "10px" },
        normal: { width: "150px", height: "50px", radius: "10px" },
        large: { width: "250px", height: "50px", radius: "20px" },
        small: { width: "24px", height: "24px", radius: "5px" },
        verticalLarge: { width: "200px", height: "200px", radius: "20px" },
        signin: { width: '333px', height: '40px', radius: '1px' },
        signup: { width: '333px', height: '40px', radius: '1px' },
        tabletest: { width: "90px", height: "20px", radius: "10px" },
        syncbutton: { width: "164px", height: "40px", radius: "1px" },
        freeze: { width: "100px", height: "40px", radius: "1px" },
        menu: { width: "40px", height: "40px", radius: "1px" },
        detailbutton: { width: "84px", height: "40px", radius: "0px" },
        changehistorybutton: { width: "136px", height: "40px", radius: "0px" },
        remoteButton: { width: "110px", height: "33px", radius: "0px" },
        localButton: { width: "93px", height: "33px", radius: "0px" },
        //device
        alldevicebutton: { width: "96px", height: "30px", radius: "12px" },
        localbutton: { width: "75px", height: "26px", radius: "100px" },
        remotebutton: { width: "90px", height: "26px", radius: "100px" },
        deviceText: { width: "80px", height: "32px", radius: "12px" },
        deviceonlinetext:{width:"78px",height:"25px",radius:"12px"},
        deviceBA:{width:"50px",height:"50px",radius:"10px"},
        configaration:{width:'118px',height:'100%',radius:'0px'},
        device:{width:'56px',height:'100%',radius:'0px'},
        download:{width:'166px',height:'50px',radius:'12px'},
        Remote:{width:'48px',height:'50px',radius:'12px'},
        createNew:{width:'112px',height:'40px',radius:'0px'},
        AllConfigurations:{width:'135px',height:'30px',radius:'12px'},
        profile:{width:'40px',height:'40px',radius:'20px'},
        popupform1:{width:'384px',height:'40px',radius:'0px'},
        downloadLocal:{width:'168px',height:'33px',radius:'0px'},
        duplicate:{width:'118px',height:"40px",radius:'0px'},
        ViewAddendum:{width:'135px',height:"33px",radius:'0px'},
        FC:{width:"50px",height:"30px",radius:"0px"},
        SPI:{width:"52px",height:"30px",radius:"0px"},
        NONE:{width:"63px",height:"30px",radius:"0px"},
        pinoutsubmit:{width:"164px",height:"40px",radius:"0px"},
        reatime: {width:'70px',height:'100%',radius:'0px'},
        tctodevice: {width:'140px',height:'40px',radius:'0px'},
        openConfig: {width:'220px',height:'40px',radius:'0px'}
    },
    stroke: {
        none: "none",
        thin: "1px solid #067D6F",
        thick: "5px solid #067D6F",
        signin:'1px solid #B90B2E',
        freeze: '1px solid #3399D5',
        status:'1px solid #56BA64',
        sync:'1px solid #3399D5',
        local:'1px solid #E2E2E2',
        FC:"1px solid #3399D5",
    },
    fill: {
        normal: { normal: "#5ABCB0", hover: "#035437" },
        transparent: { normal: "none", hover: "none" },
        confirm: { normal: "#3E9EBC", hover: "#117696" },
        cancel: { normal: "#B563BC", hover: "#8C1B96" },
        danger: { normal: "#8E4A66", hover: "#6B0F36" },
        signin: { normal: '#B90B2E', hover: '' },
        signup: { normal: '#B90B2E', hover: '' },
        tabletest: { normal: '#8E4A66', hover: "#330206" },
        button: { normal: '#1E1E1E', hover: '' },
        freeze: { normal: '#F3F3F3', hover: '' },
        menu: { normal: "#F3F3F3", hover: '' },
        detailbutton: { normal: '#3399D5', hover: '' },
        changehistorybutton: { normal: '#F0EFEF', hover: '' },
        //device
        alldevicebutton: { normal: "#1E1E1E", hover: "" },
        localbutton: { normal: "#F3F3F3", hover: "" },
        remotebutton: { normal: "#F3F3F3", hover: "" },
        deviceText:{normal:"#1E1E1E",hover:""},
        deviceonlinetext:{normal:"rgba(40, 255, 70, 0.1)",hover:""},
        deviceBA:{normal:"#F3F3F3",hover:""},
        createNew:{normal:'#B90B2E',hover:''},
        local:{normal:'#2F517B',hover:""},
        remote:{normal:'#3399D5',hover:""},
        syncbutton:{normal:'#B90B2E',hover:''},
        white:{normal:'white',hover:'none'},
        offline:{normal:'#FF282826',hover:'none'},
        history:{normal:'#231F20',hover:''},
        FC:{normal:"inherit",hover:""},
        pinoutsubmit:{normal:"#B90B2E",hover:""},
        selected: {normal:'#3399D5',hover:""},
    },
    effects: {
        none: "none",
        insetShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
        dropShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)"
    },
    spacing: {
        none:{gap:'6px',padding:'0px'},
        normal: { gap: "10px", padding: "20px 10px" },
        button: { gap: "0px", padding: "13px 16px 13px 16px" },
        freeze: { gap: "0px", padding: "13px 16px 13px 16px" },
        menu: { gap: "0px", padding: "13px 12px 13px 12px" },
        detailbutton: { gap: '0px', padding: "4px 10px 4px 10px" },
        changehistorybutton: { gap: '0px', padding: "4px 10px 4px 10px" },
        //device
        alldevicebutton: { gap: "6px", padding: "6px" },
        localbutton: { gap: "6px", padding: "6px" },
        remotebutton: { gap: "6px", padding: "6px" },
        deviceText: { gap: "10px", padding: "4px 10px 4px 10px" },
        deviceonlinetext:{gap:"8px",padding:"2px 8px 2px 8px"},
        deviceBA:{gap:"0px",padding:"13px 14px 13px 14px"},
        AllConfigurations:{gap:"0px",padding:'0px'},
        remote:{gap:"6px",padding:'6px 12px 6px 12px'},
        form: {gap: "0px", padding: "0px"},
        createNew: {gap: "6px", padding: "6px 18px"},
        password: {gap:"",padding:'0px 0px 0px 0px'},
        downloadLocal:{gap:'4px',padding:'0px'},
        duplicate: { gap: "6px", padding: "10px 16px 10px 16px" },
        ViewAddendum:{gap:'4px',padding:'4px 16px'},
        FC:{gap:"4px",padding:"4px 16px 4px 16px"}
    },
    transition: "all 0.5s",
    color: {
        signin: '#FFFFFF',
        signup: '#FFFFFF',
        button: '#F3F3F3',
        freeze: '#1E1E1E',
        detailbutton: '#F3F3F3',
        changehistorybutton: "#F3F3F3",
        deviceText:"#FFFFFF",
        deviceonlinetext:"#FFFFFF",
        color:"#8E8D8A",
        white:"white",
        header:'transperent',
        status:'#56BA64',
        offline:'red',
        sync:'#3399D5',
        FC:"#3399D5",
    }
}


export default ButtonThemes
