import { useEffect, useState } from 'react';
import Icon from '../../icons/icons';
import { Button } from '../button/button';
import { Container, Iconstyle } from './menuStyles'
import Tooltip from '../toolTip/toolTip';
interface PropsIF {
    data: { name: string, icon: string, tooltip:string,onclick: () => void }[]
    onClick?: (itemName: string) => void;
    shape?: string;
    stroke?: string;
    fill?: string;
    effects?: string;
    spacing?: string;
    icon?: boolean;
    iconsize?: string;
    fontSize?:string;
    iconstroke?: string;
    animated?: boolean;
    visible: any;
    hide: any;
}

export const Menu = ({ shape = "normal",fontSize='normal', visible, spacing = "normal", animated = true, stroke = "none", hide, icon, iconsize = "15px", iconstroke, data, fill = "normal", effects = "normal", onClick }: PropsIF) => {

    
    return (
        <Container fontSize={fontSize} shape={shape} stroke={stroke} fill={fill} effects={effects} spacing={spacing} animated={animated}>
            {data.map((item) => (
                <Tooltip shape='menu' fill='grey' spacing='normal' message={item.tooltip} X={'-210px'} Y={'-30px'} location='left' >
                    <li onClick={() => { item.onclick() }}  >{icon ? <Iconstyle><Icon iconName={item.icon} size={iconsize} stroke={iconstroke} /></Iconstyle> : null} {item.name}</li>
                </Tooltip>
            ))}
        </Container>
    )
}
