import React from "react";
import Icons from "../../../icons/icons";
import GridContainer from "../../../components/gridContainer/gridContainer";
import FlexBox from "../../../components/flexBox/flexBox";
import { Button } from "../../../components/button/button";
import HistoryTable from "./historyTable";
import AppData from "../../../appData";

function HistoryScreen() {
    return (
        <FlexBox shape="tablecontainer" layout="horizontal-top-left" spacing="boxcontainer" fill="historyblockdiagram">
            <HistoryTable data={AppData.history.data.local}/>
        </FlexBox>
    )
}
export default HistoryScreen
