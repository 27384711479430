import React, { useState } from "react";
import FlexBox from "../../../components/flexBox/flexBox";
import Blockdiagram from "../../../components/blockDiagram/blockDiagram";
import AppData from "../../../appData";
import GPIOinterface from "../../../deviceSpecific/GPIOpins/interfaceSelect";
import GPIOi2c from "../../../deviceSpecific/GPIOpins/i2c";
import GPIOspi from "../../../deviceSpecific/GPIOpins/spi";
import GPIONone from "../../../deviceSpecific/GPIOpins/none";
import Icons from "../../../icons/icons";
import { Button } from "../../../components/button/button";
import InputString from "../../../components/inputString/inputString";
import Dropdown from "../../../components/dropDown/dropDown";
import { functionSearch, getDevicePropertiesByName, getFunctionState, getPinDiagramData, processGPIO, resetFunctions } from "./blockDiagramEvents";
import DropdownCheckBox from "../../../components/dropDown/dropDownCheckbox";
import Tooltip from "../../../components/toolTip/toolTip";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";

interface propsIF {
    inputContent: any
    pinOut: boolean
    directioncurrent: string,
    typecurrent: string,
    DirectionList: string[],
    TypeList: string[],
    FunctionList: string[]
    FunctionCurrent: string,
    disabledList: boolean[]
    showfunction: boolean
    showtype: boolean
    ShowInverse: boolean
    InverseList: string[]
    InverseCurrent: string
    ShowOPType: boolean
    OPList: string[]
    OPValue: string
}
function PinoutViewScreen({ ShowOPType,OPList,OPValue,ShowInverse,InverseList,InverseCurrent,showtype,showfunction, inputContent, disabledList, FunctionCurrent, pinOut, directioncurrent, typecurrent, DirectionList, TypeList, FunctionList }: propsIF) {    const [pinout, SetPinout] = useState(pinOut)
    const [i2c, setI2C] = useState(AppData.GPIOstate == 'I2C' ? true : false)
    const [spi, setSPI] = useState(AppData.GPIOstate == 'SPI' ? true : false)
    const [none, setNone] = useState(AppData.GPIOstate == 'NONE' ? true : false)
    const [address, setAddress] = useState<any>()
    const [render, setRender] = useState(false)
    const [content, setContent] = useState<string>(inputContent)
    const [directionList, setDirectionList] = useState<any>(DirectionList)
    const [typeList, setTypeList] = useState<any>(TypeList)
    const [functionList, setFunctionList] = useState<any>(FunctionList)
    const [directionCurrent, setDirectionCurrent] = useState<any>(directioncurrent)
    const [typeCurrent, setTypeCurrent] = useState<any>(typecurrent)
    const [functionCurrent, setFunctionCurrent] = useState<any>(FunctionCurrent)
    const [disableList, setDisableList] = useState<any>(disabledList)
    const [message, setMessage] = useState("")
    const [loader, setLoader] = useState(false)
    const [showFunction, setShowFunction] = useState(showfunction)
    const [showType,setShowType] = useState(showtype)
    const [showerror,setShowError] = useState(false)
    const [errorMessage,setErrorMessage] = useState('')
    const [showInverse, setShowInverse] = useState(ShowInverse)
    const [inverseCurrent, setInverseCurrent] = useState<any>(InverseCurrent)
    const [inverseList, setInverseList] = useState<any>(InverseList)
    const [showOPType, setshowOPType] = useState(ShowOPType)
    const [opValue, setopValue] = useState<any>(OPValue)
    const [opList, setopList] = useState<any>(OPList)
    const style = {
        compStyle: "symbolClass",
        boundaryStyle: "symbolBoundaryclass",
        labelStyle: "pinsLabelClass",
        portStyle: "symbolPortClass",
        lineStyle: "blockDiagramLineClass",
        nonActiveStyle: "nonActiveClass",
        activeStyle: "activeClass",
        nonActiveRecStyle: "nonActiveRecClass",
    }
    const onClickSubmit = async () => {
        //After selecting interface respective pin diagram is searched based on function_state
        setShowError(false)
        setErrorMessage('')
        setLoader(true)
        AppData.pinComp = 'GPIO0'
        var value = "disable"
        var diagramName = AppData.GPIOCONFIGURE
        if (i2c) {
            if(AppData.GPIOstate !== 'I2C'){
                resetFunction()
            }
            AppData.GPIOstate = 'I2C'
            value = "I2C"
            diagramName = AppData.GPIOI2C
        }
        else if (spi) {
            if(AppData.GPIOstate !== 'SPI'){
                resetFunction()
            }
            AppData.GPIOstate = 'SPI'
            value = "SPI"
            diagramName = AppData.GPIOSPI
        }
        else {
            if(AppData.GPIOstate !== 'NONE'){
                resetFunction()
            }
            AppData.GPIOstate = 'NONE'
            value = "NONE"
            diagramName = AppData.GPIONONE
        }
        AppData.diagramName = diagramName
        var addressError
        var data
        var originalPinDIagramData = AppData.pinDiagramData
        var valuee = await getPinDiagramData(AppData.currentConfig, AppData.server, diagramName)
        var val =  await processGPIO('GPIO4', 'I2C_SPI_NONE', 'Mode', value)
        addressError = ''
        if (i2c) {
            data = await processGPIO('GPIO4', 'Address', 'Address', address)
            if (!data) {
                onClickComp('GPIO0', '', 'YES', '')
                SetPinout(true)
            }
            else {
                AppData.pinDiagramData = originalPinDIagramData
                addressError = data
                setShowError(true)
                setErrorMessage(data)
            }
        }
        else {
            if (addressError === '') {
                onClickComp('GPIO0', '', 'YES', '')
                SetPinout(true)
            }
            else { SetPinout(false) }
        }
        setRender(!render)
        setLoader(false)

    }

    const onClickBack = () => {
        getPinDiagramData(AppData.currentConfig, AppData.server, AppData.GPIOCONFIGURE).then((data) => {
            getFunctionState(["I2C_SPI_NONE", "Address"], AppData.server, AppData.pinDiagramData[0]._id).then((val) => {
                AppData.diagramName = AppData.GPIOCONFIGURE
                setContent(val[1].function_state)
                setAddress(val[1].function_state)
                SetPinout(false)
            })
        })
    }
    const onClickComp = (name: string, label: string, clickable: any, component_name: any) => {
        //Loading functions of the pin selected
        //If type or function is disable then empty string is set
        setFunctionList([])
        setDisableList([])
        setopList([])
        setopValue("")
        setMessage('')
        console.log('fun res', functionCurrent)
        if ((clickable != 'NO') && (name != '')) {
            if (name === 'GPIO4' && AppData.diagramName == AppData.GPIOCONFIGURE) {
                SetPinout(false)
                getFunctionState(["I2C_SPI_NONE", 'Address'], AppData.server, AppData.pinDiagramData[0]._id).then((data) => {
                    console.log('gpiostate', AppData.GPIOstate)
                    if (AppData.GPIOstate === 'I2C') {
                        setI2C(true)
                        setNone(false)
                        setSPI(false)
                        setContent(data[1].function_state)
                    }
                    else if (AppData.GPIOstate === 'SPI') {
                        setNone(false)
                        setI2C(false)
                        setSPI(true)
                        setContent('')
                    }
                    else if (AppData.GPIOstate === 'NONE') {
                        setSPI(false)
                        setI2C(false)
                        setNone(true)
                        setContent('')
                    }
                })
            }
            else {
                AppData.pinComp = name
                var function_list = [name + '_Direction', name + '_Type', name + "_Inverse", name + "_OPType"]
                var check = 0
                getFunctionState(function_list, AppData.server, AppData.pinDiagramData[0]._id).then((data) => {
                    setDirectionList(data[0].parameters[0].inputs)
                    if ((data[0].function_state) != null) { setDirectionCurrent(data[0].function_state) }
                    else { setDirectionCurrent('') }
                    if (data[0].function_state === 'Input') {
                        setShowInverse(true)
                        setshowOPType(false)
                        setShowType(false)
                        setInverseCurrent(data[2].function_state)
                        setInverseList(data[2].parameters[0].inputs)
                        setopValue("")
                        if(data.length > 3) {
                            setopValue(data[3].function_state)
                            setopList(data[3].parameters[0].inputs)
                            setshowOPType(true)
                        }
                        AppData.direction = data[0].function_state
                        var function_list: any[] = []
                        getDevicePropertiesByName(AppData.server, AppData.pinComp + '_INPUT_' + AppData.GPIOstate).then((inputData: any) => {
                            if (inputData[0].inputs.length != 0) {
                                setShowFunction(true)
                                setFunctionList(inputData[0].inputs)
                            }
                            else { 
                                setShowFunction(false)
                                setShowType(false)
                                setshowOPType(false)    
                            }
                            function_list = inputData[0].inputs

                            getFunctionState(function_list, AppData.server, AppData.pinDiagramData[0]._id).then((stateData) => {
                                console.log('stateData', stateData)
                                var disablelist: boolean[] = []
                                stateData.map((state: any, index: number) => {
                                    if (state.function_state === AppData.pinComp) {
                                        check = 1
                                        disablelist[index] = false
                                        setFunctionCurrent(state.parameters[0].param_name)
                                    }
                                    else if (state.function_state == null || state.function_state == 'disable') {
                                        disablelist[index] = false
                                    }
                                    else { disablelist[index] = true }
                                })
                                setDisableList(disablelist)
                                if (check != 1) {
                                    setFunctionCurrent('')
                                }
                                setRender(!render)
                            })
                        })
                    }
                    if (data[0].function_state === 'Output') {
                        setShowInverse(false)
                        setShowType(true)
                        setshowOPType(false)
                        setopValue("")
                        if(data.length > 3) {
                            setopValue(data[3].function_state)
                            setopList(data[3].parameters[0].inputs)
                            setshowOPType(true)
                        }
                        setDisableList([])
                        AppData.direction = data[0].function_state
                        var fun_list = [AppData.pinComp + "_OUTPUT_" + AppData.GPIOstate]
                        functionSearch(AppData.pinComp + "_OUTPUT_" + AppData.GPIOstate, AppData.server).then((result) => {
                            if (result[0].parameters[0].inputs.length != 0) {
                                setShowFunction(true)
                                getFunctionState(fun_list, AppData.server, AppData.pinDiagramData[0]._id).then((data) => {
                                    setFunctionList(data[0].parameters[0].inputs)
                                    getFunctionState([AppData.pinComp + '_OUTPUT_' + AppData.GPIOstate], AppData.server, AppData.pinDiagramData[0]._id).then((val) => {
                                        if (val[0].function_state != null) { setFunctionCurrent(val[0].function_state) }
                                        else { setFunctionCurrent('') }
                                    })
                                })
                            }
                            else { 
                                setShowFunction(false)
                                setShowType(false)
                                setshowOPType(false)
                             }
                        })
                    }
                    var typeListt: string[] = [], count = 0
                    data[1].parameters[0].inputs.map((type: string) => {
                        if (type != 'disable') {
                            typeListt[count] = type
                            count += 1
                        }
                    })
                    setTypeList(typeListt)
                    if (data[1].function_state != 'disable') { setTypeCurrent(data[1].function_state) }
                    else { setTypeCurrent('') }
                })
                SetPinout(true)
            }
        }
        setRender(!render)
    }
    const onChangeDirection = (value: string) => {
        //After change in direction, based on the selected value inputs are called for function field
        //If direction is input device_properties call is made
        //if direction is output respective function call is made
        setMessage('')
        var resetList
        if (typeof (value) == 'string') {
            if(value === 'Input'){
                setShowInverse(true)
                setshowOPType(true)
                setShowType(false)
                getFunctionState([AppData.pinComp + '_Inverse'], AppData.server, AppData.pinDiagramData[0]._id).then((val) => {
                    setInverseCurrent(val[0].function_state)
                    setInverseList(val[0].parameters[0].inputs)
                })
            }
            else{
                setShowInverse(false)
                setshowOPType(true)
                setShowType(true)}
            setDisableList([])
            processGPIO(AppData.pinComp, AppData.pinComp + '_Direction', 'Direction', value).then((data) => {
                getFunctionState([AppData.pinComp + '_Direction'], AppData.server, AppData.pinDiagramData[0]._id).then((val) => {
                    setDirectionCurrent(val[0].function_state)
                    if (val[0].function_state === 'Input') {
                        resetList = [AppData.pinComp + '_OUTPUT_' + AppData.GPIOstate, AppData.pinComp + '_Type', AppData.pinComp + '_Inverse', AppData.pinComp + "_OPType"]
                        resetFunctions(resetList)
                        setTypeCurrent('')
                        setFunctionCurrent('')
                        setopValue('')
                        AppData.direction = val[0].function_state
                        getDevicePropertiesByName(AppData.server, AppData.pinComp + '_INPUT_' + AppData.GPIOstate).then((inputData: any) => {
                            if (inputData[0].inputs.length != 0) {
                                setShowFunction(true)
                                setFunctionList(inputData[0].inputs)
                            }
                            else { 
                                setShowFunction(false) 
                                setShowType(false)
                                setshowOPType(false)
                            }
                            var function_list: any[] = []
                            var check = 0
                            function_list = inputData[0].inputs

                            getFunctionState(function_list, AppData.server, AppData.pinDiagramData[0]._id).then((stateData) => {
                                console.log('stateData', stateData)
                                var disablelist: boolean[] = []
                                stateData.map((state: any, index: number) => {
                                    if (state.function_state === AppData.pinComp) {
                                        check = 1
                                        disablelist[index] = false
                                        setFunctionCurrent(state.parameters[0].param_name)
                                    }
                                    else if (state.function_state == null || state.function_state == 'disable') {
                                        disablelist[index] = false
                                    }
                                    else { disablelist[index] = true }
                                })
                                setDisableList(disablelist)
                                if (check != 1) {
                                    setFunctionCurrent('')
                                }
                            })
                        })
                    }
                    if (val[0].function_state === 'Output') {
                        resetList = [functionCurrent, AppData.pinComp + '_Type', AppData.pinComp + '_Inverse', AppData.pinComp + "_OPType"]
                        resetFunctions(resetList)
                        setTypeCurrent('')
                        setFunctionCurrent('')
                        setopValue('')
                        AppData.direction = val[0].function_state
                        var fun_list = [AppData.pinComp + "_OUTPUT_" + AppData.GPIOstate]
                        functionSearch(AppData.pinComp + "_OUTPUT_" + AppData.GPIOstate, AppData.server).then((result) => {
                            if (result[0].parameters[0].inputs.length != 0) {
                                setShowFunction(true)
                                getFunctionState(fun_list, AppData.server, AppData.pinDiagramData[0]._id).then((data) => {
                                    setFunctionList(data[0].parameters[0].inputs)
                                    getFunctionState([AppData.pinComp + '_OUTPUT_' + AppData.GPIOstate], AppData.server, AppData.pinDiagramData[0]._id).then((val) => {
                                        if (val[0].function_state != null) { setFunctionCurrent(val[0].function_state) }
                                        else { setFunctionCurrent('') }
                                    })
                                })
                            }
                            else { 
                                setShowFunction(false)
                                setShowType(false)
                                setshowOPType(false)
                             }
                        })
                    }
                })
                setRender(!render)
            })
        }
    }
    const onChangeType = (value: string) => {
        //Type is updated when changed
        setMessage('')
        if (typeof (value) === 'string') {
            processGPIO(AppData.pinComp, AppData.pinComp + '_Type', 'Type', value).then((data) => {
                getFunctionState([AppData.pinComp + '_Type'], AppData.server, AppData.pinDiagramData[0]._id).then((val) => {
                    if (val[0].function_state != 'disable') { setTypeCurrent(val[0].function_state) }
                    else { setTypeCurrent('') }
                })
            })
        }
    }
    const onChangeInverse = (value: string) =>{
        //Inverse is updated when changed
        if (typeof(value) === 'string'){
            processGPIO(AppData.pinComp, AppData.pinComp + '_Inverse', 'Inverse', value).then((data) => {
                getFunctionState([AppData.pinComp + '_Inverse'], AppData.server, AppData.pinDiagramData[0]._id).then((val) => {
                    setInverseCurrent(val[0].function_state)
                })
            })
        }
    }

    const onChangeOP = (value: string) =>{
        //OP is updated when changed
        if (typeof(value) === 'string'){
            processGPIO(AppData.pinComp, AppData.pinComp + '_OPType', 'O/P Type', value).then((data) => {
                getFunctionState([AppData.pinComp + '_OPType'], AppData.server, AppData.pinDiagramData[0]._id).then((val) => {
                    setopValue(val[0].function_state)
                })
            })
        }
    }

    
    const onChangeFunction = (value: string) => {
        //Process function
        if (typeof (value) === 'string') {
            console.log('selected function value', value)
            if (directionCurrent === 'Input') {
                AppData.direction = directionCurrent
                getFunctionState(functionList, AppData.server, AppData.pinDiagramData[0]._id).then((stateData) => {
                    stateData.map((fun: any) => {
                        if (fun.function_state === AppData.pinComp) {
                            resetFunctions([fun.parameters[0].param_name])
                        }
                    })
                    processGPIO(AppData.pinComp, value, value, AppData.pinComp).then((data) => {
                        //If OE_ALL is selected other OE cannot be selected
                        if (data) {
                            if ((value === 'OE0' || value === 'OE1' || value === 'OE2' || value === 'OE3')) {
                                getFunctionState(["OE_ALL"], AppData.server, AppData.pinDiagramData[0]._id).then((OE: any) => {
                                    setMessage(data + `for ${OE[0].function_state}. ` + `${value} can't be selected. Please deselect OE_ALL to proceed.`)
                                    var resetList = ['OE0', 'OE1', 'OE2', 'OE3']
                                    resetFunctions(resetList)
                                })
                            }
                        }
                        else {
                            getFunctionState([value], AppData.server, AppData.pinDiagramData[0]._id).then((val) => {
                                if (val[0].function_state === AppData.pinComp) { setFunctionCurrent(value) }
                                setMessage("")
                            })
                        }
                        getFunctionState(functionList, AppData.server, AppData.pinDiagramData[0]._id).then((stateData) => {
                            var disablelist: boolean[] = []
                            var check
                            stateData.map((state: any, index: number) => {
                                if (state.function_state === AppData.pinComp) {
                                    check = 1
                                    disablelist[index] = false
                                    setFunctionCurrent(state.parameters[0].param_name)
                                }
                                else if (state.function_state == null || state.function_state == 'disable') {
                                    disablelist[index] = false
                                }
                                else { disablelist[index] = true }
                            })
                            setDisableList(disablelist)
                            if (check != 1) {
                                setFunctionCurrent('')
                            }
                            setRender(!render)
                        })
                    })
                })
            }
            else if (directionCurrent === 'Output') {
                AppData.direction = directionCurrent
                var fun_list = [AppData.pinComp + "_OUTPUT_" + AppData.GPIOstate]
                setDisableList([])
                processGPIO(AppData.pinComp, AppData.pinComp + '_OUTPUT_' + AppData.GPIOstate, "Function", value).then((data) => {
                    getFunctionState([AppData.pinComp + '_OUTPUT_' + AppData.GPIOstate], AppData.server, AppData.pinDiagramData[0]._id).then((val) => {
                        if (val[0].function_state != null) { setFunctionCurrent(val[0].function_state) }
                        else { setFunctionCurrent('') }
                        getFunctionState(fun_list, AppData.server, AppData.pinDiagramData[0]._id).then((data) => {
                            setFunctionList(data[0].parameters[0].inputs)
                        })
                    })
                })
            }
        }
    }
    const resetFunction = () => {
        //Reset function when interface is changed
        var fun_list = ['GPIO1_OUTPUT_I2C', 'GPIO1_OUTPUT_SPI', 'GPIO1_OUTPUT_NONE', 'GPIO2_OUTPUT_I2C', 'GPIO2_OUTPUT_SPI', 'GPIO2_OUTPUT_NONE', 'GPIO8_OUTPUT_I2C', 'GPIO8_OUTPUT_SPI',
            'GPIO8_OUTPUT_NONE', 'GPIO9_OUTPUT_I2C', 'GPIO9_OUTPUT_SPI', 'GPIO9_OUTPUT_NONE', 'OE2', 'OE3', 'SSEN', 'OE0', 'OE1', 'OE_ALL', 'FS0', 'FS1', 'A1', 'A0', 'GPIO0_Direction', 'GPIO1_Direction',
            'GPIO2_Direction', 'GPIO3_Direction', 'GPIO4_Direction', 'GPIO5_Direction', 'GPIO8_Direction', 'GPIO9_Direction',
            'GPIO6_Direction', 'GPIO7_Direction',
            'GPIO0_Type', 'GPIO1_Type', 'GPIO2_Type', 'GPIO3_Type', 'GPIO5_Type', 'GPIO6_Type', 'GPIO7_Type', 'GPIO8_Type', 'GPIO9_Type',
            'GPIO0_Inverse','GPIO1_Inverse','GPIO2_Inverse','GPIO3_Inverse','GPIO4_Inverse','GPIO6_Inverse','GPIO7_Inverse','GPIO8_Inverse','GPIO9_Inverse',
            'GPIO0_OPType','GPIO1_OPType','GPIO2_OPType','GPIO3_OPType','GPIO4_OPType','GPIO8_OPType','GPIO9_OPType']
        resetFunctions(fun_list)
    }
    const unCheckFunction = (value: string) => {
        //Unchecking the checkbox should reset the particular function.
        setMessage('')
        if (directionCurrent === 'Input') {
            var disablelist: boolean[] = []
            AppData.direction = directionCurrent
            resetFunctions([value]).then((res: any) => {
                getFunctionState([value], AppData.server, AppData.pinDiagramData[0]._id).then((val) => {
                    console.log('data.name', [value], val)
                    if (val[0].function_state === AppData.pinComp) { setFunctionCurrent(value) }
                    else { setFunctionCurrent('') }
                    getDevicePropertiesByName(AppData.server, AppData.pinComp + '_INPUT_' + AppData.GPIOstate).then((inputData: any) => {
                        if (inputData[0].inputs.length != 0) {
                            setShowFunction(true)
                            setFunctionList(inputData[0].inputs)
                        }
                        else { 
                            setShowFunction(false) 
                            setShowType(false)
                            setshowOPType(false)    
                        }
                    })
                })
            })
        }
        else if (directionCurrent === 'Output') {
            AppData.direction = directionCurrent
            setDisableList([])
            resetFunctions([AppData.pinComp + '_OUTPUT_' + AppData.GPIOstate]).then((res: any) => {
                getFunctionState([AppData.pinComp + '_OUTPUT_' + AppData.GPIOstate], AppData.server, AppData.pinDiagramData[0]._id).then((val) => {
                    setFunctionCurrent(val[0].function_state)
                })
            })
        }
    }
    return (
        < FlexBox shape="boxcontainer" layout="horizontal-top-left" spacing="boxcontainer" >
            {loader && <LoadingSpinner shape={"medium"} stroke={"normal"} fill={"none"} innerfill={"loader"} speed={"1"} text={"   Loading..."} position={{ top: '40vh', left: '45vw' }} type={'circular'} disabled={false} ></LoadingSpinner>}
            <FlexBox layout="vertical-top-center" spacing="blockdiagrambox" shape="blockdiagrambox" fill="none" stroke="device">
                <Blockdiagram place={AppData.pinDiagramData[0].place} route={AppData.pinDiagramData[0].route} style={style} size={{ height: 600, width: 800 }} onClick={onClickComp} />
            </FlexBox>
            <FlexBox layout="vertical-top-left" spacing="blockdiagrambox2" shape="blockdiagrambox2" fill="none" stroke="device">
                {pinout ?
                    <FlexBox shape="hug" spacing="pinout" layout="vertical-top-left">
                        <FlexBox shape="hug" spacing="none">
                            <div style={{ margin: '0px', padding: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '14px', cursor: "pointer", color: "#8E8D8A" }} onClick={AppData.currentConfig.frozen ? undefined : onClickBack}><Icons iconName={"arrow-left"} size={"20px"} /> Back</div>
                        </FlexBox>
                        <FlexBox shape="hug" spacing="none">
                            <div style={{ color: "#231F20", fontSize: "26px", fontWeight: "500" }}>
                                {AppData.pinComp}
                            </div>
                        </FlexBox>
                        <FlexBox shape="hug" spacing="pinoutDrop" layout="vertical-top-left">
                            <Tooltip shape="functionalForm" fill="grey" spacing="normal" font="normal" message={"Select Direction Input/Output"} location="top" X={"0px"} Y={"-100px"}>
                                <FlexBox shape="hug" spacing="none" layout="vertical-top-left">
                                    <p style={{ padding: "0px", margin: "0px" }}>Direction</p>
                                    <Dropdown disabled={((AppData.currentConfig.frozen) || (AppData.server === 'remote')) ? true : false} current={directionCurrent} select={"single"} shape={"voh"} InputSearch={false} effects={"dropShadow"} contentItems={directionList} fill={"popupform1"} spacing={"normal"} stroke={"none"} onClick={(value: string) => { onChangeDirection(value) }} ></Dropdown>
                                </FlexBox>
                            </Tooltip>
                            {showType &&
                                <Tooltip shape="functionalForm" fill="grey" spacing="normal" font="normal" message={"Select Type Push-pull / Open Drain"} location="top" X={"0px"} Y={"-100px"}>
                                <FlexBox shape="hug" spacing="none" layout="vertical-top-left">
                                    <p style={{ padding: "0px", margin: "0px" }}>Type</p>
                                    <Dropdown disabled={((AppData.currentConfig.frozen) || (AppData.server === 'remote')) ? true : false} current={typeCurrent} select={"single"} shape={"voh"} InputSearch={false} effects={"dropShadow"} contentItems={typeList} fill={"popupform1"} spacing={"normal"} stroke={"none"} onClick={(value: string) => { onChangeType(value) }} ></Dropdown>
                                </FlexBox>
                            </Tooltip>}
                            {showFunction &&
                                <Tooltip shape="functionalForm" fill="grey" spacing="normal" font="normal" message={"Select Function"} location="top" X={"0px"} Y={"-100px"}>
                                    <FlexBox shape="hug" spacing="none" layout="vertical-top-left">
                                        <p style={{ padding: "0px", margin: "0px" }}>Function</p>
                                        <DropdownCheckBox disabled={((AppData.currentConfig.frozen) || (AppData.server === 'remote')) ? true : false} unCheck={unCheckFunction} disabledItems={disableList} select={"single"} current={functionCurrent} shape={"voh"} InputSearch={false} effects={"dropShadow"} contentItems={functionList} fill={"popupform1"} spacing={"normal"} stroke={"none"} onClick={(value: string) => { onChangeFunction(value) }}></DropdownCheckBox>
                                    </FlexBox>
                                </Tooltip>
                            }
                        </FlexBox>
                        {(!showFunction) &&
                            <FlexBox shape='hug' layout='vertical-top-left' spacing='none'>
                                <div style={{ marginTop: '10px', fontStyle: "italic", display: "flex", flexDirection: "column", gap: "5px" }}>
                                    <p style={{ padding: "0px", margin: "0px" }}>Functions under Direction: {AppData.direction} for PIN: {AppData.pinComp} are not configurable.</p>
                                    <p style={{ padding: "0px", margin: "0px" }}>Possible Reasons:</p>
                                    <p style={{ padding: "0px", margin: "0px" }}>1. It is not present.</p>
                                    <p style={{ padding: "0px", margin: "0px" }}>2. Functions are automatically mapped.</p>
                                </div>
                            </FlexBox>}
                            {showInverse &&
                                <Tooltip shape="functionalForm" fill="grey" spacing="normal" font="normal" message={"Select Inversion"} location="top" X={"0px"} Y={"-100px"}>
                                    <FlexBox shape="hug" spacing="none" layout="vertical-top-left">
                                        <p style={{ padding: "0px", margin: "0px" }}>Inversion</p>
                                        <Dropdown disabled={((AppData.currentConfig.frozen) || (AppData.server === 'remote')) ? true : false} select={"single"} current={inverseCurrent} shape={"voh"} InputSearch={false} effects={"dropShadow"} contentItems={inverseList} fill={"popupform1"} spacing={"normal"} stroke={"none"} onClick={(value: string) => { onChangeInverse(value) }}></Dropdown>
                                    </FlexBox>
                                </Tooltip>
                            }
                            {showOPType &&
                                <Tooltip shape="functionalForm" fill="grey" spacing="normal" font="normal" message={"Select O/P Type"} location="top" X={"0px"} Y={"-100px"}>
                                    <FlexBox shape="hug" spacing="none" layout="vertical-top-left">
                                        <p style={{ padding: "0px", margin: "0px" }}>O/P Type</p>
                                        <Dropdown disabled={((AppData.currentConfig.frozen) || (AppData.server === 'remote')) ? true : false} select={"single"} current={opValue} shape={"voh"} InputSearch={false} effects={"dropShadow"} contentItems={opList} fill={"popupform1"} spacing={"normal"} stroke={"none"} onClick={(value: string) => { onChangeOP(value) }}></Dropdown>
                                    </FlexBox>
                                </Tooltip>
                            }
                        <FlexBox shape='hug' layout='vertical-top-left' spacing='none'>
                            <div style={{ color: 'red', marginTop: '10px' }}>
                                {message}
                            </div>
                        </FlexBox>
                    </FlexBox>
                    :
                    <FlexBox shape="serial" layout="vertical-top-left" spacing="pinout">
                        <FlexBox shape="serial" layout="vertical-top-left" spacing="serial" fill="serial">
                            <div>Serial Interface</div>
                            <FlexBox shape="hug" layout="horizontal-top-left" spacing="pinbuttons">
                                <Button disabled={((AppData.currentConfig.frozen) || (AppData.server === 'remote')) ? true : false} shape="FC" spacing="FC" color={i2c ? "white" : "FC"} stroke="FC" fill={i2c ? "selected" : "FC"} onClick={() => { return setI2C(true),  setNone(false), setSPI(false), setShowError(false), setErrorMessage('') }}>I2C</Button>
                                <Button disabled={((AppData.currentConfig.frozen) || (AppData.server === 'remote')) ? true : false} shape="SPI" spacing="FC" color={spi ? "white" : "FC"} stroke="FC" fill={spi ? "selected" : "FC"} onClick={() => { return setSPI(true),  setI2C(false), setNone(false),setShowError(false), setErrorMessage('') }}>SPI</Button>
                                <Button disabled={((AppData.currentConfig.frozen) || (AppData.server === 'remote')) ? true : false} shape="NONE" spacing="FC" color={none ? "white" : "FC"} stroke="FC" fill={none ? "selected" : "FC"} onClick={() => { return setNone(true), setSPI(false), setI2C(false),setShowError(false), setErrorMessage('') }}>None</Button>
                            </FlexBox>
                        </FlexBox>
                        {(i2c) && <FlexBox layout="vertical-top-left" spacing="none" shape="serial">
                            <p style={{ margin: "0px", padding: "0px" }}>Address</p>
                            <Tooltip shape="pin" fill="grey" spacing="normal" font="normal" message={'Enter slave address in hexadecimal.'} location="bottom" X={"0px"} Y={"0px"}>
                            <InputString errorMessage={errorMessage} Error={showerror} disabled={((AppData.currentConfig.frozen) || (AppData.server === 'remote')) ? true : false} shape="serial" spacing="medium" stroke="none" fill="signin" regex={undefined} fonts={""} content={content} getValue={(add) => { setAddress(add) }} />
                        </Tooltip>
                        </FlexBox>}
                        <FlexBox shape="serial" spacing="none" layout="horizontal-top-right">
                            <Button disabled={((AppData.currentConfig.frozen) || (AppData.server === 'remote')) ? true : false} fill="pinoutsubmit" shape="pinoutsubmit" color="white" onClick={onClickSubmit}>
                                Submit
                            </Button>
                        </FlexBox>
                    </FlexBox>
                }
            </FlexBox>
        </FlexBox >
    )
}
export default PinoutViewScreen