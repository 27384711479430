import { IconThemeIF } from "../icons/iconsThemeIF"

const IconThemes: IconThemeIF = {
    stroke: {
        normal: "#5ABCB0",
        black: "black",
        none: "currentcolor",
        confirm: "#3E9EBC",
        cancel: "#B563BC",
        danger: "#8E4A66",
        white:'white',
        status:'#56BA64',
        menu:'#3399D5',
        remote:"#8E8D8A",
        flag:'#3399D5',
        dropdown:'#3399D5',
        duplicate:'#3399D5'
    },
    fill:{
        white:'white',
        status:'#56BA64',
        menu:'#3399D5',
        remote:"#8E8D8A",
        flag:"#3399D5"
    }
}

export default IconThemes