import React from 'react';
import styled, { css } from 'styled-components';

interface DialogcontainerIF {
  shape: string;
  stroke: string;
  fill: string;
  effects: string;
  font: string;
  position: "top-left" | "top-center" | "top-right" | "middle-left" | "middle-center" | "middle-right" | "bottom-left" | "bottom-center" | "bottom-right",
  spacing:string
}

const Dialog = styled.div<DialogcontainerIF>`
  box-sizing: border-box;
  position:fixed;
  z-index:99;
  border-radius:${props => props.theme.dialog.shape[props.shape].radius}; 
  width:${props => props.theme.dialog.shape[props.shape].width};
  height:${props => props.theme.dialog.shape[props.shape].height};
  border:${props => props.theme.dialog.stroke[props.stroke].normal};
  background-color:${props => props.theme.dialog.fill[props.fill].normal};
  float:left;
  padding:${props => props.theme.dialog.spacing[props.spacing].padding};
  font-size:${props => props.theme.dialog.font[props.font].size};
  box-shadow:${props => props.theme.dialog.effects[props.effects]};
  ${props => (props.position === 'top-left') ? `top:0%; left:0%` : (props.position === 'top-center') ? `top:0%; left:35%` : (props.position === 'top-right') ? `top:0%; right:0%` :
    (props.position === 'middle-left') ? `top:50%; left:0%` : (props.position === 'middle-center') ? `top:30%; left:35%` : (props.position === 'middle-right') ? `top:50%; right:0%` :
      (props.position === 'bottom-left') ? `bottom:0%; left:0%` : (props.position === 'bottom-center') ? `bottom:0%; left:35%` : (props.position === 'bottom-right') ? `bottom:0%; right:0%` : 'none'};
  `
const DialogIcon = styled.div`
  cursor: pointer;
  display:flex;
  float:right;
  
  `
interface DialogInletIF {
  font: string
}
const DialogInlet = styled.div<DialogInletIF>`
  font-weight:${props=>props.theme.dialog.font[props.font].fontweight};
  color:${props => props.theme.dialog.font[props.font].color};
  background:none;
  width:100%;
  height:fit-content;
  border: none;
  float:right;
  cursor: pointer;
  display:inline;
  
  `
const Dialogbuttondiv = styled.div`
  position:relative;
  display:flex;
  width:max-content;
  gap:10px;
`
interface ButtonpropsIF {
  shape: string;
  fill: string;
  animated: boolean;
  stroke: string;
}

const Dialogbutton = styled.button<ButtonpropsIF>`
  background:${props => props.theme.dialog.fill[props.fill].buttonfill}; 
  border-radius:${props => props.theme.dialog.shape[props.shape].buttonradius}; 
  width:${props => props.theme.dialog.shape[props.shape].buttonwidth}; 
  height:${props => props.theme.dialog.shape[props.shape].buttonheight}; 
  border:${props => props.theme.dialog.stroke[props.stroke].buttonstroke}; 
   
  ${props => props.animated && css` 
  &:hover {
    transition: ${props => props.theme.dialog.transition};
    background: ${props => props.theme.dialog.fill[props.fill].hover};
    cursor:pointer;
  }
  `
  }
`
const Modal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 500;
`
export { Dialog, Dialogbutton, Dialogbuttondiv, DialogIcon, DialogInlet, Modal }