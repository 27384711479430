import React, { useRef, useState } from "react";
import { Button } from "../../../components/button/button";
import FlexBox from "../../../components/flexBox/flexBox";
import GridContainer from "../../../components/gridContainer/gridContainer";
import InputString from "../../../components/inputString/inputString";
import Logo from "../../logo/logo";

function SignupScreen({ onSigninSuccess = () => { }, onSigninFailure = () => { }, onClickSignin = () => { } }) {
    const submit = () => {
        //Data.User.name = userDetails.current.FirstName;
        onSigninSuccess();
        onClickSignin()
    }
    return (
        <GridContainer shape="signin" layout="signup" fill='signin'>
        <FlexBox gridName="e" layout="vertical-top-left" shape='signup'>
            <FlexBox shape="signupinner" layout="vertical-top-left" spacing="signupinner" fill='signin'>
                <FlexBox shape='siTimelogo' layout="vertical-top-left" spacing='none'>
                   <div style={{ height: "25.77px", width: '5.6640625vw' }}><Logo /></div>
                </FlexBox>
                <FlexBox shape="signUpHeader" layout="vertical-top-left" spacing="none">
                    <p style={{ margin: '0px', color: " #1E1E1E", fontStyle: 'normal', fontSize: '28px', fontWeight: '700' }}>Sign up</p>
                </FlexBox>
                <FlexBox shape="signupText" layout="vertical-top-left" spacing="none">
                    <p style={{ margin: '0px', color: " #1E1E1E", fontWeight: '500', fontStyle: 'normal', fontSize: '12px' }}>First Name*</p>
                    <InputString placeholder="Enter your first name"shape="signup" fill="signup" stroke="none" spacing="frequency" regex={undefined} fonts={""} content={""}></InputString>
                    <p style={{ margin: '0px', color: " #1E1E1E", fontWeight: '500', fontStyle: 'normal', fontSize: '12px' }}>Last Name*</p>
                    <InputString placeholder="Enter your last name" shape="signup" fill="signup" stroke="none" spacing="frequency" regex={undefined} fonts={""} content={""}></InputString>
                    <p style={{ margin: '0px', color: " #1E1E1E", fontWeight: '500', fontStyle: 'normal', fontSize: '12px' }}>Email*</p>
                    <InputString placeholder="Enter your email" shape="signup" fill="signup" stroke="none" spacing="frequency" regex={undefined} fonts={""} content={""}></InputString>
                    <p style={{ margin: '0px', color: " #1E1E1E", fontWeight: '500', fontStyle: 'normal', fontSize: '12px' }}>Company*</p>
                    <InputString placeholder="Enter your company name" shape="signup" fill="signup" stroke="none" spacing="frequency" regex={undefined} fonts={""} content={""}></InputString>
                    <Button color="signup" fill="signup" shape="signup" onClick={submit} >Sign up</Button>
                </FlexBox>
            </FlexBox>
            {/* <div style={{ display: "flex", gap: "5px" }}><p style={{ marginTop: '20px', color: '#595A5A', fontWeight: '400', fontSize: '14px', fontFamily: 'Inter', fontStyle: 'normal' }}>Already a member?</p>
                <p style={{ marginTop: '20px', color: '#3399D5', fontWeight: '400', fontSize: '14px', fontFamily: 'Inter', fontStyle: 'bold', textDecoration: 'underline' }} onClick={onClickSignin}> Sign in</p></div> */}
            <FlexBox shape="signinFinal" layout="vertical-top-left" spacing="none">
            <p style={{ margin: '10px 0px 0px 0px', color: '#595A5A', fontWeight: '400', fontSize: '14px', fontStyle: 'normal', display:"flex" }}>Already a member?
            <p style={{ margin: '0px 0px 0px 3px', color: '#3399D5', fontWeight: '400', fontSize: '14px', fontStyle: 'bold', cursor: 'pointer' }} onClick={onClickSignin}> Sign in</p></p>
            </FlexBox>
        </FlexBox>

    </GridContainer>
    )
}
export default SignupScreen