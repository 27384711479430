import React from 'react';
import FlexBoxStyle from './flexBoxStyles'

export interface PropsIF {
    shape?: string
    layout?: string
    spacing?: string
    stroke?: string
    fill?: string
    effects?:string
    gridName?:string
    id?: string
    children:React.ReactNode
}

export default function FlexBox({ id="", shape="normal", layout="horizontal-center-center", spacing="normal", stroke="none", fill="none", effects="none", children, gridName=""}:PropsIF) {

    return(
        <FlexBoxStyle shape={shape} layout={layout} spacing={spacing} stroke={stroke} fill={fill} effects={effects} gridName={gridName}> 
            {children} 
        </FlexBoxStyle>
    )
}