import React, { Children, useState } from 'react';
import FlexBox from '../flexBox/flexBox';
import {LoadingSpinnerDiv,LoadingSpinnerContainer,LoadingSpinnertext,LinerloadingSpinnerDiv, Modal} from "./loadingSpinnerStyles"
interface propsif {
    shape: string
    stroke: string
    fill: string
    innerfill: string
    speed: string
    text:string
    position:any
    type:string
    disabled: boolean
    setdisable?:any
    settingdisablefunction?: (disable:boolean,setdisable:any) =>void;
}
export default function LoadingSpinner({shape = 'hug',speed="1",innerfill,type,stroke, text, fill,disabled,position,setdisable,settingdisablefunction=(disable:boolean,setdisable:any)=>{}}: propsif) {
    const top =position['top']
    const left =position['left']

    if(disabled){
        settingdisablefunction(disabled,setdisable)}
    else{
        settingdisablefunction(disabled,setdisable)
    }

    return (
        <Modal>
            <LoadingSpinnerContainer shape={shape} stroke={stroke} fill={fill} top={top} left={left}>              
                {type=='linear'?<LinerloadingSpinnerDiv speed={speed} shape={shape} stroke={stroke} fill={fill} innerfill={innerfill}/>:<LoadingSpinnerDiv speed={speed} shape={shape} stroke={stroke} fill={fill} innerfill={innerfill}/>}
                <LoadingSpinnertext>
                    {text}
                </LoadingSpinnertext>  
            </LoadingSpinnerContainer>
        </Modal>
    )
}
