import GridContainerThemes from "./gridContainerThemes"
import ButtonThemes from "./buttonThemes"
import FlexBoxThemes from "./flexBoxThemes"
import TooltipThemes from "./toolTipThemes"
import DialogTheme from "./dialogBoxThemes"
import InputStringThemes from "./inputStringThemes"
import DataGridThemes from "./dataGridThemes"
import DropdownThemes from "./dropDownThemes"
import IconThemes from "./iconsThemes"
import RadioButtonThemes from "./radioButtonThemes"
import PassWordThemes from "./passWordThemes"
import MenuThemes from "./menuThemes"
import PopUpFormThemes from "./popupFormThemes"
import InputTextAreaThemes from "./multiLineTextAreaThemes"
import InputBooleanThemes from "./inputBooleanThemes"
import MessageTheme from "./messagesThemes"
import SelectButtonThemes from "./selectButtonThemes"
import LoadingSpinnerThemes from "./loadingSpinnerThemes"
import CalendarThemes from "./calendarThemes"

const PrimaryTheme = {

    flexBox: FlexBoxThemes,
    gridContainer: GridContainerThemes,
    button: ButtonThemes,
    tooltip: TooltipThemes,
    dataGrid: DataGridThemes,
    inputString: InputStringThemes,
    dialog: DialogTheme,
    dropdown: DropdownThemes,
    icon: IconThemes,
    radiobutton: RadioButtonThemes,
    loadingSpinner:LoadingSpinnerThemes,
    inputtextarea:InputTextAreaThemes,
    password:PassWordThemes,
    menu:MenuThemes,
    inputBoolean:InputBooleanThemes,
    popupform:PopUpFormThemes,
    message:MessageTheme,
    Selectbutton:SelectButtonThemes,
    calendar:CalendarThemes
}

export default PrimaryTheme
