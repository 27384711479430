import AppData from './appData'

async function startUpAsyncActions() {
        // create a local AXIOS server connection
        await AppData.localServer.create()
        // Create a connection to remote server
        await AppData.remoteServer.create()
        //Create a connection to Device server
        await AppData.deviceServer.create()
      }

function appStartup() {
    startUpAsyncActions().catch((message) => {console.log(message.error)})

}

async function Server(){
    let result:any
    try{
        result=await AppData.localServer.sendCommand(AppData.api.Server_check,`getting server details failed`,1)
        console.log(result)
        return result
    }catch{

    }
}

async function ServerDevice(){
    let result:any
    try{
        result=await AppData.deviceServer.sendCommand(AppData.api.Server_check,`getting server details failed`,1)
        console.log(result)
        return result
    }catch{
            console.error("Device Server not running")
    }
}

export {appStartup,Server,ServerDevice}