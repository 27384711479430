import React from 'react';
import styled, { css } from 'styled-components';

interface MessageIF {
    type: "success" | "warn" | "info" | "error"|"custom"
    shape: string
    stroke: string
    fill: string;
    font: string;
    position: "top-left" | "top-center" | "top-right" | "middle-left" | "middle-center" | "middle-right" | "bottom-left" | "bottom-center" | "bottom-right"
}
const Messagecontainer = styled.div<MessageIF>`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    position:fixed;
    z-index:600;
    width:${props => props.theme.message.shape[props.shape].width};
    height:${props => props.theme.message.shape[props.shape].height};
    border-radius:${props => props.theme.message.shape[props.shape].radius};
    border-left:${props => (props.type === 'success') ? props.theme.message.stroke[props.stroke].success : (props.type === 'warn') ?
        props.theme.message.stroke[props.stroke].warn : (props.type === 'info') ? props.theme.message.stroke[props.stroke].info :
            (props.type === 'error') ? props.theme.message.stroke[props.stroke].error : 'none'};
    font-size:${props => props.theme.message.font[props.font].size};
    color:${props => (props.type === 'success') ? props.theme.message.font[props.font].successcolor : (props.type === 'warn') ?
        props.theme.message.font[props.font].warncolor : (props.type === 'info') ? props.theme.message.font[props.font].infocolor
            : (props.type === 'error') ? props.theme.message.font[props.font].errorcolor : 'none'};
    background-color:${props => (props.type === 'success') ? props.theme.message.fill[props.fill].success : (props.type === 'warn') ?
        props.theme.message.fill[props.fill].warn : (props.type === 'info') ? props.theme.message.fill[props.fill].info : (props.type === 'error') ?
            props.theme.message.fill[props.fill].error : 'none'};

    ${props => (props.position === 'top-left') ? `top:0%; left:0%` : (props.position === 'top-center') ? `top:0%; left:30%` : (props.position === 'top-right') ? `top:0%; right:0%` :
        (props.position === 'middle-left') ? `top:50%; left:0%` : (props.position === 'middle-center') ? `top:50%; left:30%` : (props.position === 'middle-right') ? `top:50%; right:0%` :
            (props.position === 'bottom-left') ? `bottom:0%; left:0%` : (props.position === 'bottom-center') ? `bottom:0%; left:30%` : (props.position === 'bottom-right') ? `bottom:0%; right:0%` : 'none'};
`
const Messagetext = styled.div`
    position:relative;
    padding:0px;
    margin:0px 50px 0px 50px;
  `
const MessageIcon = styled.div`
    display:flex;
    align-self:right;
    cursor: pointer;
    position:relative;
    float:right;
    padding:0px;
    margin:0px;
    margin-right:10px;
`
export { Messagecontainer, MessageIcon, Messagetext }
