import React, { useEffect, useState } from "react"
import { Textarea } from "./multiLineTextAreaStyles"

interface PropsIF {
    placeholder?: string
    shape?: string;
    stroke?: string
    fill?: string
    font?: string
    disabled?: boolean
    spacing: string;
    getValue?: (text: string) => void;
    content:string
    resize?: boolean
}
function InputTextarea({ resize = false,placeholder,content= "", shape = "normal", stroke = "normal", fill = "none", font = "normal", disabled = false, spacing = "normal",getValue = (name: string) => { } }: PropsIF) {
    const [value, setvalue] = useState(content)
    useEffect(()=>{setvalue(content);},[content])

    const onValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setvalue(e.target.value);
        getValue(e.target.value)
    }
    console.log(value)
    return (
        <Textarea resize={resize} onChange={onValueChange}  shape={shape} fill={fill} stroke={stroke} font={font} disabled={disabled} placeholder={placeholder} value={value} spacing={spacing}>
        </Textarea>
    )
}
export default InputTextarea