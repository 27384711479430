import { InputTextareaThemeIF } from "../components/multiLineTextArea/multiLineTextAreaThemeIF"

const InputTextAreaThemes: InputTextareaThemeIF = {
    shape: {
        hug: { width: "200px", height: "auto", radius: "5px" },
        normal: { width: "150px", height: "90px", radius: "5px" },
        large: { width: "300px", height: "90px", radius: "5px" },
        executetextarea:{width:"360px",height:"460px",radius:"0px"},
        Description:{width:"363px",height:"100px",radius:"0px"},
        customerDescription:{width:"363px",height:"50px",radius:"0px"},
    },
    fill: {
        none: "white",
        normal: "#fff2e2",
        popupform1:'#F0EFEF'
    },
    stroke: {
        none: 'none',
        normal: "1px solid black",
        thick: "3px solid black",
    },
    spacing: {
        normal: { padding: "3px 3px" },
        medium: { padding: "5px 10px" },
        popupform1:{padding:"9px 10px 9px 12px"}
    },
    font: {
        none: { color: 'black', style: 'none', size: '15px' },
        normal: { color: 'black', style: 'none', size: '15px' },
        medium: { color: 'red', style: 'none', size: '30px' }

    },
}
export default InputTextAreaThemes
