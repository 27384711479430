import { InputBooleanThemeIF } from "../components/inputBoolean/inputBooleanThemeIF"


const InputBooleanThemes: InputBooleanThemeIF = { 
    transition: "all 1s",
    shape: {
        circle: {inner:{width:"20px", height:"20px", radius:"20px"}, outer:{width:"40px", height:"40px", radius:"40px"}},
        square:  {inner:{width:"20px", height:"20px", radius:"4px"}, outer:{width:"40px", height:"40px", radius:"4px"}},     
        toggleHorizontal:   {inner:{width:"20px", height:"20px", radius:"10px"}, outer:{width:"60px", height:"30px", radius:"15px"}},       
        toggleVertical:   {inner:{width:"20px", height:"20px", radius:"10px"}, outer:{width:"30px", height:"60px", radius:"15px"}},    
        functional: {inner:{width:"10px", height:"10px", radius:"100px"}, outer:{width:"20px", height:"20px", radius:"100px"}}, 
   },

   stroke: {
       none: {innerOn:"none", outerOn:"none", innerOff:"none", outerOff:"none"},
       change: {innerOn:"5px solid #067D6F", outerOn:"none", innerOff:"none", outerOff:"none"},
       functional: {innerOn:"none", outerOn:"none", innerOff:"none", outerOff:"2px solid #8E8D8A"}
   },
   fill: {
       normal:{innerOn:"#035437", outerOn:"#AADBD5", innerOff:"#AADBD5", outerOff:"#AADBD5"},
       toggle:{innerOn:"#AADBD5", outerOn:"#035437", innerOff:"#AADBD5", outerOff:"#AADBD5"},
       noChange:{innerOn:"#AADBD5", outerOn:"#AADBD5", innerOff:"#AADBD5", outerOff:"#AADBD5"},
       functional:{innerOn:"white", outerOn:"blue", innerOff:"none", outerOff:"none"},

   },

   effects: {
        none:{inner: "none", outer: "none"},
        normal:{outer: "0px 4px 4px rgba(0, 0, 0, 0.25)", inner: "0px 4px 4px rgba(0, 0, 0, 0.25)"}
   }
}

export default InputBooleanThemes