import React, { useState } from "react";
import { ErrorMessage, Password } from "./passWordStyles";

interface propsif {
    shape?: string
    stroke?: string
    fill?: string
    font?:string
    placeHolder?:string,
    getValue?: (text: string) => void;
    Error?:any
    errorMessage?: any
    onKeyPress:any
    regex:any
    spacing?:string
    showEyeIcon?: boolean
}

export const PassWord = ({showEyeIcon = false,onKeyPress, regex, spacing="normal",shape = "normal",errorMessage,Error, stroke = "normal", fill = "normal", font = "normal",placeHolder="Password",getValue = (name: string) => { } }: propsif) => {
    const [value, setvalue] = useState('')
    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setvalue(e.target.value);
        getValue(e.target.value)
    }
    var inputRule = regex
    const myRegEx = new RegExp(inputRule);
   const error = myRegEx.test(value)

    const ErrorHandler = () => ( error == true ? <ErrorMessage font={font}></ErrorMessage> : <ErrorMessage font={font}> {errorMessage}</ErrorMessage>)
    return (
        <>
            <Password show={showEyeIcon} spacing={spacing}shape={shape} fill={fill} stroke={stroke} font={font} type="password" placeholder={placeHolder} onChange={onValueChange} onKeyPress={onKeyPress} />
            {ErrorHandler()}
            {Error&&<ErrorMessage font={font}> {errorMessage}</ErrorMessage>}
        </>
    );
};
export default PassWord