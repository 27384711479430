import React from "react";
import styled from "styled-components"

interface PasswordStyleIF {
    shape: string
    stroke: string
    fill: string
    font: string
    spacing:string
    show: boolean
}

const Password = styled.input<PasswordStyleIF>`
   width:${props => props.theme.password.shape[props.shape].width};
   height:${props => props.theme.password.shape[props.shape].height};
   padding:${props => props.theme.password.spacing[props.spacing]};
   background:${props => props.theme.password.fill[props.fill].normal};
   border:${props => props.theme.password.stroke[props.stroke]};
   border-radius:${props => props.theme.password.shape[props.shape].radius};
   outline:none;
   ::-webkit-input-placeholder {
    font-size:${props => props.theme.password.font[props.font].size};
    color:${props => props.theme.password.font[props.font].color};
   }
   ::-ms-reveal {
    display: ${props => (props.show) ? '' : 'none'}; 
  }
`
interface ErrorMessageIF {
    font:string   
}
const ErrorMessage=styled.p <ErrorMessageIF>`

   display:inline-block;
   width:max-content;
   color:red;
   font-size:${props => props.theme.inputString.font[props.font].size};
   font-style:${props => props.theme.inputString.font[props.font].style};
   padding:none;
   margin:0px;
`
export { Password,ErrorMessage }