import React from "react";
import styled from 'styled-components';
interface DropdownStyleIF{
    shape:string
    disabled:boolean
}
const DropdownStyle =styled.div<DropdownStyleIF>`
height:max-content;
width:${props => props.theme.dropdown.shape[props.shape].width};
pointer-events: ${props=>props.disabled==true?'none':''};
opacity: ${props=>props.disabled==true?'0.4':''};
display:block;
`
const ButtonRadius = (inp: string,dropdown:boolean,selected:boolean,radius:string) => ( (dropdown==true)?` ${inp} ${inp} 0px 0px`:(radius)&&(selected==true)?`${inp} ${inp} 0px 0px`:(radius)  )
const BoxRadius = (inp: string) => ( `0px 0px ${inp} ${inp} `)

interface DropdownItemsIF{
    shape:string
    dropdown?:boolean
    fill:string
    spacing:string
    stroke:string
    options:number
}
const DropdownItems=styled.div <DropdownItemsIF>`
height:max-content;
position:absolute;
z-index:1;
visibility:${props => (props.dropdown == true) ? 'visible':'hidden'};
height:${props =>(props.options<3?'fit-content':props.theme.dropdown.shape[props.shape].dropdownheight)};
border-radius:${props => BoxRadius(props.theme.dropdown.shape[props.shape].radius)};
border:${props => props.theme.dropdown.stroke[props.stroke].dropdownStroke};
padding:0px;
margin:-10px 0px 0px 0px;
background:${props => props.theme.dropdown.fill[props.fill].innerfill};
`
interface DropdownItemsDivIF{
    shape:string
    spacing:string
    Itemselected?:boolean
 
}

const DropdownItemsDiv=styled.div<DropdownItemsDivIF>`
justify-content:start;
align-items:center;
display:flex;
padding:${props=>props.theme.dropdown.spacing[props.spacing].padding};
margin:0px;
background:${props=>props.Itemselected?'red':'inherit'};
:hover{
    cursor:pointer;
}

`
interface DropdownItemscheckDivIF{
    shape:string
    spacing:string
    Itemselected?:boolean
    disabled?:boolean
}
const DropdownCheckItemsDiv=styled.div<DropdownItemscheckDivIF>`
width:100%;
justify-content:start;
align-items:center;
display:flex;
padding:${props=>props.theme.dropdown.spacing[props.spacing].padding};
margin:0px;
overflow:hidden;
pointer-events: ${props=>props.disabled==true?'none':''};
opacity: ${props=>props.disabled==true?'0.4':''};
background:${props=>props.Itemselected?'red':'inherit'};
:hover{
    cursor:pointer;
}

`
interface SelecteOptionsIF {
    shape:string
    selectedOptions?:boolean
    stroke:string
    options:number
    effects:string
    inputsearch:boolean
}
const SelectOptions=styled.div<SelecteOptionsIF>`
position:relative;
width:${props => props.theme.dropdown.shape[props.shape].width};
height:${props =>(props.options<3?'fit-content':props.theme.dropdown.shape[props.shape].dropdownheight)};
background:inherit;
font-weight:bold;
margin-bottom:10px;
overflow-y: auto;
overflow-x:hidden;
box-shadow:${props => (props.inputsearch?'3px 6px 5px 3px rgba(0,0,0,0.25)':props.theme.dropdown.effects[props.effects])};
`
const SelectedItems=styled.button`
display:flex;
align-items:center;
justify-content:start;
cursor:pointer;
`
interface DropdownInputDivIF{
    inputsearch:boolean
    spacing:string
    dropdown:boolean
    shape:string
    stroke:string
}
const DropdownInputDiv =styled.div<DropdownInputDivIF>`
position:relative;
display:flex;
align-items:center;
justify-content:flex-start;
margin-top:5px;
padding-top:5px;
padding-bottom:5px;
height:14px;
width:${props => props.theme.dropdown.shape[props.shape].width};
background:#F0EFEF;
border:${props => props.theme.dropdown.stroke[props.stroke].dropdownStroke};
visibility:${props => (props.inputsearch&&props.dropdown == true) ? 'visible':'hidden'};
box-shadow:3px 4px 5px 4px rgba(0,0,0,0.25);
`
interface DropdownInputIF {
   shape:string
}
const DropdownInput=styled.input<DropdownInputIF>`
display:flex;
background:inherit; 
width:${props => props.theme.dropdown.shape[props.shape].search};
box-sizing: border-box;
outline:none;
border:none;
`
const DropdownIcon=styled.button`
cursor:pointer;
flex:auto;
border:none;
display:flex;
justify-content:end;
align-self:center;
padding:none;
margin:none;
`
interface DropdownButtonIF {
    shape: string
    fill:string
    dropdown:boolean
    selected:boolean
    stroke:string
}
const DropdownButton = styled.button <DropdownButtonIF>`
    display:flex;
    color:black;
    padding:0px;
    align-items:center;
    border:${props=>props.theme.dropdown.stroke[props.stroke].buttonStroke};
    background:${props => props.theme.dropdown.fill[props.fill].outerfill};
    width:${props => props.theme.dropdown.shape[props.shape].width};
    height: ${props => props.theme.dropdown.shape[props.shape].height};
    border-radius:${props => ButtonRadius(props.theme.dropdown.shape[props.shape].radius,props.dropdown,props.selected,props.theme.dropdown.shape[props.shape].radius)};
    `

export  {DropdownStyle,DropdownButton,DropdownItemsDiv,DropdownInput,DropdownItems,DropdownIcon,SelectOptions,SelectedItems,DropdownInputDiv,DropdownCheckItemsDiv}
