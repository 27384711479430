import AppData from '../../../appData'
import { getBlockFunctions, getDevicePropertiesByName } from '../blockDiagramScreen/blockDiagramEvents';

const formDataToSend = (_result: any) => {
    const resData: { [index: string]: string | [] } = {};
    //get the date
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //if single digit pad 0 at start
    var yyyy = today.getFullYear();
    var date = dd + '-' + mm + '-' + yyyy;
    //form the data to send
    for (let key in _result) {
        var id = _result[key].split(':')[0]
        var val = _result[key].split(':')[1]
        resData[id] = val
    }
    return { resData, date }
}

async function syncConfig(source: string, row: any, onSync: any, setRecentData: any) {
    //Local to Remote sync or Remote to Local download 
    const [_sourceServer, _destinationServer, _destination] = (source == "local") ? [AppData.localServer, AppData.remoteServer, "remote"] : [AppData.remoteServer, AppData.localServer, "local"]
    let _data = (source == "local") ? AppData.userConfig.data.local : AppData.userConfig.data.remote

    // Down load config data from source
    AppData.api.configSyncDownload.bodyParams = { udc_uuids: [row.udc_uuid] }
    const _result = await _sourceServer.sendCommand(AppData.api.configSyncDownload, `Getting config data from ${source} failed.`)

    // Upload config data to destination
    AppData.api.configSyncUpload.bodyParams = _result
    await _destinationServer.sendCommand(AppData.api.configSyncUpload, `Uploading config data to ${_destination} failed.`)

    if (_result['udc'][0].frozen) {
        AppData.api.getCustomPartNumberHistory.bodyParams = { configuration_id: _result['udc'][0].udc_uuid }
        const partNumberHistory = await AppData.remoteServer.sendCommand(AppData.api.getCustomPartNumberHistory, "Could not get the custom part number history")
        if(partNumberHistory.length > 0){
            console.log('history', partNumberHistory, _result['udc'][0].udc_uuid)
            AppData.api.customPartNumberHistory.bodyParams = { custom_part_number_history: { _id: _result['udc'][0]._id, platform: partNumberHistory[0].platform, variant: partNumberHistory[0].variant, configuration_id: _result['udc'][0].udc_uuid, user_id: _result['udc'][0].created_by, custom_part_number: _result['udc'][0].custom_part_number, sequence: partNumberHistory[0].sequence }, doc_id: partNumberHistory[0]._id }
            await AppData.remoteServer.sendCommand(AppData.api.customPartNumberHistory, "Couldn't upload data")
        }
    }
    // Update the destination
    if (_destination == "local")
        await _destinationServer.sendCommand(AppData.api.configSearch, "Could not update remote DB after sync").then((res: any) => {
            let actualData1 = res;
            let modified_date: any[] = [];
            actualData1.forEach((value: any) => { return modified_date.push(value['modified_date'].replace('T', ' ').split('.')[0]) })

            let a = []
            let sortedData = []
            for (let i in modified_date) {
                a.push([i, modified_date[i]])
            }
            a.sort(function (i, j) { return i[1] - j[1]; }).reverse();
            for (let i in a) {
                sortedData.push(actualData1[a[i][0]])
            };
            AppData.userConfig.data.local = sortedData;
            var recentData = sortedData.slice(0, 2);
            AppData.userConfig.data.recent = recentData; setRecentData(AppData.userConfig.data.recent);
            onSync(sortedData);
        })
    else
        await _destinationServer.sendCommand(AppData.api.configSearch, "Could not update remote DB after sync").then((res: any) => {
            let actualData1 = res;
            let modified_date: any[] = [];
            actualData1.forEach((value: any) => { return modified_date.push(value['modified_date'].replace('T', ' ').split('.')[0]) })

            let a = []
            let sortedData = []
            for (let i in modified_date) {
                a.push([i, modified_date[i]])
            }
            a.sort(function (i, j) { return i[1] - j[1]; }).reverse();
            for (let i in a) {
                sortedData.push(actualData1[a[i][0]])
            }
            AppData.userConfig.data.remote = sortedData; onSync(sortedData)
        })

    return true;
}

async function addUserConfig(_result: any, Selectrow: any, setConfigData: any,setRemoteData?: any) {
    let result: any
    var { resData, date } = formDataToSend(_result)
    resData['created_by'] = AppData.User.data._id
    resData['modified_date'] = date
    resData['created_date'] = date
    resData['configuration_steps'] = []
    AppData.device.data.local.map((device: any) => {
        if (AppData.variant == device.variant) {
            resData['device_id'] = device._id
        }
    })
    result = Object.assign({}, Selectrow, resData);
    console.log('resss', result)
    // Upload User config to local
    AppData.api.addConfig.bodyParams = result
    console.log('resreuslt', result)
    await AppData.localServer.sendCommand(AppData.api.addConfig, "Uploading User config to local server failed.", 1)
    await AppData.localServer.sendCommand(AppData.api.configSearch, `Getting config data from local server failed.`, 1).then((res: any) => {
        let actualData1 = res;
        let modified_date: any[] = [];
        actualData1.forEach((value: any) => { return modified_date.push(value['modified_date'].replace('T', ' ').split('.')[0]) })
        let a = []
        let sortedData = []
        for (let i in modified_date) {
            a.push([i, modified_date[i]])
        }
        a.sort(function (i, j) { return i[1] - j[1]; }).reverse();
        for (let i in a) {
            sortedData.push(actualData1[a[i][0]])
        }
        var recentData = sortedData.slice(0, 2); AppData.userConfig.data.local = sortedData;
        AppData.userConfig.data.recent = recentData;
        setConfigData(AppData.userConfig.data.local)
    })
    await AppData.remoteServer.sendCommand(AppData.api.configSearch, `Getting config data from remote server failed.`, 1).then((res: any) => {
        let actualData1 = res;
        let modified_date: any[] = [];
        actualData1.forEach((value: any) => { return modified_date.push(value['modified_date'].replace('T', ' ').split('.')[0]) })

        let a = []
        let sortedData = []
        for (let i in modified_date) {
            a.push([i, modified_date[i]])
        }
        a.sort(function (i, j) { return i[1] - j[1]; }).reverse();
        for (let i in a) {
            sortedData.push(actualData1[a[i][0]])
        }
        AppData.userConfig.data.remote = sortedData
        setRemoteData(AppData.userConfig.data.remote)
    })
    return AppData.userConfig.data.recent
}

//Creating customer endpoint

async function CreateCustomer(customer_company:string,customer_address:string,customer_contact_name_title:string,customer_contact_number:any) {
    var result:any
    AppData.api.customerCreate.bodyParams={user_id: AppData.User.data._id,customer_company:customer_company,customer_address:customer_address,customer_contact_name_title:customer_contact_name_title,customer_contact_number:customer_contact_number}
    result= await AppData.localServer.sendCommand(AppData.api.customerCreate, "Uploading User config to local server failed.", 1)
    return result
}
//searching customer
async function CustomerSearch(_id:string){
    var result:any
    AppData.api.customerSearch.bodyParams={_id:_id}
    result = await AppData.localServer.sendCommand(AppData.api.customerSearch,"Customer search failed",1)
    return  result
}
//Customer Update
async function CustomerUpdate(_id:any,customer_company:any,customer_address:any,customer_contact_name_title:any,customer_contact_number:any,customer_details_id:any){
    var result:any
    AppData.api.customerUpdate.bodyParams={customer_details:{_id:_id,customer_company:customer_company,customer_address:customer_address,customer_contact_name_title:customer_contact_name_title,customer_contact_number:customer_contact_number},customer_detail_id:customer_details_id}
    result =await AppData.localServer.sendCommand(AppData.api.customerUpdate,"Customer update failed",1)
    return result
}
async function UpdateCustomerId( customer_details_id: string,udc_uuid: string){
    var result:any 
    AppData.api.updateCustomerId.bodyParams={customer_details_id:customer_details_id,udc_uuid:udc_uuid}
    result= await AppData.localServer.sendCommand(AppData.api.updateCustomerId,"Updating customer Id failed",1)
    return result
}
//Update SalesID and MarketID
async function UpdateSalesMarketID(){
    var result:any 
    AppData.api.updateId.bodyParams={udc_uuid:AppData.currentConfig.udc_uuid}
    result= await AppData.localServer.sendCommand(AppData.api.updateId,"Updating customer Id failed",1)
    return result
}
//Process the component function
async function ProcessFunction(device_id:string,function_name:string,param_name:string,token_name:string,component_name:string,block_diagram_id:string) {
    AppData.api.componentFunctionsProcess.bodyParams={device_id:device_id,function_name:function_name,parameter_name:param_name,token_name:token_name,component_name:component_name,block_diagram_id:block_diagram_id}
    await AppData.localServer.sendCommand(AppData.api.componentFunctionsProcess,"component function process failed",1)
}
//Search for customer details
async function customerDetailsSearch(){
    var result =await AppData.localServer.sendCommand(AppData.api.customerDetailsSearch,"Customer details search failed",1)
    return result
}
async function copyUserConfig(_result: any, Selectrow: any, setConfigData: any, setRecentData: any) {
    let result: any
    var { resData, date } = formDataToSend(_result)
    resData['created_by'] = AppData.User.data._id
    resData['modified_date'] = date
    result = Object.assign({}, Selectrow, resData);
    // Upload User config to local
    AppData.api.addConfig.bodyParams = result
    await AppData.localServer.sendCommand(AppData.api.addConfig, "Uploading User config to local server failed.", 1)
    await AppData.localServer.sendCommand(AppData.api.configSearch, `Getting config data from local server failed.`, 1).then((res: any) => {
        let actualData1 = res;
        let modified_date: any[] = [];
        actualData1.forEach((value: any) => { return modified_date.push(value['modified_date'].replace('T', ' ').split('.')[0]) })
        let a = []
        let sortedData = []
        for (let i in modified_date) {
            a.push([i, modified_date[i]])
        }
        a.sort(function (i, j) { return i[1] - j[1]; }).reverse();
        for (let i in a) {
            sortedData.push(actualData1[a[i][0]])
        }
        AppData.userConfig.data.local = sortedData;
        var recentData = sortedData.slice(0, 2);
        AppData.userConfig.data.recent = recentData; setRecentData(recentData);
        setConfigData(AppData.userConfig.data.local)
    })

}

async function updateUserConfig(_result: any, Selectrow: any, setConfigData: any, setRecentData: any) {
    let result: any
    var { resData, date } = formDataToSend(_result)
    resData['modified_date'] = date
    //check if value is changed, if no take the existing
    if (resData['name'] == '' && Selectrow['name'] != '') {
        resData['name'] = Selectrow['name']
    }
    if (resData['description'] == '' && Selectrow['description'] != '') {
        resData['description'] = Selectrow['description']
    }
    result = Object.assign({}, Selectrow, resData);
    // Remove User config from local 
    AppData.api.updateConfig.bodyParams = {
        device_id: result['device_id'],
        name: result['name'],
        udc_uuid: result['udc_uuid'],
        description: result['description']
    }
    await AppData.localServer.sendCommand(AppData.api.updateConfig, "Updating User config from local server failed.", 1)
    await AppData.localServer.sendCommand(AppData.api.configSearch, `Getting config data from local server failed.`, 1).then((res: any) => {
        let actualData1 = res;
        let modified_date: any[] = [];
        actualData1.forEach((value: any) => { return modified_date.push(value['modified_date'].replace('T', ' ').split('.')[0]) })
        let a = []
        let sortedData = []
        for (let i in modified_date) {
            a.push([i, modified_date[i]])
        }
        a.sort(function (i, j) { return i[1] - j[1]; }).reverse();
        for (let i in a) {
            sortedData.push(actualData1[a[i][0]])
        }
        AppData.userConfig.data.local = sortedData;
        var recentData = sortedData.slice(0, 2);
        AppData.userConfig.data.recent = recentData; setRecentData(recentData);
        setConfigData(AppData.userConfig.data.local)
    })
    AppData.api.initialyzeContext.bodyParams = { 'configuration_id': AppData.currentConfig.udc_uuid, 'user_details_id': AppData.User.data._id }
    await AppData.localServer.sendCommand(AppData.api.initialyzeContext, "Setting the user config to work on config selection", 1)
    var current = await AppData.localServer.sendCommand(AppData.api.currentConfig, `Getting current configuration failed`)
    AppData.api.deviceSearchWithId.bodyParams = { _id: current.device_id }
    var currentDevice = await AppData.localServer.sendCommand(AppData.api.deviceSearchWithId, `Getting current Device failed`, 1)
    current['platform'] = currentDevice[0].platform
    current['variant'] = currentDevice[0].variant
    AppData.currentConfig = current;
}

async function removeUserConfig(Selectrow: any, setConfigData: any, setRecentData: any) {
    let result: any
    result = Object.assign({}, Selectrow, {});
    // Remove User config from local 
    AppData.api.removeConfig.bodyParams = { 'udc_uuid': result['udc_uuid'] }
    await AppData.localServer.sendCommand(AppData.api.removeConfig, "Removing User config from local server failed.", 1)
    await AppData.localServer.sendCommand(AppData.api.configSearch, `Getting config data from local server failed.`, 1).then((res: any) => {
        let actualData1 = res;
        let modified_date: any[] = [];
        actualData1.forEach((value: any) => { return modified_date.push(value['modified_date'].replace('T', ' ').split('.')[0]) })
        let a = []
        let sortedData = []
        for (let i in modified_date) {
            a.push([i, modified_date[i]])
        }
        a.sort(function (i, j) { return i[1] - j[1]; }).reverse();
        for (let i in a) {
            sortedData.push(actualData1[a[i][0]])
        }
        AppData.userConfig.data.local = sortedData;
        var recentData = sortedData.slice(0, 2);
        AppData.userConfig.data.recent = recentData; setRecentData(recentData);
        setConfigData(AppData.userConfig.data.local)
    })
}

async function copyUserConfigRemote(_result: any, Selectrow: any, setRemoteConfigData: any) {
    let result: any
    var { resData, date } = formDataToSend(_result)
    resData['created_by'] = AppData.User.data._id
    resData['modified_date'] = date
    result = Object.assign({}, Selectrow, resData);
    // Upload User config to local
    AppData.api.addConfig.bodyParams = result
    const addResult = await AppData.remoteServer.sendCommand(AppData.api.addConfig, "Uploading User config to local server failed.", 1)
    console.log('add', addResult)
    await AppData.remoteServer.sendCommand(AppData.api.configSearch, `Getting config data from local server failed.`, 1).then((res) => {
        let actualData1 = res;
        let modified_date: any[] = [];
        actualData1.forEach((value: any) => { return modified_date.push(value['modified_date'].replace('T', ' ').split('.')[0]) })
        let a = []
        let sortedData = []
        for (let i in modified_date) {
            a.push([i, modified_date[i]])
        }
        a.sort(function (i, j) { return i[1] - j[1]; }).reverse();
        for (let i in a) {
            sortedData.push(actualData1[a[i][0]])
        }
        AppData.userConfig.data.remote = sortedData;
        setRemoteConfigData(sortedData);
    })
}
async function initializeConfig(currentRow: { id: string, device_id: string, udc_uuid: string, platform: string, variant: string, silicon_revision: string }, server: string) {
    //Initialize a configuration 
    const _sourceServer = (server == 'local') ? AppData.localServer : AppData.remoteServer
    AppData.api.initialyzeContext.bodyParams = { 'configuration_id': currentRow.udc_uuid, 'user_details_id': AppData.User.data._id }
    await _sourceServer.sendCommand(AppData.api.initialyzeContext, "Setting the user config to work on config selection", 1)
}
async function generateStates(server: string) {
    //Generate states after initialization to load configuration steps
    const _sourceServer = (server == 'local') ? AppData.localServer : AppData.remoteServer
    await _sourceServer.sendCommand(AppData.api.generateState, "Generating states and bringing the states to the head", 1)
}
async function GetHtml(customer_company:any,customer_address:any,customer_contact_name_title:any,customer_contact_number:any) {
    AppData.api.pdfGeneratorHtml.bodyParams = {header:true,footer:true,user_id:AppData.User.data._id,customer_company:customer_company,customer_address:customer_address,customer_contact_name_title:customer_contact_name_title,customer_contact_number:customer_contact_number}
    var result:any
    result = await AppData.localServer.sendCommand( AppData.api.pdfGeneratorHtml  ,"Uploading User config to local server failed.", 1)
    return result
}
async function getBlockDiagramData(currentRow: any, server: string) {
    //Load the functional block diagram 
    const _sourceServer = (server == 'local') ? AppData.localServer : AppData.remoteServer
    AppData.label = ''
    AppData.functionFormData = {
        "param": [],
        "commonfunctions": [],
        "delay": [],
        "type": [],
        "differential": [],
        "single": [],
        "op": [],
        "singlecommon": [],
        "singlevoh": [],
        "diffvoh": [],
        "slew": [],
        "diffref": [],
        "freq": [],
        "vdd": []
    }
    AppData.api.blockDiagramSearch.bodyParams = { device_id: currentRow['device_id'], name: currentRow['platform'].toUpperCase() + " " + currentRow['variant'].toUpperCase() + " " + AppData.FUNCTIONALDIAGRAM}
    const result = await _sourceServer.sendCommand(AppData.api.blockDiagramSearch, `Getting Block diagram failed`, 1)
    AppData.blockDiagramData = result
    AppData.api.historySearch.bodyParams = { 'udc_uuid': AppData.currentConfig.udc_uuid }
    const historyData = _sourceServer.sendCommand(AppData.api.historySearch, `Getting config data from local server failed.`, 1)
    historyData.then((res: any) => { AppData.history.data.local = res })
}

async function SummaryView() {
    //Load summaryView when configuration is selected.
    var index, signalling, clkP, clkN
    var blockFunctions = await getBlockFunctions(AppData.componentList, AppData.server)
    AppData.deviceFrequenciesData = []
    let devicePropertyData = await getDevicePropertiesByName(AppData.server,AppData.currentConfig.variant.toUpperCase() + '_FREQUENCIES')
    if(devicePropertyData.length != 0){
        devicePropertyData[0].inputs.map((data:any)=>{
            AppData.deviceFrequenciesData.push(data.toString())
        })
    }

    AppData.deviceFrequenciesDataDiff = []
    devicePropertyData = await getDevicePropertiesByName(AppData.server,AppData.currentConfig.variant.toUpperCase() + '_FREQUENCIES_DIFF')
    if(devicePropertyData.length != 0){
        devicePropertyData[0].inputs.map((data:any)=>{
            AppData.deviceFrequenciesDataDiff.push(data.toString())
        })
    }

    AppData.deviceFrequenciesDataSE = []
    devicePropertyData = await getDevicePropertiesByName(AppData.server,AppData.currentConfig.variant.toUpperCase() + '_FREQUENCIES_LVCMOS')
    if(devicePropertyData.length != 0){
        devicePropertyData[0].inputs.map((data:any)=>{
            AppData.deviceFrequenciesDataSE.push(data.toString())
        })
    }
    AppData.clkPlist = []
    AppData.clkNlist = []
    AppData.clkPDataList =[]
    AppData.clkNDataList = []
    blockFunctions.map((val: any) => {
        var functionData: {}[] = []
        try {
            index = AppData.componentList.indexOf(val['Block/component name'])
            for (const [key, value] of Object.entries(val.Functions)) {
                if(val.Functions[key].name === 'VDD'){
                    AppData.vddList = val.Functions[key].parameters[0].inputs
                    AppData.vddState = val.Functions[key].function_state
                }
                if(val.Functions[key].name === 'VDDA'){
                    AppData.vddaList = val.Functions[key].parameters[0].inputs
                    AppData.vddaState = val.Functions[key].function_state
                }
                if (val.Functions[key].parameters[0].param_name == 'CLK_P' &&
                 ((val.Functions[key].name == 'OUT0_OUTP_OUTN1') || (val.Functions[key].name == 'OUT1_OUTP_OUTN1') || (val.Functions[key].name == 'OUT2_OUTP_OUTN1') || (val.Functions[key].name == 'OUT3_OUTP_OUTN1'))) {
                    if(val.Functions[key].function_state=='True')
                    {
                        AppData.clkPlist[index] = true
                    }
                    else
                    {
                        AppData.clkPlist[index] = ''
                    }
                }
                if (val.Functions[key].parameters[0].param_name == 'CLK_N' &&
                ((val.Functions[key].name == 'OUT0_OUTP_OUTN2') || (val.Functions[key].name == 'OUT1_OUTP_OUTN2') || (val.Functions[key].name == 'OUT2_OUTP_OUTN2') || (val.Functions[key].name == 'OUT3_OUTP_OUTN2'))) {
                    if (val.Functions[key].function_state == 'True') 
                    { 
                        AppData.clkNlist[index] = true 
                    }
                    else 
                    { 
                        AppData.clkNlist[index] = '' 
                    }
                }
            }

            for (const [key, value] of Object.entries(val.Functions)) {
                functionData.push({
                    'name': val.Functions[key].name, 'parameters': val.Functions[key].parameters, "function_state": val.Functions[key].function_state
                })
                if (val.Functions[key].parameters[0].param_name == 'Signaling') {
                    AppData.signalList[index] = val.Functions[key].function_state
                }					
                if (val.Functions[key].name === 'PPM'){AppData.ppmValue = val.Functions[key].function_state}
                if ((val.Functions[key].name == 'OUT0OUTP') || (val.Functions[key].name == 'OUT1OUTP') || (val.Functions[key].name == 'OUT2OUTP') || (val.Functions[key].name == 'OUT3OUTP')){
                    console.log('clkPlist',AppData.clkPlist)
                    if(AppData.clkPlist[index]){
                        AppData.clkPDataList[index] = 'CLK_P (' + val.Functions[key].function_state + ')'
                    }
                    else{ AppData.clkPDataList[index] = ''}
                }
                if ((val.Functions[key].name == 'OUT0OUTN') || (val.Functions[key].name == 'OUT1OUTN') || (val.Functions[key].name == 'OUT2OUTN') || (val.Functions[key].name == 'OUT3OUTN')){
                    console.log('clkNlist',AppData.clkNlist)
                    if(AppData.clkNlist[index]){
                        AppData.clkNDataList[index] = 'CLK_N (' + val.Functions[key].function_state + ')'
                    }
                    else{ AppData.clkNDataList[index] = ''}
                }
            }
            AppData.compData[index] = functionData
            console.log('comppp', functionData, AppData.signalList, AppData.clkNDataList , AppData.clkPDataList, AppData.ppmValue)
        } catch {
        }
    })
    //const result = await resolveAfter2Seconds();
    var compCount = 0
    AppData.summaryData = []
    AppData.compData.forEach((functionData: any) => {
        console.log('functions', functionData)
        let compFunction: any = {}
        var count = 0
        for (const [key, value] of Object.entries(functionData)) {
            let functionName = functionData[key].name
            let paramName = functionData[key].parameters[0].param_name
            let functionValue = functionData[key].function_state
            if ((paramName == 'Mode') || (paramName == 'VDDO') || (paramName == 'Frequency(MHz)') || (paramName == 'Type') || (paramName == 'Hi-Z State') || (paramName == 'Delay Common Mode')) { compFunction[paramName] = functionValue }
            else if ((paramName == 'Delay CLK1N') || (paramName == 'Delay CLK0N') || (paramName == 'Delay CLK2N') || (paramName == 'Delay CLK3N')) { compFunction[paramName] = functionValue }
            else if ((paramName == 'Delay CLK0P') || (paramName == 'Delay CLK1P') || (paramName == 'Delay CLK2P') || (paramName == 'Delay CLK3P')) { compFunction[paramName] = functionValue }
        }
        AppData.summaryData[compCount] = compFunction
        compCount += 1
        console.log('summaryData', AppData.summaryData,AppData.clkNlist,AppData.clkPlist)

    })
}
async function searchSalesRegion() {
    const sales = await AppData.localServer.sendCommand(AppData.api.salesRegionSearch,`Fetching sales region data failed`,1)
    return sales
}
async function searchMarketSegment() {
    const market = await AppData.localServer.sendCommand(AppData.api.marketSegmentSearch,`Fetching sales region data failed`,1)
    return market
}
export { UpdateSalesMarketID,searchSalesRegion,searchMarketSegment,addUserConfig, syncConfig, copyUserConfig, SummaryView,GetHtml,ProcessFunction,CreateCustomer,customerDetailsSearch,UpdateCustomerId,CustomerSearch,CustomerUpdate,updateUserConfig, removeUserConfig, copyUserConfigRemote, initializeConfig, getBlockDiagramData, generateStates }