import React from 'react';
import ButtonStyle from "./buttonStyles"


interface PropsIF{
    vertical?: boolean
    disabled?: boolean
    animated?: boolean
    shape?: string
    spacing?: string
    stroke?: string
    color?:string
    fill?: string
    effects?:string
    gridName?: string
    id?:string
    onClick?: (id:string) => void,
    children:React.ReactNode
}
export const Button = ({ gridName="", vertical=false, spacing="normal",color='', disabled=false, animated=true, shape="normal", stroke="none", fill="normal", effects="none", id="", onClick=(id:string)=>{},  children}:PropsIF) => {

    const  callBack = disabled ?  () => {} :  () => {onClick(id)}
    return (
        <ButtonStyle color={color} gridName={gridName} vertical={vertical} spacing={spacing} disabled={disabled} animated={animated} shape={shape} stroke={stroke} fill={fill} effects={effects} id={id}  onClick={callBack} >
             {children} 
        </ButtonStyle>
 
    )

}
//export Button;

