import React from 'react';
import styled, {css} from 'styled-components';

interface GridContainerIF {
    shape: string
    layout: string
    spacing: string
    stroke: string
    fill: string
    effects:string
    gridName:string
}


const gridContainerStyle = styled.div <GridContainerIF>` {
    
    box-sizing:border-box;
    display:grid ;
    grid-template-columns:${props => props.theme.gridContainer.layout[props.layout].columns};
    grid-template-rows:${props => props.theme.gridContainer.layout[props.layout].rows};
    grid-template-areas:${props => `"${props.theme.gridContainer.layout[props.layout].areas.join("\"\n\"")}"`};
    gap: ${props => props.theme.gridContainer.spacing[props.spacing].gap};
    height: ${props => props.theme.gridContainer.shape[props.shape].height};
    width: ${props => props.theme.gridContainer.shape[props.shape].width};
    border-radius: ${props => props.theme.gridContainer.shape[props.shape].radius};
    background: ${props => props.theme.gridContainer.fill[props.fill]};
    border: ${props => props.theme.gridContainer.stroke[props.stroke]};
    box-shadow: ${props => props.theme.gridContainer.effects[props.effects]};
    grid-area:${props => props.gridName};

    `;
export default gridContainerStyle