import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { appStartup } from './appEvents'
import AppData from './appData'
import PrimaryTheme from './Themes/primaryTheme'
import SigninScreen from './appComponents/Screens/signInScreen/signInScreen'
import SignupScreen from './appComponents/Screens/signUpScreen/signUpScreen'
import AppTable from './appComponents/appTable/appTable'
import BlockDiagramScreen from './appComponents/Screens/blockDiagramScreen/blockDiagramScreen'
import HistoryScreen from './appComponents/Screens/historyScreen/historyScreen'
import DeviceScreen from './appComponents/Screens/deviceScreen/deviceScreen'
import ConfigurationDeviceScreen from './appComponents/Screens/configurationDeviceScreen/configurationDeviceScreen'
import ConfigurationScreen from './appComponents/Screens/configurationScreen/configurationScreen'

function App() {
	useEffect(appStartup, [])
	AppData.mainUrl = window.location.href
	const enum ScreenType {
		Listing = "Listing",
		FileProcessing = "FileProcessing",
		signin = 'signin',
		signup = "signup",
		blockdiagram = "blockdiagram",
		history = "history",
		device = "device",
		configuration = "configuration",
		table = "table",
		ConfigurationDeviceScreen = "ConfigurationDeviceScreen"
	}
	const [currentScreen, setCurrentScreen] = useState(ScreenType.signin)
	const [config, setconfig] = useState(false)
	const getScreen = () => {
		switch (currentScreen) {
			case (ScreenType.signin):
				return <SigninScreen onSigninSuccess={() => setCurrentScreen(ScreenType.ConfigurationDeviceScreen)} onClickSignup={() => setCurrentScreen(ScreenType.signup)} />
			case (ScreenType.signup):
				return <SignupScreen onClickSignin={() => setCurrentScreen(ScreenType.signin)} />
			case (ScreenType.blockdiagram):
				return <BlockDiagramScreen configBack={() => { }} configData={''} onSync={''} recentData={''} onClickDelete={() => { }} />
			case (ScreenType.history):
				return <HistoryScreen />
			case (ScreenType.device):
				return <DeviceScreen />
			case (ScreenType.configuration):
				return <ConfigurationScreen remotetabledata={AppData.userConfig.data.remote} localtabledata={AppData.userConfig.data.local} />
			case (ScreenType.table):
				return <AppTable data={[]} Columns={[]} />
			case (ScreenType.ConfigurationDeviceScreen):
				return <ConfigurationDeviceScreen Config={true} onclickConfig={() => { }} onClickLogout={() => { setCurrentScreen(ScreenType.signin) }} />
		}
	}

	return (
		<ThemeProvider theme={PrimaryTheme}>
			{getScreen()}
		</ThemeProvider>
	);
}

export default App;
