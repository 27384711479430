import { TooltipThemeIF } from "../components/toolTip/toolTipThemeIF"

const TooltipThemes: TooltipThemeIF = {
    shape: {
        normal: { width: "150px", height: "100%", radius: "10px" },
        functionalForm:{width:"180px",height:'100%',radius:'10px'},
        menu:{width:"180px",height:'100%',radius:'10px'},
        pin:{width:"100%",height:'100%',radius:'10px'},
    },
    fill: {
        normal: "black",
        grey: 'grey',
    },
    effects: {
        none: "none",
        insetShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
        dropShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)"
    },
    font: {
        normal: { color: 'white', style: 'none', size: '12px' },
        grey: { color: 'black', style: 'italics', size: '15px' }
    },
    spacing: {
        none: { padding: '0px' },
        normal: { padding: '10px' }
    },

}

export default TooltipThemes