import React, { useEffect, useState } from "react";
import AppData from "../../../appData";
import { Button } from "../../../components/button/button";
import Dropdown from "../../../components/dropDown/dropDown";
import GridContainer from "../../../components/gridContainer/gridContainer";
import FlexBox from "../../../components/flexBox/flexBox";
import DialogBox from "../../../components/dialogBox/dialogBox";
import PopUpForm from "../../../components/popupForm/popupForm";
import { DataGrid } from "../../../components/dataGrid/dataGrid";
import { addTempConfig, addUserConfig, compareConfig, connectDevice, deleteTempConfig, disconnectDriver, initializeRealtimeConfig, overwriteConfig, tempDCSearch } from "./realtimeEvents";
import App from "../../../App";
import RealtimeConfig from "./realtimeConfigTable";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import RegisterTable from "./registerTable";
import { SummaryView, getBlockDiagramData } from "../configurationScreen/configurationEvents";
import Message from "../../../components/messages/messages";
import InputString from "../../../components/inputString/inputString";

interface propsIF {
    block?: any
    message?: any
    change? : any
}
function RealtimeScreen({ block,message,change  }: propsIF) {
    const [active, setactive] = useState(false)
    const [createConfig, setCreateConfig] = useState(false)
    const [deviceToTc_diabox, setDeviceToTc_diaBox] = useState(false)
    const [error, setError] = useState('')
    const [showConfigTable, setShowConfigTable] = useState(false)
    const [showregisterTable, setShowRegisterTable] = useState(false)
    const [device, setDevice] = useState('')
    const [protocol,setProtocol] = useState('')
    const [render, setRender] = useState(false)
    const [configdata, setConfigData] = useState(AppData.userConfig.data.local)
    const [loader, setLoader] = useState(false)
    const [messageText, setMessage] = useState('')
    const [visible, setVisible] = useState(false)
    const [open,setOpen] = useState(false)
    var addingForm = require('../configurationScreen/createconfig.json')
    const handleConnectClick = async () => {
        setLoader(true)
        var result = await tempDCSearch()
        if (result.length != 0) { await deleteTempConfig(result[0].udc_uuid) }
        await addTempConfig()
        var result = await tempDCSearch()
        AppData.deviceConfig = result[0]
        const res = await connectDevice(setLoader)
        res?setactive(false):setactive(true)
        res?AppData.connected = false:AppData.connected = true
        change(AppData.connected);
        setLoader(false)
    }
    const handleNewConfig = () => {
        setactive(false)
        addingForm.data[0].current = device
        addingForm.data[0].disable = true
        console.log('app', addingForm.data[0])
        setRender(!render)
        setCreateConfig(true)
        setDeviceToTc_diaBox(false)
    }
    const onChangeConfigName = (value: string, id: string) => {
        if (id === 'name') {
            if (value.length > 60) { setError('Configuration name must contain less than 60 characters.') }
            else { setError('') }
        }
        else if (id === 'description') {
            if (value.length > 255) { setError('Description must contain less than 255 characters.') }
            else { setError('') }
        }
    }
    const onCloseCreateForm = () => {
        setCreateConfig(false)
        setError('')
    }
    const handleYesClick = () => {
        var data: {}[] = []
        AppData.userConfig.data.local.map((config: any) => {
            if ((config.variant === AppData.connectedDevice) && (!config.frozen)) {
                data.push(config)
            }
        })
        setOpen(true)
        setConfigData(data)
        setShowConfigTable(true)
        console.log('Connect', AppData.connectedDevice, configdata, configdata.length);
    }

    const onSelectDevice = (selected: any) => {
        setVisible(false)
        if (typeof (selected) == 'string') {
            setDevice(selected)
            AppData.connectedDevice = selected
        }
        AppData.device.data.local.map((device: any) => {
            if (AppData.connectedDevice == device.variant) {
                AppData.connectedDeviceId = device._id
                AppData.connectedDevicePlatform = device.platform;
            }
        })
    }
    const onSelectProtocol = (selected: any) => {
        setVisible(false)
        if (typeof (selected) == 'string') {
            setProtocol(selected)
            AppData.protocol = selected
        }
    }
    const handleOpenCLick = async () => {
        setVisible(false)
        setLoader(true)
        await initializeRealtimeConfig(AppData.currentConfig,setLoader)    
        try {
            const result = await compareConfig()
            AppData.comparedData = result
            setShowConfigTable(false)
            setShowRegisterTable(true)
            console.log(AppData.comparedData)
            setLoader(false)
        }
        catch { 
            setLoader(false) 
            setMessageComp(true,'Opening configuration failed.Try again.')
        }
    }
    const onClickOverwrite = async () => {
        console.log(AppData.deviceConfig, AppData.currentConfig)
        setDeviceToTc_diaBox(false)
        await overwriteConfig(setLoader)
        block(true)
        AppData.block = true
        setLoader(false)
    }
    const columns = [{ width: "10vw", toolbar: false, header: "S.No" },
    { width: "27vw", toolbar: false, header: "Name" },
    { width: "27vw", toolbar: false, header: "Base Part" },
    { width: "26vw", toolbar: false, header: "Last Opened" }]

    const reg_columns = [{ width: "10vw", toolbar: false, header: "S.No" },
    { width: "15vw", toolbar: false, header: "Register Name" },
    { width: "15vw", toolbar: false, header: "Field Name" },
    { width: "30vw", toolbar: false, header: `User Configuration - ${AppData.currentConfig.name}` },
    { width: "20vw", toolbar: false, header: `${AppData.deviceConfig.name}` },]
    const onClickCreateConfig = async (formData:{id:string,value:string}[]) => {
        setVisible(false)
        var checkName=0,checkDescription=0
        let count=false
        setError('')
        formData.map((val)=>{
            if(val.id == 'name'){
                const newValue = val.value.trim()
                AppData.userConfig.data.local.map((local:any)=>{
                    if(local.name === newValue){
                    count = true
                    }
                })
                if(newValue != ''){
                    if(count){
                        checkName = 0
                        setError('Please enter a unique configuration name')
                        setCreateConfig(true)
                    }
                    else{
                        if(newValue.length>60){
                            checkName =0
                            setError('Configuration name must contain less than 60 characters.')
                            setCreateConfig(true)
                        }
                        else{checkName = 1}
                    }
                }
                else{
                    checkName = 0
                    setError('Please enter the required fields')
                    setCreateConfig(true)
                }
            }
            else if(val.id === 'description'){
                if(val.value.length>255){
                    checkDescription=0
                    setError('Description must contain less than 255 characters.')
                    setCreateConfig(true)
                }
                else{checkDescription = 1}
            }
        })
        if((checkDescription ===1)&&(checkName === 1)){
        setLoader(true)
        setCreateConfig(false)
        setError('')
        console.log('formData', formData)
        formData[0].value = device
        await addUserConfig(formData, setLoader)
        console.log(AppData.currentConfig, 'llll')
        await getBlockDiagramData(AppData.currentConfig, AppData.server)
        AppData.componentList = []
        AppData.blockDiagramData[0].place.map((placeData: any) => {
            if ((placeData.type === 'Driver') && (placeData.clickable !== 'NO')) {
                AppData.componentList.push(placeData.name)
                AppData.labelList.push(placeData.label)
            }
        })
        await SummaryView()
        AppData.block = true
        block(true)
        message(true,'Configuration is created')
        setLoader(false)
    }}
    const onClickTCtoDevice = async () => {
        setLoader(true)
        await getBlockDiagramData(AppData.currentConfig, AppData.server)
        AppData.componentList = []
        AppData.blockDiagramData[0].place.map((placeData: any) => {
            if ((placeData.type === 'Driver') && (placeData.clickable !== 'NO')) {
                AppData.componentList.push(placeData.name)
                AppData.labelList.push(placeData.label)
            }
        })
        await SummaryView()
        AppData.block = true
        block(true)
        setLoader(false)
    }
    const onClickDisconnect = async ()=>{
       setVisible(false)
       try{ await disconnectDriver()
        AppData.connected = false
        AppData.protocol = ''
        AppData.slave_address = ''
        AppData.connectedDevice = ''
        AppData.connectedDeviceId = ''
        AppData.connectedDevicePlatform = ''
        setShowConfigTable(false)
        setShowRegisterTable(false)
        setProtocol('')
        setDevice('')
        addingForm.data[0].current = device
        addingForm.data[0].disable = false
        setMessageComp(true,'Disconnected the interface successfully')
        setRender(!render)
        change(AppData.connected);}
        catch{alert('Disconnecting interface failed')}
    }
    const setMessageComp = (visible: boolean, message: any) => {
        return setMessage(message), setVisible(visible)
    }
    return (
        <GridContainer shape="Realtime" layout="Realtime">
            <Message visible={visible} shape="config" font="config" position="bottom-right" text={messageText} hide={setVisible}></Message>
            {loader && <LoadingSpinner shape={"medium"} stroke={"normal"} fill={"none"} innerfill={"loader"} speed={"1"} text={"Loading Configuration..."} position={{ top: '40vh', left: '45vw' }} type={'circular'} disabled={false} ></LoadingSpinner>}
            <FlexBox gridName="b" shape="realtime" layout="vertical-top-center" spacing="realtimeContainer"><FlexBox shape="realtimeTop" layout="horizontal-center-center" spacing="realtimeTop">
                <p>Device:</p><Dropdown current={AppData.connectedDevice} disabled={AppData.connected}  iconcolor="dropdown" select={"single"} shape="device" InputSearch={false} effects={"dropShadow"} contentItems={AppData.devicelist} fill="popupform1" spacing="normal" stroke={"none"} onClick={(selected: any) => onSelectDevice(selected)} />
                <p>Protocol:</p><Dropdown current={AppData.protocol} disabled={AppData.connected} iconcolor="dropdown" select={"single"} shape="device" InputSearch={false} effects={"dropShadow"} contentItems={['I2C', 'SPI', 'None']} fill="popupform1" spacing="normal" stroke={"none"} onClick={(selected: any) => onSelectProtocol(selected)} />
                <p>Slave Address:</p><InputString disabled={true} shape="serial" spacing="medium" stroke="none" fill="signin" regex={undefined} fonts={""} content={AppData.slave_address} />
                <Button fill="createNew" disabled={(AppData.connected)? false :(!AppData.connected) && (protocol && device) ? false : true} color='signin' shape="createNew" spacing="createNew" onClick={AppData.connected?onClickDisconnect:handleConnectClick} ><div style={{ display: 'flex', padding: '0px', margin: '0px', width: '100px', height: "21px", fontSize: '16px', fontWeight: "400", justifyContent: 'center', alignItems: 'center' }}>{!AppData.connected?'Connect':'Disconnect'}</div></Button>
                <DialogBox shape="dialog" Buttoncontent={["Yes", "No"]} position='middle-center' visible={active} header='Open Configuration ' text={" Want to open existing configuration ? "} spacing="dialog" fill="dialog" font="dialog" hide={setactive} OnclickNo={handleNewConfig} OnclickYes={handleYesClick}></DialogBox>
                {createConfig && <PopUpForm valueChange={(value: string, id: string) => { onChangeConfigName(value, id) }} OnclickCancel={onCloseCreateForm} shape={"hug"} spacing={"popupform1"} fill={"white"} stroke={""} font={""} initFormData={addingForm.data} onSubmit={(formData: any) => onClickCreateConfig(formData)} visible={createConfig} hide={setCreateConfig} popupformshape={"popupform1"} popupfill={"popupform"} popupstroke={"popupform"} items={[]}
                    buttonContent="Create Configuration" buttonName="popupform1" heading="Create New Configuration" Error={error}></PopUpForm>}
            </FlexBox>
                {showConfigTable && <FlexBox shape="configTable" layout="vertical-top-right" spacing="realtimeTop">
                    {/* <DataGrid shape='realtimeconfig' fill="table1" stroke="rowOnly" data={[]} columns={columns}></DataGrid> */}
                    <RealtimeConfig selected={(value: boolean)=>{setOpen(!value)}} shape='realtimeconfig' fill="table1" stroke="rowOnly" configData={setConfigData} data={configdata} Columns={columns} />
                    <FlexBox shape="realtimeButton" layout="horizontal-bottom-right" spacing="realtimeTop">
                        <Button fill="createNew" color='signin' shape="duplicate" spacing="createNew" disabled={open} onClick={handleOpenCLick} ><div style={{ display: 'flex', padding: '0px', margin: '0px', width: '100px', height: "21px", fontSize: '16px', fontWeight: "400", justifyContent: 'center', alignItems: 'center' }}>Open</div></Button>
                        <Button fill="createNew" color='signin' shape="duplicate" spacing="createNew" onClick={() => { setShowConfigTable(false) }} ><div style={{ display: 'flex', padding: '0px', margin: '0px', width: '100px', height: "21px", fontSize: '16px', fontWeight: "400", justifyContent: 'center', alignItems: 'center' }}>Close</div></Button>
                    </FlexBox>
                </FlexBox>}
                {showregisterTable && <FlexBox shape="configTable" layout="vertical-top-right" spacing="realtimeTop">
                    {/* <DataGrid shape='realtimeconfig' fill="table1" stroke="rowOnly" data={[]} columns={reg_columns}></DataGrid> */}
                    <RegisterTable shape='realtimeconfig' fill="table1" stroke="rowOnly" columns={reg_columns} />
                    <FlexBox shape="realtimeButton" layout="horizontal-bottom-right" spacing="realtimeTop">
                        <Button fill="createNew" color='signin' shape="tctodevice" spacing="createNew" onClick={onClickTCtoDevice} ><div style={{ display: 'flex', padding: '0px', margin: '0px', width: '100px', height: "21px", fontSize: '16px', fontWeight: "400", justifyContent: 'center', alignItems: 'center' }}>TC to Device</div></Button>
                        {/* <Button fill="createNew" color='signin' shape="tctodevice" spacing="createNew" onClick={() => { setDeviceToTc_diaBox(true) }} ><div style={{ display: 'flex', padding: '0px', margin: '0px', width: '100px', height: "21px", fontSize: '16px', fontWeight: "400", justifyContent: 'center', alignItems: 'center' }}>Device to TC</div></Button> */}
                        <Button fill="createNew" color='signin' shape="openConfig" spacing="createNew" onClick={() => { setShowConfigTable(true); setShowRegisterTable(false) }} ><div style={{ display: 'flex', padding: '0px', margin: '0px', width: '200px', height: "21px", fontSize: '16px', fontWeight: "400", justifyContent: 'center', alignItems: 'center' }}>Select Configuration</div></Button>
                        <DialogBox shape="dialog" Buttoncontent={["Yes", "No"]} position='middle-center' visible={deviceToTc_diabox} header='Device to TC' text={"Overwrite the existing configuration ?"} spacing="dialog" fill="dialog" font="dialog" hide={setDeviceToTc_diaBox} OnclickNo={handleNewConfig} OnclickYes={onClickOverwrite}></DialogBox>
                    </FlexBox>
                </FlexBox>}
            </FlexBox>

        </GridContainer>
    )
}

export default RealtimeScreen