import React, { useState } from "react"
import { Input, Inputcontainer } from "./radioButtonStyles";
import Icons from '../../icons/icons';
import AppData from "../../appData";
interface PropsIF {
    radios: { name: string, key: string }[];
    onClick: (itemName: string) => void;
    shape?: string;
    fill?: string;
    stroke?: string;
    font?: string;
    transition?: string;
    disabled?: boolean;
    display: "row" | "row-reverse" | "column" | "column-reverse";
    initialState?: string

}
function RadioButton({ radios,shape = "normal", display = "row", font = "normal", stroke = "normal", fill = "normal", transition = "normal", disabled = false, onClick, initialState }: PropsIF) {
    var checkedRadio = initialState
    const onChangeValue = (e:React.ChangeEvent<HTMLInputElement>) => {
        checkedRadio=e.currentTarget.value
        onClick(checkedRadio)
        if(checkedRadio === 'Differential'){
            AppData.type='Differential'
        }
        else if(checkedRadio === 'Single Ended (LVCMOS)'){
            AppData.type='Single Ended (LVCMOS)'
        }
        console.log('radio',e.currentTarget.value)
    }
    return (
        <div style={{display:"flex",gap:'5px',flexDirection:display}}>
            {radios.map((item) => (
                <Inputcontainer display={display} shape={shape} font={font}>
                    <Input shape={shape} stroke={stroke} fill={fill} transition={transition} disabled={disabled} type="radio" value={item.key} checked={checkedRadio === item.key} onChange={onChangeValue} />
                    <label style={{display:"flex",alignItems:"end",justifyContent:"center"}}>{item.name}</label>
                </Inputcontainer>
                
            ))}
            
        </div>
    )
}
export default RadioButton;