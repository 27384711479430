import { MenuThemeIF } from "../components/menu/menuThemeIF"

const MenuThemes: MenuThemeIF = {
    shape: {
        normal: { width: "120px", height: "max-content", radius: "4px" },
        large: { width: "130px", height: "fit-content", radius: "10px" },
        small: { width: "105px", height: "fit-content", radius: "5px" },
        maxcontent: { width: "max-content", height: "max-content", radius: "3px" },
        local: { width: "max-content", height: "max-content", radius: "0px" },
        blockdiagram: { width: "max-content", height: "max-content", radius: "0px" }
    },
    stroke: {
        none: "none",
        thin: "1px solid #dee2e6",
        thick: "2px solid black"
    },
    fill: {
        normal: { normal: "#383838;", hover: "#4b4b4b", fontcolor: "white" },
        transparent: { normal: "none", hover: "#4b4b4b", fontcolor: "black" },
        color: { normal: "#2083c3", hover: "#dbf9e7", fontcolor: "none" },
        local: { normal: "white", hover: "none", fontcolor: "black" }
    },
    effects: {
        none: "none",
        insetShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.1)",
        dropShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
        outerShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.25)",
    },
    spacing : {
        normal: {padding: "2px 2px 0px 10px"},
        medium: {padding: "3px 3px 0px 12px"}, 
        local:{padding:'6px 6px 6px 6px'}
    },
    transition: "all 0.5s",
    fontSize:{
        normal:'12px',
        local:'14px'
    }
}

export default MenuThemes
