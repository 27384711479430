import { CalendarThemeIF } from "../components/calendar/calendarThemeIF"

const CalendarThemes:CalendarThemeIF  = {
        shape: {
            hug: { width: "fit-content", height: "fit-content", radius: "10px"},
            small: { width: "200px", height: "19px", radius: "10px"},
            normal : { width: "520px", height: "50px", radius: "20px"},
        },
        stroke : {
            normal:{ frameStroke: "1px solid lightgrey", currentStroke: "1px solid #ee0eff",selectorStroke : "1px solid  #00ff00"},
            thin: { frameStroke: "1px solid #000000", currentStroke: "1px solid #0000ff",selectorStroke : "1px solid #067D6F"},
            thick:{ frameStroke: "5px solid #000000", currentStroke: "5px solid #067D6F",selectorStroke : "5px solid #ff7dff"} 
        },
        fill : {
            normal: "white",
            wheat: "wheat",
            yellow:"#FFFF00",
            auqa: "#00FFFF",
            calendar:"#F0EFEF",
            black:"#212120",
            white:"white"
        },
        effects : {
            none: "none",
            insetShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
            dropShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)"
        },
        font : {
            normal:  {headerFont: "13px", bodyFont: "10px"},
            small:{ headerFont: "10px", bodyFont: "4px"},
            large:{ headerFont: "30px", bodyFont: "19px"},
        },

    } 

    
export default CalendarThemes