import React from 'react';
import styled, {css} from 'styled-components';

interface ButtonStyleIF {
    vertical?: boolean
    disabled?: boolean
    animated?: boolean
    shape: string
    color:string
    spacing: string
    stroke: string
    fill: string
    effects:string
    gridName: string
}


const ButtonStyle = styled.div <ButtonStyleIF>`
    box-sizing:border-box;
    display:flex ;
    flex-direction:${props => props.vertical ? "column" : "row"};
    color:${props => props.theme.button.color[props.color]};
    justify-content:center;
    align-items:center;
    gap: ${props => props.theme.button.spacing[props.spacing].gap};
    padding: ${props => props.theme.button.spacing[props.spacing].padding};
    height: ${props => props.theme.button.shape[props.shape].height};
    width: ${props => props.theme.button.shape[props.shape].width};
    border-radius: ${props => props.theme.button.shape[props.shape].radius}    ;
    background: ${props => props.theme.button.fill[props.fill].normal};
    border: ${props => props.theme.button.stroke[props.stroke]};
    box-shadow: ${props => props.theme.button.effects[props.effects]};
    grid-area:${props => props.gridName};
    position:relative;

    ${props => props.disabled ?  
        css`
        opacity: 0.5;
        cursor: default;`: 
        css`
        opacity: 1;
        cursor: pointer;`
        }
    ${props => props.animated && !props.disabled && css` 
    &:hover {
        transition: ${props => props.theme.button.transition};
        background: ${props => props.theme.button.fill[props.fill].hover};
    }
    ` 
    }
    `;
export default ButtonStyle




