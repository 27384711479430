import React from 'react';
import styled, {css} from 'styled-components';

interface CalendarContainerStyleIF {
  shape:string
  stroke:string
  effects:string
  fill:string
  font:string
}

const CalendarContainer = styled.div <CalendarContainerStyleIF>`
  /* ~~~ container styles ~~~ */
  width: ${props => props.theme.calendar.shape[props.shape].width};
  max-width: 100%;
  position:absolute;
  background-color: ${props => props.theme.calendar.fill[props.fill]};
  color: black;
  border: ${props => props.theme.calendar.stroke[props.stroke].frameStroke};
  box-shadow: ${props => props.theme.calendar.effects[props.effects]};
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  .react-calendar__navigation button {
    color: black;
    min-width: 44px;
    background:none;
    font-size: ${props =>  props.theme.calendar.font[props.font].headerFont};
    margin-top: 8px;
   }
   .react-calendar *{
    text-decoration:none;
    
   }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    /* background: none; */
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-size: ${props =>  props.theme.calendar.font[props.font].headerFont};
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    text-decoration:none;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--weekend {
    color: black; 
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: grey;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    text-decoration:none;
    padding: 0.75em 0.5em;
    font-size: ${props =>  props.theme.calendar.font[props.font].bodyFont};
    background: none;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    /* background: #ffff76; */
    
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
  }
  .react-calendar__tile--active:enabled {
    border:2px solid #0d68de;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
`;

export {CalendarContainer};