import { SelectbuttonThemeIF } from "../components/selectButton/selectButtonThemeIF";

const SelectButtonThemes: SelectbuttonThemeIF = {

    shape: {
        normal: { width: "80px", height: "50px", radius: "10px" },
        large: { width: "80px", height: "60px", radius: "15px" },
        small: { width: "50px", height: "40px", radius: "5px" },
        verticalLarge: { width: "100px", height: "100px", radius: "20px" },
        functional:{width:'12.5vw',height:'30px',radius:'0px'}
    },
    spacing: {
        normal: { gap: "5px", margin: "0px", padding: " 0px" },
        large: { gap: "7px", margin: "0px", padding: " 3px" },
        functional: { gap: "7px", margin: "0px", padding: " 3px" },

    },
    stroke: {
        none: "none",
        thin: "3px solid black",
        thick: "5px solid #067D6F",
        functional:'none'
    },
    fill: {
        normal: { normal: "#1E6883", hover: "#B5F0F5", selectcolor: "#B9CEE7" },
        medium: { normal: "red", hover: "#B5F0F5", selectcolor: "#ffa07a" },
        transparent: { normal: "none", hover: "#035437", selectcolor: "#f08080" },
        functional:{ normal: "#F0EFEF", hover: "", selectcolor: "#3399D5" },
    },
    effects: {
        none: "none",
        insetShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
        dropShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)"
    },
    font: {
        normal: { color: 'black', style: 'none', size: '1vw' },
        Dark: { color: 'black', style: 'bold', size: '15px' }
    },
    transition: "all 0.5s"
}
export default SelectButtonThemes;



