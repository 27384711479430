import axios from 'axios'
import AppData from '../../../appData'
import configJson from "../../../config.json"

async function loadlocalConfig(result: any) {
    AppData.api.configSearch.bodyParams = { created_by: result[0]._id }
    var res = await AppData.localServer.sendCommand(AppData.api.configSearch, `Getting config data from local server failed.`, 1)
    return res
}

async function loadremoteConfig(result: any) {
    AppData.api.configSearch.bodyParams = { created_by: result[0]._id }
    var res = await AppData.remoteServer.sendCommand(AppData.api.configSearch, `Getting config data from remote server failed.`, 1)
    console.log('remote config', res)
    return res
}
 
async function signinValidate(ShowPassword: any, setShowPassword: any, username: string, password: string, onSigninSuccess: any, setsigninUser: any, setLoginwait: any, setalert: any, SetValidate: any, setPassword: any, setvalPassword: any) {
    let result: any
    let _resultdata: any
    let loginPage: boolean
    let signinSuccess: boolean
    let noInternet: boolean
    signinSuccess = false
    noInternet = false
    let hashedPassword: string

    let sessionAvail: boolean
    sessionAvail = false
    AppData.loginScreen = true
    setalert('')
    // Get User from local
    AppData.api.userSearch.bodyParams = { 'username': username }
    result = await AppData.localServer.sendCommand(AppData.api.userSearch, "Getting User details from local failed.", 1)

    if (result.length == 0) {

        //checking if user is available on remote
        result = await AppData.remoteServer.sendCommand(AppData.api.userSearch, `Getting user data from remote failed`, 1)

        if (result.length != 0) {
            setShowPassword(true)
            SetValidate(false)
            //check if valid session is available on the remote
            for (const [key, value] of Object.entries(result)) {
                if (sessionAvail) {
                    break
                }
                for (const [Skey, Svalue] of Object.entries(result[key].sessions)) {
                    try {
                        if (result[key].sessions[Skey].token) {
                            AppData.User.token = `Bearer ${result[key].sessions[Skey].token}`
                            const validate_session = await AppData.localServer.sendCommand(AppData.api.validateSession, `Getting user data from remote`, 1)
                            console.log('hi', validate_session)
                            if (validate_session == 'Valid Session') {
                                AppData.User.username = username
                                AppData.User.session_id = result[key].sessions[Skey].session_id
                                AppData.User.data = result[0]
                                AppData.User.token = `Bearer ${result[key].sessions[Skey].token}`
                                sessionAvail = true
                                await AppData.localServer.setHeader()
                                await AppData.remoteServer.setHeader()
                                AppData.loginScreen = false
                            }
                            else {
                                //delete the session in remote if token is not available for the session
                                AppData.api.deleteSession.bodyParams = { 'session_id': result[key].sessions[Skey].session_id }
                                await AppData.remoteServer.sendCommand(AppData.api.deleteSession, "Removing Invalid Session from remote server failed.", 1)
                            }
                        }
                        else {
                            //delete the session in remote if token is not available for the session
                            AppData.api.deleteSession.bodyParams = { 'session_id': result[key].sessions[Skey].session_id }
                            await AppData.remoteServer.sendCommand(AppData.api.deleteSession, "Removing Invalid Session from remote server failed.", 1)
                        }
                    }
                    catch {
                        //delete the session in both local and remote if token is not avaialble for the session
                        AppData.api.deleteSession.bodyParams = { 'session_id': result[key].sessions[Skey].session_id }
                        await AppData.remoteServer.sendCommand(AppData.api.deleteSession, "Removing Invalid Session from remote server failed.", 1)
                    }
                }
            }
            //if session is not available take to login page else open welcome screen
            if (!sessionAvail) {
                loginPage = true
                setShowPassword(true)
                SetValidate(false)
            }
            else {
                loginPage = true
                signinSuccess = true
                SetValidate(false)
            }
        }
        else {
            //throw invalid user error
            loginPage = false
            SetValidate(true)
            // setalert('User Does not exist!! Please SignUp..')
        }
    }
    //checking if the session is valid at local 
    else {
        for (const [key, value] of Object.entries(result)) {
            if (sessionAvail) {
                break
            }
            for (const [Skey, Svalue] of Object.entries(result[key].sessions)) {
                try {
                    if (result[key].sessions[Skey].token) {
                        AppData.api.validateSession.config = { headers: { Authorization: `Bearer ${result[key].sessions[Skey].token}` } }
                        const validate_session = await AppData.localServer.sendCommand(AppData.api.validateSession, `Getting user data from remote`, 1)
                        if (validate_session == 'Valid Session') {
                            AppData.User.username = username
                            AppData.User.data = result[0]
                            AppData.User.session_id = result[key].sessions[Skey].session_id
                            AppData.User.token = `Bearer ${result[key].sessions[Skey].token}`
                            sessionAvail = true
                            await AppData.localServer.setHeader()
                            await AppData.remoteServer.setHeader()
                            AppData.loginScreen = false
                        }
                        else {
                            //delete the session in both local and remote if token is not avaialble for the session
                            AppData.api.deleteSession.bodyParams = { 'session_id': result[key].sessions[Skey].session_id }
                            await AppData.localServer.sendCommand(AppData.api.deleteSession, "Removing Invalid Session from local server failed.", 1)
                            try {
                                await AppData.remoteServer.sendCommand(AppData.api.deleteSession, "Removing Invalid Session from remote server failed.", 1)
                            }
                            catch { }
                        }
                    }
                    else {
                        //delete the session in both local and remote if token is not avaialble for the session
                        AppData.api.deleteSession.bodyParams = { 'session_id': result[key].sessions[Skey].session_id }
                        await AppData.localServer.sendCommand(AppData.api.deleteSession, "Removing Invalid Session from local server failed.", 1)
                        try {
                            await AppData.remoteServer.sendCommand(AppData.api.deleteSession, "Removing Invalid Session from remote server failed.", 1)
                        }
                        catch { }
                    }
                }
                //delete the session when token is invalid, as axios throws error when token is invalid
                catch {
                    AppData.api.deleteSession.bodyParams = { 'session_id': result[key].sessions[Skey].session_id }
                    await AppData.localServer.sendCommand(AppData.api.deleteSession, "Removing Invalid Session from local server failed.", 1)
                    try {
                        await AppData.remoteServer.sendCommand(AppData.api.deleteSession, "Removing Invalid Session from remote server failed.", 1)
                    }
                    catch { }

                }
            }
        }
        //if session is not available take to login page else open welcome screen
        if (!sessionAvail) {
            loginPage = true
            setShowPassword(true)
            SetValidate(false)
        }
        //if session is avaialble set the config(done) & device(todo) for the user
        else {
            loginPage = false
            // SetValidate(true)
            AppData.api.userDeviceSearch.bodyParams = { 'username': username }
            try {
                var resDevices =  await AppData.remoteServer.sendCommand(AppData.api.userDeviceSearch, `Getting device data from remote server failed.`, 1)
                for (const [key, value] of Object.entries(resDevices)) {
                    AppData.device.data.remote = resDevices[key].devices
                }
            }
            catch { }
            if((configJson.WEB_ONLY === 0)) {
                resDevices = await AppData.localServer.sendCommand(AppData.api.userDeviceSearch, `Getting device data from local server failed.`, 1)
                for (const [key, value] of Object.entries(resDevices)) {
                    AppData.device.data.local = resDevices[key].devices
                }
            }
            else{
                AppData.device.data.local = AppData.device.data.remote
            }

            AppData.api.configSearch.bodyParams = { created_by: result[0]._id }
            //loadremoteConfig(result).then((res: any) => {
            if(navigator.onLine){
            try {
                AppData.api.configSearch.bodyParams = { created_by: result[0]._id }
                var res = await AppData.remoteServer.sendCommand(AppData.api.configSearch, `Getting config data from remote server failed.`, 1)
                console.log('remote config', res)
                let actualData1 = res;
                let modified_date: any[] = [];
                actualData1.forEach((value: any) => { return modified_date.push(value['modified_date'].replace('T', ' ').split('.')[0]) })

                let a = []
                let sortedData = []
                for (let i in modified_date) {
                    a.push([i, modified_date[i]])
                }
                a.sort(function (i, j) { return i[1] - j[1]; }).reverse();
                for (let i in a) {
                    sortedData.push(actualData1[a[i][0]])
                }
                 AppData.userConfig.data.remote = sortedData;
            }
            catch{ }}
            console.log('remote', AppData.userConfig.data.remote)

           // loadlocalConfig(result).then((res: any) => {
            if((configJson.WEB_ONLY === 0)) {
                AppData.api.configSearch.bodyParams = { created_by: result[0]._id }
                res = await AppData.localServer.sendCommand(AppData.api.configSearch, `Getting config data from local server failed.`, 1)
                let actualData1 = res;
                let modified_date: any[] = [];
                actualData1.forEach((value: any) => { return modified_date.push(value['modified_date'].replace('T', ' ').split('.')[0]) })

                let a = []
                let sortedData = []
                for (let i in modified_date) {
                    a.push([i, modified_date[i]])
                }
                a.sort(function (i, j) { return i[1] - j[1]; }).reverse();
                for (let i in a) {
                    sortedData.push(actualData1[a[i][0]])
                }
                var recentData = sortedData.slice(0, 2); AppData.userConfig.data.local = sortedData;
                AppData.userConfig.data.recent = recentData; 
            }
            else {
                AppData.userConfig.data.local = AppData.userConfig.data.remote
                AppData.userConfig.data.recent = AppData.userConfig.data.remote.slice(0, 2);
            }
            onSigninSuccess()
           // }).catch((error: any) => { alert(error.message) })
            setsigninUser(username);


            // AppData.userConfig.data.local = await AppData.localServer.sendCommand(AppData.api.configSearch, `Getting config data from local server failed.`, 1)
            // setsigninUser(username)
        }
    }

    //redirect to login page
    if (loginPage) {
        if (!signinSuccess) {

            if (password == '') {
                return 
            }
  
            AppData.User.username = username
            AppData.api.passwordHash.bodyParams = { 'password': password }
            hashedPassword = await AppData.localServer.sendCommand(AppData.api.passwordHash, "Hashing the password failed", 1)
            AppData.api.userLogin.epParams = { 'username': username }
            const qs = require('qs')
            var a = qs.stringify({
                'grant_type': "",
                'username': username,
                'password': hashedPassword,
                'scope': '',
                'client_id': "",
                'client_secret': "",
            })

            try {
                AppData.api.user_login.bodyParams = { a }
                await AppData.remoteServer.sendCommand(AppData.api.user_login, "User Login Failed", 1)
            }
            catch{
                if (ShowPassword) {
                    setvalPassword(true)
                }
                return
            }
            result = await AppData.remoteServer.sendCommand(AppData.api.userSearch, `Getting user data from remote failed`, 1)

            //check if valid session is avaialble on the remote
            for (const [key, value] of Object.entries(result)) {
                if (sessionAvail) {
                    break
                }
                for (const [Skey, Svalue] of Object.entries(result[key].sessions)) {
                    try {
                        if (result[key].sessions[Skey].token) {
                            AppData.api.validateSession.config = { headers: { Authorization: `Bearer ${result[key].sessions[Skey].token}` } }
                            const validate_session = await AppData.localServer.sendCommand(AppData.api.validateSession, `Getting user data from remote`, 1)
                            console.log('hi', validate_session)
                            if (validate_session == 'Valid Session') {
                                AppData.User.username = username
                                AppData.User.session_id = result[key].sessions[Skey].session_id
                                AppData.User.data = result[0]
                                AppData.User.token = `Bearer ${result[key].sessions[Skey].token}`
                                sessionAvail = true
                                await AppData.localServer.setHeader()
                                await AppData.remoteServer.setHeader()
                                AppData.loginScreen = false
                            }
                            else {
                                //delete the session in remote if token is not available for the session
                                AppData.api.deleteSession.bodyParams = { 'session_id': result[key].sessions[Skey].session_id }
                                await AppData.remoteServer.sendCommand(AppData.api.deleteSession, "Removing Invalid Session from remote server failed.", 1)
                            }
                        }
                        else {
                            //delete the session in remote if token is not available for the session
                            AppData.api.deleteSession.bodyParams = { 'session_id': result[key].sessions[Skey].session_id }
                            await AppData.remoteServer.sendCommand(AppData.api.deleteSession, "Removing Invalid Session from remote server failed.", 1)
                        }
                    }
                    catch {
                        //delete the session in both local and remote if token is not avaialble for the session
                        AppData.api.deleteSession.bodyParams = { 'session_id': result[key].sessions[Skey].session_id }
                        await AppData.remoteServer.sendCommand(AppData.api.deleteSession, "Removing Invalid Session from remote server failed.", 1)
                    }
                }
            }
            //if session is not available take to login page else open welcome screen
            if (!sessionAvail) {
                loginPage = true
                SetValidate(false)
            }
            else {
                loginPage = true
                signinSuccess = true
                SetValidate(false)
            }

        }

        //if login was not successfull, throw error
        if (!signinSuccess) {
            if (!noInternet) {
                setPassword(true)
                setalert('Credentials Invalid! Login Failed..')
            }
            else {
                setPassword(true)
                setalert('No Internet! Login Failed..')
            }
        }
        //if login was successfull, sync the user to local and open welcome screen
        else {
            if((configJson.WEB_ONLY === 0)) {
                AppData.api.userDownload.bodyParams = { _id: result[0]._id }
                _resultdata = await AppData.remoteServer.sendCommand(AppData.api.userDownload, "Getting User details from remote failed.", 1)

                AppData.api.userUpload.bodyParams = { _result: _resultdata }
                await AppData.localServer.sendCommand(AppData.api.userUpload, "Uploading User details to local server failed.", 1)
            }    
            AppData.User.username = username
            AppData.User.data = result[0]
            AppData.api.userDeviceSearch.bodyParams = { 'username': username }
            try {
                resDevices =  await AppData.remoteServer.sendCommand(AppData.api.userDeviceSearch, `Getting device data from remote server failed.`, 1)
                for (const [key, value] of Object.entries(resDevices)) {
                    AppData.device.data.remote = resDevices[key].devices
                }
            }
            catch { }
            if((configJson.WEB_ONLY === 0)) {
                resDevices = await AppData.localServer.sendCommand(AppData.api.userDeviceSearch, `Getting device data from local server failed.`, 1)
                for (const [key, value] of Object.entries(resDevices)) {
                    AppData.device.data.local = resDevices[key].devices
                }
            }
            else{
                AppData.device.data.local = AppData.device.data.remote
            }

            setsigninUser(username)
            //loadremoteConfig(result).then((res: any) => {
            try {
                AppData.api.configSearch.bodyParams = { created_by: result[0]._id }
                res = await AppData.remoteServer.sendCommand(AppData.api.configSearch, `Getting config data from remote server failed.`, 1)
                console.log('remote config', res)
                let actualData1 = res;
                let modified_date: any[] = [];
                actualData1.forEach((value: any) => { return modified_date.push(value['modified_date'].replace('T', ' ').split('.')[0]) })

                let a = []
                let sortedData = []
                for (let i in modified_date) {
                    a.push([i, modified_date[i]])
                }
                a.sort(function (i, j) { return i[1] - j[1]; }).reverse();
                for (let i in a) {
                    sortedData.push(actualData1[a[i][0]])
                }
                AppData.userConfig.data.remote = sortedData;
            }
            catch{ }
            //}).catch((error: any) => { alert(error.message) })

            //loadlocalConfig(result).then((res: any) => {
                if((configJson.WEB_ONLY === 0)) {
                    AppData.api.configSearch.bodyParams = { created_by: result[0]._id }
                    res = await AppData.localServer.sendCommand(AppData.api.configSearch, `Getting config data from local server failed.`, 1)
                    let actualData1 = res;
                    let modified_date: any[] = [];
                    actualData1.forEach((value: any) => { return modified_date.push(value['modified_date'].replace('T', ' ').split('.')[0]) })

                    let a = []
                    let sortedData = []
                    for (let i in modified_date) {
                        a.push([i, modified_date[i]])
                    }
                    a.sort(function (i, j) { return i[1] - j[1]; }).reverse();
                    for (let i in a) {
                        sortedData.push(actualData1[a[i][0]])
                    }
                    var recentData = sortedData.slice(0, 2); AppData.userConfig.data.local = sortedData;
                    AppData.userConfig.data.recent = recentData; 
                }
                else {
                    AppData.userConfig.data.local = AppData.userConfig.data.remote
                    AppData.userConfig.data.recent = AppData.userConfig.data.remote.slice(0, 2);
                }
                onSigninSuccess()
           // }).catch((error: any) => { alert(error.message) })

        }
    }
}



export { signinValidate };
