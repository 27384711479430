import { stringify } from 'querystring'
import React from 'react'

export interface PointIF {
    x: number,
    y: number
}
export type ComponentType = "None" | "Dot" | "Resistor" | "Ground" | "PFET" | "Current Source" | "PowerFET" | "DAC" | "Amp" | "Opamp" | "NFET" | "MEMS" | "Driver" | "Rect_0_0_0_0" | "Rect_4_4_0_0" | "Rect_0_4_0_0" | "Rect_0_0_1_0" | "Rect_1_1_0_0" | "Rect_1_0_1_0" | "Rect_2_0_0_0" | "Rect_1_0_4_0" | "Rect_3_0_0_1" | "Rect_1_1_1_3" | "Rect_0_1_0_1" | "Rect_2_0_1_0" | "Rect_1_1_1_1" | "Rect_0_2_0_4" | "Rect_0_2_1_6" | "Rect_0_0_0_1" | "Rect_2_1_2_2" |
    "Rect_1_0_1_3" | "Rect_1_1_2_2" | "TempFlat" | "Rect_0_1_1_0" | "Pin" | "SingleDot"
export interface SizeIF {
    width: any,
    height: number
}

interface ComponentDetails {
    size: SizeIF,
    center: PointIF
}
// interface ComponentsDetails {
//     size: SizeIF,
//     center: PointIF,
//     ports: any,
// }
interface PropsIF {
    boundaryStyle: string,
    symbolStyle: string
}

export interface PortDataIF {
    position: "left" | "bottom" | "right" | "top",
    offset: number
}
type PortRecord = Record<string, PortDataIF>
type LibraryRecord = Record<string, { details: ComponentDetails, element: (props: PropsIF) => JSX.Element, ports: PortRecord }>

//-------------------
// Resistor
//------------------

const ResistorPorts: PortRecord = {
    "A": { position: "left", offset: 15 },
    "B": { position: "right", offset: 15 }
}

const ResistorDetails: ComponentDetails = {
    size: { height: 30, width: 100 },
    center: { x: 50, y: 50 },
}

const Resistor = (props: PropsIF) => {
    return (
        <g >
            <rect height={ResistorDetails.size.height} width={ResistorDetails.size.width} className={props.boundaryStyle} />
            <g transform="translate(5 5)" >
                <path className={props.symbolStyle} d={"M0 10 L5 10 L10 20 L20 0 L30 20 L40 0 L50 20 L60 0 L70 20 L80 0 L85 10 L90 10"} />
            </g>
        </g>
    )
}

export const ComponentLibrary: LibraryRecord = { "Resistor": { details: ResistorDetails, element: Resistor, ports: ResistorPorts } }

//------------------
// None
//------------------

const NoneDetails: ComponentDetails = {
    size: { height: 30, width: 42 },
    center: { x: 50, y: 50 },
}

const NonePorts: PortRecord = {
}

const None = ({ ...props }: any) =>
    <g >
    </g>
ComponentLibrary["None"] = { details: NoneDetails, element: None, ports: NonePorts };

//------------------
// Dot
//------------------

const DotDetails: ComponentDetails = {
    size: { height: 30, width: 42 },
    center: { x: 50, y: 50 },
}

const DotPorts: PortRecord = {
}

const Dot = ({ ...props }: any) =>
    <g >
        <circle cx="5" cy="5" r="0.5" stroke="black" stroke-width="3" fill="black" />
        <circle cx="15" cy="5" r="0.5" stroke="black" stroke-width="3" fill="black" />
    </g>
ComponentLibrary["Dot"] = { details: DotDetails, element: Dot, ports: DotPorts };

//----------------
//Single dot
//----------------
const SingleDotDetails: ComponentDetails = {
    size: { height: 30, width: 42 },
    center: { x: 50, y: 50 },
}
const DotsinglePorts: PortRecord = {
    'A':{ position: "top", offset: 4.5 }
}

const SingleDot = ({ ...props }: any) =>
    <g >
        <circle cx="5" cy="5" r="0" stroke="black" stroke-width="3" fill="black" />
    </g>
ComponentLibrary["SingleDot"] = { details: SingleDotDetails, element: SingleDot, ports: DotsinglePorts };
//------------------
// Ground
//------------------

const GroundDetails: ComponentDetails = {
    size: { height: 30, width: 42 },
    center: { x: 50, y: 50 },
}

const GroundPorts: PortRecord = {
    "A": { position: "top", offset: 21 },
}

const Ground = ({ ...props }: any) =>
    <g >
        <rect height={GroundDetails.size.height} width={GroundDetails.size.width} className={props.boundaryStyle} />
        <g transform="translate(-29 1)" >
            <path className={props.symbolStyle} d={"M50 0 L50 10 M30 10 L70 10 M40 15 L60 15 M45 20 L55 20"} />
        </g>
    </g>
ComponentLibrary["Ground"] = { details: GroundDetails, element: Ground, ports: GroundPorts };

//------------------
// PFET
//------------------

const PFETDetails: ComponentDetails = {
    size: { height: 50, width: 38 },
    center: { x: 60, y: 60 },
}

const PFETPorts: PortRecord = {
    "A": { position: "top", offset: 5 },
    "B": { position: "right", offset: 31 },
    "C": { position: "bottom", offset: 5 }
}
const PFET = ({ ...props }: any) =>

    <g>
        <rect height={PFETDetails.size.height} width={PFETDetails.size.width} className={props.boundaryStyle} />
        <g transform="translate(-5 -9)" >
            <path className={props.symbolStyle} d={"M10 10 V10 25 M10 25 L20 25 M20 23 L20 27 M10 33 L20 33 M20 31 L20 35 M10 33 V33 53 M10 41 L20 41 M20 39 L20 43 M24 26 V23 41 M25 41 L38 41 M18 30.5 L18 35.5 L14 33 M18 30.5 L14 33 M18 31.5 L14 33 M18 34.5 L14 33 M18 32 L14 33 M18 34 L14 33"} />
            <circle cx="20" cy="33" r="14" className={props.symbolStyle} />
            {props.children}
        </g>
    </g>
ComponentLibrary["PFET"] = { details: PFETDetails, element: PFET, ports: PFETPorts };

//------------------
// CurrentSource
//------------------

const CurrentDetails: ComponentDetails = {
    size: { height: 53, width: 45 },
    center: { x: 50, y: 50 },
}

const CurrentSourcePorts: PortRecord = {
    "A": { position: "top", offset: 19 },
    "B": { position: "bottom", offset: 19 },
}

const CurrentSource = ({ ...props }: any) =>
    <g>
        <rect height={CurrentDetails.size.height} width={CurrentDetails.size.width} className={props.boundaryStyle} />
        <g transform="translate(-10 -6)">
            <path className={props.symbolStyle} d={"M 29 13 L 47 31 L 29 49 L 11 31 L 29 13 L 29 7 M 29 49 L 29 55 M 29 41 L 29 25 L 32 25 L 29 21 L 26 25 L 29 25"} />
            {props.children}
        </g>
    </g>
ComponentLibrary["CurrentSource"] = { details: CurrentDetails, element: CurrentSource, ports: CurrentSourcePorts };

//------------------
// PowerFet
//------------------

const PowerFETDetails: ComponentDetails = {
    size: { height: 50, width: 60 },
    center: { x: 50, y: 50 },
}

const PowerFETPorts: PortRecord = {
    "A": { position: "top", offset: 28 },
    "B": { position: "bottom", offset: 27 },
    "C": { position: "left", offset: 23 },
}

const PowerFET = ({ ...props }: any) =>
    <g>
        <rect height={PowerFETDetails.size.height} width={PowerFETDetails.size.width} className={props.boundaryStyle} />
        <g transform="translate(-7 )">
            <path className={props.symbolStyle} d={"M 8 23 L 16 23 L 16 11 M 16 35 L 16 23 M 20 7 L 20 15 M 20 19 L 20 27 M 20 31 L 20 39 M 20 11 L 34 11 M 22 23 M 22 23 L 28 19 L 28 27 L 22 23 L 20 23 M 28 23 L 34 23 M 20 35 L 46 35 M34 45 L34 23 M20 35 M 46 35 L 46 11 L 30 11 M 35 11 L 35 1 M 41 19 L 50 19 M 46 19 L 50 27 L 42 27 L 46 19"} />
            {props.children}
        </g>
    </g>
ComponentLibrary["PowerFET"] = { details: PowerFETDetails, element: PowerFET, ports: PowerFETPorts };

//------------------
// Rectangle
//------------------

const RectangleDetails: ComponentDetails = {
    size: { height: 70, width: 70 },
    center: { x: 50, y: 50 },
}

const Rect_0_0_0_0_ports: PortRecord = {
}
//neolite blockdiagram
const Rect_4_4_0_0_ports: PortRecord = {
    "TA": { position: "top", offset: 53 },
    "TB": { position: "top", offset: 57 },
    "TC": { position: "top", offset: 61 },
    "TD": { position: "top", offset: 66 },
    "LA": { position: "left", offset: 53.1 },
    "LB": { position: "left", offset: 55.6 },
    "LC": { position: "left", offset: 62.8 },
    "LD": { position: "left", offset: 65.25 },
}

const Rect_0_4_0_0_ports: PortRecord = {
    "TA": { position: "top", offset: 13 },
    "TB": { position: "top", offset: 27 },
    "TC": { position: "top", offset: 41 },
    "TD": { position: "top", offset: 58.5 },
}

const Rect_0_0_1_0_ports: PortRecord = {
    "RA": { position: "right", offset: 35 },
}

const Rect_1_0_1_0_ports: PortRecord = {
    "RA": { position: "right", offset: 35 },
    "LA": { position: "left", offset: 35 },
}
const Rect_1_1_0_0_ports: PortRecord = {
    "TA": { position: "top", offset: 35 },
    "LA": { position: "left", offset: 36 },
}

const Rect_2_0_0_0_ports: PortRecord = {
    "LA": { position: "left", offset: 20 },
    "LB": { position: "left", offset: 50 },
}
const Rect_1_0_4_0_ports: PortRecord = {
    "LA": { position: "left", offset: 35 },
    "RA": { position: "right", offset: 5 },
    "RB": { position: "right", offset: 25 },
    "RC": { position: "right", offset: 45 },
    "RD": { position: "right", offset: 65 },
}
const Rect_3_0_0_1_ports: PortRecord = {
    "LA": { position: "left", offset: 12 },
    "LB": { position: "left", offset: 36 },
    "LC": { position: "left", offset: 63 },
    "BA": { position: "bottom", offset: 35 },
}

//spinelblockdiagram
const Rect_1_1_1_3_ports: PortRecord = {
    "LA": { position: "left", offset: 34.5 },
    "TA": { position: "top", offset: 35.5 },
    "RA": { position: "right", offset: 32 },
    "BA": { position: "bottom", offset: 12 },
    "BB": { position: "bottom", offset: 34.7 },
    "BC": { position: "bottom", offset: 60 },
}
const Rect_0_1_0_1_ports: PortRecord = {
    "TA": { position: "top", offset: 33.7 },
    "BA": { position: "bottom", offset: 36 },
}
const Rect_2_0_1_0_ports: PortRecord = {
    "LA": { position: "left", offset: 12 },
    "LB": { position: "left", offset: 43.5 },
    "RA": { position: "right", offset: 36 },
}
const Rect_1_1_1_1_ports: PortRecord = {
    "LA": { position: "left", offset: 35 },
    "TA": { position: "top", offset: 35 },
    "RA": { position: "right", offset: 35 },
    "BA": { position: "bottom", offset: 35 },
}
const Rect_0_2_0_4_ports: PortRecord = {
    "TA": { position: "top", offset: 26.3 },
    "TB": { position: "top", offset: 59.5 },
    "BA": { position: "bottom", offset: 5 },
    "BB": { position: "bottom", offset: 25 },
    "BC": { position: "bottom", offset: 45 },
    "BD": { position: "bottom", offset: 65 },
}
const Rect_0_2_1_6_ports: PortRecord = {
    "TA": { position: "top", offset: 7 },
    "TB": { position: "top", offset: 61.83 },
    "RA": { position: "right", offset: 25.6 },
    "BA": { position: "bottom", offset: 7 },
    "BB": { position: "bottom", offset: 35 },
    "BC": { position: "bottom", offset: 47 },
    "BD": { position: "bottom", offset: 52.4 },
    "BE": { position: "bottom", offset: 57.9 },
    "BF": { position: "bottom", offset: 63.3 },
}
const Rect_0_0_0_1_ports: PortRecord = {
    "BA": { position: "bottom", offset: 32.6 }
}


//Elite
const Rect_2_1_2_2_ports: PortRecord = {
    "LA": { position: "left", offset: 15 },
    "LB": { position: "left", offset: 55 },
    "TA": { position: "top", offset: 56.13 },
    "RA": { position: "right", offset: 55.9 },
    "RB": { position: "right", offset: 60 },
    "BA": { position: "bottom", offset: 54 },
    "BB": { position: "bottom", offset: 59.9 }
}
const Rect_1_0_1_3_ports: PortRecord = {
    "LA": { position: "left", offset: 34.5 },
    "RA": { position: "right", offset: 32.5 },
    "BA": { position: "bottom", offset: 23 },
    "BB": { position: "bottom", offset: 43 },
    "BC": { position: "bottom", offset: 63 },
}
const Rect_1_1_2_2_ports: PortRecord = {
    "LA": { position: "left", offset: 35 },
    "TA": { position: "top", offset: 33.5 },
    "RA": { position: "right", offset: 17 },
    "RB": { position: "right", offset: 54 },
    "BA": { position: "bottom", offset: 24 },
    "BB": { position: "bottom", offset: 51 },
}
const Rect_0_1_1_0_ports: PortRecord = {
    "TA": { position: "top", offset: 35 },
    "RA": { position: "right", offset: 34 }
}
const RectComp = ({ ...props }: any) =>
    <g>
        <rect height={RectangleDetails.size.height} width={RectangleDetails.size.width} className={props.boundaryStyle} />
        <rect vector-effect="non-scaling-stroke" height={RectangleDetails.size.height} width={RectangleDetails.size.width} className={props.symbolStyle} />
    </g>
//neolite blockdiagram
ComponentLibrary["Rect_0_0_0_0"] = { details: RectangleDetails, element: RectComp, ports: Rect_0_0_0_0_ports };
ComponentLibrary["Rect_4_4_0_0"] = { details: RectangleDetails, element: RectComp, ports: Rect_4_4_0_0_ports };
ComponentLibrary["Rect_0_4_0_0"] = { details: RectangleDetails, element: RectComp, ports: Rect_0_4_0_0_ports };
ComponentLibrary["Rect_0_0_1_0"] = { details: RectangleDetails, element: RectComp, ports: Rect_0_0_1_0_ports };
ComponentLibrary["Rect_1_0_1_0"] = { details: RectangleDetails, element: RectComp, ports: Rect_1_0_1_0_ports };
ComponentLibrary["Rect_2_0_0_0"] = { details: RectangleDetails, element: RectComp, ports: Rect_2_0_0_0_ports };
ComponentLibrary["Rect_1_1_0_0"] = { details: RectangleDetails, element: RectComp, ports: Rect_1_1_0_0_ports };
ComponentLibrary["Rect_1_0_4_0"] = { details: RectangleDetails, element: RectComp, ports: Rect_1_0_4_0_ports };
ComponentLibrary["Rect_3_0_0_1"] = { details: RectangleDetails, element: RectComp, ports: Rect_3_0_0_1_ports };

//spinel block diagram
ComponentLibrary["Rect_1_1_1_3"] = { details: RectangleDetails, element: RectComp, ports: Rect_1_1_1_3_ports };
ComponentLibrary["Rect_0_1_0_1"] = { details: RectangleDetails, element: RectComp, ports: Rect_0_1_0_1_ports };
ComponentLibrary["Rect_2_0_1_0"] = { details: RectangleDetails, element: RectComp, ports: Rect_2_0_1_0_ports };
ComponentLibrary["Rect_1_1_1_1"] = { details: RectangleDetails, element: RectComp, ports: Rect_1_1_1_1_ports };
ComponentLibrary["Rect_0_2_0_4"] = { details: RectangleDetails, element: RectComp, ports: Rect_0_2_0_4_ports };
ComponentLibrary["Rect_0_2_1_6"] = { details: RectangleDetails, element: RectComp, ports: Rect_0_2_1_6_ports };
ComponentLibrary["Rect_0_0_0_1"] = { details: RectangleDetails, element: RectComp, ports: Rect_0_0_0_1_ports };

//Elite
ComponentLibrary["Rect_2_1_2_2"] = { details: RectangleDetails, element: RectComp, ports: Rect_2_1_2_2_ports }
ComponentLibrary["Rect_1_0_1_3"] = { details: RectangleDetails, element: RectComp, ports: Rect_1_0_1_3_ports }
ComponentLibrary["Rect_1_1_2_2"] = { details: RectangleDetails, element: RectComp, ports: Rect_1_1_2_2_ports }

//Neolite Functional Block diagram
ComponentLibrary["Rect_0_1_1_0"] = { details: RectangleDetails, element: RectComp, ports: Rect_0_1_1_0_ports }

//------------------
// DAC
//------------------

const DACDetails: ComponentDetails = {
    size: { height: 42, width: 65 },
    center: { x: 50, y: 50 },
}

const DACPorts: PortRecord = {
    "A": { position: "right", offset: 20 },
}

const DAC = ({ ...props }: any) =>
    <g >
        <rect height={DACDetails.size.height} width={DACDetails.size.width} className={props.boundaryStyle} />
        <g transform="translate(-9 -9 )" >
            <path className={props.symbolStyle} d="M10 10 L50 10 L70 30 L50 50 L10 50 L10 10" />
            {props.children}
        </g>
    </g>
ComponentLibrary["DAC"] = { details: DACDetails, element: DAC, ports: DACPorts };

//------------------
// AMP
//------------------
const TempFlat = ({ ...props }: any) =>
    <svg width="200px" height="200px" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 20 70 70" stroke-width="3" stroke="black" transform="rotate(-10 50 100) skewX(60) translate(-36 45.5) scale(1 0.5)" />
        <path d="M70 20 20 70" stroke-width="3" stroke="black" transform="rotate(-10 30 100) skewX(60) translate(-36 45.5) scale(1 0.5)" />
        <circle cx="20" cy="20" r="10" fill="black" transform="rotate(-10 50 100) skewX(60) translate(-36 45.5) scale(1 0.5)" />
        <circle cx="70" cy="70" r="10" fill="black" transform="rotate(-10 50 100) skewX(60) translate(-36 45.5) scale(1 0.5)" />
        <circle cx="70" cy="20" r="10" fill="black" transform="rotate(-10 50 100) skewX(60) translate(-36 45.5) scale(1 0.5)" />
        <circle cx="20" cy="70" r="10" fill="black" transform="rotate(-10 50 100) skewX(60) translate(-36 45.5) scale(1 0.5)" />
    </svg>
const TempDetails: ComponentDetails = {
    size: { height: 50, width: 50 },
    center: { x: 50, y: 50 }
}
const TempPorts: PortRecord = {

}
ComponentLibrary["TempFlat"] = { details: TempDetails, element: TempFlat, ports: TempPorts };
const AmpDetails: ComponentDetails = {
    size: { height: 42, width: 32 },
    center: { x: 50, y: 50 },
}

const AmpPorts: PortRecord = {
    "A": { position: "right", offset: 20, },
}
const Amp = ({ ...props }: any) =>
    <g >
        <rect height={AmpDetails.size.height} width={AmpDetails.size.width} className={props.boundaryStyle} />
        <g transform="translate(-9 -9 )">
            <path className={props.symbolStyle} d="M10 10 L10 50 L36 30 L10 10 Z" />
        </g>
    </g>
ComponentLibrary["Amp"] = { details: AmpDetails, element: Amp, ports: AmpPorts };

//------------------
// Opamp
//------------------

const OpampDetails: ComponentDetails = {
    size: { height: 42, width: 47 },
    center: { x: 50, y: 50 },
}

const OpampPorts: PortRecord = {
    "A": { position: "left", offset: 20 },
    "B": { position: "right", offset: 29 },
    "C": { position: "right", offset: 15 },
}

const Opamp = ({ ...props }: any) =>
    <g >
        <rect height={OpampDetails.size.height} width={OpampDetails.size.width} className={props.boundaryStyle} />
        <g transform="translate(-9 -9 )" >
            <path className={props.symbolStyle} d={"M50 10 L10 30 L50 50 L50 10 z"} />
            <path className={props.symbolStyle} d={"M42 20 L42 28 M38 24 L46 24"} />
            <path className={props.symbolStyle} d={"M38 37 L46 37"} />
            {props.children}
        </g>
    </g>
ComponentLibrary["Opamp"] = { details: OpampDetails, element: Opamp, ports: OpampPorts };

//------------------
// NFET
//------------------

const NFETDetails: ComponentDetails = {
    size: { height: 50, width: 38 },
    center: { x: 50, y: 50 },
}

const NFETPorts: PortRecord = {
    "A": { position: "top", offset: 5 },
    "B": { position: "right", offset: 32 },
    "C": { position: "bottom", offset: 5 },
}

const NFET = ({ ...props }: any) =>
    <g>
        <rect height={NFETDetails.size.height} width={NFETDetails.size.width} className={props.boundaryStyle} />
        <g transform="translate(-5 -9)">
            <path className={props.symbolStyle} d={"M10 10 V10 25 M10 25 L20 25 M20 23 L20 27 M10 33 L20 33 M20 31 L20 35 M10 33 V33 53 M10 41 L20 41 M20 39 L20 43 M24 26 V23 41 M25 41 L38 41    M14 30.5 L14 35.5 L18 33 M14 30.5 L18 33 M14 31.5 L18 33 M14 34.5 L18 33 M14 32 L18 33 M14 34 L18 33"} />
            <circle cx="20" cy="33" r="14" className={props.symbolStyle} />
            {props.children}
        </g>
    </g>
ComponentLibrary["NFET"] = { details: NFETDetails, element: NFET, ports: NFETPorts };

//------------------
// MEMS
//------------------

const MEMSDetails: ComponentDetails = {
    size: { height: 65, width: 75 },
    center: { x: 50, y: 50 },
}

const MEMSPorts: PortRecord = {
    "A": { position: "top", offset: 30 },
    "B": { position: "right", offset: 30 },
    "C": { position: "bottom", offset: 30 },
}
const MEMS = ({ ...props }: any) =>
    <g>
        <rect height={MEMSDetails.size.height} width={MEMSDetails.size.width} className={props.boundaryStyle} />
        <g transform="translate(-10 -5)">
            <path className={props.symbolStyle} d="M 11 26 L 11 46 L 69 46 L 69 26 L 11 26 M 69 36 L 81 36 M 11 19 L 69 19 M 40 19 L 40 7 M 11 53 L 69 53 M 40 53 L 40 65" />
            {props.children}
        </g>
    </g>
ComponentLibrary["MEMS"] = { details: MEMSDetails, element: MEMS, ports: MEMSPorts };

//------------------
// Driver
//------------------

const DriverDetails: ComponentDetails = {

    size: { height: 42, width: 38 },
    center: { x: 50, y: 50 },
}

const DriverPorts: PortRecord = {
    "A": { position: "right", offset: 15 },
    "B": { position: "left", offset: 23 },
    "C": { position: "right", offset: 32 },
}

const Driver = ({ ...props }: any) =>

    <g >
        <rect height={DriverDetails.size.height} width={DriverDetails.size.width} className={props.boundaryStyle} />
        <g transform="translate(-7.5 -9 )">
            <path className={props.symbolStyle} d="M10 10 L10 50 L36 30 L10 10 Z" />
            <path className={props.symbolStyle} d="M 29 24 l 14 0" />
            {/* <path className={props.symbolStyle} d="M 0 29 l 10 0" /> */}
            <circle cx="28" cy="41" r="3" className={props.symbolStyle} />
            <path className={props.symbolStyle} d="M 31 41 l 14 0" />
        </g>
    </g>
ComponentLibrary["Driver"] = { details: DriverDetails, element: Driver, ports: DriverPorts };
const PinDetails: ComponentDetails = {

    size: { height: 7, width: 7 },
    center: { x: 50, y: 50 },
}
const PinPorts: PortRecord = {
}
const Pin = ({ ...props }: any) =>
    <g>
        <rect height={PinDetails.size.height} width={PinDetails.size.width} className={props.boundaryStyle} />
        <g transform="translate(-24.5 -37.5 )">
            <circle cx="28" cy="41" r="2" className={props.symbolStyle} />
        </g>
    </g>
ComponentLibrary["Pin"] = { details: PinDetails, element: Pin, ports: PinPorts }
export default ComponentLibrary;