import { PopUpFormThemeIF } from "../components/popupForm/popupFormThemeIF"

const PopUpFormThemes:PopUpFormThemeIF  = {

    shape: {
        hug: {width:"auto",height:"auto",radius:"0px"},
        normal:{width:"300px",height:"200px",radius:"0px"},
        popupform1:{width:"403px",height:"100%",radius:"0px"},
        customerpopup:{width:"403px",height:"1800px",radius:"0px"},
    },
    spacing: {
        none: { gap: "0px",padding:'0px' },
        normal: { gap: "10px" ,padding:'0px'},
        popupform1: { gap: "20px" ,padding:'0px 20px 0px 20px'}

    },
    stroke: {
        none: "none",
        normal: "1px solid #067D6F",
        popupform:"1px solid black"
    },
    fill: {
        none: "none",
        popupform:"white",
        popupform1:'#F0EFEF'
    },
    effects: {
        none: "none"
    }
}

export default PopUpFormThemes