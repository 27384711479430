import React  from 'react'
import ComponentLibrary, { ComponentType, PointIF, PortDataIF, SizeIF } from "./componentLibrary"
import  Port  from './port'

interface PropsIF {

    //Essential
    type: ComponentType,
    id:string,
    compStyle: string,
    position: PointIF
    scale: number|SizeIF,
    rotation:number,
    nonActiveStyle: string

    // Boundary
    boundaryStyle: string,

    // Label
    label: string,
    labelStyle: string,
    label_offset: PointIF,
    labelRotation: number,

    // Port
    portStyle: string,
    port_size: number,

    onClick:()=>void,

    // Function
    functions?: string[]

}

const getPorts = (ports: Record<string, PortDataIF>, compType:ComponentType, port_size:number, portStyle:string) => { 
    const AllPorts:any = []
    for (let aPort in ports) {AllPorts.push(<Port component={compType} port={aPort} port_size={port_size} portStyle={portStyle} />)} 
    return AllPorts  
}
export default function Component(props:PropsIF) {
    const Icon = ComponentLibrary[props.type].element
    const _details = ComponentLibrary[props.type].details
    const _ports = ComponentLibrary[props.type].ports
    const thisClick = props.onClick ? (evt:any)=>{(props.onClick as ((id:string)=>void)) (evt.target.id)} : undefined
    if (typeof props.scale === "number") {
        return(
            <svg x = {props.position.x} y = {props.position.y} >
                <text className={props.labelStyle} x={ props.label_offset.x} y={props.label_offset.y} transform={`rotate(${props.labelRotation}  ${props.label_offset.x} ${props.label_offset.y})`}> 
                    {props.label == "id" ? props.id : props.label}
                </text>
                <g onClick={thisClick} transform={`scale(${props.scale}) rotate(${props.rotation} ${_details.center.x} ${_details.center.y}) translate(${_details.center.x-_details.size.width/2} ${_details.center.y - _details.size.height/2})`}   >
                    <Icon  symbolStyle={props.compStyle} boundaryStyle={props.boundaryStyle} />
                    { getPorts(_ports, props.type,props.port_size, props.portStyle ) }
                </g>
            </svg> 
        )
      }      
    else {
        return(
            <svg x = {props.position.x} y = {props.position.y} >
                <text className={props.labelStyle} x={ props.label_offset.x} y={props.label_offset.y} transform={`rotate(${props.labelRotation}  ${props.label_offset.x} ${props.label_offset.y})`}> 
                    {props.label == "id" ? props.id : props.label}
                </text>
                <g onClick={thisClick} transform={`scale(${props.scale.width},${props.scale.height}) rotate(${props.rotation} ${_details.center.x} ${_details.center.y}) translate(${_details.center.x-_details.size.width/2} ${_details.center.y - _details.size.height/2})`}   >
                    <Icon  symbolStyle={props.compStyle} boundaryStyle={props.boundaryStyle} />
                    { getPorts(_ports, props.type,props.port_size, props.portStyle ) }
                </g>
            </svg> 
        )
      }
    
}




