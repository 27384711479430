import React, { useCallback, useEffect, useRef, useState } from "react";
import Icons from "../../../icons/icons";
import GridContainer from "../../../components/gridContainer/gridContainer";
import FlexBox from "../../../components/flexBox/flexBox";
import { Button } from "../../../components/button/button";
import AppData from '../../../appData'
import { changeFunctionState, changeRegister, getBlockFields, getBlockFunctions, getDevicePropertiesByName, getFunctionState, percentReset, percentSpread, processComponentFUnctions, processFunction, processTypeFunction, realizeFrequencies, resetFunctions } from "./blockDiagramEvents";
import Blockdiagram from "../../../components/blockDiagram/blockDiagram";
import FunctionalForm from "./functionalForm";
import '../../../appStyle.css'
import SummaryView from "./summaryView";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import PopUpForm from "../../../components/popupForm/popupForm";
import './writeDevice.json'

const getFormElement = (_elemData: { param_name: string, param_type: string, inputs: string[] }, name: string, function_state: string, help_text: string, validationerror?: string, error?: boolean) => {
    //generate form elements for functional form
    const baseObj = { id: name, name: _elemData.param_name, label: _elemData.param_name }
    var disableComp
    if ((AppData.currentConfig.frozen)) {
        disableComp = true
        AppData.disableDiff = true
        AppData.disableLVCMOS = true
    }
    else if (AppData.server == 'remote') {
        disableComp = true
        AppData.disableDiff = true
        AppData.disableLVCMOS = true
    }
    else {
        if (_elemData.param_name === 'Mode') {
            AppData.mode = function_state
            disableComp = false
        }
        else if (AppData.mode === 'Enabled') {
            disableComp = false
        }
        else {
            disableComp = true
            AppData.disableDiff = true
            AppData.disableLVCMOS = true
        }

    }
    if (_elemData.param_type == "LIST" || _elemData.param_type == "FORMULA") {
        let items: string[]
        var shape
        if (AppData.mode == 'Enabled') {
            if (_elemData.param_name === 'VDDO') {
                let val = parseFloat(function_state)
                AppData.vddVal = val
            }
            if (_elemData.param_name === 'Reference Voltage(VoH/VoL)') {
                AppData.refId = function_state
            }
            if (_elemData.param_name === 'Signaling') {
                AppData.signalingId = name
            }
            if (AppData.type === 'Differential') {
                if (_elemData.param_name === 'Signaling') {
                    if (function_state == null) {
                        function_state = ''
                    }
                    AppData.signal = function_state
                }
                if (!AppData.currentConfig.frozen) {
                    if (_elemData.param_name === 'VOH') {
                        AppData.vohList = _elemData.inputs
                        if (AppData.signal != 'FlexSwing') {
                            disableComp = true
                        }
                        else {
                            disableComp = false
                            if (AppData.refId == 'GND') {
                                let baseVal = parseFloat(AppData.volData) + 0.3
                                var filteredValues: any = []
                                _elemData.inputs.forEach(val => { (parseFloat(val) > baseVal) && filteredValues.push(val) })
                                _elemData.inputs = filteredValues
                                { (parseFloat(function_state) >= baseVal) ? console.log(function_state) : function_state = _elemData.inputs[0]; if (function_state != null) changeFunctionState(name, function_state, 'VOH', '', AppData.server) }
                            } else {
                                let baseVal = parseFloat(_elemData.inputs[_elemData.inputs.length - 1]) + 0.3
                                var filteredValues: any = []
                                _elemData.inputs.forEach(val => { (parseFloat(val) > baseVal) && filteredValues.push(val) })
                                _elemData.inputs = filteredValues
                            }
                        }
                    }
                    else if (_elemData.param_name === 'VOL') {
                        AppData.volList = _elemData.inputs
                        if (AppData.signal != 'FlexSwing') {
                            disableComp = true
                        }
                        else {
                            disableComp = false
                            if (AppData.refId == 'GND') {
                                let baseVal = parseFloat(_elemData.inputs[_elemData.inputs.length - 1]) - 0.3
                                var filteredValues: any = []
                                _elemData.inputs.forEach(val => { (parseFloat(val) < baseVal) && filteredValues.push(val) })
                                _elemData.inputs = filteredValues
                            } else {
                                let baseVal = parseFloat(AppData.vohData) - 0.3
                                var filteredValues: any = []
                                _elemData.inputs.forEach(val => { (parseFloat(val) < baseVal) && filteredValues.push(val) })
                                _elemData.inputs = filteredValues
                                { (parseFloat(function_state) <= baseVal) ? console.log(function_state) : function_state = _elemData.inputs[0]; if (function_state != null) changeFunctionState(name, function_state, 'VOL', '', AppData.server) }
                            }
                        }
                    }
                    else if (_elemData.param_name === 'Reference Voltage(VoH/VoL)') {
                        if (AppData.signal != 'FlexSwing') {
                            disableComp = true
                        }
                        else disableComp = false
                    }
                }
            }
        }
        var shape
        var search = false
        if ((_elemData.param_name === 'Mode') || (_elemData.param_name === 'Frequency(MHz)') || (_elemData.param_name === "PPM") || (_elemData.param_name === 'VDDO') || (_elemData.param_name === 'VDD') || (_elemData.param_name === 'VDDA')) {
            shape = 'mode'
        }
        else if ((_elemData.param_name === 'Delay CLK0P') || (_elemData.param_name === 'Delay CLK1P') || (_elemData.param_name === 'Delay CLK2P') || (_elemData.param_name === 'Delay CLK3P')) {
            shape = 'mode'
        }
        else if ((_elemData.param_name === 'Delay CLK0N') || (_elemData.param_name === 'Delay CLK1N') || (_elemData.param_name === 'Delay CLK2N') || (_elemData.param_name === 'Delay CLK3N')) {
            shape = 'mode'
        }
        else if ((_elemData.param_name === 'VOH') || (_elemData.param_name === 'VOL')) {
            shape = 'mode'
            search = true
            if (!AppData.currentConfig.frozen) {
                if ((_elemData.param_name === 'VOH')) {
                    AppData.vohList = _elemData.inputs
                    if (AppData.refId == 'GND') {
                        let baseVal = parseFloat(AppData.volData) + 0.3
                        var filteredValues: any = []
                        _elemData.inputs.forEach(val => { (parseFloat(val) > baseVal) && filteredValues.push(val) })
                        _elemData.inputs = filteredValues
                        { (parseFloat(function_state) >= baseVal) ? console.log(function_state) : function_state = _elemData.inputs[0]; if (function_state != null) changeFunctionState(name, function_state, 'VOH', '', AppData.server) }
                    } else {
                        let baseVal = parseFloat(_elemData.inputs[_elemData.inputs.length - 1]) + 0.3
                        var filteredValues: any = []
                        _elemData.inputs.forEach(val => { (parseFloat(val) > baseVal) && filteredValues.push(val) })
                        _elemData.inputs = filteredValues
                    }
                }

                if ((_elemData.param_name === 'VOL')) {
                    AppData.volList = _elemData.inputs
                    if (AppData.refId == 'GND') {
                        let baseVal = parseFloat(_elemData.inputs[_elemData.inputs.length - 1]) - 0.3
                        var filteredValues: any = []
                        _elemData.inputs.forEach(val => { (parseFloat(val) < baseVal) && filteredValues.push(val) })
                        _elemData.inputs = filteredValues
                    } else {
                        let baseVal = parseFloat(AppData.vohData) - 0.3
                        var filteredValues: any = []
                        _elemData.inputs.forEach(val => { (parseFloat(val) < baseVal) && filteredValues.push(val) })
                        _elemData.inputs = filteredValues
                        { (parseFloat(function_state) <= baseVal) ? console.log(function_state) : function_state = _elemData.inputs[0]; if (function_state != null) changeFunctionState(name, function_state, 'VOL', '', AppData.server) }
                    }
                }
            }
        }
        else if ((name === 'OUT0SlewRate_CLK_P') || (name === 'OUT1SlewRate_CLK_P') || (name === 'OUT2SlewRate_CLK_P') || (name === 'OUT3SlewRate_CLK_P')) {
            shape = 'mode'
            search = true
            if ((AppData.currentConfig.frozen)) {
                disableComp = true
            }
            else if ((AppData.mode == 'Enabled') && (AppData.server != 'remote')) {
                if (AppData.checkoutp === 'True') {
                    disableComp = false
                }
                else if (AppData.checkoutp === 'False') {
                    disableComp = true
                }
            }
        }
        else if ((name === 'OUT0SlewRate_CLK_N') || (name === 'OUT1SlewRate_CLK_N') || (name === 'OUT2SlewRate_CLK_N') || (name === 'OUT3SlewRate_CLK_N')) {
            shape = 'mode'
            search = true
            if ((AppData.currentConfig.frozen)) {
                disableComp = true
            }
            else if ((AppData.mode == 'Enabled') && (AppData.server != 'remote')) {
                if (AppData.checkoutn === 'True') {
                    disableComp = false
                }
                else if (AppData.checkoutn === 'False') {
                    disableComp = true
                }
            }
        }
        else {
            shape = 'voh'
        }
        if (_elemData.param_name === 'PPM') {
            disableComp = true
        }
        return (
            { type: "select", ...baseObj, tooltip: help_text, search: search, help: help_text, tooltipPosition: 'right', content: function_state, enableFilter: false, contentItems: _elemData.inputs, disable: disableComp, shape: shape })
    }
    else if (_elemData.param_type == "NUMBER") {
        AppData.Frequency = function_state != null ? function_state.toString() : ''
        return ({ type: "text", ...baseObj, tooltip: help_text, content: AppData.Frequency, disable: disableComp, errorMessage: validationerror, help: help_text, tooltipPosition: 'right', error: error })
    }
    else if (_elemData.param_type == "CHECKBOX") {
        let items = _elemData.inputs.map((_item: any) => { return { name: _item, key: _item } })
        let state
        if (_elemData.param_name === 'CLK_P') {
            AppData.checkClkPId = name
            if (function_state === 'True') {
                AppData.checkoutp = 'True'
                state = true
            }
            else {
                AppData.checkoutp = 'False'
                state = false
            }
        }
        if (_elemData.param_name === 'CLK_N') {
            AppData.checkClkNId = name
            if (function_state === 'True') {
                AppData.checkoutn = 'True'
                state = true
            }
            else {
                AppData.checkoutn = 'False'
                state = false
            }
        }
        return ({ type: "check", ...baseObj, tooltip: help_text, labelvalues: _elemData.param_name, help: help_text, tooltipPosition: 'right', checked: function_state, disable: disableComp, initial: state, items: items })
    }
    else if (_elemData.param_type == "SELECTBUTTON") {
        AppData.type = function_state
        return ({ type: "selectbutton", tooltip: help_text, ...baseObj, values: _elemData.inputs, initial: function_state, disable: disableComp })
    }
    else if (_elemData.param_type == "BOOLEAN") {
        let items = _elemData.inputs.map((_item: any) => { return { name: _item, key: _item } })
        if ((AppData.currentConfig.frozen)) {
            disableComp = true
        }
        else {
            if ((AppData.mode == 'Enabled') && (AppData.server != 'remote')) {
                if (_elemData.param_name === 'CLK_P') {
                    AppData.radioClkPId = name
                    if (AppData.checkoutp === 'True') {
                        disableComp = false
                    }
                    else if (AppData.checkoutp === 'False') {
                        disableComp = true
                    }
                }
                if (_elemData.param_name === 'CLK_N') {
                    AppData.radioClkNId = name
                    if (AppData.checkoutn === 'True') {
                        disableComp = false
                    }
                    else if (AppData.checkoutn === 'False') {
                        disableComp = true
                    }
                }
            }
        }
        return ({ type: "radio", ...baseObj, tooltip: help_text, items, disable: disableComp, tooltipPosition: 'right', help: help_text, initial: function_state })

    }
}

function containsNumbers(str: string) {
    return /\d/.test(str);
}

const FrequencyCalculation = (input: string, setalert: any) => {
    //Calculate frequency entered in functional view
    if (input == '') {
        input = '0'
    }
    //input string
    var validationmsg = ''
    var lowFrequency
    var highFrequency
    //Valid units
    const list = ['Hz', 'MHz', 'kHz', 'M', 'GHz', 'k', 'G', 'e6', 'e3', 'e9']
    var final: any
    if (list.some(v => input.includes(v))) {
        let result = input.split('MHz').join('*10**6')
        result = result.split('M').join('*10**6')
        result = result.split('kHz').join('*10**3')
        result = result.split('k').join('*10**3')
        result = result.split('GHz').join('*10**9')
        result = result.split('G').join('*10**9')
        result = result.split('e6').join('*10**6')
        result = result.split('e3').join('*10**3')
        result = result.split('e9').join('*10**9')
        final = result.split('Hz').join('*1')
    }
    else {
        try {
            if (!containsNumbers(input)) {
                validationmsg = 'Enter a valid value'
                let answer = ''
                return { validationmsg, answer }
            }
            let newValue = eval(input)
            final = newValue + '*10**6'
        }
        catch (e: any) {
            validationmsg = 'Invalid Input.';
            return { validationmsg }
        }
    }
    // Check if the unit inputted is in the list
    try {
        if (final.length === 0) {
            final = "0"
        }
        let answer = eval(final)
        const checkValue = (answer/(10**6)).toString();
        if (AppData.deviceFrequenciesData.length != 0 && !AppData.deviceFrequenciesData.includes(checkValue)) {
            validationmsg = 'Enter a valid value'
            let answer = ''
            return { validationmsg, answer }
        }
        if (((answer >= AppData.freqList[0]) && (answer <= AppData.freqList[1])) || answer == 0) {
            validationmsg = ''
            return { validationmsg, answer }
        }
        else {
            lowFrequency = AppData.freqList[0] / 1000000
            highFrequency = AppData.freqList[1] / 1000000
            validationmsg = `Enter value between ${lowFrequency} MHz and ${highFrequency} MHz`
            return { validationmsg, answer }
        }
    }
    catch (e: any) {
        validationmsg = 'Invalid Input.';
        return { validationmsg }
    }

}

export function formDataInitializeVOHVOL() {
    //Calculate and Initialize VOH and VOL values
    console.log('Functions', AppData.functions)
    if (AppData.functions.length > 0) {
        AppData.functions.map((data: any) => {
            if (data.parameters) {
                if (data.parameters[0].param_name === 'Reference Voltage(VoH/VoL)') {
                    AppData.refId = data.function_state
                    AppData.refFunction = data.name
                }
                else if (data.parameters[0].param_name === 'VOL') {
                    AppData.volid = data.name
                    AppData.volData = data.function_state
                }
                else if ((data.parameters[0].param_name === 'VOH')) {
                    AppData.vohData = data.function_state
                    AppData.vohid = data.name
                }
                else if (data.parameters[0].param_name === 'Signaling') {
                    AppData.signal = data.function_state
                }
                else if (data.parameters[0].param_name === 'Frequency(MHz)') {
                    AppData.freqList = data.parameters[0].inputs
                }
            }
        })
    }
}

export function formData(freqvalidation?: string, error?: boolean) {
    //Split the functions data from dB and add it to AppData in appropriate form input for functional form
    AppData.functionFormData = {
        "param": [],
        "commonfunctions": [],
        "delay": [],
        "type": [],
        "differential": [],
        "single": [],
        "op": [],
        "singlecommon": [],
        "singlevoh": [],
        "diffvoh": [],
        "slew": [],
        "diffref": [],
        "freq": [],
        "vdd": []
    }
    AppData.disableDiff = false
    AppData.disableLVCMOS = false
    console.log('Functions', AppData.functions)
    if (AppData.functions.length > 0) {
        AppData.functions.map((data: any) => {
            if (data.parameters) {
                if ((data.parameters[0].param_name === 'Mode') || (data.parameters[0].param_name === 'VDDO')) {
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text)
                    AppData.functionFormData.param.push(formDatas)
                }
                else if (data.parameters[0].param_name === 'Frequency(MHz)') {
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text, freqvalidation, error)
                    AppData.functionFormData.freq[0] = formDatas
                }
                else if (data.parameters[0].param_name === 'PPM') {
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text, freqvalidation, error)
                    AppData.functionFormData.freq[1] = formDatas
                }
                else if (data.parameters[0].param_name === 'Delay Common Mode') {
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text)
                    AppData.functionFormData.commonfunctions[1] = formDatas
                }
                else if ((data.parameters[0].param_name === 'Delay CLK0P') || (data.parameters[0].param_name === 'Delay CLK1P') || (data.parameters[0].param_name === 'Delay CLK2P') || (data.parameters[0].param_name === 'Delay CLK3P')) {
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text)
                    AppData.functionFormData.delay[0] = formDatas
                }
                else if ((data.parameters[0].param_name === 'Delay CLK0N') || (data.parameters[0].param_name === 'Delay CLK1N') || (data.parameters[0].param_name === 'Delay CLK2N') || (data.parameters[0].param_name === 'Delay CLK3N')) {
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text)
                    AppData.functionFormData.delay[1] = formDatas
                }
                else if (data.parameters[0].param_name === 'Hi-Z State') {
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text)
                    AppData.functionFormData.commonfunctions[0] = formDatas
                }
                else if ((data.parameters[0].param_name === 'Type')) {
                    AppData.type = data.function_state
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text)
                    AppData.functionFormData.type[1] = formDatas
                }
                else if (data.parameters[0].param_name === 'Signaling') {
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text)
                    AppData.functionFormData.differential[0] = formDatas
                }
                else if (data.parameters[0].param_name === 'Reference Voltage(VoH/VoL)') {
                    AppData.refFunction = data.name
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text)
                    AppData.functionFormData.diffref[0] = formDatas
                    AppData.functionFormData.singlecommon[0] = formDatas
                }
                else if (data.parameters[0].param_name === 'VOL') {
                    AppData.volid = data.name
                    AppData.volData = data.function_state
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text)
                    AppData.functionFormData.diffvoh[1] = formDatas
                    AppData.functionFormData.singlevoh[1] = formDatas
                }
                else if ((data.name === 'OUT0SlewRate_CLK_P') || (data.name === 'OUT1SlewRate_CLK_P') || (data.name === 'OUT2SlewRate_CLK_P') || (data.name === 'OUT3SlewRate_CLK_P')) {
                    AppData.slewFunctionCLKP = data.name
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text)
                    AppData.functionFormData.slew[0] = formDatas
                }
                else if ((data.name === 'OUT0SlewRate_CLK_N') || (data.name === 'OUT1SlewRate_CLK_N') || (data.name === 'OUT2SlewRate_CLK_N') || (data.name === 'OUT3SlewRate_CLK_N')) {
                    AppData.slewFunctionCLKN = data.name
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text)
                    AppData.functionFormData.slew[1] = formDatas
                }
                else if ((data.parameters[0].param_type === 'CHECKBOX')) {
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text)
                    AppData.functionFormData.op.push(formDatas)
                }
                else if ((data.parameters[0].param_type === 'BOOLEAN') && (data.parameters[0].param_name === 'CLK_P')) {
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text)
                    AppData.functionFormData.single[0] = formDatas
                }
                else if ((data.parameters[0].param_type === 'BOOLEAN') && (data.parameters[0].param_name === 'CLK_N')) {
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text)
                    AppData.functionFormData.single[1] = formDatas
                }
                else if ((data.parameters[0].param_name === 'VOH')) {
                    var name = data.name
                    AppData.vohData = data.function_state
                    AppData.vohid = data.name
                    let formDatas = getFormElement(data.parameters[0], data.name, data.function_state, data.help_text)
                    if (AppData.type == 'Differential') {
                        AppData.functionFormData.diffvoh[0] = formDatas
                    }
                    else {
                        AppData.functionFormData.singlevoh[0] = formDatas
                    }
                }
            }
        })
    }
}
interface propsIF {
    onDuplicate: any
}
function DetailScreen({onDuplicate=false}:propsIF) {
    const [clickEvent, forceClickEvent] = useState(false)
    const [alert, setalert] = useState('')
    const [form, setForm] = useState(onDuplicate)
    const [loader, setLoader] = useState(false)
    const [analyse, setAnalyse] = useState(false)
    const [render, setRender] = useState(false)
    const [error, setError] = useState('')
    const [ssc, setSSC] = useState(false)
    const style = {
        compStyle: "symbolClass",
        boundaryStyle: "symbolBoundaryclass",
        labelStyle: "pinsLabelClass",
        portStyle: "symbolPortClass",
        lineStyle: "blockDiagramLineClass",
        nonActiveStyle: "nonActiveClass",
        activeStyle: "activeClass",
        nonActiveRecStyle: "nonActiveRecClass",
    }
    var formdata = require('./formData.json')
    function resolveAfter1Seconds() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve('resolved');
            }, 1000);
        });
    }
    console.log('duplli',onDuplicate,form)
    useEffect(() => { 
        setForm(onDuplicate)
    },[onDuplicate])
    async function onClickEdit(label: string) {
        //OnCLick of summaryView edit button takes to the particular component's functional form
        var index = AppData.labelList.indexOf(label)
        AppData.componentName = AppData.componentList[index]
        selectComp(AppData.componentName, label, 'YES', AppData.componentName)
        const result = await resolveAfter1Seconds();
        setForm(true)
    }
    function resolveAfter4Seconds() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve('resolved');
            }, 4000);
        });
    }
    async function summaryView() {
        var index, signalling, clkP, clkN
        var blockFunctions = await getBlockFunctions(AppData.componentList, AppData.server)
        AppData.deviceFrequenciesData = []
        let devicePropertyData = await getDevicePropertiesByName(AppData.server,AppData.currentConfig.variant.toUpperCase() + '_FREQUENCIES')
        if(devicePropertyData.length != 0){
            devicePropertyData[0].inputs.map((data:any)=>{
                AppData.deviceFrequenciesData.push(data.toString())
            })
        }

        AppData.deviceFrequenciesDataDiff = []
        devicePropertyData = await getDevicePropertiesByName(AppData.server,AppData.currentConfig.variant.toUpperCase() + '_FREQUENCIES_DIFF')
        if(devicePropertyData.length != 0){
            devicePropertyData[0].inputs.map((data:any)=>{
                AppData.deviceFrequenciesDataDiff.push(data.toString())
            })
        }

        AppData.deviceFrequenciesDataSE = []
        devicePropertyData = await getDevicePropertiesByName(AppData.server,AppData.currentConfig.variant.toUpperCase() + '_FREQUENCIES_LVCMOS')
        if(devicePropertyData.length != 0){
            devicePropertyData[0].inputs.map((data:any)=>{
                AppData.deviceFrequenciesDataSE.push(data.toString())
            })
        }

        
        AppData.clkPlist = []
        AppData.clkNlist = []
        AppData.clkPDataList = []
        AppData.clkNDataList = []
        blockFunctions.map((val: any) => {
            var functionData: {}[] = []
            try {
                index = AppData.componentList.indexOf(val['Block/component name'])
                for (const [key, value] of Object.entries(val.Functions)) {
                    if (val.Functions[key].name === 'VDD') {
                        AppData.vddList = val.Functions[key].parameters[0].inputs
                        AppData.vddState = val.Functions[key].function_state
                    }
                    if (val.Functions[key].name === 'VDDA') {
                        AppData.vddaList = val.Functions[key].parameters[0].inputs
                        AppData.vddaState = val.Functions[key].function_state
                    }
                    if (val.Functions[key].parameters[0].param_name === 'VDDO') {                        
                        let value = parseFloat(val.Functions[key].function_state)
                        AppData.vddVallist[index] = value
                    }
                    if (val.Functions[key].parameters[0].param_name === 'Reference Voltage(VoH/VoL)') {
                        AppData.refIdList[index] = val.Functions[key].function_state
                    }
                    if (val.Functions[key].parameters[0].param_name == 'CLK_P' &&
                        ((val.Functions[key].name == 'OUT0_OUTP_OUTN1') || (val.Functions[key].name == 'OUT1_OUTP_OUTN1') || (val.Functions[key].name == 'OUT2_OUTP_OUTN1') || (val.Functions[key].name == 'OUT3_OUTP_OUTN1'))) {
                        if (val.Functions[key].function_state == 'True') { AppData.clkPlist[index] = true }
                        else { AppData.clkPlist[index] = '' }
                    }
                    if (val.Functions[key].parameters[0].param_name == 'CLK_N' &&
                        ((val.Functions[key].name == 'OUT0_OUTP_OUTN2') || (val.Functions[key].name == 'OUT1_OUTP_OUTN2') || (val.Functions[key].name == 'OUT2_OUTP_OUTN2') || (val.Functions[key].name == 'OUT3_OUTP_OUTN2'))) {
                        if (val.Functions[key].function_state == 'True') { AppData.clkNlist[index] = true }
                        else { AppData.clkNlist[index] = '' }
                    }
                }

                for (const [key, value] of Object.entries(val.Functions)) {
                    functionData.push({
                        'name': val.Functions[key].name, 'parameters': val.Functions[key].parameters, "function_state": val.Functions[key].function_state
                    })
                    if (val.Functions[key].parameters[0].param_name == 'Signaling') {
                        AppData.signalList[index] = val.Functions[key].function_state
                    }
                    if (val.Functions[key].name === 'PPM') { AppData.ppmValue = val.Functions[key].function_state }
                    if ((val.Functions[key].name == 'OUT0OUTP') || (val.Functions[key].name == 'OUT1OUTP') || (val.Functions[key].name == 'OUT2OUTP') || (val.Functions[key].name == 'OUT3OUTP')) {
                        if (AppData.clkPlist[index]) {
                            AppData.clkPDataList[index] = 'CLK_P (' + val.Functions[key].function_state + ')'
                        }
                        else { AppData.clkPDataList[index] = '' }
                    }
                    if ((val.Functions[key].name == 'OUT0OUTN') || (val.Functions[key].name == 'OUT1OUTN') || (val.Functions[key].name == 'OUT2OUTN') || (val.Functions[key].name == 'OUT3OUTN')) {
                        if (AppData.clkNlist[index]) {
                            AppData.clkNDataList[index] = 'CLK_N (' + val.Functions[key].function_state + ')'
                        }
                        else { AppData.clkNDataList[index] = '' }
                    }
                }
                AppData.compData[index] = functionData
            } catch {
            }
        })
        //const result = await resolveAfter2Seconds();
        var compCount = 0
        AppData.summaryData = []
        AppData.compData.forEach((functionData: any) => {
            console.log('functions', functionData)
            let compFunction: any = {}
            var count = 0
            for (const [key, value] of Object.entries(functionData)) {
                let functionName = functionData[key].name
                let paramName = functionData[key].parameters[0].param_name
                let functionValue = functionData[key].function_state
                if ((paramName == 'Mode') || (paramName == 'VDDO') || (paramName == 'Frequency(MHz)') || (paramName == 'Type') || (paramName == 'Hi-Z State') || (paramName == 'Delay Common Mode')) { compFunction[paramName] = functionValue }
                else if ((paramName == 'Delay CLK1N') || (paramName == 'Delay CLK0N') || (paramName == 'Delay CLK2N') || (paramName == 'Delay CLK3N')) { compFunction[paramName] = functionValue }
                else if ((paramName == 'Delay CLK0P') || (paramName == 'Delay CLK1P') || (paramName == 'Delay CLK2P') || (paramName == 'Delay CLK3P')) { compFunction[paramName] = functionValue }
            }
            AppData.summaryData[compCount] = compFunction
            compCount += 1

        })
    }
    function selectComp(compName: string, label: string, clickable: any, name: any) {
        //OnClick of a component from block diagram 
        if ((clickable != 'NO') && (name != '')) {
            AppData.componentName = compName
            AppData.label = label
            AppData.functionFormData = {
                "param": [],
                "commonfunctions": [],
                "delay": [],
                "type": [],
                "differential": [],
                "single": [],
                "op": [],
                "singlecommon": [],
                "singlevoh": [],
                "diffvoh": [],
                "slew": [],
                "diffref": [],
                "freq": [],
                "vdd": []
            }
            AppData.disableDiff = false
            AppData.disableLVCMOS = false
            var list = [compName]
            var blockFunctions = getBlockFunctions(list, AppData.server)
            let functionData: {}[] = []
            //Gets the respective functions and load the functional form

            blockFunctions.then((data: any) => {
                data.map((val: any) => {
                    try {
                        for (const [key, value] of Object.entries(val.Functions)) {
                            functionData.push({
                                "_id": val.Functions[key]._id, "name": val.Functions[key].name, "device_id": val.Functions[key].device_id,
                                'parameters': val.Functions[key].parameters, "function_state": val.Functions[key].function_state, "help_text": val.Functions[key].help_text
                            })
                        }
                        AppData.functions = functionData
                        formDataInitializeVOHVOL()
                        formData()
                        setForm(true)
                        forceClickEvent(!clickEvent)
                    } catch {
                        AppData.functions = []
                    }
                })
            })
            console.log('function data', AppData.functionFormData)
        }
        else {
            onClickBack()
        }
    }
    async function onItemChange(id: string, value: string, label: string) {
        //On each change in functional form , API call is made to update the function_state
        setLoader(true)
        let functionData: {}[] = []
        AppData.componentList = []
        AppData.labelList = []
        AppData.compData = []
        AppData.blockDiagramData[0].place.map((placeData: any) => {
            if ((placeData.type == 'Driver') && (placeData.clickable != 'NO')) {
                AppData.componentList.push(placeData.name)
                AppData.labelList.push(placeData.label)
            }
        })
        if (label === 'Frequency(MHz)') {
            //Onchange of each element
            var frequency = FrequencyCalculation(value, setalert)
            if (frequency?.validationmsg != '') {
                formData(frequency?.validationmsg, true)
                console.log('validating', frequency?.validationmsg)
                forceClickEvent(!clickEvent)
                setLoader(false)
            }
            else {
                value = frequency?.answer
                await changeFunctionState(id, value, label, setalert, AppData.server)
                if (AppData.alert == 'No Solution Found') {
                    formData('No Solution Found', true)
                    setForm(true)
                    forceClickEvent(!clickEvent)
                    setLoader(false)
                }
                else {
                    var list = [AppData.componentName]
                    var blockFunctions = getBlockFunctions(list, AppData.server)
                    let functionData: {}[] = []

                    blockFunctions.then((data: any) => {
                        data.map((val: any) => {
                            try {
                                for (const [key, value] of Object.entries(val.Functions)) {
                                    functionData.push({
                                        "_id": val.Functions[key]._id, "name": val.Functions[key].name, "device_id": val.Functions[key].device_id,
                                        'parameters': val.Functions[key].parameters, "function_state": val.Functions[key].function_state, "help_text": val.Functions[key].help_text
                                    })
                                }
                                AppData.functions = functionData
                                formDataInitializeVOHVOL()
                                formData()
                                setForm(true)
                                setLoader(false)
                                forceClickEvent(!clickEvent)
                            } catch {
                                AppData.functions = []
                                setLoader(false)
                            }
                        })
                    })
                }
            }
        }
        else if ((label == 'VOH') || (label == 'VOL')) {
            //When either of VOH or VOL is changed , the other will be calculated and updated with separate API call
            if ((typeof (value) == 'string') || (typeof (value) == 'number') || (typeof (value) == 'boolean')) {
                if (label === 'VOH') {
                    AppData.vohVal = value
                    AppData.vohData = value
                    var baseVal = parseFloat(value) - 0.3
                    if (parseFloat(AppData.volData) <= baseVal) {
                        console.log(value)
                    } else {
                        var filteredValues: any = []
                        AppData.volList.forEach((val: any) => { (parseFloat(val) <= baseVal) && filteredValues.push(val) })
                        AppData.volList = filteredValues
                        AppData.volData = AppData.volList[0]
                    }
                    AppData.volVal = ""
                }
                else if (label === 'VOL') {
                    AppData.volVal = value
                    AppData.volData = value
                    var baseVal = parseFloat(value) + 0.3
                    if (parseFloat(AppData.vohData) >= baseVal) {
                        console.log(value)
                    }
                    else {
                        var filteredValues: any = []
                        AppData.vohList.forEach((val: any) => { (parseFloat(val) >= baseVal) && filteredValues.push(val) })
                        AppData.vohList = filteredValues
                        AppData.vohData = AppData.vohList[0]
                    }
                    AppData.vohVal = ""
                }

                changeFunctionState(AppData.vohid, AppData.vohData, 'VOH', setalert, AppData.server).then((data: any) => {
                    data.map((val: any) => {
                        functionData = []
                        try {
                            for (const [key, value] of Object.entries(val.Functions)) {
                                functionData.push({
                                    "_id": val.Functions[key]._id, "name": val.Functions[key].name, "device_id": val.Functions[key].device_id,
                                    'parameters': val.Functions[key].parameters, "function_state": val.Functions[key].function_state, "help_text": val.Functions[key].help_text
                                })
                            }
                            AppData.functions = functionData
                            formDataInitializeVOHVOL()
                            formData()
                            //summaryView()
                            forceClickEvent(!clickEvent)
                        } catch {
                            AppData.functions = []
                        }
                    })
                })

                changeFunctionState(AppData.volid, AppData.volData, 'VOL', setalert, AppData.server).then((data: any) => {
                    data.map((val: any) => {
                        functionData = []
                        try {
                            for (const [key, value] of Object.entries(val.Functions)) {
                                functionData.push({
                                    "_id": val.Functions[key]._id, "name": val.Functions[key].name, "device_id": val.Functions[key].device_id,
                                    'parameters': val.Functions[key].parameters, "function_state": val.Functions[key].function_state, "help_text": val.Functions[key].help_text
                                })
                            }
                            AppData.functions = functionData
                            formDataInitializeVOHVOL()
                            formData()
                            //summaryView()
                            forceClickEvent(!clickEvent)
                            setLoader(false)
                        } catch {
                            AppData.functions = []
                            setLoader(false)
                        }
                    })
                    console.log('changed', AppData.functionFormData);
                })
            }

        }
        else {
            if ((typeof (value) == 'string') || (typeof (value) == 'number') || (typeof (value) == 'boolean')) {
                changeFunctionState(id, value, label, setalert, AppData.server).then((data: any) => {
                    data.map((val: any) => {
                        try {
                            for (const [key, value] of Object.entries(val.Functions)) {
                                functionData.push({
                                    "_id": val.Functions[key]._id, "name": val.Functions[key].name, "device_id": val.Functions[key].device_id,
                                    'parameters': val.Functions[key].parameters, "function_state": val.Functions[key].function_state, "help_text": val.Functions[key].help_text
                                })
                            }
                            AppData.functions = functionData
                            formDataInitializeVOHVOL()
                            formData()
                            //summaryView()
                            forceClickEvent(!clickEvent)
                            setLoader(false)
                        } catch {
                            AppData.functions = []
                            setLoader(false)
                        }
                    })
                    console.log('changed', AppData.functionFormData);
                })
            }
        }
    }
    async function onClickBack() {
        //OnClick back in summaryView 
        setLoader(true)
        //const result = await resolveAfter3Seconds();
        await summaryView()
        setLoader(false)
        setForm(false)
    }
    function getSolutionFormData() {
        AppData.errorLimit = ''
        //Generate form input details for solution finder form
        AppData.solutionData = []
        AppData.frequencies_data = []
        AppData.freqData = []
        var ppmFunctionState = AppData.compData[1][18].function_state
        var ppmLabel = AppData.compData[1][18].name
        var ppmInput = AppData.compData[1][18].parameters[0].inputs
        var ppmData = { type: "select", id: ppmLabel, name: ppmLabel, label: ppmLabel, tooltip: 'PPM', help: 'PPM', tooltipPosition: 'right', current: ppmFunctionState, enableFilter: false, contentItems: ppmInput, disable: false }
        AppData.solutionData[0] = ppmData
        AppData.compData.map((data: any, index: number) => {
            data.map((functionData: any) => {
                var freq_data = { function_name: '', parameter_name: '', token_name: '', component_name: '' }
                if (functionData.parameters[0].param_name === 'Frequency(MHz)') {
                    AppData.clkList[index] = AppData.labelList[index]
                    AppData.limit = functionData.parameters[0].inputs
                    freq_data["function_name"] = functionData.name
                    freq_data["parameter_name"] = functionData.parameters[0].param_name
                    freq_data["component_name"] = AppData.componentList[index]
                    if (functionData.function_state != null) { freq_data['token_name'] = functionData.function_state }
                    else { freq_data['token_name'] = '' }
                    var freqData = { type: "text", id: functionData.name, name: 'popupform1', label: AppData.labelList[index] + ' (MHz)', tooltip: 'Output Frequency', content: functionData.function_state != null ? functionData.function_state.toString() : '', disable: false, help: 'Output Frequency', tooltipPosition: 'right' }
                    AppData.solutionData.push(freqData)
                    AppData.frequencies_data[index] = freq_data
                }
            })
        })
        setAnalyse(true)
    }
    function onChangeFrequency(value: any, id: any) {
        if (id != 'PPM') {
            let index = id.match(/(\d+)/)
            console.log('freq', value, AppData.frequencies_data, index[0])
            if (value === '') AppData.frequencies_data[index[0]]['token_name'] = '';
            else AppData.frequencies_data[index[0]]['token_name'] = value
        }
    }
    function freqConversion(freqVal: string, error: string, functionName: string) {
        //Frequency conversion for solution finder form
        const list = ['Hz', 'MHz', 'kHz', 'M', 'GHz', 'k', 'G', 'e6', 'e3', 'e9']
        var final: any
        if (list.some(v => freqVal.includes(v))) {
            let result = freqVal.split('MHz').join('*10**6')
            result = result.split('M').join('*10**6')
            result = result.split('kHz').join('*10**3')
            result = result.split('k').join('*10**3')
            result = result.split('GHz').join('*10**9')
            result = result.split('G').join('*10**9')
            result = result.split('e6').join('*10**6')
            result = result.split('e3').join('*10**3')
            result = result.split('e9').join('*10**9')
            final = result.split('Hz').join('*1')
        }
        else {
            if (!containsNumbers(freqVal)) {
                error = `Enter a valid value in ${functionName}`
                setLoader(false)
                setAnalyse(true)
                let answer = ''
                return { answer, error }
            }
            final = freqVal + '*10**6'
        }
        try {
            let answer = eval(final)
            //check for valid frequencies
            const checkValue = (answer/(10**6)).toString();
            if (AppData.deviceFrequenciesData.length != 0 && !AppData.deviceFrequenciesData.includes(checkValue)) {
                error = `Enter a valid value in ${functionName}`
                setLoader(false)
                setAnalyse(true)
                let answer = ''
                return { answer, error }
            }
            error = ''
            if ((AppData.limit[0] <= answer) && (answer <= AppData.limit[1]) || (freqVal === '0')) {
                console.log('Answer value', AppData.limit[0], answer, AppData.limit[1])
                return { answer, error }
            }
            else {
                error = 'Limit error'
                AppData.errorLimit = functionName
                return { answer, error }
            }
        }
        catch {
            error = 'Enter a valid value'
            AppData.errorLimit = functionName
            setLoader(false)
            setAnalyse(true)
            let answer = ''
            return { answer, error }
        }
    }
    async function onSubmitAnalyse(formData: { id: string, value: string }[]) {
        //Onsubmit 2 API call are made to update the frequencies and PPM
        AppData.errorLimit = ''
        AppData.freqData = []
        setLoader(true)
        var index = 0
        var errorIndex = 0
        var errorMsg = ''
        var PPMValue: any
        var answer: any
        // AppData.compData.map((data: any, index: number) => {
        //     data.map((functionData: any) => {
        //         var freq_data = { function_name: '', parameter_name: '', token_name: '', component_name: '' }
        //         if (functionData.parameters[0].param_name === 'Frequency(MHz)') {
        //             freq_data["function_name"] = functionData.name
        //             freq_data["parameter_name"] = functionData.parameters[0].param_name
        //             freq_data["component_name"] = AppData.componentList[index]
        //             if (functionData.function_state != null) { freq_data['token_name'] = functionData.function_state }
        //             else { freq_data['token_name'] = '' }
        //             AppData.frequencies_data[index] = freq_data
        //         }
        //     })
        // })

        //if formData is empty the existing function_state will be updated again.
        formData.map(async (data: any, key: number) => {
            console.log('formdata', formData, AppData.frequencies_data, index)
            if ((key > 0)) {
                if ((data.value === '') && (AppData.frequencies_data[index].token_name === '' || AppData.frequencies_data[index].token_name === 0)) {
                    AppData.frequencies_data[index]["token_name"] = 0
                    AppData.freqData.push(AppData.frequencies_data[index])
                    index += 1
                }
                else if ((data.value != '')) {
                    answer = freqConversion(data.value, errorMsg, AppData.clkList[index])
                    setError(answer.error)
                    if (!answer.error) {
                        AppData.frequencies_data[index]["token_name"] = answer.answer
                        AppData.freqData.push(AppData.frequencies_data[index])
                        index += 1
                    }
                    else {
                        errorIndex += 1
                    }
                }
                else if (AppData.frequencies_data[index].token_name != '') {
                    var freqVal: any = AppData.frequencies_data[index].token_name.toString()
                    const [whole, decimal] = freqVal.split('.');
                    if (whole.length > 6) {
                        AppData.frequencies_data[index].token_name = AppData.frequencies_data[index].token_name / 10 ** 6
                    }
                    freqVal = AppData.frequencies_data[index].token_name.toString()
                    answer = freqConversion(freqVal, errorMsg, AppData.clkList[index])
                    setError(answer.error)
                    if (!answer.error) {
                        AppData.frequencies_data[index]["token_name"] = answer.answer
                        AppData.freqData.push(AppData.frequencies_data[index])
                        index += 1
                    }
                }
            }
        })
        //check all empty and show error
        if (index != (formData.length - 1)) {
            if (AppData.errorLimit) { setError(`Enter a valid value in ${AppData.errorLimit}`) }
            else { setError('Enter a valid value') }
            setLoader(false)
            setAnalyse(true)
        } else if (errorIndex > 0) {
            setError('Enter a valid value')
            setLoader(false)
            setAnalyse(true)
        }
        else {
            PPMValue = formData[0].value
            if (PPMValue === '') { PPMValue = AppData.compData[1][18].function_state }
            if (errorIndex == 0) {
                try {
                    const result = await realizeFrequencies(PPMValue)
                    if (result != '') {
                        setAnalyse(true)
                        setError('No Solution Found')
                        setLoader(false)
                    }
                    else {                        
                        setError('')
                        AppData.solutionData = []
                        AppData.frequencies_data = []
                        setAnalyse(false)
                        await summaryView()
                        await AppData.freqData.map((data: any, index: number) => {
                            // Process Function based on Value
                            let checkValue = (data.token_name/(10**6)).toString();
                            if (AppData.deviceFrequenciesDataDiff.length != 0 && AppData.deviceFrequenciesDataDiff.includes(checkValue)) {
                                AppData.disableDiff = false
                                if(!AppData.deviceFrequenciesDataSE.includes(checkValue)){
                                    AppData.disableLVCMOS = true
                                    processTypeFunction(index,'Differential')
                                }
                            }
                            if (AppData.deviceFrequenciesDataSE.length != 0 && AppData.deviceFrequenciesDataSE.includes(checkValue)) {
                                AppData.disableLVCMOS = false
                                if(!AppData.deviceFrequenciesDataDiff.includes(checkValue)){
                                    AppData.disableDiff = true
                                    var slewFunctionCLKP = {} as any;
                                    slewFunctionCLKP[0] = 'OUT0SlewRate_CLK_P'
                                    slewFunctionCLKP[1] = 'OUT1SlewRate_CLK_P'
                                    slewFunctionCLKP[2] = 'OUT2SlewRate_CLK_P'
                                    slewFunctionCLKP[3] = 'OUT3SlewRate_CLK_P'
                                    var slewFunctionCLKN = {} as any;
                                    slewFunctionCLKN[0] = 'OUT0SlewRate_CLK_N'
                                    slewFunctionCLKN[1] = 'OUT1SlewRate_CLK_N'
                                    slewFunctionCLKN[2] = 'OUT2SlewRate_CLK_N'
                                    slewFunctionCLKN[3] = 'OUT3SlewRate_CLK_N'
                                    var vohid = {} as any;
                                    vohid[0] = 'OUT0VOH'
                                    vohid[1] = 'OUT1VOH'
                                    vohid[2] = 'OUT2VOH'
                                    vohid[3] = 'OUT3VOH'
                                    var volid = {} as any;
                                    volid[0] = 'OUT0VOL'
                                    volid[1] = 'OUT1VOL'
                                    volid[2] = 'OUT2VOL'
                                    volid[3] = 'OUT3VOL'
                                    var refFunction = {} as any;
                                    refFunction[0] = 'OUT0Reference'
                                    refFunction[1] = 'OUT1Reference'
                                    refFunction[2] = 'OUT2Reference'
                                    refFunction[3] = 'OUT3Reference'
                                    AppData.api.slewRateCalculation.bodyParams = { vddio: AppData.vddVallist[index], function: slewFunctionCLKP[index] }
                                    AppData.localServer.sendCommand(AppData.api.slewRateCalculation, `fetching slewrate value failed`, 1)
                                    AppData.api.slewRateCalculation.bodyParams = { vddio: AppData.vddVallist[index], function: slewFunctionCLKN[index] }
                                    AppData.localServer.sendCommand(AppData.api.slewRateCalculation, `fetching slewrate value failed`, 1)
                                    AppData.api.vddCalculation.bodyParams = { vdd: AppData.vddVallist[index], voh: vohid[index], vol: volid[index], refVal: AppData.refIdList[index], refFunction: refFunction[index] }
                                    AppData.localServer.sendCommand(AppData.api.vddCalculation, `fetching value for VOH,VOL failed`)
                                    processTypeFunction(index,'Single Ended (LVCMOS)')
                                }
                            }
                        })
                        await AppData.summaryData.map((data: any, index: number) => {
                            if ((data["Frequency(MHz)"] != null) && (data["Mode"] != 'Enabled')) {
                                processFunction(index, "Enabled")
                            }
                            if (data["Frequency(MHz)"] === null) {
                                processFunction(index, "Disabled")
                            }
                        })
                        await summaryView()
                        setLoader(false)
                    }
                }
                catch
                {
                    setAnalyse(true)
                    setError('No Solution Found')
                    setLoader(false)
                }

            }
        }
        setRender(!render)
    }
    const onClickCancel = () => {
        setError('')
    }
    var sscFormData = require('./writeDevice.json')
    const onClickSSC = async () => {
        var disable : boolean
        let functionList = ["SSC_enabled", "SSC_mode", "SSC_profile"]
        let result = await getFunctionState(functionList, 'local', AppData.blockDiagramData[0]._id)
        var percentData = await percentSpread(result[1].function_state, AppData.currentConfig.device_id)
        result[0].function_state === null ? sscFormData.ssc[0].current = '' : sscFormData.ssc[0].current = result[0].function_state
        result[0].function_state === 'Enabled' ? disable = false : disable = true
        AppData.enabled = result[0].function_state
        sscFormData.ssc[1].current = result[1].function_state
        sscFormData.ssc[2].current = result[2].function_state
        sscFormData.ssc[0].contentItems = result[0].parameters[0].inputs
        sscFormData.ssc[1].contentItems = result[1].parameters[0].inputs
        sscFormData.ssc[2].contentItems = result[2].parameters[0].inputs
        sscFormData.ssc[1].disable = disable
        sscFormData.ssc[2].disable = disable
        functionList = ["Percent_spread"]
        result = await getFunctionState(functionList, 'local',AppData.blockDiagramData[0]._id)
        sscFormData.ssc[3].disable = disable
        sscFormData.ssc[3].current = result[0].function_state
        sscFormData.ssc[3].contentItems = percentData.status.parameters[0].inputs
        console.log('check', sscFormData, percentData)
        setRender(!render)
        setSSC(true)
    }
    const onValueChangeSSC = async (value: any, id: any) => {
        if (typeof (value) === 'string') {
            if (id === 'Mode') {
                let functionList = ["Percent_spread"]
                await percentReset(functionList)
                sscFormData.ssc[3].current = ''
                const percentData = await percentSpread(value, AppData.currentConfig.device_id)
                sscFormData.ssc[3].contentItems = percentData.status.parameters[0].inputs
                setRender(!render)
            }
            if (id === 'Enabled') {
                if (value === 'Enabled') {
                    sscFormData.ssc[1].disable = false
                    sscFormData.ssc[2].disable = false
                    sscFormData.ssc[3].disable = false
                    setRender(!render)
                } 
                else if(value === 'Disabled') {
                    sscFormData.ssc[1].disable = true
                    sscFormData.ssc[2].disable = true
                    sscFormData.ssc[3].disable = true
                    setRender(!render)
                }
            }
        }
    }
    async function onSubmitSSC(formData: { id: string, value: string }[]) {
        var functionName = '',tokenName = '',paramName = '', found : boolean
        found = false
        formData.map(async (data: any, key: number) => {
            console.log('data',data,key)
            if((data.id === 'Enabled') && (data.value != '')){
                found = true
                functionName = 'SSC_enabled'
                paramName = 'SSC FOD0'
                tokenName = data.value
            }
            else if((data.id === 'Mode') && (data.value != '')){
                found = true
                functionName = 'SSC_mode'
                paramName = "SSC mode"
                tokenName = data.value
            }
            else if((data.id === 'Pattern') && (data.value != '')){
                found = true
                functionName = 'SSC_profile'
                paramName = "SSC profile"
                tokenName = data.value
            }
            else if((data.id === 'Percent') && (data.value != '')){
                found = true
                functionName = 'Percent_spread'
                paramName = 'Percent spread'
                tokenName = data.value
            }
            data.value!="" && processComponentFUnctions(functionName,tokenName,paramName,setSSC)
        })
        if(!found){
            functionName = 'SSC_enabled'
            paramName = 'SSC FOD0'
            processComponentFUnctions(functionName,AppData.enabled,paramName,setSSC)
        }
    }
    return (
        < FlexBox shape="boxcontainer" layout="horizontal-top-left" spacing="boxcontainer" >
            {loader && <LoadingSpinner shape={"medium"} stroke={"normal"} fill={"none"} innerfill={"loader"} speed={"1"} text={"Processing..."} position={{ top: '40vh', left: '45vw' }} type={'circular'} disabled={false} ></LoadingSpinner>}
            <FlexBox layout="vertical-top-center" spacing="blockdiagrambox" shape="blockdiagrambox" fill="none" stroke="device">
                <Blockdiagram place={AppData.blockDiagramData[0].place} route={AppData.blockDiagramData[0].route} style={style} size={{ height: 600, width: 800 }} onClick={selectComp} />
            </FlexBox>
            <FlexBox layout="vertical-top-left" spacing="blockdiagrambox2" shape="blockdiagrambox2" fill="none" stroke="device">
                {form ? <FunctionalForm initfreqData={AppData.functionFormData.freq} initdiffref={AppData.functionFormData.diffref} initSlewData={AppData.functionFormData.slew} initdiffvohData={AppData.functionFormData.diffvoh} initsinglevohData={AppData.functionFormData.singlevoh} initdelayData={AppData.functionFormData.delay} inittypeData={AppData.functionFormData.type} onItemChange={onItemChange} header={AppData.label} initFormData={AppData.functionFormData.commonfunctions} initdiffdata={AppData.functionFormData.differential}
                    initsingledata={AppData.functionFormData.single} initParamData={AppData.functionFormData.param} initopdata={AppData.functionFormData.op} initsinglecommonData={AppData.functionFormData.singlecommon} onBackclick={onClickBack} />
                    : <SummaryView onClickSSC={onClickSSC} onClickEdit={onClickEdit} onClickAnalyse={getSolutionFormData}></SummaryView>}
                {analyse ? <PopUpForm form='PPMform' valueChange={(value: any, id: any) => onChangeFrequency(value, id)} OnclickCancel={onClickCancel} Error={error} shape={"hug"} spacing={"popupform1"} fill={"white"} stroke={""} font={""} initFormData={AppData.solutionData} onSubmit={onSubmitAnalyse} visible={analyse} hide={setAnalyse} popupformshape={"popupform1"} popupfill={"popupform"} popupstroke={"popupform"} items={[]}
                    buttonContent={"Analyze"} buttonName="popupform1" heading={"Solution Finder"}></PopUpForm> : <></>}
                {ssc ? <PopUpForm form='PPMform' valueChange={(value: any, id: any) => onValueChangeSSC(value, id)} OnclickCancel={onClickCancel} Error={error} shape={"hug"} spacing={"popupform1"} fill={"white"} stroke={""} font={""} initFormData={sscFormData.ssc} onSubmit={onSubmitSSC} visible={ssc} hide={setSSC} popupformshape={"popupform1"} popupfill={"popupform"} popupstroke={"popupform"} items={[]}
                    buttonContent={"Submit"} buttonName="popupform1" heading={"Spread Spectrum Control"}></PopUpForm> : <></>}
            </FlexBox>
        </FlexBox >
    )
}
export default DetailScreen