
// interface PropsIF {
//     data: { name: string, icon: string, onclick: () => void }[]
//     onClick?: (itemName: string) => void;

import { useState } from "react"
import { ThemeProvider } from "styled-components"
import AppData from "../../../appData"
import { Button } from "../../../components/button/button"
import { DataGrid } from "../../../components/dataGrid/dataGrid"
import Icons from "../../../icons/icons"
import PrimaryTheme from "../../../Themes/primaryTheme"
import { syncDevice } from "./deviceEvents"
import Message from "../../../components/messages/messages"
import { convertUTCDateToLocalDate } from "../configurationScreen/configLocalTable"
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner"
import configJson from "../../../config.json"

// }
interface PropsIF {
	data: any[]
	Columns: any[]
	shape?: string
	fill?: string
	stroke?: string
	onSync: any
}

function LocalTable({ data, shape = 'normal', fill = 'normal', stroke = 'normal', Columns, onSync }: PropsIF) {
	const [show, setShow] = useState(false);
	const [selectedRow, setSelectedrow] = useState();
	const [message, setMessage] = useState()
	const [visible, setVisible] = useState(false)
	const [loader,setloader] = useState(false)
	const setMessageComp = (visible: boolean, message: any) => {
		return setMessage(message), setVisible(visible)
	}
	const handler = () => {
		setShow(!show);
	}
	var localdata: any[] = []
	var remotedata: any[] = []
	var platform: any[] = []
	var variant: any[] = []
	var silicon_revision: any[] = []
	var version: any[] = []
	var created_on: any[] = []
	var id: any = []
	localdata.push(platform, variant, silicon_revision, version, created_on)
	AppData.device.data.remote.forEach((value: any) => { return platform.push(value['platform']) })
	AppData.device.data.remote.forEach((value: any) => { return variant.push(value['variant']) })
	AppData.device.data.remote.forEach((value: any) => { return silicon_revision.push(value['silicon_revision']) })
	AppData.device.data.remote.forEach((value: any) => { return version.push(value['version']) })
	AppData.device.data.remote.forEach((value: any) => { return created_on.push(value['created_on']) })
	AppData.device.data.remote.forEach((value: any) => { return id.push(value['_id']) })
	const col1 = (coldata: any, rowindex: any) => {
		return (rowindex.map((row: any) => (coldata[row] == 'local' ? "" : <div style={{ width: '17vw', padding: '0px', margin: '0px', display: 'flex', alignItems: 'center', justifyContent: "end" }}><Button shape="downloadLocal" fill="transparent" spacing="downloadLocal" stroke="sync" color="sync" onClick={() => { return setloader(true),syncDevice(id[row], onSync,setloader).then((res) => { setloader(false);setMessageComp(true, `${variant[row]} device is downloaded to Local`) }) }}><Icons iconName={"download"} size={"12px"} />Download to Local</Button></div>))
		)
	};
	const col2 = (coldata: any) => {
		return (coldata.map((col: string) => (
			<Button shape="remotebutton" spacing="localbutton" fill="transparent" stroke='local'><Icons iconName={'cloud'} size={'18px'} stroke='remote' fill="remote"></Icons><p style={{ fontWeight: "500", fontSize: "12px", color: '#8E8D8A', fontFamily: "Inter" }}>Remote</p></Button>
		)))
	}

	const col6 = (coldata: any) => {
		return (coldata.map((col: any) => (
			<p>{col}</p>))
		)
	};
	const colum0 = platform.map(x => (x))
	const colum1 = col2(data.map(x => (x[1])))
	const colum2 = col6(variant.map(x => (x)))
	const rowLengthArray = Array.from({ length: data.length }, (v, i) => i)
	const colum3 = col6(version.map(x => (x)))
	const colum5 = col6(silicon_revision.map(x => (x)))
	const colum7 = col6(created_on.map(x => (convertUTCDateToLocalDate(new Date(x + 'Z')).toLocaleString())))
	const colum8 = col1(localdata.map(x => (x[1])), rowLengthArray)

	const displayData = colum0.map(function (e, i) {
		return (configJson.WEB_ONLY===0)?[colum2[i], colum1[i], colum3[i], colum5[i], colum7[i], colum8[i]]:[colum2[i], colum3[i], colum5[i], colum7[i]];
	});

	const activeRow = (rowSelected: any) => {
		setSelectedrow(rowSelected)
		console.log(id[rowSelected])
	}

	return (
		<ThemeProvider theme={PrimaryTheme}>
			{loader && <LoadingSpinner shape={"medium"} stroke={"normal"} fill={"none"} innerfill={"loader"} speed={"1"} text={"Downloading the device"} position={{ top: '40vh', left: '45vw' }} type={'circular'} disabled={false} ></LoadingSpinner>}
			<Message visible={visible} shape="config" font="config" position="bottom-right" text={message} hide={setVisible}></Message>
			<DataGrid data={data} displayData={displayData} columns={Columns} shape={shape} fill={fill} stroke={stroke} onClick={activeRow} />
		</ThemeProvider>
	);
}

export default LocalTable;
