import { useEffect, useState } from "react";
import AppData from "../../../appData";
import FlexBox from "../../../components/flexBox/flexBox";
import Dropdown from "../../../components/dropDown/dropDown";
import { ProcessFunction } from "../configurationScreen/configurationEvents";
import { functionSearch } from "./blockDiagramEvents";

interface PropsIF {
    onClickEdit: (label:string) => void
    onClickAnalyse?: any
    onClickSSC?: any
}
export default function SummaryView({onClickEdit,onClickAnalyse, onClickSSC}:PropsIF) {
    const[clock0,setClock0] = useState(false)
    const[clock1,setClock1] = useState(false)
    const[clock2,setClock2] = useState(false)
    const[clock3,setClock3] = useState(false)
    const[vdd,setVDD] = useState(AppData.vddState)
    const[vdda,setVDDA] = useState(AppData.vddaState)
    console.log('summaryData', AppData.summaryData)
    useEffect(() => { 
        setVDD(AppData.vddState)
    },[AppData.vddState])
    useEffect(() => { 
        setVDDA(AppData.vddaState)
    },[AppData.vddaState])
    const changeFunctionState = (token_name:string,functionName:string) =>{
        var param_name=functionName
        {typeof(token_name) == 'string' && ProcessFunction(AppData.currentConfig.device_id,functionName,param_name,token_name,'driver1',AppData.blockDiagramData[0]._id).then((val:any)=>{
           if(functionName==='VDD'){setVDD(token_name)}
           else if(functionName==='VDDA'){setVDDA(token_name)}
        })}
    }
    return (
        <div style={{ overflowY: "auto", padding: "0px", margin: '0px' }}>
            <FlexBox layout="vertical-top-left" spacing="summaryView" shape="hug" >
                <div style={{fontSize:'1.4285714285714286vw',color:' #231F20',padding:'0px',margin:'0px',fontWeight:400,display:'flex'}}>
                    Configuration Details
                    <div onClick={(!(AppData.currentConfig.frozen||AppData.server==="remote"))?onClickSSC: undefined} style={{ fontSize: '16px', fontWeight: 400, color:(!(AppData.currentConfig.frozen||AppData.server==="remote"))?"#3399D5": 'black', textDecorationLine: (!(AppData.currentConfig.frozen||AppData.server==="remote"))?'underline':"none", cursor: (!(AppData.currentConfig.frozen||AppData.server==="remote"))?"pointer":"auto",marginLeft:'30px', marginTop:'5px' }}>SSC</div>
                    <div onClick={(!(AppData.currentConfig.frozen||AppData.server==="remote"))?onClickAnalyse: undefined} style={{ fontSize: '16px', fontWeight: 400, color:(!(AppData.currentConfig.frozen||AppData.server==="remote"))?"#3399D5": 'black', textDecorationLine: (!(AppData.currentConfig.frozen||AppData.server==="remote"))?'underline':"none", cursor: (!(AppData.currentConfig.frozen||AppData.server==="remote"))?"pointer":"auto",marginLeft:'15px', marginTop:'5px' }}>Solution Finder</div>
                </div>
                <div style={{display:'flex',flexDirection:'row',margin:'0px',padding:'0px'}}>
                    <div style={{marginRight:'11.8vw',fontSize:'16px'}}>VDD</div>
                    <div style={{fontSize:'16px'}}>VDDA</div>
                </div>
                <FlexBox layout="horizontal-top-left" shape="hug" spacing="summary">
                <Dropdown  disabled={((AppData.currentConfig.frozen)||(AppData.server === 'remote'))?true:false} iconcolor="dropdown" current={vdd} spacing="normal" shape='mode' fill='popupform1' select={'single'} InputSearch={false} effects={'dropShadow'} contentItems={AppData.vddList} stroke={'none'} onClick={(value)=>{changeFunctionState(value,'VDD')}}></Dropdown>
                <Dropdown  disabled={((AppData.currentConfig.frozen)||(AppData.server === 'remote'))?true:false} iconcolor="dropdown" current={vdda} spacing="normal" shape='mode' fill='popupform1' select={'single'} InputSearch={false} effects={'dropShadow'} contentItems={AppData.vddaList} stroke={'none'} onClick={(value)=>{changeFunctionState(value,'VDDA')}}></Dropdown>
                </FlexBox>
                <FlexBox fill="summaryBg" shape={clock0 ? "Expand" : "summaryView"} layout="vertical-top-left" spacing="summaryCard">
                    <FlexBox layout="horizontal-top-left"shape="Summaryedit" spacing="none">
                        <div style={{ height: "27px", padding: "0px", margin: '0px', fontSize: "18px", color: "#231F20", fontWeight: 700 }}>
                            {AppData.labelList[0]}
                        </div>
                        <FlexBox shape="Summaryedit" layout="horizontal-top-right" spacing="none">
                            <div onClick={clock0 ? () => setClock0(false) : () => setClock0(true)} style={{ fontSize: '14px', fontWeight: 400, color: '#3399D5', textDecorationLine: 'underline', cursor: 'pointer' }} >{clock0 ? 'COLLAPSE' : 'SEE MORE'}</div>
                        </FlexBox>
                    </FlexBox>
                    {/* CLK0 */}
                    <FlexBox layout={clock0 ? "vertical-top-left" : "horizontal-top-left"} spacing="summaryGap" shape="hug" >
                        <FlexBox shape="hug" layout="horizontal-top-left" spacing="collapse"> {Object.entries(AppData.summaryData[0]).filter(([key, value], val) => ((val == 0)||(val == 2)||(val == 1)||(val == 3))).map(([key, value]) =>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0px', gap: "2px" }}>
                                <div style={{ width: '5.92vw', height: '18px', fontSize: "0.78125vw", fontWeight: 400, color: "#8E8D8A" }}>
                                    {`${key}`}
                                </div>
                                <div style={{ width: '5.92vw', height: '21px', fontSize: "0.9114583333333334vw", fontWeight: 400, color: '#231F20',wordWrap:"break-word" }}>
                                    {`${value==null?'':( value == 'Differential') ? `${AppData.signalList[0]}` : ( value == 'Single Ended (LVCMOS)') ? 'LVCMOS': value}`}
                                </div>
                            </div>
                        )}
                        </FlexBox>
                        {clock0 && <FlexBox shape="hug" layout="horizontal-top-left" spacing="collapse">{Object.entries(AppData.summaryData[0]).filter(([key, value], val) => ((val > 3) && (val <= 6))).map(([key, value]) =>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0px', gap: "2px" }}>
                                <div style={{ width: 'fit-content', height: '18px', fontSize: "0.78125vw", fontWeight: 400, color: "#8E8D8A" }}>
                                    {`${key}`}
                                </div>
                                <div style={{ width: '5.92vw', height: '21px', fontSize: "0.9114583333333334vw", fontWeight: 400, color: '#231F20' }}>
                                    {`${value}`}
                                </div>
                            </div>
                        )}
                        </FlexBox>}
                        {clock0 && <FlexBox shape="hug" layout="horizontal-top-left" spacing="collapse">{Object.entries(AppData.summaryData[0]).filter(([key, value], val) => ((val > 6)||(val == 1))).slice(0).reverse().map(([key, value]) =>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0px', gap: "2px" }}>
                                <div style={{ width: '5.92vw', height: '18px', fontSize: "0.78125vw", fontWeight: 400, color: "#8E8D8A" }}>
                                    {`${key}`}
                                </div>
                                <div style={{  height: '21px', fontSize: "0.9114583333333334vw", fontWeight: 400, color: '#231F20', }}>
                                {( value == 'Single Ended (LVCMOS)') ? `${AppData.clkPDataList[0]+' ' +AppData.clkNDataList[0]}` : `${value}`}
                                </div>
                            </div>
                        )}
                        </FlexBox>}
                    </FlexBox>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#3399D5', textDecorationLine: 'underline', cursor: 'pointer' }} onClick={()=>onClickEdit(AppData.labelList[0])}>{((clock0)&&(AppData.server=='remote')) ? 'VIEW' : clock0 ? 'EDIT' :''}</div>
                </FlexBox>
                {/* CLK1 */}
                <FlexBox fill="summaryBg" shape={clock1 ? "Expand" : "summaryView"} layout="vertical-top-left" spacing="summaryCard">
                <FlexBox layout="horizontal-top-left"shape="Summaryedit" spacing="none">
                        <div style={{ height: "27px", padding: "0px", margin: '0px', fontSize: "18px", color: "#231F20", fontWeight: 700 }}>
                            {AppData.labelList[1]}
                        </div>
                        <FlexBox shape="Summaryedit" layout="horizontal-top-right" spacing="none">
                            <div onClick={clock1 ? () => setClock1(false) : () => setClock1(true)} style={{ fontSize: '14px', fontWeight: 400, color: '#3399D5', textDecorationLine: 'underline', cursor: 'pointer' }} >{clock1 ? 'COLLAPSE' : 'SEE MORE'}</div>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox layout={clock1 ? "vertical-top-left" : "horizontal-top-left"}  spacing="summaryGap" shape="hug" >
                        <FlexBox shape="hug" layout="horizontal-top-left" spacing="collapse"> {Object.entries(AppData.summaryData[1]).filter(([key, value], val) => ((val == 0)||(val == 2)||(val == 1)||(val == 3))).map(([key, value]) =>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0px', gap: "2px" }}>
                                <div style={{ width: '5.92vw', height: '18px', fontSize: "0.78125vw", fontWeight: 400, color: "#8E8D8A" }}>
                                    {`${key}`}
                                </div>
                                <div style={{ width: '5.92vw', height: '21px', fontSize: "0.9114583333333334vw", fontWeight: 400, color: '#231F20',wordWrap:"break-word"  }}>
                                {`${value==null?'':( value == 'Differential') ? `${AppData.signalList[1]}` : ( value == 'Single Ended (LVCMOS)') ? 'LVCMOS': value}`}
                                </div>
                            </div>
                        )}
                        </FlexBox> 
                        {clock1 && <FlexBox shape="hug" layout="horizontal-top-left" spacing="collapse">{Object.entries(AppData.summaryData[1]).filter(([key, value], val) => ((val > 3) && (val <= 6))).map(([key, value]) =>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0px', gap: "2px" }}>
                                <div style={{ width: 'fit-content', height: '18px', fontSize: "0.78125vw", fontWeight: 400, color: "#8E8D8A" }}>
                                    {`${key}`}
                                </div>
                                <div style={{ width: '5.92vw', height: '21px', fontSize: "0.9114583333333334vw", fontWeight: 400, color: '#231F20' }}>
                                    {`${value}`}
                                </div>
                            </div>
                        )}
                        </FlexBox>}
                       {clock1 && <FlexBox shape="hug" layout="horizontal-top-left" spacing="collapse">{Object.entries(AppData.summaryData[1]).filter(([key, value], val) => ((val > 6)||(val == 1))).slice(0).reverse().map(([key, value]) =>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0px', gap: "2px" }}>
                                <div style={{ width: '5.92vw', height: '18px', fontSize: "0.78125vw", fontWeight: 400, color: "#8E8D8A" }}>
                                    {`${key}`}
                                </div>
                                <div style={{ height: '21px', fontSize: "0.9114583333333334vw", fontWeight: 400, color: '#231F20' }}>
                                {( value == 'Single Ended (LVCMOS)') ? `${AppData.clkPDataList[1]+' ' +AppData.clkNDataList[1]}` : `${value}`}
                                </div>
                            </div>
                        )}
                        </FlexBox>}
                    </FlexBox>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#3399D5', textDecorationLine: 'underline', cursor: 'pointer' }} onClick={()=>onClickEdit(AppData.labelList[1])}>{((clock1)&&(AppData.server=='remote')) ? 'VIEW' : clock1 ? 'EDIT' :''}</div>
                </FlexBox>
                {/* CLK2 */}
                <FlexBox fill="summaryBg" shape={clock2 ? "Expand" : "summaryView"} layout="vertical-top-left" spacing="summaryCard">
                <FlexBox layout="horizontal-top-left"shape="Summaryedit" spacing="none">
                        <div style={{ height: "27px", padding: "0px", margin: '0px', fontSize: "18px", color: "#231F20", fontWeight: 700 }}>
                            {AppData.labelList[2]}
                        </div>
                        <FlexBox shape="Summaryedit" layout="horizontal-top-right" spacing="none">
                            <div onClick={clock2 ? () => setClock2(false) : () => setClock2(true)} style={{ fontSize: '14px', fontWeight: 400, color: '#3399D5', textDecorationLine: 'underline', cursor: 'pointer' }} >{clock2 ? 'COLLAPSE' : 'SEE MORE'}</div>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox layout={clock2 ? "vertical-top-left" : "horizontal-top-left"}  spacing="summaryGap" shape="hug" >
                        <FlexBox shape="hug" layout="horizontal-top-left" spacing="collapse"> {Object.entries(AppData.summaryData[2]).filter(([key, value], val) => ((val == 0)||(val == 2)||(val == 1)||(val == 3))).map(([key, value]) =>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0px', gap: "2px" }}>
                                <div style={{ width: '5.92vw', height: '18px', fontSize: "0.78125vw", fontWeight: 400, color: "#8E8D8A" }}>
                                    {`${key}`}
                                </div>
                                <div style={{ width: '5.92vw', height: '21px', fontSize: "0.9114583333333334vw", fontWeight: 400, color: '#231F20',wordWrap:"break-word" }}>
                                {`${value==null?'':( value == 'Differential') ? `${AppData.signalList[2]}` : ( value == 'Single Ended (LVCMOS)') ? 'LVCMOS': value}`}
                                </div>
                            </div>
                        )}
                        </FlexBox>
                        {clock2 && <FlexBox shape="hug" layout="horizontal-top-left" spacing="collapse">{Object.entries(AppData.summaryData[2]).filter(([key, value], val) => ((val > 3) && (val <= 6))).map(([key, value]) =>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0px', gap: "2px" }}>
                                <div style={{ width: 'fit-content', height: '18px', fontSize: "0.78125vw", fontWeight: 400, color: "#8E8D8A" }}>
                                    {`${key}`}
                                </div>
                                <div style={{ width: '5.92vw', height: '21px', fontSize: "0.9114583333333334vw", fontWeight: 400, color: '#231F20' }}>
                                    {`${value}`}
                                </div>
                            </div>
                        )}
                        </FlexBox>}
                        {clock2 && <FlexBox shape="hug" layout="horizontal-top-left" spacing="collapse">{Object.entries(AppData.summaryData[2]).filter(([key, value], val) => ((val > 6)||(val == 1))).slice(0).reverse().map(([key, value]) =>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0px', gap: "2px" }}>
                                <div style={{ width: '5.92vw', height: '18px', fontSize: "0.78125vw", fontWeight: 400, color: "#8E8D8A" }}>
                                    {`${key}`}
                                </div>
                                <div style={{ height: '21px', fontSize: "0.9114583333333334vw", fontWeight: 400, color: '#231F20' }}>
                                {( value == 'Single Ended (LVCMOS)') ? `${AppData.clkPDataList[2]+' ' +AppData.clkNDataList[2]}` : `${value}`}
                                </div>
                            </div>
                        )}
                        </FlexBox>}
                    </FlexBox>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#3399D5', textDecorationLine: 'underline', cursor: 'pointer' }} onClick={()=>onClickEdit(AppData.labelList[2])}>{((clock2)&&(AppData.server=='remote')) ? 'VIEW' : clock2 ? 'EDIT' :''}</div>
                </FlexBox>
                 {/* CLK3 */}
                 <FlexBox fill="summaryBg" shape={clock3 ? "Expand" : "summaryView"} layout="vertical-top-left" spacing="summaryCard">
                 <FlexBox layout="horizontal-top-left"shape="Summaryedit" spacing="none">
                        <div style={{ height: "27px", padding: "0px", margin: '0px', fontSize: "18px", color: "#231F20", fontWeight: 700 }}>
                            {AppData.labelList[3]}
                        </div>
                        <FlexBox shape="Summaryedit" layout="horizontal-top-right" spacing="none">
                            <div onClick={clock3 ? () => setClock3(false) : () => setClock3(true)} style={{ fontSize: '14px', fontWeight: 400, color: '#3399D5', textDecorationLine: 'underline', cursor: 'pointer' }} >{clock3 ? 'COLLAPSE' : 'SEE MORE'}</div>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox layout={clock3 ? "vertical-top-left" : "horizontal-top-left"}  spacing="summaryGap" shape="hug" >
                        <FlexBox shape="hug" layout="horizontal-top-left" spacing="collapse"> {Object.entries(AppData.summaryData[3]).filter(([key, value], val) => ((val == 0)||(val == 2)||(val == 3)||(val == 1))).map(([key, value]) =>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0px', gap: "2px" }}>
                                <div style={{ width: '5.92vw', height: '18px', fontSize: "0.78125vw", fontWeight: 400, color: "#8E8D8A" }}>
                                    {`${key}`}
                                </div>
                                <div style={{ width: '5.92vw', height: '21px', fontSize: "0.9114583333333334vw", fontWeight: 400, color: '#231F20',wordWrap:"break-word"  }}>
                                {`${value==null?'':( value == 'Differential') ? `${AppData.signalList[3]}` : ( value == 'Single Ended (LVCMOS)') ? 'LVCMOS': value}`}
                                </div>
                            </div>
                        )}
                        </FlexBox>
                        {clock3 && <FlexBox shape="hug" layout="horizontal-top-left" spacing="collapse">{Object.entries(AppData.summaryData[3]).filter(([key, value], val) => ((val > 3) && (val <= 6))).map(([key, value]) =>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0px', gap: "2px" }}>
                                <div style={{ width: 'fit-content', height: '18px', fontSize: "0.78125vw", fontWeight: 400, color: "#8E8D8A" }}>
                                    {`${key}`}
                                </div>
                                <div style={{width: '5.92vw',height: '21px', fontSize: "0.9114583333333334vw", fontWeight: 400, color: '#231F20' }}>
                                    {`${value}`}
                                </div>
                            </div>
                        )}
                        </FlexBox>}
                        {clock3 && <FlexBox shape="hug" layout="horizontal-top-left" spacing="collapse">{Object.entries(AppData.summaryData[3]).filter(([key, value], val) => ((val > 6)||(val == 1))).slice(0).reverse().map(([key, value]) =>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0px', gap: "2px" }}>
                                <div style={{ width: '5.92vw', height: '18px', fontSize: "0.78125vw", fontWeight: 400, color: "#8E8D8A" }}>
                                    {`${key}`}
                                </div>
                                <div style={{  height: '21px', fontSize: "0.9114583333333334vw", fontWeight: 400, color: '#231F20' }}>
                                {( value == 'Single Ended (LVCMOS)') ? `${AppData.clkPDataList[3]+' ' +AppData.clkNDataList[3]}` : `${value}`}
                                </div>
                            </div>
                        )}
                        </FlexBox>}
                    </FlexBox>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#3399D5', textDecorationLine: 'underline', cursor: 'pointer' }} onClick={()=>onClickEdit(AppData.labelList[3])}>{((clock3)&&(AppData.server=='remote')) ? 'VIEW' : clock3 ? 'EDIT' :''}</div>
                </FlexBox>
            </FlexBox>
        </div>

    )
}
