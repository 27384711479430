import AppData from "../../../appData"

async function logOutEvent(onClickLogout:any,setavatarButton:any){
    AppData.api.deleteSession.bodyParams = {'session_id': AppData.User.session_id}
    try {
        await AppData.localServer.sendCommand(AppData.api.deleteSession, "Removing Invalid Session from local server failed.",1)
        await AppData.remoteServer.sendCommand(AppData.api.deleteSession, "Removing Invalid Session from remote server failed.",1) 
    }
    catch {
    }
    AppData.User.session_id=''
    setavatarButton('')
    onClickLogout()        
}

async function logOutShutDownEvent(username:string, onSigninSuccess:any,setsigninUser:any,setLoginwait:any,setalert:any){
}


export {logOutEvent,logOutShutDownEvent};