import AppData from '../../../appData'

async function fetchHistoryData(Selectrow:any){
    //Load history data for history screen
    const _sourceServer = (AppData.server == 'local') ? AppData.localServer : AppData.remoteServer
    AppData.api.setHead.bodyParams = {"step_id": Selectrow.step_id}
    await _sourceServer.sendCommand(AppData.api.setHead, "Setting head from local server failed.",1)
        
    AppData.api.initialyzeContext.bodyParams = {'configuration_id': AppData.currentConfig.udc_uuid,'user_details_id': AppData.User.data._id}
    await _sourceServer.sendCommand(AppData.api.initialyzeContext, "Setting the user config to work on config selection",1)
    AppData.api.historySearch.bodyParams = { 'udc_uuid': AppData.currentConfig.udc_uuid }
    const historyData =await _sourceServer.sendCommand(AppData.api.historySearch, `Getting config data from local server failed.`, 1)
    AppData.history.data.local = historyData
    console.log('head',historyData,AppData.history.data.local)
    return historyData
}
async function generateStates(){
    const _sourceServer = (AppData.server == 'local') ? AppData.localServer : AppData.remoteServer
    await _sourceServer.sendCommand(AppData.api.generateState, "Generating states and bringing the states to the head", 1)
}
export {fetchHistoryData,generateStates}