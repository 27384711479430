import React, { useState } from "react";
import AppData from "../../../appData";
import { Button } from "../../../components/button/button";
import { DataGrid } from "../../../components/dataGrid/dataGrid";
import Icons from "../../../icons/icons";
import AppTable from "../../appTable/appTable";
import { fetchHistoryData, generateStates } from "./historyEvents";
import { convertUTCDateToLocalDate } from "../configurationScreen/configLocalTable"
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";

interface propsIF {
  data: any[]
}
function HistoryTable({ data }: propsIF) {
  const [render, setrender] = useState(false)
  const [loader, setLoader] = useState(false)
  const [historydata, setHistoryData] = useState<any>(AppData.history.data.local)
  var columns = [{ width: "25vw", toolbar: false, header: "Name" },
  { width: "22vw", toolbar: false, header: "Field Name" },
  { width: "22vw", toolbar: false, header: "Field Value" },
  { width: "20.5vw", toolbar: false, header: "Date Edited" },]
  const col2 = (coldata: any) => {
    return (coldata.map((col: string, row: any) => (
      <div style={{ display: 'flex', gap: "8px", margin: '0px', padding: '0px', cursor: 'pointer' }} onClick={() => { return activeRow(row), setrender(!render) }}><Icons iconName={'flag'} size={'17px'} stroke='duplicate' fill={(historydata[row].enableColor) ? "flag" : "none"}  ></Icons><p style={{ fontWeight: "500", fontSize: "12px", width: "21vw", height: "30px", fontFamily: "Inter", margin: '0px', padding: '0px', wordWrap: "break-word" }}>{col}</p></div>
    )))
  }
  const col1 = (coldata: any) => {
    return (coldata.map((col: any) => (
      <p style={{ padding: "0px", margin: "0px", width: "20vw", wordWrap: "break-word" }}>{col}</p>))
    )
  };
  var Name: any[] = []
  var Field_Name: any[] = []
  var Field_Value: any[] = []
  var modified_date: any[] = []
  historydata.forEach((value: any) => { Name.push(typeof (value['reference']) === 'string' ? value['reference'] : JSON.stringify(value['reference'])) });
  historydata.forEach((value: any) => { Field_Name.push(value['name']) });
  historydata.forEach((value: any) => { Field_Value.push(value['value']) });
  historydata.forEach((value: any) => { modified_date.push(value['modified_date']) });
  const colum0 = Field_Name.map(x => (x))
  const colum1 = col2(Name.map(x => (x)))
  const colum2 = col1(Field_Value.map(x => (x)))
  const colum3 = col1(modified_date.map(x => (convertUTCDateToLocalDate(new Date(x + 'Z')).toLocaleString())))
  const displayData = colum0.map(function (e, i) {
    return [colum1[i], e, colum2[i], colum3[i]];
  });
  const setHead = async (steps: any) => {
    if (!AppData.currentConfig.frozen) {
      setLoader(true)
      try {
        const result = await fetchHistoryData(steps)
        setHistoryData(result)
        await generateStates()
        setLoader(false)
      }
      catch {
        setLoader(false)
      }
    }
  }
  const activeRow = (rowSelected: any) => {
    let historyData = AppData.history.data.local[rowSelected]
    setHead(historyData)
  }
  return (
    <>
      {loader && <LoadingSpinner shape={"medium"} stroke={"normal"} fill={"none"} innerfill={"loader"} speed={"1"} text={"Setting head.."} position={{ top: '40vh', left: '45vw' }} type={'circular'} disabled={false} ></LoadingSpinner>}
      <DataGrid Scroll={displayData.length > 9 ? true : false} data={historydata} columns={columns} displayData={displayData} shape='history' fill="table1" stroke="rowOnly" />
    </>
  )
}

export default HistoryTable