import React,{useState} from "react";
import { ThemeProvider } from "styled-components";
import AppData from "../../../appData";
import { DataGrid } from "../../../components/dataGrid/dataGrid";
import configJson from "../../../config.json"
import PrimaryTheme from "../../../Themes/primaryTheme";
import FlexBox from "../../../components/flexBox/flexBox";
import { initializeRealtimeConfig } from "./realtimeEvents";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";

interface PropIF{
    data:any[]
    Columns:any[]
    shape?: string
	fill?: string
	stroke?: string
    configData:any    
    selected?: any
}

function convertUTCDateToLocalDate(utcDate: any) {
	var newDate = new Date(utcDate);
	return newDate;
}

function RealtimeConfig({selected,data,Columns,shape,fill,stroke,configData}:PropIF){

var platform:any[]=[]
var variant:any[]=[]
var modified_date:any[]=[]
var name:any[]=[]
const [loader,setLoader] = useState(false)

data.forEach((value: any) => { return platform.push(value['platform']) })
data.forEach((value: any) => { return name.push(value['name']) })
data.forEach((value: any) => { return modified_date.push(value['modified_date']) })
data.forEach((value: any) => { return variant.push(value['variant']) })


const column=(coldata:any)=>{
    return(coldata.map((col:any)=>(
        <p style={{ padding: '0px', marginLeft:"0px", cursor: 'pointer' }}>{col}</p>
    )))
};

const activeRow=async(selectedRow:any)=>{
    console.log('selected',selectedRow,data[selectedRow])
    selected(true)
    AppData.currentConfig = data[selectedRow]
}

const colum0=column(platform.map(x=>(x)))
const colum1=column(platform.map((x,index)=>(index+1)))
const colum2=column(name.map(x=>(x)))
const colum3=column(variant.map(x=>(x)))
const colum4= column(modified_date.map(x => (convertUTCDateToLocalDate(new Date(x + 'Z')).toLocaleString())))

const displayData=colum0.map(function(e:any,i:any){
    return [colum1[i],colum2[i],colum3[i],colum4[i]]
});

return (
    <>
    {loader && <LoadingSpinner shape={"medium"} stroke={"normal"} fill={"none"} innerfill={"loader"} speed={"1"} text={"Loading Configuration..."} position={{ top: '40vh', left: '45vw' }} type={'circular'} disabled={false} ></LoadingSpinner>}
     <DataGrid Scroll={displayData.length>8?true:false}  data={data} displayData={displayData} columns={Columns} shape={shape} fill={fill} stroke={stroke} onClick={activeRow}/>
     </>
)

}
export default RealtimeConfig