import AppData from '../../../appData'
import { RestServer } from './../../../REST/axios'
import { ErrorIF } from '../../../appTypes'

async function syncDevice(row: any, onSync: any,setloader?: any) {
    let _result: any
    let result: any
    let deviceFound: any
    deviceFound = false
    try {
        // Down load device data from remote
        AppData.api.deviceSyncDownload.bodyParams = { device_id: row }
        _result = await AppData.remoteServer.sendCommand(AppData.api.deviceSyncDownload, "Getting device data from remote failed.", 1)
        // Upload device data to local
        AppData.api.deviceSyncUpload.bodyParams = { device: _result }
        await AppData.localServer.sendCommand(AppData.api.deviceSyncUpload, "Uploading device data to local server failed.", 1)
        // check if the device is in current user in local
        AppData.api.userSearch.bodyParams = { 'username': AppData.User.username }
        result = await AppData.localServer.sendCommand(AppData.api.userSearch, "Getting User details from local failed.", 1)
        if (result.length != 0) {
            for (const [key, value] of Object.entries(result)) {
                deviceFound = false
                for (const [Skey, Svalue] of Object.entries(result[key].devices)) {
                    if (Svalue == row){
                        deviceFound = true    
                    }
                    console.log(Svalue)
                }
            }
            if(!deviceFound){
                // update device to the user
                const newList = result[0].devices.concat(row);
                AppData.api.modifyUserDeviceDetails.bodyParams = { _id: result[0]._id,devices:newList  }
                await AppData.localServer.sendCommand(AppData.api.modifyUserDeviceDetails, "Modify user devices to local server failed.", 1)
            }
        }
        // Update the local device data
        AppData.api.userDeviceSearch.bodyParams = { 'username': AppData.User.username }
        var resDevices = await AppData.localServer.sendCommand(AppData.api.userDeviceSearch, `Getting device data from local server failed.`, 1)
        for (const [key, value] of Object.entries(resDevices)) {
            AppData.device.data.local = resDevices[key].devices
        }
        onSync(AppData.device.data.local)
        return true;
    }
    catch{
        setloader(false)
    }

}

export { syncDevice }