import React, { useEffect, useState } from "react";
import AppData from "../../../appData";
import { Button } from "../../../components/button/button";
import { DataGrid } from "../../../components/dataGrid/dataGrid";
import DialogBox from "../../../components/dialogBox/dialogBox";
import FlexBox from "../../../components/flexBox/flexBox";
import GridContainer from "../../../components/gridContainer/gridContainer";
import Icons from "../../../icons/icons";
import Logo from "../../logo/logo";
import ConfigurationScreen from "../configurationScreen/configurationScreen";
import DeviceScreen from "../deviceScreen/deviceScreen";
import { configurationSearch } from "../blockDiagramScreen/blockDiagramEvents";
import { Menu } from "../../../components/menu/menu";
import { logOutEvent } from "./logoutEvent";
import { Server, ServerDevice } from "../../../appEvents";
import configData from "../../../config.json";
import configJson from "../../../config.json"
import RealtimeScreen from "../realtimeScreen/realtimeScreen";
import { SummaryView, getBlockDiagramData } from "../configurationScreen/configurationEvents";
interface propsIF {
    onclickConfig: () => void
    onClickLogout: () => void
    Config: any
}
function ConfigurationDeviceScreen({ onclickConfig,onClickLogout,Config }: propsIF) {
    const [configuration, setconfiguration] = useState(Config)
    const [device,setDevice] = useState(false)
    const [realtime,setRealtime] = useState(false)
    const [configdata, setConfigData] = useState(AppData.userConfig.data.local)
    const [remoteConfigdata, setRemoteConfigData] = useState(AppData.userConfig.data.remote)
    const [recentdata, setRecentData] = useState(AppData.userConfig.data.recent)
    const [online, setonline] = useState<boolean>(window.navigator.onLine)
    const [show,setShow] = useState<boolean>(false)
    const [active,setActive]=useState(false)
    const [showMessage,setShowMessage] = useState(false)
    const [message,setMessage] = useState('')

    let [state, setState] = useState("Initial");
    function handleState(newValue: any) {
        setState(newValue);
    }

    const lostfocus = () => {
		setShow(false);
	}

    const onclickDevice = () => {
        if(!AppData.connected){AppData.connectedDevice = '';AppData.protocol = ''}
        return setDevice(true),setconfiguration(false),setRealtime(false), setConfigData(AppData.userConfig.data.local), setRecentData(AppData.userConfig.data.recent), setRemoteConfigData(AppData.userConfig.data.remote)
    }
    const onClickConfigure = () => {
        configurationSearch(setConfigData, setRemoteConfigData, setRecentData)
        setDevice(false)
        setRealtime(false)
        if(!AppData.connected){AppData.connectedDevice = '';AppData.protocol = ''}
        return setconfiguration(true), AppData.block = false
    }
    const onclickRealtime = () =>{
        console.log('realtime')
        if(!AppData.connected){AppData.connectedDevice = '';AppData.protocol = ''}
        AppData.devicelist = []
        AppData.device.data.local.map((devicename: any) => {
            return (
                AppData.devicelist.push(devicename['variant'])
            )
        })
        return setRealtime(true),setconfiguration(false),setDevice(false)
    }
    if(configData.WEB_ONLY == 0){
        var server=Server() 
   
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            server.then((value:any)=>{
                try{
                    if(value['message']==='hello user'){
                        return setActive(false)
                    }else{
                        setActive(true)
                    }
                }catch{
                    setActive(true)
                }})
        },[server]);
    }
    else{
        var serverDevice=ServerDevice() 
   
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            serverDevice.then((value:any)=>{
                try{
                    if(value['message']==='hello user'){
                        console.log("Device Server is running")
                    }else{
                        console.log("Device Server is not running")
                    }
                }catch{
                    console.log("Device Server is not running")
                }})
        },[serverDevice]);
    }

    // Register the event listeners
    useEffect(() => {
        window.addEventListener('online', () => setonline(true));
        window.addEventListener('offline', () => setonline(false));

        // cleanup if we unmount
        return () => {
            window.removeEventListener('offline', () => setonline(false));
            window.removeEventListener('online', () => setonline(true));
        }
    }, []);
    const logout = () => {
        logOutEvent(onClickLogout,setShow)
        AppData.block = false
    }
    return (
        <GridContainer shape="configaration" layout="configaration">
            <div style={{ width: '100vw', padding: '0px', margin: '0px', position: 'fixed', zIndex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#595A5A' }}>
                <FlexBox gridName="a" shape="configurationMain" fill="configurationMain" spacing="configurationMain" layout="horizontal-center-left">
                <DialogBox shape="server" Buttoncontent={[]} position='middle-center' visible={active} text={'Please check the server and refresh the application'} spacing="server" fill="dialog" font="server" hide={setActive} ></DialogBox>
                    <FlexBox shape="header1" spacing="header" layout="horizontal-center-left">
                        <div style={{ height: "25.77px", width: '87px' }}><Logo /></div>
                        <FlexBox shape="header" spacing="header2" layout="horizontal-center-left">
                            <Button shape="configaration" fill='transparent' spacing="AllConfigurations" color={(configuration == true) ? 'signin' : 'color'} onClick={onClickConfigure}>
                                <div style={{ height: '100%', margin: '0px', fontSize: '14px', fontFamily: 'Ubuntu', padding: "0px", justifyContent: 'center', alignItems: 'center', display: 'flex' }}>CONFIGURATIONS</div>
                            </Button>
                            <Button shape="device" fill='transparent' spacing="AllConfigurations" color={(device == true) ? 'signin' : 'color'} onClick={onclickDevice}>
                                <div style={{ height: "100%", padding: "0px", margin: '0px', fontSize: '14px', fontFamily: 'Ubuntu', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>DEVICES</div>
                            </Button>
                            <Button shape="reatime" fill='transparent' spacing="AllConfigurations" color={(realtime == true) ? 'signin' : 'color'} onClick={onclickRealtime}>
                                <div style={{ height: "100%", padding: "0px", margin: '0px', fontSize: '14px', fontFamily: 'Ubuntu', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>REALTIME</div>
                            </Button>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox shape="header2" layout="horizontal-center-right" spacing="remote">
                        <div style={{ margin: "0px 0px 0px 0px" }}>
                            {AppData.connected ?
                                <div style={{ color: "#56BA64", height: "26px", width: '155px', borderRadius: "100px", background: "#28FF461A", padding: "2px 8px 2px 8px", display: 'flex', gap: "8px", justifyContent: 'center', alignItems: "center" }}><p style={{ fontSize: "14px", fontWeight: "500", fontFamily: "Inter", margin: "0px" }}>Device Connected</p></div> :
                                <div style={{ color: "#FF6161", height: "26px", width: '155px', borderRadius: "100px", background: "#FF282826", padding: "2px 8px 2px 8px", display: 'flex', gap: "8px", justifyContent: 'center', alignItems: "center" }}><p style={{ fontSize: "14px", fontWeight: "500", fontFamily: "Inter", margin: "0px" }}>Device Disconnected</p></div>}
                        </div>
                       {(configJson.WEB_ONLY===0)&& <div style={{ margin: "0px 0px 0px 0px" }}>
                            {online ?
                                <div style={{ color: "#56BA64", height: "26px", width: '65px', borderRadius: "100px", background: "#28FF461A", padding: "2px 8px 2px 8px", display: 'flex', gap: "8px", justifyContent: 'center', alignItems: "center" }}><p style={{ height: '9px', width: '9px', background: '#56BA64', borderRadius: '100px', padding: '0px', margin: '0px' }}></p><p style={{ fontSize: "14px", fontWeight: "500", fontFamily: "Inter", margin: "0px" }}>Online</p></div> :
                                <div style={{ color: "#FF6161", height: "26px", width: '65px', borderRadius: "100px", background: "#FF282826", padding: "2px 8px 2px 8px", display: 'flex', gap: "8px", justifyContent: 'center', alignItems: "center" }}><p style={{ height: '9px', width: '9px', background: 'red', borderRadius: '100px', padding: '0px', margin: '0px' }}></p><p style={{ fontSize: "14px", fontWeight: "500", fontFamily: "Inter", margin: "0px" }}>Offline</p></div>}
                        </div>}
                        <div style={{display:'flex',flexDirection:'column'}} onMouseLeave={lostfocus}>
                        <Button shape="profile" fill="freeze" onClick={()=>setShow(!show)}> {AppData.User.data.avatar_initials}
                        </Button>
                        <div onMouseLeave={lostfocus} >
                            {show && (
                                <Menu hide={setShow} spacing='local' shape='blockdiagram' fontSize='local' effects='outerShadow' fill='local' data={[
                                    { name: 'Logout', icon: "logout", tooltip: 'Logout', onclick: () => { logout()} },]} visible={show} />
                            )}
                        </div>
                        </div>
                    </FlexBox>
                </FlexBox>
            </div>
            <FlexBox gridName="b" shape="mainscreen" spacing="none" layout="vertical-top-left">
                {(configuration == true) ? <ConfigurationScreen setShowMessage={showMessage} MessageText={message} remotetabledata={remoteConfigdata} localtabledata={configdata} recentData={recentdata} /> : (device == true) ?<DeviceScreen /> :  <RealtimeScreen message={(value:any,message:any)=>{return setMessage(message),setShowMessage(value)}} block={(value:any)=>{ return setconfiguration(true),setRealtime(false)}} change = {handleState}></RealtimeScreen>}
            </FlexBox>

        </GridContainer>
    )
}
export default ConfigurationDeviceScreen