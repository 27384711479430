import React, { useEffect, useState } from "react"
import {Input,Checkboxstyle} from './checkBoxStyles'
interface PropsIF{
    values: { name: string, key: string }[];
    onClick?: (item: any) => void;
    select?: "multi" | "single";
    disabled?:boolean
    initialState?:boolean
}
function Checkbox({values,select, onClick=(clicked:any)=>{},disabled=false,initialState=false}:PropsIF){
    // for select option multi or single
    const [checked, setChecked] = useState(initialState);
    useEffect(() => { setChecked(initialState); }, [initialState])

    const handleClick = (e: any) => { 
        if (!disabled) {
            setChecked(!checked);
            console.log('check',!checked)
            onClick(!checked)
            
        }
    }
  return (
    <Input disabled={disabled}>
        <Checkboxstyle type="checkbox" checked={checked} onClick={handleClick} /> 
    </Input>
  );
}
export default Checkbox;